export const HoverSvg: React.FC<{ color: React.CSSProperties["color"] }> = ({
  color,
}) => {
  return (
    <svg
      width="543"
      height="376"
      viewBox="0 0 543 376"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.9164 14.6389C57.208 -5.68769 150 2.7817 250.297 5.04021C336.948 0.523191 492.51 -7.0428 514.344 14.6389C541.635 41.741 536.859 127 536.859 162.571C536.859 198.143 543 296.952 543 317.279C543 337.605 504.109 369.225 449.526 369.225C391.853 369.225 341.724 376 259.849 376C177.974 376 109.834 367.466 93.3699 369.225C29.9164 376 -11.0211 315.02 2.62508 290.741C16.2713 266.462 5.35439 134.904 2.62522 110.061C-0.103943 85.2172 2.62471 34.9654 29.9164 14.6389Z"
        fill={color}
      />
    </svg>
  );
};
