import React from "react";

export const BookSvg: React.FC<{ width?: string; height?: string }> = ({
  width,
  height,
}) => {
  return (
    <svg
      width={width ? width : "140"}
      height={height ? height : "130"}
      viewBox="0 0 140 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="75" cy="65" r="65" fill="#A2DD8A" />
      <path
        d="M57.9401 28.4296C53.9401 20.9996 45.0001 17.8996 36.7001 16.4296C30.2901 15.3196 23.6401 14.8196 17.3401 16.4296L20.15 79.2396C27.4196 77.2512 35.1603 77.9248 41.9772 81.1388C48.794 84.3529 54.2388 89.8962 57.3301 96.7696C58.9001 93.2696 61.78 90.5196 64.89 88.2696C74.4541 81.4273 86.3217 78.6121 97.9401 80.4296C99.5701 58.4296 101.21 36.4296 101.06 14.3296C92.2682 13.3916 83.3781 14.4619 75.0601 17.4596C67.4601 20.2096 60.37 20.6596 57.99 28.3796C57.09 31.3096 57.63 38.8096 57.49 41.8696C56.86 55.3796 56.6 60.5396 57.49 75.4196"
        stroke="#FFECD2"
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.62 28.6792C3.3295 29.1998 3.20069 29.7951 3.25 30.3892C2.75 53.8959 3 77.4059 4 100.919C5.79 96.6992 10.74 94.9192 15.25 94.0792C22.8644 92.781 30.6651 93.0721 38.1615 94.9342C45.6579 96.7963 52.6881 100.189 58.8101 104.899C65.4001 98.0992 75.04 94.6592 84.5 94.7492C93.96 94.8392 103.22 98.2492 111.11 103.489C112.37 78.7592 115.01 52.6492 113.11 27.9492"
        stroke="#FFECD2"
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
