import { Box, Grid, Typography, styled } from "@mui/material";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { theme } from "../../../../theme";
import prettyLoudBackground from "../../assets/pretty-loud.jpg";
import { InterviewQuestion } from "../InterviewQuestion";
import { InterviewAnswer } from "../InterviewAnswer";
import { ChatSvg } from "../../assets";
import { CultureFooter } from "../CultureFooter";
import { useIsLgScreen } from "../../../../hooks/useIsLgScreen";

const StyledPhotographer = styled(Typography)(({ theme }) => ({
  position: "absolute",
  bottom: 30,
  left: 30,

  color: theme.palette.yellow.regular,
  fontFamily: "'Inter', 'sans-serif'",
  lineHeight: "130%",
  fontWeight: 400,
}));

export const PrettyLoudPage: React.FC = () => {
  const isMobile = useIsMobile();
  const isLgScreen = useIsLgScreen();

  return (
    <>
      <Grid container sx={{ marginTop: isMobile ? "-155px" : "-12px" }}>
        {!isMobile ? (
          <>
            <Grid
              item
              sx={(theme) => ({
                backgroundColor: theme.palette.errors.disabled,
                paddingLeft: isLgScreen ? 22 : 30,
                paddingTop: isLgScreen ? 3 : 10,
                paddingRight: 10,
                paddingBottom: isLgScreen ? 3 : 10,
                minHeight: isLgScreen ? "584px" : "734px",
              })}
              md={6}
            >
              <ChatSvg
                color={theme.palette.yellow.regular}
                backgroundColor={theme.palette.errors.regular}
                width={isLgScreen ? "100" : "155"}
              />
              <Box mt={2} />
              <Box sx={{ maxWidth: "507px" }}>
                <Typography
                  variant="h3"
                  sx={(theme) => ({
                    color: theme.palette.yellow.regular,
                    fontWeight: 700,
                    lineHeight: "100%",
                  })}
                >
                  INTERVJU:
                  <br /> PRETTY LOUD
                </Typography>

                <Box mt={isMobile ? 3 : 5} />
              </Box>
              <Typography
                variant={isLgScreen ? "subtitle1" : "h6"}
                sx={(theme) => ({
                  maxWidth: isLgScreen ? "320px" : "420px",
                  color: theme.palette.yellow.regular,
                  fontFamily: "'Inter', 'sans-serif'",
                  lineHeight: "130%",
                  fontWeight: 400,
                })}
              >
                Pretty Loud je prvi ženski romski hip-hop bend na svetu. Ovih
                šest devojaka su svoj umetnički put započele u Beogradu, a
                njihova upadljiva energija i pesme koje nose snažnu poruku vrlo
                brzo su očarale čitavu Srbiju. Upravo zato, odlučili smo da Ivi,
                Zlati i Elmi postavimo nekoliko pitanja.
              </Typography>
            </Grid>

            <Grid
              item
              md={6}
              sx={{
                backgroundImage: `url(${prettyLoudBackground})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: "100%",
                position: "relative",
              }}
            >
              <StyledPhotographer variant="subtitle2">
                foto Nada Zgank
              </StyledPhotographer>
            </Grid>
          </>
        ) : (
          <Grid
            item
            sx={{
              backgroundImage: `url(${prettyLoudBackground})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: "100%",
              position: "relative",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Box
              sx={{
                alignContent: "center",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Box sx={{ ml: 6, mr: 6, mt: 10 }}>
                <ChatSvg
                  width="90"
                  color={theme.palette.yellow.regular}
                  backgroundColor={theme.palette.errors.regular}
                />
                <Box mt={isMobile ? 3 : 5} />

                <Typography
                  variant="h2"
                  sx={(theme) => ({
                    color: theme.palette.yellow.regular,
                    fontWeight: 700,
                  })}
                >
                  INTERVJU:
                  <br /> PRETTY LOUD
                </Typography>

                <Box mt={isMobile ? 8 : 5} />
              </Box>
              <Typography
                variant="h5"
                sx={(theme) => ({
                  color: theme.palette.yellow.regular,
                  ml: 6,
                  mr: 6,
                  mb: 5,
                  fontWeight: 400,
                  fontFamily: "'Inter', 'sans-serif'",
                  lineHeight: "130%",
                  textAlign: "left",
                  position: "relative",
                })}
              >
                Pretty Loud je prvi ženski romski hip-hop bend na svetu. Ovih
                šest devojaka su svoj umetnički put započele u Beogradu, a
                njihova upadljiva energija i pesme koje nose snažnu poruku vrlo
                brzo su očarale čitavu Srbiju. Upravo zato, odlučili smo da Ivi,
                Zlati i Elmi postavimo nekoliko pitanja.{" "}
                <Typography
                  variant="body2"
                  sx={{ marginTop: 5, fontFamily: "'Inter', 'sans-serif'" }}
                >
                  foto Nada Zgank
                </Typography>
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
      <Box
        justifyContent={"center"}
        display={"flex"}
        alignContent={"center"}
        alignItems={"center"}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            backgroundColor: theme.palette.yellow.regular,
            width: "100%",
            paddingBottom: 10,
            paddingLeft: isMobile ? 1 : 10,
            paddingRight: isMobile ? 1 : 10,
          })}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              maxWidth: isLgScreen ? "760px" : isMobile ? "98%" : "950px",
              paddingTop: isMobile ? 5 : 8,
            }}
          >
            <Box>
              <InterviewQuestion question="Šta po vama čini dobru pop i hip-hop pesmu?" />
              <InterviewAnswer
                hasAvatarImg={false}
                backgroundColor={theme.palette.errors.disabled}
                interviewee="Elma"
                intervieweeColor={theme.palette.errors.regular}
                answer="Mislim da je za dobru pesmu najvažnija dobra poruka, odnosno kada se u pesmi priča o onome o čemu se ne priča svakodnevno. To u poslednje vreme retko čujem, te takoreći pametne pesme (hahah). Naravno važan je i dobar ritam, bilo da se repuje ili da se peva."
              />
              <InterviewAnswer
                hasAvatarImg={false}
                backgroundColor={theme.palette.green.disabled}
                interviewee="Zlata"
                intervieweeColor={theme.palette.green.regular}
                answer="Važno je da pesmu razumeju svi i rep je dobar za to, jer možemo da iskažemo svoja osećanja kroz njega."
                imgSrc={[
                  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/pretty-loud-1.png",
                ]}
                photographer="foto Alessandro Gandolfi"
              />

              <InterviewQuestion question="Kada ste počele da se bavite pevanjem i kako ste otkrile svoju ljubav prema muzici?" />
              <InterviewAnswer
                hasAvatarImg={false}
                backgroundColor={theme.palette.green.disabled}
                interviewee="Zlata"
                intervieweeColor={theme.palette.green.regular}
                answer="Sve je počelo 2014. godine. GRUBB fondacija nam je dala priliku da napravimo bend kroz koji možemo da iskažemo šta mislimo. Tada smo bile dosta mlađe i neiskusne, imale smo veliku želju da pokrenemo bend, a sada posle toliko godina imamo veliku želju da promenimo društvo na bolje - jer smo kao Romkinje duplo diskriminisane: i kao žene i kao pripadnice romske zajednice. To je ono što nam je trenutno najvažnije i što nas pokreće. To, kao i ljubav prema muzici. Takođe, živimo u istoj mahali, od malena se družimo i poznajemo jer smo komšinice."
              />
              <InterviewAnswer
                hasAvatarImg={false}
                backgroundColor={theme.palette.blue.disabled}
                interviewee="Iva"
                intervieweeColor={theme.palette.blue.regular}
                answer="Na samom početku dosta su nam pomogle kolege iz Roma Sijam, koji su mnogo iskusniji i upoznatiji sa muzikom i repom, dosta smo naučili od njih."
                imgSrc={[
                  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/pretty-loud-2.png",
                ]}
                photographer="foto Alessandro Gandolfi"
              />
              <InterviewQuestion question="Kako pišete svoje tekstove i kako birate teme za njih? Šta vas inspiriše za muziku, ples i tekstove?" />
              <InterviewAnswer
                hasAvatarImg={false}
                backgroundColor={theme.palette.blue.disabled}
                interviewee="Iva"
                intervieweeColor={theme.palette.blue.regular}
                answer="Mi, kao neko ko se bori sa predrasudama i diskriminacijom, gledamo da budemo drugim ženama i Romkinjama podrška i da u našim pesmama neko može da se pronađe. Ono što je kod nas očigledno jeste da Romi vole muziku i samim tim jako nam je važan dobar ritam, posle toga razmišljamo o čemu ćemo repovati i pevati. Sve same radimo i muziku i tekst, kao i koreografiju za ples. Kroz sve to možeš da iskažeš ono što osećaš – pevanjem, repovanjem ili kroz pokret."
              />
              <InterviewAnswer
                hasAvatarImg={false}
                backgroundColor={theme.palette.green.disabled}
                interviewee="Zlata"
                intervieweeColor={theme.palette.green.regular}
                answer="Ono što mi pišemo volela bi da čujem da pišu i drugi, važno mi je da osetim tekst i da se pronađem u njemu."
                imgSrc={[
                  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/pretty-loud-3.png",
                ]}
                photographer="foto Alessandro Gandolfi"
              />
              <InterviewQuestion question="Koje izazove ste imale pri pravljenju benda i da li je teško održati jedan ovakav bend?" />
              <InterviewAnswer
                hasAvatarImg={false}
                backgroundColor={theme.palette.green.disabled}
                interviewee="Zlata"
                intervieweeColor={theme.palette.green.regular}
                answer="Nas šest smo sve drugačije na svoj način i to je ono što jeste izazovno, ali ono što nam pomaže je da se dobro razumemo. Ono što nas drži zajedno je da imamo isti cilj. To da se sve borimo za istu stvar je ono što nas drži zajedno, a povezuje nas i zajednička ljubav prema muzici. Mi nismo klasičan bend koji samo repuje, peva i pleše, već mi šaljemo važnu poruku da nije sve crno i da ne mora sve da se hejtuje."
              />
              <InterviewAnswer
                hasAvatarImg={false}
                backgroundColor={theme.palette.blue.disabled}
                interviewee="Iva"
                intervieweeColor={theme.palette.blue.regular}
                answer="Ja sam na početku imala problem samo sa bakom i dekom, jer mi nisu dali da idem da plešem, pevam i repujem dok ne završim školu sa dobrim ocenama. To mi je na početku u isto vreme bila i prepreka i motivacija. Morala sam da dođem kući sa dobrim ocenama da bi mogla da idem u GRUBB da radim ono što volim."
              />
              <InterviewAnswer
                hasAvatarImg={false}
                backgroundColor={theme.palette.errors.disabled}
                interviewee="Elma"
                intervieweeColor={theme.palette.errors.regular}
                answer="Trebalo im je nešto vremena da shvate da to nije bilo gubljenje vremena. Sada smo to i dokazale. (hahah)"
                imgSrc={[
                  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/pretty-loud-4.png",
                ]}
                photographer="foto Milenko Savović Artera doo i Slobodan Vukajlović Bus Plus"
              />
              <InterviewQuestion question="Koliko je muzika važna u borbi sa siromaštvom, predrasudama i diskriminacijom u društvu?" />
              <InterviewAnswer
                hasAvatarImg={false}
                backgroundColor={theme.palette.blue.disabled}
                interviewee="Iva"
                intervieweeColor={theme.palette.blue.regular}
                answer="Smatram da će naše generacije pre poslušati pesmu nego nešto pročitati, prosto ih muzika više privlači jer je zanimljivija. Tako da smo mi odlučile da se muzikom bavimo na naš način jer sve volimo muziku i osećamo da možemo da ispričamo našu priču kroz rep i da je otpevamo. Muzika nema granice jer si u muzici slobodan. Takođe, muzika usrećuje i opušta tako da je ona dobar način da iskažemo bitnu poruku i kažemo ono do čega nam je stalo."
              />
              <InterviewQuestion question="Šta su vam planovi za budućnost i šta planirate?" />
              <InterviewAnswer
                hasAvatarImg={false}
                backgroundColor={theme.palette.green.disabled}
                interviewee="Zlata"
                intervieweeColor={theme.palette.green.regular}
                answer="Planiramo nastupe i planiramo spot za novu pesmu. Uskoro sviramo u Kučevu, što nas raduje jer je to prvi put da smo dobile poziv da nastupimo u manjem mestu izvan Beograda. Naš cilj jeste da dođemo i do publike iz manjih mesta po Srbiji."
              />
              <InterviewAnswer
                hasAvatarImg={false}
                backgroundColor={theme.palette.errors.disabled}
                interviewee="Elma"
                intervieweeColor={theme.palette.errors.regular}
                answer="Za sledeću godinu imamo velike planove, pa nas zapratite na Instagramu na pretty.loud da biste bili u toku!"
                imgSrc={[
                  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/pretty-loud-5.png",
                ]}
                photographer="foto Milenko Savović Artera doo i Slobodan Vukajlović Bus Plus"
              />
              <InterviewQuestion question="Koji savet biste dale mladima koji žele da počnu da pišu svoje tekstove? O čemu vredi pisati?" />
              <InterviewAnswer
                hasAvatarImg={false}
                backgroundColor={theme.palette.blue.disabled}
                interviewee="Iva"
                intervieweeColor={theme.palette.blue.regular}
                answer="Inspiraciju možete da nađete u sebi. Sve ono što držite u sebi izbacite na papir jer kroz pisanje i muziku možete da ispoljite ono što osećate. Morate da pišete ono što se vama prvenstveno sviđa."
              />
              <InterviewAnswer
                hasAvatarImg={false}
                backgroundColor={theme.palette.errors.disabled}
                interviewee="Elma"
                intervieweeColor={theme.palette.errors.regular}
                answer="Prava emocija se uvek oseti. Možete napisati jedan jak tekst, a možda i napravite hit, ko zna!"
              />
              <InterviewQuestion question="Koji savet biste dale mladima koji žele da se bave muzikom i koji imaju ambiciju da postanu uspešni muzičari?" />
              <InterviewAnswer
                hasAvatarImg={false}
                backgroundColor={theme.palette.green.disabled}
                interviewee="Zlata"
                intervieweeColor={theme.palette.green.regular}
                answer="Nikada ne treba odustati! Ja sam svoj talenat i ljubav prema repovanju otkrila tek kada sam probala da repujem. Nikad ne znaš šta sve zapravo znaš dok ne probaš! Uvek treba probati, ja sam ušla u priču sa idejom da plešem, a počela sam da repujem. Ako probaš i ne uspeš – i to je okej, ali ako nešto voliš onda se boriš i na kraju se izboriš. Eto sada imam bend, a nisam ni sanjala da će do toga doći."
              />
              <InterviewAnswer
                hasAvatarImg={false}
                backgroundColor={theme.palette.blue.disabled}
                interviewee="Iva"
                intervieweeColor={theme.palette.blue.regular}
                answer="Ja na početku nisam mogla ni da sanjam da ću da repujem, a kamoli da ću da budem profesorka repovanja deci. Morate da radite na sebi i da se trudite, tako se dobija samopouzdanje što je jako važno. Dosta stvari se nalazi na internetu odakle možemo da naučimo puno toga. Smatram da svako može da nađe svoj talenat i da uspe u životu!"
              />
              <InterviewAnswer
                hasAvatarImg={false}
                backgroundColor={theme.palette.errors.disabled}
                interviewee="Elma"
                intervieweeColor={theme.palette.errors.regular}
                answer="Slažem se, koliko je bitan talenat, toliko je bitan i trud. Ja sam se dosta poboljšala i što se tiče muzike, a i što se tiče karaktera. Trud je ključna stvar za uspeh, ja sada učim neke tehnike pevanja npr. kako da se ne umaram. Tako da znajte, uvek ima nešto da se uči!"
              />
              <InterviewQuestion
                question="Zahvaljujemo se Svi UGLAS! :)<br/>Ispod poslušajte novu pesmu Jag Tabilji, i zapratite Pretty Loud na društvenim mrežama<br/><br/>FACEBOOK <a href='https://www.facebook.com/prettyloudmusic/' target='_blank'>@prettyloudmusic</a><br/>INSTAGRAM: <a href='https://www.instagram.com/pretty.loud/' target='_blank'>@pretty.loud</a>"
                videoId="_0M3iINIk6M"
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <CultureFooter />
    </>
  );
};
