import { Box, Button, styled } from "@mui/material";
import React from "react";
import { useRecordingConsentForm } from "../../../state/useRecordingConsentForm";
import * as ReactDOMServer from "react-dom/server";
import { ShortButtonBackgroundSvg } from "../../../../../menuHovers";
import { theme } from "../../../../../theme";
import { ArrowRightSvg } from "../../../../quiz/images/ArrowRightSvg";
import { ArrowLeftSvg } from "../../../../quiz/images/ArrowLeftSvg";
import { DownloadSvg } from "../assets";
import { useMediaRecorder } from "../../../state/useMediaRecorder";
import { useSupportedMimeType } from "../../../state";
import { useIsMobile } from "../../../../../hooks/useIsMobile";
import { useMergeVideos } from "../../../state/useMergeVideos";
import { useIsLgScreen } from "../../../../../hooks/useIsLgScreen";

const StyledButton = styled(Button)<{
  background: string;
  hover: string;
  isMobile: boolean;
  isLgScreen: boolean;
}>(({ theme, background, hover, isMobile, isLgScreen }) => ({
  backgroundImage: `url("data:image/svg+xml,${background}")`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  width: isMobile ? "90px" : isLgScreen ? "95px" : "114px",
  height: isMobile ? "33px" : isLgScreen ? "37px" : "41px",
  color: theme.palette.yellow.regular,
  fontWeight: 700,
  fontSize: isMobile ? "12px" : "",
  "&:hover": {
    backgroundImage: `url("data:image/svg+xml,${hover}")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
}));
export interface HorizontalLinearStepperActionsProps {
  activeStep: number;
  stepsLength: number;
  handleNext(): void;
  handleBack(): void;
}

export const HorizontalLinearStepperActions: React.FC<
  HorizontalLinearStepperActionsProps
> = ({ activeStep, stepsLength, handleNext, handleBack }) => {
  const recordingConsentForm = useRecordingConsentForm();
  const mediaRecorder = useMediaRecorder();
  const isMobile = useIsMobile();
  const { videoExtension } = useSupportedMimeType(isMobile);
  const mergedVideo = useMergeVideos();
  const isLgScreen = useIsLgScreen();

  const background = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      <ShortButtonBackgroundSvg color={theme.palette.green.disabled} />
    )
  );

  const hover = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      <ShortButtonBackgroundSvg color={theme.palette.green.regular} />
    )
  );

  const download = () => {
    const blob = base64ToBlob(mergedVideo.base64Video!, "video/mp4");
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "svi-uglas-" + Date.now() + "." + videoExtension;
    a.style.display = "none";
    a.setAttribute("sandbox", "allow-downloads");
    document.body.appendChild(a);
    a.click();
  };

  function base64ToBlob(base64: any, mimeType: any) {
    const binaryString = window.atob(base64);
    const byteNumbers = new Array(binaryString.length);

    for (let i = 0; i < binaryString.length; i++) {
      byteNumbers[i] = binaryString.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        pt: 0,
        marginTop: "auto",
      }}
    >
      {activeStep !== 0 && (
        <StyledButton
          color="inherit"
          onClick={handleBack}
          background={background}
          hover={hover}
          isMobile={isMobile}
          startIcon={<ArrowLeftSvg width={isMobile ? 15 : 20} />}
          isLgScreen={isLgScreen}
        >
          NAZAD
        </StyledButton>
      )}
      <Box sx={{ flex: "1 1 auto" }} />

      {activeStep !== stepsLength - 1 && (
        <StyledButton
          onClick={handleNext}
          disabled={activeStep === 1 && !recordingConsentForm.isAccepted}
          background={background}
          hover={hover}
          isMobile={isMobile}
          isLgScreen={isLgScreen}
          endIcon={
            <ArrowRightSvg
              width={isMobile ? 15 : 20}
              color={
                activeStep === 1 && !recordingConsentForm.isAccepted
                  ? "#FFECD24D"
                  : null
              }
            />
          }
        >
          NAPRED
        </StyledButton>
      )}
      {mediaRecorder.recordedVideo &&
        mediaRecorder.isRecordingCompleted &&
        activeStep === 2 && (
          <>
            <StyledButton
              sx={{ mr: isMobile ? 12 : isLgScreen ? 10 : 18 }}
              onClick={download}
              background={background}
              hover={hover}
              startIcon={<DownloadSvg width={isMobile ? 15 : 20} />}
              isMobile={isMobile}
              disabled={!mergedVideo.isMerged}
              isLgScreen={isLgScreen}
            >
              PREUZMI
            </StyledButton>
          </>
        )}
    </Box>
  );
};
