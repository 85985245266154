export const MusicalNoteSvg: React.FC<{
  backgroundColor: React.CSSProperties["color"];
  width?: string;
}> = ({ backgroundColor, width }) => {
  return (
    <svg
      width={width ? width : "130"}
      height="156"
      viewBox="0 0 130 156"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="65" cy="91" r="65" fill={backgroundColor} />
      <path
        d="M95.7842 96.5C88.3742 86.85 75.6542 82.6 63.4942 82.23C57.0542 82.03 50.4342 82.81 44.7042 85.75C38.9742 88.69 34.2342 94.05 33.2042 100.42C32.1042 107.24 35.5442 114.42 41.2042 118.42C46.8642 122.42 54.2742 123.51 61.0142 121.97C64.442 121.284 67.6207 119.684 70.2142 117.34C75.8242 111.95 76.2142 103.18 75.3742 95.45C71.8642 63.45 53.7542 34.7 48.0442 3C53.4542 10.5 61.9042 15.11 70.0442 19.42C78.1842 23.73 86.6942 28.24 92.2742 35.61C97.8542 42.98 99.6142 54.13 93.7442 61.28"
        stroke="#FFECD2"
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
