import { Grid } from "@mui/material";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { useIsLgScreen } from "../../../hooks/useIsLgScreen";

export interface VideoBackgroundGridProps {
  videoSrc: string;
  children: React.ReactNode;
}

export const VideoBackgroundGrid: React.FC<VideoBackgroundGridProps> = ({
  videoSrc,
  children,
}) => {
  const isMobile = useIsMobile();
  const isLgScreen = useIsLgScreen();
  return (
    <Grid
      item
      sx={{
        position: "relative",
        overflow: "hidden",
        width: "100%",
        minHeight: isMobile ? "724px" : isLgScreen ? "584px" : "674px",
        marginTop: isMobile ? "-155px" : "-15px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <video
        autoPlay
        loop
        muted
        playsInline
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "auto",
          height: "auto",
          minWidth: "100%",
          minHeight: "100%",
          transform: "translate(-50%, -50%)",
          zIndex: -1,
        }}
        crossOrigin="anonymous"
      >
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.6)", // Adjust the opacity (last value) to control the darkness
          zIndex: -1,
        }}
      ></div>
      {children}
    </Grid>
  );
};
