import { Button, Dialog, DialogProps } from "@mui/material";
import React from "react";
import { XSvg } from "../modules/virtual-choir/components/HorizontalLinearStepper/assets";

export const VirtualChoirDialog: React.FC<DialogProps> = ({
  onClose,
  children,
  ...props
}) => {
  return (
    <Dialog
      maxWidth="sm"
      sx={(theme) => ({
        ".MuiPaper-root.MuiDialog-paper": {
          borderRadius: "22px",
          boxShadow: "0px 4px 8px rgba(20, 17, 16, 0.25)",
          overflow: "unset",
          border: "1px solid black",
          borderColor: theme.palette.yellow.regular,
          width: "100%",
          backgroundColor: theme.palette.yellow.regular,
        },
        ".MuiPaper-root": {
          margin: 0,
          [theme.breakpoints.down("md")]: {
            padding: "10px 0px",
          },
        },
        ".MuiDialog-container": {
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        },
        [theme.breakpoints.down("md")]: {
          margin: !props.fullScreen ? "20px" : "56px 0px 0px 0px",
        },
      })}
      onClose={onClose}
      {...props}
    >
      <Button
        disableRipple
        onClick={() => onClose?.({}, "escapeKeyDown")}
        sx={{
          position: "absolute",
          right: "5px",
          top: "3px",
          "&:hover": {
            backgroundColor: "inherit",
          },
        }}
      >
        <XSvg />
      </Button>
      {children}
    </Dialog>
  );
};
