import { useMemoizedFn } from "ahooks";
import { atom, useAtom } from "jotai";

type MobileHeaderState =
  | {
      isOpen: true;
    }
  | {
      isOpen: false;
    };

export type UseMobileHeaderResult = MobileHeaderState & {
  open(): void;
  close(): void;
};

const mobileHeaderStateAtom = atom<MobileHeaderState>({
  isOpen: false,
});

export function useMobileHeader(): UseMobileHeaderResult {
  const [mobileHeaderState, setMobileHeaderState] = useAtom(
    mobileHeaderStateAtom
  );

  const open = useMemoizedFn(() => {
    setMobileHeaderState({
      isOpen: true,
    });
  });

  const close = useMemoizedFn(() => {
    setMobileHeaderState({
      isOpen: false,
    });
  });

  return {
    ...mobileHeaderState,
    open,
    close,
  };
}
