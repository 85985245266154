import { createTheme } from "@mui/material";
import "./body.css";
import { palette } from "./palette";

export const theme = createTheme({
  typography: {
    fontFamily: "Pally, sans-serif",
  },
  palette,
  breakpoints: {
    values: {
      xxs: 0,
      xs: 480,
      sm: 900,
      md: 1024,
      lg: 1560,
      xl: 1536,
    },
  },
});

theme.typography.h5 = {
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
  },
  fontSize: "24px",
  lineHeight: "100%",
};

theme.typography.h4 = {
  [theme.breakpoints.down("md")]: {
    fontSize: "20px",
    lineHeight: "100%",
  },
  fontSize: "34px",
  lineHeight: "100%",
};

theme.typography.h1 = {
  [theme.breakpoints.down("md")]: {
    fontSize: "53px",
  },
  fontSize: "96px",
  lineHeight: "100%",
};

theme.typography.h2 = {
  [theme.breakpoints.down("md")]: {
    fontSize: "45px",
    lineHeight: "100%",
  },
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "70px",
    lineHeight: "100%",
  },
  fontSize: "60px",
  lineHeight: "100%",
};

theme.typography.h3 = {
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "47px",
    lineHeight: "100%",
  },
  fontSize: "48px",
  lineHeight: "100%",
};

theme.typography.subtitle1 = {
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "15.5px",
    lineHeight: "130%",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "12px",
    lineHeight: "130%",
  },
  fontSize: "16px",
};

theme.typography.subtitle2 = {
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "12px",
    lineHeight: "130%",
  },
  fontSize: "14px",
};

theme.typography.h6 = {
  [theme.breakpoints.between("md", "lg")]: {
    fontSize: "17px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "13px",
  },
  fontSize: "20px",
};
