export const RecordingSvg: React.FC<{ opacity: number; width?: number }> = ({
  opacity,
  width,
}) => {
  return (
    <svg
      width={width ? width : "69"}
      height="55"
      viewBox="0 0 69 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.9124 62.5748C47.9849 62.5748 61.8248 48.7349 61.8248 31.6624C61.8248 14.59 47.9849 0.75 30.9124 0.75C13.84 0.75 0 14.59 0 31.6624C0 48.7349 13.84 62.5748 30.9124 62.5748Z"
        fill="#A2DD8A"
        style={{ opacity: opacity }}
      />
      <path
        d="M54.6888 20.73C56.6385 19.7552 58.4469 18.829 62.3365 16.8842C62.6914 16.6914 63.0824 16.5741 63.4848 16.5398C63.8872 16.5056 64.2923 16.555 64.6746 16.6851C65.057 16.8151 65.4083 17.0229 65.7064 17.2955C66.0044 17.568 66.2428 17.8993 66.4065 18.2685C66.6307 18.7121 67.0645 19.1215 67.0645 19.6187C67.0645 30.4444 66.9963 30.4444 66.9963 41.2652C66.9772 42.1264 66.6227 42.9461 66.0081 43.5497C65.3935 44.1534 64.5676 44.4932 63.7062 44.4968C63.1962 44.5309 62.685 44.451 62.2098 44.2628C55.8733 41.0897 53.1193 42.8737 49.3516 39.0425"
        stroke="#FFECD2"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
        style={{ opacity: opacity }}
      />
      <path
        d="M9.3047 17.6389C9.3034 16.8 9.46793 15.9692 9.78879 15.1942C10.1096 14.4191 10.5805 13.7151 11.1744 13.1226C11.7682 12.5302 12.4732 12.0609 13.249 11.7418C14.0248 11.4228 14.856 11.2602 15.6948 11.2634C28.4994 11.2634 28.4994 11.0879 41.2992 11.0879C43.0257 11.1157 44.6733 11.8155 45.892 13.0388C47.1107 14.262 47.8045 15.9123 47.8258 17.6389C47.8258 30.4435 47.8599 30.4435 47.8599 43.2432C47.8599 46.7819 44.8379 49.3896 41.2992 49.3896C28.4994 49.3896 28.4994 49.3896 15.6948 49.3896C12.1561 49.3896 9.63617 46.7673 9.63617 43.2286C9.64592 30.4435 9.3047 30.4435 9.3047 17.6389Z"
        stroke="#FFECD2"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
        style={{ opacity: opacity }}
      />
    </svg>
  );
};
