import React from "react";
import { HorizontalLinearStepper } from "./components/HorizontalLinearStepper";
import { SEO } from "../../components";

export const VirtualChoirPage: React.FC = () => {
  return (
    <>
      <SEO
        title="Svi UGLAS | Studio za snimanje"
        description='Oživite svoje muzičke snove na našoj stranici "Studio za snimanje"! Snimite svoj glas uz naše muzičke podloge i dobijte jedinstveni video zapis u kojem se vaša izvedba spaja sa numerom.'
      />
      <HorizontalLinearStepper />
    </>
  );
};
