import { Typography, styled } from "@mui/material";
import { useIsLgScreen } from "../../../../../hooks/useIsLgScreen";
import { useIsMobile } from "../../../../../hooks/useIsMobile";

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.purple.regular,
  fontFamily: "'Inter', 'sans-serif'",
  fontWeight: 400,
  lineHeight: "130%",
}));

export const RecordingDisabled: React.FC = () => {
  const isMobile = useIsMobile();
  const isLgScreen = useIsLgScreen();

  return (
    <StyledTypography
      variant={isLgScreen ? "subtitle1" : isMobile ? "h5" : "h6"}
      mb={"5px"}
      mt={"10px"}
    >
      Dragi naši, <br />
      <br /> Hvala vam što koristite naš studio za snimanje. Trenutno, naš
      studio za snimanje nije dostupan. To se može dogoditi kada nema aktivnih
      projekata za snimanje u određenom vremenskom intervalu. <br />
      <br />
      Vaša kreativnost je važna za nas i radimo na tome da obezbedimo što bolje
      iskustvo. U međuvremenu, molimo vas za strpljenje dok ne započne sledeći
      projekat za snimanje. <br />
      <br />
      Ako imate bilo kakvih pitanja ili želite dodatne informacije, slobodno nas
      kontaktirajte putem društvenih mreža ili putem e-pošte. <br />
      <br /> Hvala vam na razumevanju. <br />
      <br />
      Vaš ArtAparat
    </StyledTypography>
  );
};
