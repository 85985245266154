import { Box, Typography, styled } from "@mui/material";
import { ScaleLoader } from "react-spinners";
import { theme } from "../theme";

const StyledBox = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 99999,
}));

export interface LoadingOverlayProps {
  isLoading: boolean;
}

export const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
  isLoading,
}) => {
  if (!isLoading) {
    return null;
  }

  return (
    <StyledBox>
      <ScaleLoader
        color={theme.palette.purple.regular}
        height={60}
        width={10}
      />
      <Typography
        variant="h6"
        sx={(theme) => ({
          textAlign: "center",
          color: theme.palette.yellow.regular,
        })}
      >
        Tvoj video se šalje, molimo za strpljenje! <br />
        Nemojte napuštati sajt dok se video uspešno ne pošalje.
        <br />
        Hvala!
      </Typography>
    </StyledBox>
  );
};
