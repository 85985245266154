export const XSvg: React.FC<{ width?: number }> = ({ width }) => {
  return (
    <svg
      width={width ? width : "44"}
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="22" cy="22" r="22" fill="#FFECD2" />
      <path
        d="M30.041 13.7773L13.7776 30.0408"
        stroke="#7A5D7F"
        stroke-width="3"
        stroke-linecap="round"
      />
      <path
        d="M30.3945 30.3945L14.1311 14.1311"
        stroke="#7A5D7F"
        stroke-width="3"
        stroke-linecap="round"
      />
    </svg>
  );
};
