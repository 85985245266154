import { Box, List, ListItem, Typography, styled } from "@mui/material";
import React from "react";
import { useIsMobile } from "../../../../../hooks/useIsMobile";
import { useIsLgScreen } from "../../../../../hooks/useIsLgScreen";

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.purple.regular,
  fontFamily: "'Inter', 'sans-serif'",
  fontWeight: 400,
  lineHeight: "130%",
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: "0px 10px",
}));

export const ImportantRecordingNotes: React.FC = () => {
  const isMobile = useIsMobile();
  const isLgScreen = useIsLgScreen();

  return (
    <>
      <StyledTypography
        variant={isLgScreen ? "subtitle1" : isMobile ? "h5" : "h6"}
        mb={"5px"}
        mt={"10px"}
      >
        Dobro došli u virtuelni muzički studio Svi UGLAS!
      </StyledTypography>
      <StyledTypography
        variant={isLgScreen ? "subtitle1" : isMobile ? "h5" : "h6"}
        sx={{ fontWeight: 700 }}
      >
        Važno:
      </StyledTypography>
      <Box sx={{ display: "flex" }}>
        <List
          sx={(theme) => ({
            listStyleType: "disc",
            pl: isMobile ? 1.5 : 10,
            "& .MuiListItem-root": {
              display: "list-item",
            },
          })}
        >
          <StyledListItem key={0}>
            <StyledTypography
              variant={isLgScreen ? "subtitle1" : isMobile ? "h5" : "h6"}
              sx={{ fontWeight: 700 }}
            >
              Za snimanje su vam neophodne slušalice. Koristite one koje imaju
              što bolji mikrofon.
            </StyledTypography>
          </StyledListItem>
          <StyledListItem key={1}>
            <StyledTypography
              variant={isLgScreen ? "subtitle1" : isMobile ? "h5" : "h6"}
              sx={{ fontWeight: 700 }}
            >
              Koristite Google Chrome pretraživač. Ukoliko ga nemate, možete ga
              preuzeti{" "}
              <a
                href="https://www.google.com/chrome/?brand=YTUH&gclid=CjwKCAjwh8mlBhB_EiwAsztdBBA_RFG6Zit7LEA6_gJVxQMLouTbWQRJQUbkCrvnhBKOTLtz_ZblRBoCeDEQAvD_BwE&gclsrc=aw.ds"
                target="_blank"
                style={{ color: "inherit" }}
                rel="noreferrer"
              >
                ovde
              </a>
              .
            </StyledTypography>
          </StyledListItem>
        </List>
      </Box>
      <StyledTypography
        variant={isLgScreen ? "subtitle1" : isMobile ? "h5" : "h6"}
      >
        Ostale informacije:
      </StyledTypography>
      <Box sx={{ display: "flex" }}>
        <List
          sx={(theme) => ({
            listStyleType: "disc",
            pl: isMobile ? 1.5 : 10,
            "& .MuiListItem-root": {
              display: "list-item",
            },
          })}
        >
          <StyledListItem key={0}>
            <StyledTypography
              variant={isLgScreen ? "subtitle1" : isMobile ? "h5" : "h6"}
            >
              Za snimanje možete koristiti: telefon ili tablet, laptop ili
              desktop računar.
            </StyledTypography>
          </StyledListItem>
          <StyledListItem key={1}>
            <StyledTypography
              variant={isLgScreen ? "subtitle1" : isMobile ? "h5" : "h6"}
            >
              Tokom snimanja, budite sami i na mestu bez buke. Možete snimati
              napolju ili unutra, budite kreativni.
            </StyledTypography>
          </StyledListItem>
          <StyledListItem key={2}>
            <StyledTypography
              variant={isLgScreen ? "subtitle1" : isMobile ? "h5" : "h6"}
            >
              Ukoliko snimate u sobi, upalite svetla ili snimajte tamo gde je
              dobro prirodno osvetljenje.
            </StyledTypography>
          </StyledListItem>
          <StyledListItem key={3}>
            <StyledTypography
              variant={isLgScreen ? "subtitle1" : isMobile ? "h5" : "h6"}
            >
              Namestite kameru tako da se vaše lice dobro vidi.
            </StyledTypography>
          </StyledListItem>
          <StyledListItem key={4}>
            <StyledTypography
              variant={isLgScreen ? "subtitle1" : isMobile ? "h5" : "h6"}
            >
              Ako možete, nosite odeću bez logoa i znakova (tipa Adidas, Nike i
              slično).
            </StyledTypography>
          </StyledListItem>
          <StyledListItem key={5}>
            <StyledTypography
              variant={isLgScreen ? "subtitle1" : isMobile ? "h5" : "h6"}
            >
              Udahnite duboko, nasmejte se i zapevajte!
            </StyledTypography>
          </StyledListItem>
          <StyledListItem key={6}>
            <StyledTypography
              variant={isLgScreen ? "subtitle1" : isMobile ? "h5" : "h6"}
            >
              Slobodno se snimite više puta, dok ne budete zadovoljni.
            </StyledTypography>
          </StyledListItem>
          <StyledListItem key={70}>
            <StyledTypography
              variant={isLgScreen ? "subtitle1" : isMobile ? "h5" : "h6"}
            >
              Kada snimite najbolji snimak, kliknite <b>Pošalji</b>!
            </StyledTypography>
          </StyledListItem>
          <StyledListItem key={8}>
            <StyledTypography
              variant={isLgScreen ? "subtitle1" : isMobile ? "h5" : "h6"}
            >
              Nakon snimanja imate opciju Preuzmi, da preuzmete svoj snimak.
            </StyledTypography>
          </StyledListItem>
          <StyledListItem key={9}>
            <StyledTypography
              variant={isLgScreen ? "subtitle1" : isMobile ? "h5" : "h6"}
            >
              Slobodno ga delite na svojim društvenim mrežama uz hešteg{" "}
              <b>#virtuelnihorsviuglas</b>
            </StyledTypography>
          </StyledListItem>
        </List>
      </Box>
      <StyledTypography
        variant={isLgScreen ? "subtitle1" : isMobile ? "h5" : "h6"}
        mt={"5px"}
      >
        Pre nego što počnete sa snimanjem, dobro naučite pesme. One se nalaze u
        sekciji Pevačke radionice. Pred snimanje, raspevajte se kako biste bili
        opušteni i kako bi se vaš glas čuo glasno i jasno!
      </StyledTypography>
      <StyledTypography
        variant={isLgScreen ? "subtitle1" : isMobile ? "h5" : "h6"}
        mb={isMobile ? 2 : 0}
      >
        Ideja je da ove dve pesme pevamo zajedno, Svi UGLAS! Jedva čekamo da vas
        čujemo i vidimo! &lt;3
      </StyledTypography>
    </>
  );
};
