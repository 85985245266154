import { Quiz } from "./Quiz";
import { QuizType } from "../api/dto";
import { CultureFooter } from "../../culture/components";

const questions = [
  {
    question: "Šta simbolizuje točak na zastavi Roma?",
    answers: [
      {
        label: "Jedan poseban model romskih kočija",
        isCorrect: false,
      },
      {
        label: "Stalno kretanje života i putovanje",
        isCorrect: true,
      },
      {
        label: "Da su Romi izmislili točak",
        isCorrect: false,
      },
      {
        label: "Ljubav prema automobilima",
        isCorrect: false,
      },
    ],
  },
  {
    question: "Šta simbolizuju dve boje na romskoj zastavi?",
    answers: [
      {
        label: "Nebo i zemlju (travu)",
        isCorrect: true,
      },
      {
        label: "Krv i nadu",
        isCorrect: false,
      },
      {
        label: "Spas i sreću",
        isCorrect: false,
      },
      {
        label: "Stradanje i mir",
        isCorrect: false,
      },
    ],
  },
  {
    question: "Šta na romskom jeziku znači reč Rom?",
    answers: [
      {
        label: "Put",
        isCorrect: false,
      },
      {
        label: "Narod",
        isCorrect: false,
      },
      {
        label: "Čovek",
        isCorrect: true,
      },
      {
        label: "Ljubav",
        isCorrect: false,
      },
    ],
  },
  {
    question: "Koji su glavni dijalekti romskog jezika?",
    answers: [
      {
        label: "Lejaški, arlijski i tamarski",
        isCorrect: true,
      },
      {
        label: "Banatski, kosovski i vlaški",
        isCorrect: false,
      },
      {
        label: "Indijski, turski i grčki",
        isCorrect: false,
      },
      {
        label: "Akana, barvalo i cirdel",
        isCorrect: false,
      },
    ],
  },
  {
    question: "Koja je pesma proglašena kao himna romske zajednice?",
    answers: [
      {
        label: "Čaje šukarije",
        isCorrect: false,
      },
      {
        label: "Geljan dade",
        isCorrect: false,
      },
      {
        label: "Đelem, đelem",
        isCorrect: true,
      },
      {
        label: "Kerta mange daje",
        isCorrect: false,
      },
    ],
  },
  {
    question: "Šta znači “Đelem, Đelem“?",
    answers: [
      {
        label: "Draga, draga",
        isCorrect: false,
      },
      {
        label: "Sreća, sreća",
        isCorrect: false,
      },
      {
        label: "Nebo, nebo",
        isCorrect: false,
      },
      {
        label: "Putujem, putujem",
        isCorrect: true,
      },
    ],
  },
  {
    question: "Koje vrste muzike je direktno oblikovala izvorna romska muzika?",
    answers: [
      {
        label: "Flamenko i trubačka muzika",
        isCorrect: true,
      },
      {
        label: "Rokenrol i metal",
        isCorrect: false,
      },
      {
        label: "Tehno, trans i haus",
        isCorrect: false,
      },
      {
        label: "Rep i hip-hop",
        isCorrect: false,
      },
    ],
  },
  {
    question:
      "Kako se zvao pevač koji je u Indiji proglašen kraljem romske muzike?",
    answers: [
      {
        label: "Šaban Šaulić",
        isCorrect: false,
      },
      {
        label: "Džej Ramadanovski",
        isCorrect: false,
      },
      {
        label: "Žarko Jovanović",
        isCorrect: false,
      },
      {
        label: "Šaban Bajramović",
        isCorrect: true,
      },
    ],
  },
  {
    question: "Kako se zvala prva romska pesnikinja na našim prostorima?",
    answers: [
      {
        label: "Melita Pečić",
        isCorrect: false,
      },
      {
        label: "Gina Ranjičić",
        isCorrect: true,
      },
      {
        label: "Anabela Simić",
        isCorrect: false,
      },
      {
        label: "Drita Sulja",
        isCorrect: false,
      },
    ],
  },
  {
    question:
      "Kako se, prema predanju, zove romska isceliteljka i svetica koju pravoslavni Romi slave uoči Uskrsa?",
    answers: [
      {
        label: "Valentina",
        isCorrect: false,
      },
      {
        label: "Petka",
        isCorrect: false,
      },
      {
        label: "Bibija",
        isCorrect: true,
      },
      {
        label: "Marija",
        isCorrect: false,
      },
    ],
  },
];

export const GypsyCultureQuiz: React.FC = () => {
  return (
    <>
      <Quiz questions={questions} quizType={QuizType.GYPSY_CULTURE} />
      <CultureFooter />
    </>
  );
};
