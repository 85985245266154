import { styled } from "@mui/material";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useIsMobile } from "../../../../../hooks/useIsMobile";

const StyledButton = styled(NavLink)<{ isMobile: boolean }>(
  ({ theme, isMobile }) => ({
    padding: isMobile ? 15 : 0,
    minHeight: 0,
    minWidth: 45,
  })
);

export interface StyledSocialMediaButtonProps {
  to: string;
  element: JSX.Element;
  hoverElement?: JSX.Element;
  sx?: any;
}

export const StyledSocialMediaButton: React.FC<
  StyledSocialMediaButtonProps
> = ({ to, element, hoverElement, sx }) => {
  const [isHovered, setIsHovered] = useState(false);
  const isMobile = useIsMobile();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <StyledButton
      to={to}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      isMobile={isMobile}
      target="_blank"
      sx={sx}
    >
      {isHovered && !isMobile ? hoverElement : element}
    </StyledButton>
  );
};
