import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./fonts.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AnyRoute } from "./modules/layout/MainLayout/AnyRoute";
import { Provider } from "jotai";
import { VirtualChoirPage } from "./modules/virtual-choir/VirtualChoirPage";
import { ApplicationTheme } from "./ApplicationTheme";
import { QuizPage } from "./modules/quiz";
import { GypsyCultureQuiz, GypsyHistoryQuiz } from "./modules/quiz/components";
import { HomePage } from "./modules/home";
import { VocalWorkshopsPage } from "./modules/vocal-workshops";
import { CulturePage } from "./modules/culture";
import { AboutUsPage } from "./modules/about-us";
import ScrollRestoration from "./ScrollRestoration";
import { GinaRanjicicPage } from "./modules/culture/components";
import { DzenetKokoPage } from "./modules/culture/components/DzenetKoko/DzenetKokoPage";
import { TravellingGypsiesPage } from "./modules/culture/components/TravellingGypsiesPage";
import { PrettyLoudPage } from "./modules/culture/components/PrettyLoud/PrettyLoudPage";
import { HelmetProvider } from "react-helmet-async";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 60_000,
    },
    mutations: {
      retry: false,
    },
  },
});

const ApplicationRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <ScrollRestoration />
      <Routes>
        <Route path="/*" element={<AnyRoute />}>
          <Route path="" element={<HomePage />} />
          <Route path="vocal-workshops" element={<VocalWorkshopsPage />} />
          <Route path="virtual-choir" element={<VirtualChoirPage />} />
          <Route path="culture" element={<CulturePage />} />
          <Route path="culture/gina-ranjicic" element={<GinaRanjicicPage />} />
          <Route path="culture/dzenet-koko" element={<DzenetKokoPage />} />
          <Route path="culture/pretty-loud" element={<PrettyLoudPage />} />
          <Route
            path="culture/travelling-gypsies"
            element={<TravellingGypsiesPage />}
          />
          <Route path="about-us" element={<AboutUsPage />} />
          <Route path="quiz" element={<QuizPage />} />
          <Route path="quiz/gypsy-history" element={<GypsyHistoryQuiz />} />
          <Route path="quiz/gypsy-culture" element={<GypsyCultureQuiz />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <ApplicationTheme>
        <Provider>
          <QueryClientProvider client={queryClient}>
            <ApplicationRoutes />
          </QueryClientProvider>
        </Provider>
      </ApplicationTheme>
    </HelmetProvider>
  </React.StrictMode>
);
