import React from "react";

export const QuotationMarksSvg: React.FC<{
  backgroundColor: React.CSSProperties["color"];
  color: React.CSSProperties["color"];
  width?: number;
}> = ({ backgroundColor, color, width }) => {
  return (
    <svg
      width={width ? width : "127"}
      height="127"
      viewBox="0 0 127 127"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_324_876)">
        <path
          d="M63.42 126.84C98.4459 126.84 126.84 98.4459 126.84 63.42C126.84 28.3941 98.4459 0 63.42 0C28.3941 0 0 28.3941 0 63.42C0 98.4459 28.3941 126.84 63.42 126.84Z"
          fill={backgroundColor}
        />
        <path
          d="M26.04 53.6705C19.4312 54.5032 12.769 54.8375 6.11 54.6705C5.52291 54.7108 4.9382 54.5637 4.44001 54.2505C3.81717 53.5401 3.49763 52.6138 3.55 51.6705C2.93 42.7805 4.18 22.1305 3.55 13.2305L54.55 13.5105C56.04 28.5105 57.5 43.6405 55.77 58.5805C54.58 68.8705 51.56 79.5805 44.49 86.2805C38.2 92.2805 29.68 94.3405 21.56 96.1805C32.03 82.6205 38.42 70.2705 35.42 52.7005"
          stroke={color}
          stroke-width="6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M93.2997 53.8396C86.7325 54.958 80.0903 55.5798 73.4297 55.6996C72.8463 55.7732 72.2551 55.6501 71.7497 55.3496C71.4239 55.015 71.1678 54.6191 70.9962 54.1848C70.8245 53.7505 70.7407 53.2865 70.7497 52.8196C69.7497 43.9596 70.1396 23.2696 69.1396 14.4096L120.06 12.5596C122.18 27.4596 124.27 42.5596 123.17 57.5596C122.41 67.8896 119.84 78.6596 113.06 85.7096C107.06 91.9796 98.5997 94.3796 90.5597 96.5496C100.45 82.5496 106.32 69.9696 102.56 52.5496"
          stroke={color}
          stroke-width="6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_324_876">
          <rect width="126.84" height="126.84" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
