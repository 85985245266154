import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import ginaRanjicicBackground from "../assets/gina-ranjicic.jpg";
import ginaRanjicicMural from "../assets/gina-ranjicic-mural.png";
import { BookSvg, QuotationMarksSvg } from "../assets";
import { theme } from "../../../theme";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { CultureFooter } from "./CultureFooter";
import { useIsLgScreen } from "../../../hooks/useIsLgScreen";

export const GinaRanjicicPage: React.FC = () => {
  const isMobile = useIsMobile();
  const isLgScreen = useIsLgScreen();

  return (
    <>
      <Grid
        container
        sx={{
          marginTop: isMobile ? "-155px" : "-12px",
          position: "relative",
        }}
      >
        {!isMobile ? (
          <>
            <Box
              justifyContent="center"
              alignItems="center"
              display="flex"
              flexDirection="column"
              sx={{
                position: "absolute",
                top: "50%",
                left: "55%",
                transform: "translate(-50%, -55%)",
                zIndex: 99,
                width: "fit-content",
              }}
            >
              <img
                src={ginaRanjicicMural}
                style={{
                  width: isLgScreen ? "223px" : "303px",
                  height: isLgScreen ? "223px" : "303px",
                }}
                alt="mural"
                crossOrigin="anonymous"
              />
              <Typography
                variant="subtitle2"
                sx={{
                  color: "rgba(255, 236, 210, 0.50)",
                  maxWidth: isLgScreen ? "210px" : "251px",
                  marginLeft: 15,
                  paddingTop: 1,
                  fontWeight: 400,
                  fontFamily: "'Inter', 'sans-serif'",
                  lineHeight: "130%",
                }}
              >
                Mural sa njenim likom u Solunskoj 18, koji je 2022. godine
                naslikala autorka Marija Šoln
              </Typography>
            </Box>
            <Grid
              item
              sx={(theme) => ({
                backgroundColor: theme.palette.green.disabled,
                paddingLeft: isLgScreen ? 22 : 30,
                paddingTop: isLgScreen ? 3 : 10,
                paddingRight: 10,
                paddingBottom: isLgScreen ? 5 : 10,
                minHeight: isLgScreen ? "574px" : "734px",
              })}
              md={6}
            >
              <BookSvg width={isLgScreen ? "95" : "140"} />
              <Box mt={2} />
              <Box sx={{ maxWidth: isLgScreen ? "357px" : "360px" }}>
                <Typography
                  variant={isLgScreen ? "h6" : "h5"}
                  sx={(theme) => ({
                    color: theme.palette.yellow.regular,
                    fontWeight: 400,
                    fontFamily: "'Inter', 'sans-serif'",
                    lineHeight: "130%",
                  })}
                >
                  Zavirite u poeziju i život Gine Ranjičić:
                </Typography>
                <Typography
                  variant="h3"
                  sx={(theme) => ({
                    color: theme.palette.yellow.regular,
                    fontWeight: 700,
                    lineHeight: "100%",
                  })}
                >
                  “AKTIVNA ŽENA U PASIVNA VREMENA”
                </Typography>

                <Box mt={isMobile ? 3 : 5} />
              </Box>
              <Typography
                variant={isLgScreen ? "subtitle1" : "h6"}
                sx={(theme) => ({
                  maxWidth: isLgScreen ? "350px" : "420px",
                  color: theme.palette.yellow.regular,
                  fontWeight: 400,
                  fontFamily: "'Inter', 'sans-serif'",
                  lineHeight: "130%",
                })}
              >
                Danas, šetajući se Solunskom ulicom u Beogradu, možete primetiti
                veliki zidni mural sa dva ženska lika odevena u narodnu nošnju.
                U pitanju je umetnički prikaz jedne od najvećih srpskih
                pesnikinja XIX veka i prve prepoznate romske pesnikinje - Gine
                Ranjičić. Iako je bila stvarna, Gina Ranjičić je za nas i dalje
                mit. O njoj se ne zna puno, osim toga da je sigurno bila autorka
                svojih pesama.
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              sx={{
                backgroundImage: `url(${ginaRanjicicBackground})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: "100%",
                position: "relative",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  color: "rgba(255, 236, 210, 0.50)",
                  position: "absolute",
                  bottom: isLgScreen ? 30 : 50,
                  left: isLgScreen ? 30 : 50,
                  fontWeight: 400,
                  fontFamily: "'Inter', 'sans-serif'",
                  lineHeight: "130%",
                }}
              >
                Izvor: Louis-Marie Baader - Tzigane à la porte de Brousse 1885.
                (Wikimedia Commons)
              </Typography>
            </Grid>
          </>
        ) : (
          <Grid
            item
            sx={{
              backgroundImage: `url(${ginaRanjicicBackground})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: "100%",
              position: "relative",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Box sx={{ ml: 4, mr: 4, mt: 10 }}>
              <BookSvg width="80" />
              <Box mt={isMobile ? 3 : 5} />
              <Typography
                variant="h5"
                sx={(theme) => ({
                  color: theme.palette.yellow.regular,
                  fontWeight: 400,
                  fontFamily: "'Inter', 'sans-serif'",
                  lineHeight: "130%",
                })}
              >
                Zavirite u poeziju i život Gine Ranjičić:
              </Typography>
              <Typography
                variant="h2"
                sx={(theme) => ({
                  color: theme.palette.yellow.regular,
                  fontWeight: 700,
                })}
              >
                “AKTIVNA ŽENA U PASIVNA VREMENA”
              </Typography>

              <Box mt={isMobile ? 8 : 5} />
            </Box>
            <Typography
              variant="h5"
              sx={(theme) => ({
                color: theme.palette.yellow.regular,
                ml: 6,
                mr: 6,
                mb: 5,
                fontWeight: 400,
                fontFamily: "'Inter', 'sans-serif'",
                lineHeight: "130%",
                textAlign: "left",
              })}
            >
              Danas, šetajući se Solunskom ulicom u Beogradu, možete primetiti
              veliki zidni mural sa dva ženska lika odevena u narodnu nošnju. U
              pitanju je umetnički prikaz jedne od najvećih srpskih pesnikinja
              XIX veka i prve prepoznate romske pesnikinje - Gine Ranjičić. Iako
              je bila stvarna, Gina Ranjičić je za nas i dalje mit. O njoj se ne
              zna puno, osim toga da je sigurno bila autorka svojih pesama.
            </Typography>
          </Grid>
        )}
      </Grid>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.yellow.regular,
          width: "100%",
          paddingBottom: 10,
          paddingLeft: isMobile ? 6 : 10,
          paddingRight: isMobile ? 6 : 10,
        })}
      >
        {isMobile && (
          <Box
            justifyContent={"center"}
            display={"flex"}
            alignContent={"center"}
            alignItems={"center"}
            pt={4}
          >
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                maxWidth: "270px",
              }}
            >
              <Grid item sm={6} xs={6} xxs={6}>
                <img
                  src={ginaRanjicicMural}
                  style={{
                    width: "123px",
                    height: "123px",
                  }}
                  alt="mural"
                  crossOrigin="anonymous"
                />
              </Grid>
              <Grid item sm={6} xs={6} xxs={6}>
                <Box
                  justifyContent={"center"}
                  display={"flex"}
                  alignContent={"center"}
                  alignItems={"center"}
                >
                  <Typography
                    variant="h5"
                    sx={(theme) => ({
                      textAlign: "left",
                      color: theme.palette.purple.regular,
                      fontWeight: 400,
                      fontFamily: "'Inter', 'sans-serif'",
                      lineHeight: "130%",
                    })}
                  >
                    Mural sa njenim likom u Solunskoj 18, koji je 2022. godine
                    naslikala autorka Marija Šoln
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            maxWidth: "750px",
            margin: "0 auto",
          }}
        >
          <Grid item md={12}>
            <Box
              justifyContent={"center"}
              display={"flex"}
              alignContent={"center"}
              alignItems={"center"}
            >
              <Typography
                variant={isLgScreen ? "h6" : isMobile ? "h5" : "h6"}
                sx={(theme) => ({
                  textAlign: "left",
                  marginTop: isMobile ? 3 : 5,
                  color: theme.palette.purple.regular,
                  fontWeight: 400,
                  fontFamily: "'Inter', 'sans-serif'",
                  lineHeight: "130%",
                  maxWidth: isLgScreen ? "540px" : isMobile ? "100%" : "670px",
                })}
              >
                Gina je živela u XIX veku i za svoje vreme je bila moderna žena,
                slobodnog duha i znatiželjna. Sa 12 godina se seli u Beograd
                nakon čega se obrazuje i razvija interesovanje za evropsku
                poeziju. Njena poezija ispunjena je motivima neuzvraćene
                ljubavi, tragičnih sudbina, kao i slatkih muka oko osećanja
                zaljubljenosti.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            maxWidth: "850px",
            margin: "0 auto",
            flexDirection: "row",
            marginTop: 5,
          }}
        >
          <Box
            justifyContent={"center"}
            display={"flex"}
            alignContent={"center"}
            flexDirection={isMobile ? "column" : "row"}
          >
            <Grid item xs={12} xxs={12} md={3}>
              <Box
                sx={{
                  float: isMobile ? "center" : "right",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <QuotationMarksSvg
                  color={theme.palette.blue.disabled}
                  backgroundColor={theme.palette.blue.regular}
                  width={isMobile ? 77 : isLgScreen ? 100 : 127}
                />
              </Box>
            </Grid>
            <Grid item md={9}>
              <Typography
                variant={isLgScreen ? "h5" : "h4"}
                sx={(theme) => ({
                  color: theme.palette.purple.regular,
                  fontWeight: 700,
                  paddingLeft: isMobile ? 0 : 3,
                  marginTop: isMobile ? 0 : 5,
                  textAlign: isMobile ? "center" : "left",
                })}
              >
                Moći ću da čujem <br />
                Jednu stranu zemlju <br />
                Tamo gde si ti <br />
                Je sam s tobom
                <br />
                Tvoja sam ruža <br />
                Osećam da slatki plamen
                <br /> Ljubavi u meni bukti
                <br /> Taj što me k tebi dovede.
              </Typography>
            </Grid>
          </Box>
        </Grid>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            maxWidth: "750px",
            margin: "0 auto",
          }}
        >
          <Grid item md={12}>
            <Box
              justifyContent={"center"}
              display={"flex"}
              alignContent={"center"}
              alignItems={"center"}
            >
              <Typography
                variant={isLgScreen ? "h6" : isMobile ? "h5" : "h6"}
                sx={(theme) => ({
                  textAlign: "left",
                  marginTop: isMobile ? 4 : 7,
                  color: theme.palette.purple.regular,
                  fontWeight: 400,
                  fontFamily: "'Inter', 'sans-serif'",
                  lineHeight: "130%",
                  maxWidth: isLgScreen ? "540px" : isMobile ? "100%" : "670px",
                })}
              >
                Bila je izvanredna žena, ispred svog doba: obrazovana, govorila
                je više jezika i pisala je. Ginin turbulentni život reflektuje
                se u njenoj poeziji. Prema njenim rečima: “kada je bila srećna –
                nije puno toga pisala”.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            maxWidth: "850px",
            margin: "0 auto",
            flexDirection: "row",
            marginTop: 5,
          }}
        >
          <Box
            justifyContent={"center"}
            display={"flex"}
            alignContent={"center"}
            flexDirection={isMobile ? "column" : "row"}
          >
            <Grid item xs={12} xxs={12} md={3}>
              <Box
                sx={{
                  float: isMobile ? "center" : "right",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <QuotationMarksSvg
                  color={theme.palette.errors.disabled}
                  backgroundColor={theme.palette.errors.regular}
                  width={isMobile ? 77 : isLgScreen ? 100 : 127}
                />
              </Box>
            </Grid>
            <Grid item md={9}>
              <Typography
                variant={isLgScreen ? "h5" : "h4"}
                sx={(theme) => ({
                  color: theme.palette.purple.regular,
                  fontWeight: 700,
                  paddingLeft: isMobile ? 0 : 3,
                  marginTop: isMobile ? 0 : 5,
                  textAlign: isMobile ? "center" : "left",
                })}
              >
                U snu lutala sam <br /> Drumovima mi znanim <br /> I onim
                livadama zelenim <br /> Priđe mi moj ljubljeni <br /> Šiptar
                nežno zamilovani <br /> I tamo daleko ugledasmo
                <br />
                Spram visokih planina <br />
                Gore prema zlatnim zvezdama <br />
                Beše tamo kao neki glas <br />
                Što svima kratko kaza: <br />
                Planine i zvezde <br />
                Ostaju to što jesu <br />I te sive planine <br />
                Uzalud vole <br />
                One zvezdice zlaćane <br />A da li i ti sirota ženo <br />
                Uzalud tog čoveka?
              </Typography>
            </Grid>
          </Box>
        </Grid>
        <Box
          justifyContent={"center"}
          alignContent={"center"}
          alignItems={"center"}
          display={"flex"}
          mt={4}
        >
          <img
            src="https://virtualchoir.fra1.digitaloceanspaces.com/project-images/gina-ranjicic-blog-foto-ram.jpg"
            style={{ maxWidth: "646px" }}
            width={isMobile ? "120%" : isLgScreen ? "45%" : "50%"}
            height="auto"
            alt="gina-ranjicic"
            crossOrigin="anonymous"
          />
        </Box>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            maxWidth: "750px",
            margin: "0 auto",
          }}
        >
          <Grid item md={12}>
            <Box
              justifyContent={"center"}
              display={"flex"}
              alignContent={"center"}
              alignItems={"center"}
            >
              <Typography
                variant={isLgScreen ? "h6" : isMobile ? "h5" : "h6"}
                sx={(theme) => ({
                  textAlign: "left",
                  marginTop: isMobile ? 3 : 5,
                  color: theme.palette.purple.regular,
                  fontWeight: 400,
                  fontFamily: "'Inter', 'sans-serif'",
                  lineHeight: "130%",
                  maxWidth: isLgScreen ? "540px" : isMobile ? "100%" : "670px",
                })}
              >
                Žene tog doba bile su osuđene na bavljenje domaćinstvom i nisu
                puno izlazile iz kuće. To ipak nije bio slučaj sa Ginom. Sa
                svojim poslednjim mužem često je putovala po Evropi. Tom
                prilikom, osim pisanja svojih pesama ona i prevodi evropsku
                poeziju. Ispunjava svoju želju, i dolazi do Pariza. Poslednje
                godine svog života provodi u Srbiji, nomadskim načinom života sa
                svojim rođacima.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            maxWidth: "900px",
            margin: "0 auto",
            flexDirection: "row",
            marginTop: 5,
          }}
        >
          <Box
            justifyContent={"center"}
            display={"flex"}
            alignContent={"center"}
            flexDirection={isMobile ? "column" : "row"}
          >
            <Grid item xs={12} xxs={12} md={3}>
              <Box
                sx={{
                  float: isMobile ? "center" : "right",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <QuotationMarksSvg
                  color={theme.palette.green.disabled}
                  backgroundColor={theme.palette.green.regular}
                  width={isMobile ? 77 : isLgScreen ? 100 : 127}
                />
              </Box>
            </Grid>
            <Grid item md={9}>
              <Typography
                variant={isLgScreen ? "h5" : "h4"}
                sx={(theme) => ({
                  color: theme.palette.purple.regular,
                  fontWeight: 700,
                  paddingLeft: isMobile ? 0 : 3,
                  marginTop: isMobile ? 0 : 5,
                  textAlign: isMobile ? "center" : "left",
                })}
              >
                Ptica selica dolete <br />i šuma opet zazeleni <br />
                Ali hoću li sreću u ljubavi <br />
                Pronaći sad? <br />
                Penušava voda potoka <br />
                Žubori duž zelenih obala. <br />I leto zaboravlja <br />
                Na ljutu zimu. <br />
                Al ko zna <br />
                Da srce čovekovo
                <br />
                Od teskobe I ljubavi ranjene <br />
                Umreti neće ovakvog jednog proleća?
              </Typography>
            </Grid>
          </Box>
        </Grid>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            maxWidth: "750px",
            margin: "0 auto",
          }}
        >
          <Grid item md={12}>
            <Box
              justifyContent={"center"}
              display={"flex"}
              alignContent={"center"}
              alignItems={"center"}
            >
              <Typography
                variant={isLgScreen ? "h6" : isMobile ? "h5" : "h6"}
                sx={(theme) => ({
                  textAlign: "left",
                  marginTop: isMobile ? 3 : 5,
                  color: theme.palette.purple.regular,
                  fontWeight: 400,
                  fontFamily: "'Inter', 'sans-serif'",
                  lineHeight: "130%",
                  maxWidth: isLgScreen ? "540px" : isMobile ? "100%" : "670px",
                })}
              >
                Biti žena i Romkinja u vremenu Gine Ranjičić nije bilo lako.
                Obrazovanje, putovanja i posao nisu bili pristupačni. Ona je
                kroz svoju poeziju ženama tog vremena dala glas. Time nam je
                pružila uvid o tome kako je razmišljala jedna aktivna žena u tim
                pasivnim vremenima. Upravo zato Gina Ranjičić je danas izuzetno
                važna: i kao pionirka romske lirike, i kao istorijska ličnost u
                devetnaestovekovnoj Srbiji.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            maxWidth: "900px",
            margin: "0 auto",
            flexDirection: "row",
            marginTop: 5,
          }}
        >
          <Box
            justifyContent={"center"}
            display={"flex"}
            alignContent={"center"}
            flexDirection={isMobile ? "column" : "row"}
          >
            <Grid item xs={12} xxs={12} md={3}>
              <Box
                sx={{
                  float: isMobile ? "center" : "right",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <QuotationMarksSvg
                  color={theme.palette.blue.disabled}
                  backgroundColor={theme.palette.blue.regular}
                  width={isMobile ? 77 : isLgScreen ? 100 : 127}
                />
              </Box>
            </Grid>
            <Grid item md={9}>
              <Typography
                variant={isLgScreen ? "h5" : "h4"}
                sx={(theme) => ({
                  color: theme.palette.purple.regular,
                  fontWeight: 700,
                  paddingLeft: isMobile ? 0 : 3,
                  marginTop: isMobile ? 0 : 5,
                  textAlign: isMobile ? "center" : "left",
                })}
              >
                Mog srca radost je ko leptiri s Jemesa. <br />
                Dođu, zalepršaju, ali ne ostanu.
                <br />
                Radost moja je u meni, ali začas mine.
                <br />
                Smeh moj radostan nije, već bolan.
                <br />
                Mog srca radost je ko leptiri s Jemesa.
                <br />
                U krvavim grudima mog dragog lepršaju oni.
                <br />
                I crvi tuge ulaze u moj srce.
                <br />
                Nikad, nikada ih neću otud isterati.
                <br />
              </Typography>
            </Grid>
          </Box>
        </Grid>
      </Box>
      <CultureFooter />
    </>
  );
};
