import { Box } from "@mui/material";
import { Header } from "./Header/Header";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { Footer } from "./Footer/Footer";

export const MainLayoutPadding = {
  DesktopTop: "90px",
  MobileTop: "90px",
  Right: "0px",
  Bottom: "0px",
  Left: "0px",
};

export const MainLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { DesktopTop, MobileTop, Right, Bottom, Left } = MainLayoutPadding;
  const isMobile = useIsMobile();
  const Top = isMobile ? MobileTop : DesktopTop;

  return (
    <Box sx={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <Header />
      <Box sx={{ padding: `${Top} ${Right} ${Bottom} ${Left}` }}>
        {children}
      </Box>
      <Footer />
    </Box>
  );
};
