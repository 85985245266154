export const SendEmailBackgroundSvg: React.FC<{ width?: string }> = ({
  width,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "355"}
      height="54"
      viewBox="0 0 295 41"
      fill="none"
    >
      <path
        d="M244.761 41C227.905 41 131.223 40.1184 85.7249 41C5.3335 41 7.22519 33.5119 1.94592 24.6349C-8.31091 7.3882 24.0819 0.335231 49.9172 0.335233C77.263 0.335236 177.157 3.87987 239.231 0.33525C297.331 -2.98243 294.987 19.2901 294.987 24.6349C294.987 34.1674 269.514 41 244.761 41Z"
        fill="#7A5D7F"
      />
    </svg>
  );
};
