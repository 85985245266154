import { Box, Button, Grid, Typography, styled } from "@mui/material";
import { TitleSvg } from "./assets";
import { useIsMobile } from "../../hooks/useIsMobile";
import { useState } from "react";
import { theme } from "../../theme";
import { useNavigate } from "react-router-dom";
import * as ReactDOMServer from "react-dom/server";
import { ButtonHoverSvg } from "./assets/ButtonHoverSvg";
import learnToSing from "./assets/learnToSing.png";
import learnToSingMobile from "./assets/learnToSingMobile.png";
import recordInTheStudio from "./assets/recorInTheStudio.png";
import recordInTheStudioMobile from "./assets/recordInTheStudioMobile.png";
import learnMore from "./assets/learnMore.png";
import learnMoreMobile from "./assets/learnMoreMobile.png";
import recordInTheStudioBackgroundImg from "./assets/recordInTheStudioBackground.png";
import learnToSingBackgroundImg from "./assets/learnToSingBackground.png";
import learnMoreBackgroundImg from "./assets/learnMoreBackground.png";
import { useIsLgScreen } from "../../hooks/useIsLgScreen";
import lineWithText from "./assets/line-with-text.png";
import lineMobile from "./assets/home-page-line.png";
import learnToSingBackgroundMobile from "./assets/learnToSingBackgroundMobile.png";
import recordInTheStudioBackgroundMobile from "./assets/recordInTheStudioBackgroundMobile.png";
import learnMoreBackgroundMobile from "./assets/learnMoreBackgroundMobile.png";
import { HomePageFooter, VideoBackgroundGrid } from "./components";
import { SEO } from "../../components";

const StyledGrid = styled(Grid)<{
  color: React.CSSProperties["color"];
  isLgScreen: boolean;
  isMobile: boolean;
  first: boolean;
}>(({ color, isLgScreen, isMobile, first }) => ({
  backgroundColor: color,
  width: "100%",
  minHeight: isLgScreen ? "580px" : isMobile ? "auto" : "920px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  paddingBottom: isMobile ? 35 : 0,
  paddingTop: isMobile && first ? 135 : isMobile && !first ? 35 : 0,
}));

const StyledTypographyTitle = styled(Typography)<{
  color: React.CSSProperties["color"];
  isLgScreen: boolean;
  isMobile: boolean;
}>(({ color, isLgScreen, isMobile }) => ({
  color: color,
  fontWeight: 700,
  marginLeft: 4,
  marginRight: 4,
  width: "100%",
  maxWidth: isLgScreen ? "440px" : isMobile ? "360px" : "600px",
  fontSize: isMobile ? "50px" : "",
  lineHeight: "100%",
}));

const StyledTypographySubTitle = styled(Typography)<{
  color: React.CSSProperties["color"];
  isMobile: boolean;
  isLgScreen: boolean;
}>(({ color, isMobile, isLgScreen }) => ({
  color: color,
  fontWeight: 400,
  width: "100%",
  maxWidth: isLgScreen ? 320 : isMobile ? 340 : 460,
  padding: isMobile ? "20px 40px" : isLgScreen ? "10px 6px" : "20px 6px",
  lineHeight: "130%",
}));

const StyledButton = styled(Button)<{ isLgScreen: boolean }>(
  ({ isLgScreen }) => ({
    width: "fit-content",
    height: "80px",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  })
);

const StyledBox = styled(Box)<{
  color: React.CSSProperties["color"];
  minHeight: string;
  url: string;
  isLgScreen: boolean;
  isMobile: boolean;
}>(({ color, minHeight, url, isLgScreen, isMobile }) => ({
  backgroundImage: `url("data:image/svg+xml,${url}")`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  padding: !isLgScreen ? "10px" : "5px",
  minWidth: isLgScreen ? "135px" : isMobile ? "140px" : "207px",
  textAlign: "center",
  minHeight: isLgScreen ? "50px" : isMobile ? "50px" : "78px",
  fontSize: isLgScreen ? "22px" : isMobile ? "18px" : "30px",
  fontWeight: 700,
  color: color,
}));

export const HomePage: React.FC = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const isLgScreen = useIsLgScreen();

  const titleWidth = isMobile ? 297 : isLgScreen ? 330 : 377;
  const titleHeight = isMobile ? 259 : isLgScreen ? 389 : 439;

  const [isHoveredWorkshops, setIsHoveredWorkshops] = useState(false);
  const [isHoveredRecording, setIsHoveredRecording] = useState(false);
  const [isHoveredCulture, setIsHoveredCulture] = useState(false);

  const purpleBackground = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      <ButtonHoverSvg
        color={theme.palette.purple.regular}
        backgroundColor={theme.palette.purple.regular}
      />
    )
  );

  const yellowBackground = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      <ButtonHoverSvg
        color={theme.palette.yellow.regular}
        backgroundColor={theme.palette.yellow.regular}
      />
    )
  );

  const purpleHover = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      <ButtonHoverSvg color={theme.palette.purple.regular} />
    )
  );

  const yellowHover = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      <ButtonHoverSvg color={theme.palette.yellow.regular} />
    )
  );

  const handleMouseEnter = (buttonText: string) => {
    if (buttonText === "RADIONICE") {
      setIsHoveredWorkshops(true);
    } else if (buttonText === "SNIMANJE") {
      setIsHoveredRecording(true);
    } else {
      setIsHoveredCulture(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHoveredWorkshops(false);
    setIsHoveredRecording(false);
    setIsHoveredCulture(false);
  };

  const handleClick = (navigateTo: string) => {
    navigate(navigateTo);
  };

  return (
    <>
      <SEO
        title="Svi UGLAS | Početna"
        description="Dobrodošli na našu početnu stranicu. Pevajte sa nama."
      />
      <Grid container>
        <VideoBackgroundGrid
          videoSrc={
            "https://virtualchoir.fra1.digitaloceanspaces.com/showreel%20ubrzano%20bez%20muzike.mp4"
          }
        >
          <Grid
            sx={{
              backgroundImage: `url(${isMobile ? lineMobile : lineWithText})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right 56.5% bottom",
              [theme.breakpoints.down(900)]: {
                backgroundPosition: "left 32% bottom",
                backgroundSize: "65% 20%",
              },
              [theme.breakpoints.between(900, 960)]: {
                backgroundPosition: "left 22% bottom",
                backgroundSize: "50% 22%",
              },
              [theme.breakpoints.between(960, 1024)]: {
                backgroundPosition: "left 15% bottom",
                backgroundSize: "45% 22%",
              },
              [theme.breakpoints.between(1024, 1034)]: {
                backgroundPosition: "left 38% bottom",
                backgroundSize: "45% 28%",
              },
              [theme.breakpoints.between(1034, 1044)]: {
                backgroundPosition: "left 38% bottom",
                backgroundSize: "45% 28%",
              },
              [theme.breakpoints.between(1044, 1065)]: {
                backgroundPosition: "left 38% bottom",
                backgroundSize: "45% 28%",
              },
              [theme.breakpoints.between(1065, 1100)]: {
                backgroundPosition: "left 38% bottom",
                backgroundSize: "45% 28%",
              },
              [theme.breakpoints.between(1100, 1120)]: {
                backgroundPosition: "left 38% bottom",
                backgroundSize: "45% 28%",
              },
              [theme.breakpoints.between(1120, 1140)]: {
                backgroundPosition: "left 38% bottom",
                backgroundSize: "45% 28%",
              },
              [theme.breakpoints.between(1140, 1170)]: {
                backgroundPosition: "left 38% bottom",
                backgroundSize: "45% 28%",
              },
              [theme.breakpoints.between(1170, 1200)]: {
                backgroundPosition: "left 38% bottom",
                backgroundSize: "45% 28%",
              },
              [theme.breakpoints.between(1200, 1240)]: {
                backgroundPosition: "left 38% bottom",
                backgroundSize: "45% 28%",
              },
              [theme.breakpoints.between(1240, 1270)]: {
                backgroundPosition: "left 42% bottom",
                backgroundSize: "45% 28%",
              },
              [theme.breakpoints.between(1270, 1300)]: {
                backgroundPosition: "left 42% bottom",
                backgroundSize: "45% 29%",
              },
              [theme.breakpoints.between(1300, 1350)]: {
                backgroundPosition: "left 42% bottom",
                backgroundSize: "45% 30%",
              },
              [theme.breakpoints.between(1350, 1360)]: {
                backgroundPosition: "left 39% bottom",
                backgroundSize: "45% 30%",
              },
              [theme.breakpoints.between(1360, 1400)]: {
                backgroundPosition: "left 39% bottom",
                backgroundSize: "40% 30%",
              },
              [theme.breakpoints.between(1400, 1450)]: {
                backgroundPosition: "left 39% bottom",
                backgroundSize: "40% 30%",
              },
              [theme.breakpoints.between(1450, 1500)]: {
                backgroundPosition: "left 39% bottom",
                backgroundSize: "40% 30%",
              },
              [theme.breakpoints.between(1500, 1560)]: {
                backgroundPosition: "left 39% bottom",
                backgroundSize: "40% 30%",
              },
              [theme.breakpoints.between(1560, 1620)]: {
                backgroundPosition: "left 38% bottom",
                backgroundSize: "40% 30%",
              },
              [theme.breakpoints.between(1620, 1700)]: {
                backgroundPosition: "left 38% bottom",
                backgroundSize: "40% 30%",
              },
              [theme.breakpoints.between(1700, 1780)]: {
                backgroundPosition: "left 38% bottom",
                backgroundSize: "40% 30%",
              },
              [theme.breakpoints.between(1780, 1880)]: {
                backgroundPosition: "left 38% bottom",
                backgroundSize: "40% 30%",
              },
              [theme.breakpoints.between(1880, 1980)]: {
                backgroundPosition: "left 37% bottom",
                backgroundSize: "38% 30%",
              },
              [theme.breakpoints.between(1980, 2080)]: {
                backgroundPosition: "left 37% bottom",
                backgroundSize: "38% 30%",
              },
              [theme.breakpoints.between(2080, 2270)]: {
                backgroundPosition: "left 37% bottom",
                backgroundSize: "38% 30%",
              },
              [theme.breakpoints.between(2270, 2500)]: {
                backgroundPosition: "left 37% bottom",
                backgroundSize: "38% 30%",
              },

              height: "100%",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              display: "flex",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <Grid item>
              <Box
                flexGrow={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
                marginTop={isMobile ? 20 : isLgScreen ? -8 : -15}
                marginBottom={isLgScreen ? 3 : 0}
              >
                <TitleSvg width={titleWidth} height={titleHeight} />
              </Box>
              {isMobile && (
                <Typography
                  sx={(theme) => ({
                    color: theme.palette.yellow.regular,
                    fontSize: "20px",
                    maxWidth: "262px",
                    paddingTop: "25px",
                    fontWeight: 700,
                  })}
                >
                  TVOJ KOMP ILI TELEFON SU SADA MUZIČKI STUDIO!
                </Typography>
              )}
            </Grid>
          </Grid>
        </VideoBackgroundGrid>
        <StyledGrid
          item
          color={theme.palette.purple.regular}
          columnGap={10}
          isLgScreen={isLgScreen}
          sx={{
            backgroundImage: `url(${
              isMobile ? learnToSingBackgroundMobile : learnToSingBackgroundImg
            })`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",

            backgroundSize: isLgScreen
              ? "100% 100%"
              : isMobile
              ? "100% 100%"
              : "110% 100%",
            [theme.breakpoints.between(1023, 1223)]: {
              backgroundSize: "117% 100%",
            },
            [theme.breakpoints.between(1223, 1240)]: {
              backgroundSize: "117% 100%",
            },
            [theme.breakpoints.between(1240, 1523)]: {
              backgroundSize: "110% 100%",
            },
            [theme.breakpoints.between(1523, 1560)]: {
              backgroundSize: "108% 100%",
            },
          }}
          isMobile={isMobile}
          first={true}
        >
          <Grid container rowGap={3} justifyContent="center">
            <Grid item xs={12} sm={12} md={6} position={"relative"}>
              {!isMobile ? (
                <Box
                  sx={{
                    marginRight: "60px",
                    [theme.breakpoints.between(1024, 1034)]: {
                      marginRight: "25px",
                    },
                    [theme.breakpoints.between(1034, 1044)]: {
                      marginRight: "25px",
                    },
                    [theme.breakpoints.between(1044, 1065)]: {
                      marginRight: "26px",
                    },
                    [theme.breakpoints.between(1065, 1100)]: {
                      marginRight: "27px",
                    },
                    [theme.breakpoints.between(1100, 1120)]: {
                      marginRight: "30px",
                    },
                    [theme.breakpoints.between(1120, 1140)]: {
                      marginRight: "32px",
                    },
                    [theme.breakpoints.between(1140, 1170)]: {
                      marginRight: "33px",
                    },
                    [theme.breakpoints.between(1170, 1200)]: {
                      marginRight: "34px",
                    },
                    [theme.breakpoints.between(1200, 1240)]: {
                      marginRight: "36px",
                    },
                    [theme.breakpoints.between(1240, 1270)]: {
                      marginRight: "40px",
                    },
                    [theme.breakpoints.between(1270, 1300)]: {
                      marginRight: "42px",
                    },
                    [theme.breakpoints.between(1300, 1350)]: {
                      marginRight: "39px",
                    },
                    [theme.breakpoints.between(1350, 1360)]: {
                      marginRight: "41px",
                    },
                    [theme.breakpoints.between(1360, 1400)]: {
                      marginRight: "41px",
                    },
                    [theme.breakpoints.between(1400, 1460)]: {
                      marginRight: "43px",
                    },
                    [theme.breakpoints.between(1460, 1520)]: {
                      marginRight: "47px",
                    },
                    [theme.breakpoints.between(1520, 1620)]: {
                      marginRight: "52px",
                    },
                  }}
                >
                  <img
                    src={learnToSing}
                    style={{
                      float: "right",
                      maxWidth: isLgScreen ? "280px" : "362px",
                      height: "auto",
                    }}
                    alt="learnToSing"
                    crossOrigin="anonymous"
                  />
                </Box>
              ) : (
                <img
                  src={learnToSingMobile}
                  style={{
                    maxWidth: "312px",
                    height: "auto",
                  }}
                  alt="learnToSingMobile"
                  crossOrigin="anonymous"
                />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  textAlign: isMobile ? "center" : "left",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  margin: isMobile ? "auto" : 0,
                }}
              >
                <StyledTypographyTitle
                  variant={isLgScreen ? "h2" : "h1"}
                  color={theme.palette.yellow.regular}
                  isLgScreen={isLgScreen}
                  isMobile={isMobile}
                >
                  NAUČI DA PEVAŠ!
                </StyledTypographyTitle>
                <StyledTypographySubTitle
                  variant={isLgScreen ? "subtitle1" : isMobile ? "h5" : "h6"}
                  isMobile={isMobile}
                  color={theme.palette.yellow.regular}
                  isLgScreen={isLgScreen}
                  sx={{
                    textAlign: isMobile ? "center" : "left",
                    float: isMobile ? "center" : "right",
                    margin: isMobile ? "auto" : 0,
                    fontWeight: 400,
                    fontFamily: "'Inter', 'sans-serif'",
                    lineHeight: "130%",
                  }}
                >
                  Muzičke radionice su prvi korak da postaneš deo virtuelnog
                  hora Svi UGLAS. Ovde možeš da naučiš pesme koje smo spremili i
                  radiš na svojoj vokalnoj tehnici.
                </StyledTypographySubTitle>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: isMobile ? "center" : "",
                }}
              >
                <StyledButton
                  isLgScreen={isLgScreen}
                  onMouseEnter={() => handleMouseEnter("RADIONICE")}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => handleClick("/vocal-workshops")}
                >
                  {!isMobile && isHoveredWorkshops ? (
                    <StyledBox
                      color={theme.palette.yellow.regular}
                      minHeight={"78px"}
                      url={yellowHover}
                      isLgScreen={isLgScreen}
                      isMobile={isMobile}
                    >
                      RADIONICE
                    </StyledBox>
                  ) : (
                    <StyledBox
                      color={theme.palette.purple.regular}
                      minHeight={"78px"}
                      url={yellowBackground}
                      isLgScreen={isLgScreen}
                      isMobile={isMobile}
                    >
                      RADIONICE
                    </StyledBox>
                  )}
                </StyledButton>
              </Box>
            </Grid>
          </Grid>
        </StyledGrid>
        <StyledGrid
          item
          color={theme.palette.yellow.regular}
          columnGap={10}
          isLgScreen={isLgScreen}
          sx={{
            backgroundImage: `url(${
              isMobile
                ? recordInTheStudioBackgroundMobile
                : recordInTheStudioBackgroundImg
            })`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: isLgScreen
              ? "110% 100%"
              : isMobile
              ? "100% 100%"
              : "",
            [theme.breakpoints.between(1023, 1223)]: {
              backgroundSize: "117% 100%",
            },
            [theme.breakpoints.between(1223, 1323)]: {
              backgroundSize: "110% 100%",
            },
            [theme.breakpoints.between(1323, 1523)]: {
              backgroundSize: "90% 100%",
            },
            [theme.breakpoints.between(1523, 1560)]: {
              backgroundSize: "95% 100%",
            },
          }}
          isMobile={isMobile}
          first={false}
        >
          <Grid container rowGap={5} justifyContent={isMobile ? "center" : ""}>
            {isMobile && (
              <Grid item xs={12} sm={12} md={6} position={"relative"}>
                <img
                  src={recordInTheStudioMobile}
                  style={{
                    maxWidth: "312px",
                    height: "auto",
                    marginTop: "-50px",
                    marginRight: "10px",
                  }}
                  alt="recordInTheStudioMobile"
                  crossOrigin="anonymous"
                />
              </Grid>
            )}

            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  textAlign: isMobile ? "center" : "left",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  margin: isMobile ? "auto" : 0,
                  width: "100%",
                }}
              >
                <Box display={"flex"} justifyContent={"flex-end"}>
                  <StyledTypographyTitle
                    variant={isLgScreen ? "h2" : "h1"}
                    color={theme.palette.purple.regular}
                    sx={{ textAlign: isMobile ? "center" : "right" }}
                    isLgScreen={isLgScreen}
                    isMobile={isMobile}
                  >
                    SNIMI SE U STUDIJU!
                  </StyledTypographyTitle>
                </Box>
                <Box sx={{ float: isMobile ? "center" : "right" }}>
                  <StyledTypographySubTitle
                    variant={isLgScreen ? "subtitle1" : isMobile ? "h5" : "h6"}
                    isMobile={isMobile}
                    color={theme.palette.purple.regular}
                    isLgScreen={isLgScreen}
                    sx={{
                      textAlign: isMobile ? "center" : "right",
                      float: isMobile ? "center" : "right",
                      margin: isMobile ? "auto" : 0,
                      fontWeight: 400,
                      fontFamily: "'Inter', 'sans-serif'",
                      lineHeight: "130%",
                    }}
                  >
                    Kada si neku pesmu dobro naučio/la, snimi se u muzičkom
                    studiju, pošalji nam snimak i budi deo našeg zajedničkog
                    novog spota!
                  </StyledTypographySubTitle>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: isMobile ? "center" : "flex-end",
                }}
              >
                <StyledButton
                  isLgScreen={isLgScreen}
                  onMouseEnter={() => handleMouseEnter("SNIMANJE")}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => handleClick("/virtual-choir")}
                >
                  {!isMobile && isHoveredRecording ? (
                    <StyledBox
                      color={theme.palette.purple.regular}
                      minHeight={"78px"}
                      url={purpleHover}
                      isLgScreen={isLgScreen}
                      isMobile={isMobile}
                    >
                      SNIMANJE
                    </StyledBox>
                  ) : (
                    <StyledBox
                      color={theme.palette.yellow.regular}
                      minHeight={"78px"}
                      url={purpleBackground}
                      isLgScreen={isLgScreen}
                      isMobile={isMobile}
                    >
                      SNIMANJE
                    </StyledBox>
                  )}
                </StyledButton>
              </Box>
            </Grid>
            {!isMobile && (
              <Grid item xs={12} sm={12} md={6}>
                <Box
                  sx={{
                    marginLeft: "45px",
                    [theme.breakpoints.between(1024, 1034)]: {
                      marginLeft: "20px",
                    },
                    [theme.breakpoints.between(1034, 1044)]: {
                      marginLeft: "20px",
                    },
                    [theme.breakpoints.between(1044, 1065)]: {
                      marginLeft: "22px",
                    },
                    [theme.breakpoints.between(1065, 1100)]: {
                      marginLeft: "25px",
                    },
                    [theme.breakpoints.between(1100, 1120)]: {
                      marginLeft: "27px",
                    },
                    [theme.breakpoints.between(1120, 1140)]: {
                      marginLeft: "28px",
                    },
                    [theme.breakpoints.between(1140, 1170)]: {
                      marginLeft: "30px",
                    },
                    [theme.breakpoints.between(1170, 1200)]: {
                      marginLeft: "32px",
                    },
                    [theme.breakpoints.between(1200, 1240)]: {
                      marginLeft: "34px",
                    },
                    [theme.breakpoints.between(1240, 1270)]: {
                      marginLeft: "36px",
                    },
                    [theme.breakpoints.between(1270, 1300)]: {
                      marginLeft: "39px",
                    },
                    [theme.breakpoints.between(1300, 1350)]: {
                      marginLeft: "41px",
                    },
                    [theme.breakpoints.between(1350, 1360)]: {
                      marginLeft: "44px",
                    },
                  }}
                >
                  <img
                    src={recordInTheStudio}
                    style={{
                      float: "left",
                      maxWidth: isLgScreen ? "285px" : "460px",
                      height: "auto",
                      marginTop: isLgScreen ? "-75px" : "-130px",
                    }}
                    alt="recordInTheStudio"
                    crossOrigin="anonymous"
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </StyledGrid>
        <StyledGrid
          item
          color={theme.palette.purple.regular}
          columnGap={10}
          isLgScreen={isLgScreen}
          sx={{
            backgroundImage: `url(${
              isMobile ? learnMoreBackgroundMobile : learnMoreBackgroundImg
            })`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: isLgScreen
              ? "110% 100%"
              : isMobile
              ? "100% 100%"
              : "",

            [theme.breakpoints.between(1023, 1123)]: {
              backgroundSize: "130% 100%",
            },
            [theme.breakpoints.between(1123, 1223)]: {
              backgroundSize: "120% 100%",
            },
            [theme.breakpoints.between(1223, 1323)]: {
              backgroundSize: "110% 100%",
            },
            [theme.breakpoints.between(1323, 1523)]: {
              backgroundSize: "100% 100%",
            },
            [theme.breakpoints.between(1523, 1560)]: {
              backgroundSize: "95% 100%",
            },
          }}
          isMobile={isMobile}
          first={false}
        >
          <Grid container rowGap={5} justifyContent={isMobile ? "center" : ""}>
            <Grid item xs={12} sm={12} md={6}>
              {!isMobile ? (
                <img
                  src={learnMore}
                  style={{
                    float: "right",
                    maxWidth: isLgScreen ? "280px" : "412px",
                    height: "auto",
                  }}
                  alt="learnMore"
                  crossOrigin="anonymous"
                />
              ) : (
                <img
                  src={learnMoreMobile}
                  style={{
                    maxWidth: "312px",
                    height: "auto",
                    marginRight: "8px",
                  }}
                  alt="learnMoreMobile"
                  crossOrigin="anonymous"
                />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                paddingLeft: isMobile ? 0 : 4,
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  textAlign: isMobile ? "center" : "left",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  margin: isMobile ? "auto" : 0,
                }}
              >
                <StyledTypographyTitle
                  variant={isLgScreen ? "h2" : "h1"}
                  color={theme.palette.yellow.regular}
                  sx={{ textAlign: isMobile ? "center" : "left" }}
                  isLgScreen={isLgScreen}
                  isMobile={isMobile}
                >
                  SAZNAJ NEŠTO NOVO!
                </StyledTypographyTitle>
                <StyledTypographySubTitle
                  variant={isLgScreen ? "subtitle1" : isMobile ? "h5" : "h6"}
                  isMobile={isMobile}
                  color={theme.palette.yellow.regular}
                  isLgScreen={isLgScreen}
                  sx={{
                    textAlign: isMobile ? "center" : "left",
                    margin: isMobile ? "auto" : 0,
                    fontWeight: 400,
                    fontFamily: "'Inter', 'sans-serif'",
                    lineHeight: "130%",
                  }}
                >
                  Saznaj kako drugi stvaraju svoju umetnost, nauči stvašta o
                  kulturi i umetnosti.
                </StyledTypographySubTitle>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: isMobile ? "center" : "",
                }}
              >
                <StyledButton
                  isLgScreen={isLgScreen}
                  onMouseEnter={() => handleMouseEnter("KULTURA")}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => handleClick("/culture")}
                >
                  {!isMobile && isHoveredCulture ? (
                    <StyledBox
                      color={theme.palette.yellow.regular}
                      minHeight={"78px"}
                      url={yellowHover}
                      isLgScreen={isLgScreen}
                      isMobile={isMobile}
                    >
                      KULTURA
                    </StyledBox>
                  ) : (
                    <StyledBox
                      color={theme.palette.purple.regular}
                      minHeight={"78px"}
                      url={yellowBackground}
                      isLgScreen={isLgScreen}
                      isMobile={isMobile}
                    >
                      KULTURA
                    </StyledBox>
                  )}
                </StyledButton>
              </Box>
            </Grid>
          </Grid>
        </StyledGrid>
      </Grid>
      <HomePageFooter />
    </>
  );
};
