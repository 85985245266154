import { lighten, PaletteOptions } from "@mui/material";

export const palette: PaletteOptions = {
  errors: {
    regular: "#FF7878",
    medium: lighten("#FF7878", 0.46),
    light: lighten("#FF7878", 0.91),
    disabled: "#833434",
  },
  yellow: {
    regular: "#FFECD2",
    medium: lighten("#FFECD2", 0.46),
    light: lighten("#FFECD2", 0.71),
    hover: "#f5bf50",
    disabled: "#EFDCC1",
  },
  purple: {
    regular: "#7A5D7F",
    medium: lighten("#7A5D7F", 0.46),
    light: "#CF97D9",
    hover: "#8e64bd",
    disabled: "#7A5D7F",
  },
  green: {
    regular: "#A2DD8A",
    medium: lighten("#A2DD8A", 0.46),
    light: lighten("#A2DD8A", 0.91),
    disabled: "#5E864E",
  },
  blue: {
    regular: "#8FC3F2",
    medium: lighten("#8FC3F2", 0.46),
    light: lighten("#8FC3F2", 0.91),
    disabled: "#355D82",
  },
};
