import { jsonRequest, request } from "../../../api";
import { Device, MergedVideosDto, ProjectTrack } from "./dto";

export async function uploadVideo(
  uploadVideoRequest: {
    author: string;
    name: string;
    track: ProjectTrack;
  },
  video: Blob
): Promise<void> {
  const formData = new FormData();
  const videoFile = new File([video], "to be set", {
    type: video.type,
  });
  formData.append("video", videoFile);
  formData.append("data", JSON.stringify(uploadVideoRequest));

  await request<void>("POST", "/api/recorded-videos", formData);
}

export async function mergeVideos(
  trackBlob: Blob | undefined,
  recordedVideoBlob: Blob,
  projectTrack: ProjectTrack,
  device: Device,
  trackDuration: number
): Promise<MergedVideosDto> {
  const formData = new FormData();
  if (trackBlob) {
    const track = new File([trackBlob], "track", { type: trackBlob.type });
    formData.append("track", track);
  }

  const recordedVideo = new File([recordedVideoBlob], "recordedVideo", {
    type: recordedVideoBlob.type,
  });

  formData.append("recordedVideo", recordedVideo);
  formData.append("projectTrack", projectTrack.toString());
  formData.append("device", device.toString());
  formData.append("trackDuration", trackDuration.toString());
  const response = await request<MergedVideosDto>(
    "POST",
    "/api/merge-videos",
    formData
  );
  return new MergedVideosDto(response.base64String);
}

export async function subscribe(email: string): Promise<void> {
  await jsonRequest<void>("POST", "/api/newsletters", {
    email: email,
  });
}
