export interface LogoSvgProps {
  width: string;
  height: string;
}

export const LogoSvg: React.FC<LogoSvgProps> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 181 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7_152)">
        <path
          d="M132.507 36.3486C132.318 37.0149 131.916 37.6019 131.36 38.0204C130.804 38.4388 130.126 38.6658 129.428 38.6669C129.133 38.6652 128.84 38.6267 128.554 38.5523C125.432 37.6616 122.214 37.1397 118.968 36.9974C118.77 36.9867 118.573 36.9615 118.379 36.9219C118.867 36.9219 119.405 36.9722 119.958 37.0366C120.382 37.0889 120.812 37.0565 121.224 36.9415C121.635 36.8264 122.019 36.6309 122.353 36.3665C122.687 36.1021 122.964 35.7741 123.169 35.4019C123.373 35.0296 123.5 34.6205 123.543 34.1988C123.586 33.777 123.543 33.351 123.417 32.9459C123.292 32.5407 123.085 32.1646 122.811 31.8397C122.537 31.5147 122.199 31.2474 121.819 31.0536C121.439 30.8598 121.024 30.7433 120.598 30.7109C119.034 30.5517 117.462 30.4845 115.89 30.5096C116.713 27.481 118.238 21.6531 119.752 14.8689C120.044 13.4441 120.473 12.0505 121.035 10.7077C121.627 9.29697 122.525 8.03287 123.665 7.00518C123.094 7.59072 122.772 8.37116 122.763 9.18576C122.755 10.0003 123.062 10.7871 123.621 11.3839C124.18 11.9806 124.949 12.3421 125.768 12.3937C126.588 12.4453 127.396 12.1832 128.027 11.6613C127.526 12.1029 127.139 12.6572 126.899 13.2777C126.504 14.2357 126.204 15.2293 126.003 16.2447C124.655 22.2879 123.319 27.5062 122.422 30.8787C125.08 31.1824 127.71 31.6967 130.285 32.4168C130.692 32.53 131.071 32.7216 131.403 32.9806C131.735 33.2395 132.011 33.5608 132.218 33.9259C132.424 34.2911 132.556 34.693 132.605 35.1087C132.655 35.5244 132.622 35.9458 132.507 36.3486Z"
          fill="#A2DD8A"
        />
        <path
          d="M151.216 22.2124C150.371 19.5278 149.243 16.4992 147.574 13.686C147.534 13.6097 147.487 13.5376 147.433 13.4706C145.93 10.9538 144.007 8.60756 141.416 6.84018C137.861 4.39606 133.088 3.55712 128.636 4.58622C129.463 4.39899 130.33 4.54389 131.05 4.98925C131.769 5.43461 132.281 6.14423 132.474 6.96304C132.668 7.78185 132.526 8.64328 132.081 9.3591C131.636 10.0749 130.924 10.5869 130.099 10.7832C132.806 10.1624 135.682 10.6322 137.771 12.0696C139.188 13.071 140.399 14.3326 141.337 15.7861C140.94 16.5747 140.108 17.4137 139.31 18.1771C138.924 18.5279 138.518 18.8557 138.092 19.1587C137.72 19.4383 137.348 19.718 136.99 20.0228C134.822 21.8293 133.359 24.0329 132.761 26.4099C131.977 29.4665 132.721 32.6713 134.698 34.7994C135.504 35.64 136.476 36.3075 137.553 36.7606C138.63 37.2137 139.79 37.4428 140.96 37.4337C141.766 37.4345 142.568 37.3273 143.345 37.1149C145.733 36.4661 147.887 34.8777 149.466 32.6237C150.09 33.3863 150.79 34.0852 151.555 34.7099C150.924 34.1695 150.53 33.4061 150.458 32.5822C150.386 31.7584 150.641 30.9391 151.168 30.2988C151.696 29.6585 152.455 29.2477 153.283 29.1539C154.111 29.0601 154.944 29.2906 155.603 29.7965C154.4 28.8149 152.654 26.7036 151.216 22.2124ZM144.38 28.7646C143.635 29.9111 142.634 30.7221 141.648 31.0018C140.824 31.2171 139.888 31.0018 139.415 30.504C138.941 30.0062 138.735 28.9268 138.978 27.9872C139.26 26.9301 140.004 25.8479 141.106 24.911C141.388 24.6817 141.67 24.4664 141.952 24.2483C142.585 23.7982 143.185 23.3049 143.748 22.7717C143.943 22.5816 144.174 22.3662 144.402 22.1369C144.647 22.8249 144.879 23.4988 145.096 24.1476C145.313 24.7964 145.507 25.3697 145.724 25.9541C145.375 26.9346 144.925 27.8765 144.38 28.7646Z"
          fill="#8FC3F2"
        />
        <path
          d="M130.099 10.7804C129.351 10.9199 128.642 11.2195 128.022 11.6585C127.391 12.1804 126.583 12.4425 125.763 12.3909C124.943 12.3393 124.175 11.9778 123.616 11.381C123.057 10.7843 122.75 9.99754 122.758 9.18295C122.766 8.36836 123.089 7.58792 123.66 7.00238C123.748 6.91552 123.842 6.83515 123.942 6.76188C125.319 5.68216 126.931 4.93622 128.65 4.58343C129.477 4.39619 130.345 4.54109 131.064 4.98645C131.783 5.43181 132.295 6.14143 132.489 6.96024C132.682 7.77906 132.541 8.64049 132.096 9.35631C131.651 10.0721 130.938 10.5841 130.114 10.7804H130.099Z"
          fill="#7A5D7F"
        />
        <path
          d="M179.108 22.3019C178.781 23.4145 178.515 24.5441 178.313 25.6857C178.223 26.4614 177.849 27.1775 177.263 27.6981C176.676 28.2187 175.917 28.5077 175.13 28.5101C175.005 28.5142 174.881 28.5057 174.758 28.4849C174.34 28.4391 173.935 28.3117 173.567 28.1101C173.198 27.9085 172.874 27.6367 172.612 27.3103C172.35 26.9838 172.155 26.6093 172.04 26.2081C171.924 25.8069 171.89 25.387 171.938 24.9726C172.068 23.8288 172.44 22.4557 172.9 20.6855C173.85 17.1228 175.274 11.7508 174.222 8.72222C174.359 9.11664 174.573 9.48039 174.852 9.79271C175.131 10.105 175.469 10.3598 175.848 10.5425C176.226 10.7252 176.636 10.8322 177.056 10.8574C177.476 10.8826 177.897 10.8256 178.295 10.6895C178.692 10.5535 179.059 10.3411 179.374 10.0644C179.689 9.78779 179.946 9.45233 180.13 9.07723C180.314 8.70212 180.422 8.29471 180.447 7.87825C180.473 7.46179 180.415 7.04445 180.278 6.65003C181.97 11.5103 180.315 17.7465 179.108 22.3019Z"
          fill="#A2DD8A"
        />
        <path
          d="M160.839 34.338C160.766 35.125 160.399 35.8564 159.81 36.3888C159.221 36.9212 158.453 37.2161 157.656 37.2155C157.55 37.2225 157.443 37.2225 157.337 37.2155C155.203 36.9755 153.188 36.1122 151.549 34.735C151.222 34.471 150.951 34.1457 150.751 33.7777C150.55 33.4096 150.425 33.0061 150.382 32.5901C150.339 32.1741 150.38 31.7537 150.5 31.3531C150.621 30.9525 150.82 30.5794 151.087 30.2551C151.353 29.9308 151.681 29.6617 152.052 29.4632C152.423 29.2647 152.83 29.1405 153.249 29.098C153.669 29.0554 154.092 29.0951 154.496 29.215C154.9 29.3348 155.276 29.5324 155.603 29.7965C156.267 30.3743 157.09 30.7424 157.966 30.8536C158.812 30.9397 159.589 31.3547 160.127 32.0076C160.666 32.6606 160.922 33.4984 160.839 34.338Z"
          fill="#7A5D7F"
        />
        <path
          d="M178.299 10.6965C177.494 10.9694 176.613 10.9148 175.848 10.5448C175.084 10.1748 174.498 9.51948 174.219 8.72222C173.887 7.76862 173.297 6.84019 171.27 6.48504C170.456 6.34363 169.728 5.89624 169.238 5.23598C168.748 4.57572 168.533 3.75344 168.639 2.94028C168.745 2.12711 169.163 1.38569 169.806 0.870321C170.448 0.354948 171.267 0.105321 172.091 0.173386C172.185 0.179559 172.28 0.19264 172.373 0.212532C176.32 0.897669 179.057 3.11248 180.267 6.64443C180.546 7.44067 180.496 8.31432 180.127 9.07388C179.758 9.83345 179.101 10.417 178.299 10.6965Z"
          fill="#7A5D7F"
        />
        <path
          d="M171.27 6.49621C170.11 6.32048 168.93 6.30731 167.766 6.45706C165.482 6.70036 162.263 7.98394 161.16 9.09134C160.658 9.6003 159.672 11.2055 159.827 12.9225C159.968 14.3599 160.904 15.6966 162.623 16.8935C168.586 21.0155 169.19 26.6029 167.755 30.4453C166.227 34.5561 162.398 37.2407 158.259 37.2519C157.952 37.2519 157.644 37.2267 157.337 37.2015C157.443 37.2085 157.55 37.2085 157.656 37.2015C158.481 37.2041 159.276 36.8907 159.875 36.3266C160.474 35.7625 160.83 34.9912 160.87 34.1731C160.91 33.355 160.631 32.5532 160.09 31.9343C159.549 31.3155 158.788 30.9274 157.966 30.8507C159.711 31.0157 161.197 29.6678 161.738 28.2304C162.533 26.1051 161.518 23.8651 158.953 22.095C154.797 19.2062 153.655 15.8365 153.435 13.493C153.308 11.8781 153.523 10.2546 154.067 8.72727C154.61 7.19991 155.471 5.80256 156.593 4.62537C159.029 2.15608 163.827 0.46421 167.086 0.120243C168.75 -0.0767647 170.432 -0.0598479 172.091 0.170577C171.267 0.102513 170.449 0.35214 169.806 0.867512C169.163 1.38288 168.745 2.1243 168.639 2.93747C168.534 3.75063 168.748 4.57291 169.238 5.23317C169.728 5.89343 170.456 6.34082 171.27 6.48223V6.49621Z"
          fill="#FF7878"
        />
        <path
          d="M12.7884 36.1081C12.3082 36.1742 11.824 36.2078 11.3392 36.2088C8.84625 36.206 6.41875 35.4169 4.4077 33.9556C2.39665 32.4942 0.906259 30.4363 0.152024 28.0794C0.0246598 27.681 -0.0223377 27.2616 0.0137229 26.8451C0.0497835 26.4286 0.168207 26.0233 0.362202 25.6522C0.556197 25.2811 0.821979 24.9516 1.14437 24.6824C1.46677 24.4132 1.83946 24.2096 2.24118 24.0833C3.05248 23.8281 3.93272 23.9031 4.68828 24.2917C5.06239 24.4842 5.39464 24.7478 5.66603 25.0676C5.93743 25.3873 6.14267 25.757 6.27004 26.1555C6.63304 27.3161 7.39287 28.3149 8.41857 28.9795C9.44428 29.6441 10.6716 29.9331 11.889 29.7965C13.699 29.542 15.3399 27.7858 15.1482 26.3121C14.9678 24.9865 13.6342 23.5995 12.2104 22.1509C10.2228 20.1011 7.96447 17.7856 7.54157 14.3376C7.11867 10.8895 9.04147 7.22331 12.2612 5.54263C15.0072 4.09125 17.6772 3.69695 20.2174 4.34573C19.4098 4.1752 18.567 4.32006 17.8644 4.75016C17.1617 5.18026 16.6533 5.8625 16.4448 6.65495C16.2364 7.44741 16.344 8.28907 16.7452 9.00486C17.1465 9.72064 17.8104 10.2554 18.5991 10.498C17.6772 10.2575 16.5466 10.4728 15.2638 11.1468C14.3926 11.6166 13.7893 12.6597 13.9049 13.5517C14.0712 14.8885 15.4048 16.2615 16.8285 17.7269C18.8021 19.7627 21.0576 22.0642 21.5002 25.4871C22.1543 30.4061 18.0747 35.3699 12.7884 36.1081Z"
          fill="#8FC3F2"
        />
        <path
          d="M95.6776 26.8182C96.4271 27.6456 97.3397 28.3118 98.3588 28.7758C97.9748 29.4782 97.5245 30.143 97.014 30.7613C94.9079 33.3704 92.2211 34.9728 89.4017 35.2916C89.0348 35.3328 88.6658 35.3543 88.2965 35.3559C87.4436 35.3441 86.5939 35.2504 85.7591 35.0762C83.6847 34.7109 81.7249 33.8701 80.0358 32.6209C77.5999 30.7389 75.8406 27.7606 74.8285 23.7505C74.4676 22.3523 74.1208 20.1878 73.7515 17.9114C73.3821 15.6351 72.979 13.0763 72.6237 11.8151C72.3923 11.0019 72.4961 10.1308 72.9122 9.39339C73.3283 8.65602 74.0227 8.11282 74.8426 7.88328C75.6625 7.65373 76.5407 7.75664 77.2841 8.16937C78.0275 8.58211 78.5752 9.27086 78.8066 10.0841C79.269 11.7144 79.6665 14.2341 80.1007 16.9047C80.4362 18.9405 80.7689 21.0547 81.0649 22.1984C81.719 24.7964 82.7057 26.6029 83.9886 27.5956C84.8841 28.2333 85.9169 28.6555 87.0053 28.8289C87.5585 28.9617 88.1297 29.0052 88.6969 28.9575C89.7993 28.8429 91.0172 28.0403 92.0322 26.7791C92.5453 26.1443 93.1853 25.1655 93.3009 24.1448C93.3266 23.3466 93.2537 22.5484 93.0838 21.7678L93.0584 21.614C93.4567 23.5464 94.3599 25.3411 95.6776 26.8182Z"
          fill="#8FC3F2"
        />
        <path
          d="M90.4986 8.46774C90.2896 7.6493 90.417 6.78203 90.8527 6.05673C91.2884 5.33143 91.9967 4.8075 92.8218 4.6002C93.6469 4.39291 94.5213 4.51922 95.2525 4.95136C95.9838 5.3835 96.512 6.08607 96.721 6.90451C97.0029 8.0231 97.3102 9.39616 97.6063 10.8727C97.4145 10.9762 97.22 11.0908 97.0283 11.2167C94.7728 12.6429 93.2814 15.059 92.8838 17.8723C92.7499 18.8475 92.7499 19.8362 92.8838 20.8114C92.2918 17.2738 91.2909 11.5858 90.4986 8.46774Z"
          fill="#8FC3F2"
        />
        <path
          d="M63.926 34.6959C58.7045 35.5097 54.2668 36.2731 49.082 37.2519C48.8834 37.2919 48.6814 37.3134 48.4787 37.3162C47.6813 37.3137 46.9134 37.0169 46.3244 36.4837C45.7354 35.9506 45.3674 35.2191 45.2922 34.4317C45.2169 33.6443 45.4397 32.8573 45.9172 32.2238C46.3946 31.5903 47.0927 31.1557 47.8754 31.0046C49.4683 30.6997 50.9936 30.4201 52.4935 30.1516C52.2764 28.4737 52.0706 26.8434 51.8789 25.2662C51.5969 22.7857 51.2755 20.2129 50.878 17.5032L50.7511 16.5887C50.6468 15.9008 50.5566 15.238 50.4692 14.5892L48.3378 14.8046C49.1831 14.7204 49.96 14.3066 50.4978 13.6542C51.0355 13.0018 51.2899 12.1642 51.205 11.3257C51.1202 10.4873 50.703 9.7166 50.0452 9.18324C49.3875 8.64989 48.5431 8.39754 47.6978 8.48172L59.3079 7.30999C60.1485 7.2336 60.9856 7.48919 61.6374 8.02127C62.2892 8.55336 62.7031 9.31898 62.7892 10.1519C62.8753 10.9849 62.6268 11.8179 62.0974 12.4701C61.5681 13.1224 60.8007 13.5412 59.962 13.6356L56.8438 13.9544C56.934 14.5137 57.0101 15.0982 57.1003 15.6966L57.23 16.6139C57.6275 19.388 57.9461 21.9943 58.2563 24.5139C58.4226 25.9905 58.6143 27.5174 58.8201 29.0694C60.1537 28.8429 61.5126 28.6247 62.9251 28.4094C63.7609 28.288 64.6113 28.4984 65.2916 28.9949C65.972 29.4915 66.4275 30.2342 66.5593 31.0618C66.691 31.8894 66.4884 32.7352 65.9954 33.4155C65.5024 34.0959 64.7588 34.556 63.926 34.6959Z"
          fill="#A2DD8A"
        />
        <path
          d="M172.982 37.7944C172.447 37.7947 171.921 37.6623 171.451 37.4094C170.981 37.1566 170.583 36.7911 170.292 36.3465C170 35.9018 169.826 35.392 169.784 34.8634C169.742 34.3348 169.835 33.8043 170.052 33.32C170.361 32.6398 170.838 32.0481 171.44 31.6002C172.041 31.1523 172.747 30.8627 173.492 30.7585C174.17 30.6617 174.862 30.7679 175.48 31.0633C176.137 31.3807 176.672 31.9024 177.003 32.549C177.334 33.1955 177.444 33.9317 177.315 34.6456C177.078 35.9487 175.75 38.0964 172.982 37.7944Z"
          fill="#7A5D7F"
        />
        <path
          d="M116.025 35.4342C115.685 34.9728 115.454 34.4413 115.349 33.8792C115.245 33.3171 115.27 32.7388 115.422 32.1875C115.526 31.8071 115.678 31.2478 115.884 30.5096C115.013 30.5096 114.229 30.5711 113.499 30.6354C113.922 27.316 114.139 23.4848 113.665 20.0619C113.668 20.0314 113.663 20.0008 113.651 19.9724C113.011 15.403 110.482 11.5998 107.071 10.0338C104.173 8.71103 100.837 9.01584 97.6034 10.8727C98.2068 13.8398 98.796 17.2375 99.233 19.7683C99.1879 19.4221 99.1879 19.0715 99.233 18.7252C99.3119 18.1659 99.5685 17.148 100.465 16.5747C101.518 15.911 102.457 15.5801 103.284 15.582C103.661 15.5772 104.033 15.6555 104.375 15.8113C105.76 16.4349 106.913 18.3952 107.274 20.6967C107.248 21.793 106.58 22.5927 105.3 23.1017C103.566 23.7757 101.311 23.5212 100.437 22.5676C99.926 21.9826 99.5532 21.2918 99.3458 20.5457L99.3711 20.6967C99.6023 22.0335 99.7969 23.3562 99.6531 24.8076C99.4963 26.2003 99.055 27.5467 98.3562 28.7646C100.936 30.0258 104.22 30.2159 107.209 29.184C107.107 30.037 106.992 30.8619 106.876 31.6421C102.873 32.4307 98.0094 34.0723 94.7784 36.6283C88.1586 41.8717 87.902 47.5989 90.5691 50.9323C92.0831 52.8283 94.598 54.0084 97.485 54.0084C99.9999 54.0084 102.797 53.1163 105.452 51.0301C107.44 49.4641 109.044 47.2884 110.212 44.5395C111.162 42.2883 111.7 39.998 112.213 37.7832C112.253 37.6042 112.304 37.4001 112.34 37.1987C114.327 36.8984 116.339 36.8046 118.345 36.9191C117.413 36.7279 116.585 36.1984 116.025 35.4342ZM104.313 42.0646C103.569 43.8068 102.622 45.1408 101.494 46.0328C98.8637 48.0826 96.3488 47.8925 95.6045 46.9752C94.9391 46.1363 95.8244 43.9606 98.8016 41.606C100.428 40.3056 102.943 39.2625 105.447 38.5299C105.157 39.7342 104.779 40.9157 104.313 42.0646Z"
          fill="#FF7878"
        />
        <path
          d="M123.468 34.187C123.383 35.0272 122.964 35.7994 122.305 36.3337C121.646 36.8681 120.8 37.1209 119.952 37.0366C119.389 36.9722 118.861 36.9359 118.374 36.9219H118.348C117.835 36.8158 117.349 36.6067 116.92 36.3074C116.491 36.0081 116.128 35.6249 115.854 35.1815C115.58 34.738 115.4 34.2437 115.326 33.7288C115.252 33.2139 115.284 32.6894 115.422 32.1875C115.526 31.8071 115.678 31.2478 115.884 30.5096C117.456 30.4845 119.028 30.5517 120.592 30.7109C121.438 30.7951 122.215 31.2081 122.754 31.8597C123.293 32.5112 123.55 33.3481 123.468 34.187Z"
          fill="#7A5D7F"
        />
        <path
          d="M99.6557 24.8076C99.4989 26.2003 99.0577 27.5467 98.3588 28.7646C97.3396 28.3006 96.4271 27.6344 95.6776 26.807C94.3535 25.3355 93.4425 23.5447 93.0359 21.614C92.9964 21.3987 92.9597 21.1805 92.9203 20.94C92.8987 20.9008 92.8899 20.8558 92.8949 20.8114C92.7609 19.8362 92.7609 18.8475 92.8949 17.8723C93.2924 15.059 94.7951 12.6429 97.0394 11.2167C97.2311 11.0908 97.4256 10.9762 97.6173 10.8727C98.2207 13.8398 98.8099 17.2375 99.2469 19.7683C99.24 19.8062 99.24 19.845 99.2469 19.883C99.2636 20.0888 99.2985 20.2928 99.3512 20.4926C99.3553 20.5101 99.3553 20.5282 99.3512 20.5457L99.3766 20.6967C99.6162 22.0335 99.8023 23.3646 99.6557 24.8076Z"
          fill="#7A5D7F"
        />
        <path
          d="M26.4119 16.2308C26.0215 16.3879 25.6036 16.4668 25.1823 16.4629C24.761 16.459 24.3446 16.3724 23.9573 16.2081C23.5699 16.0438 23.2191 15.805 22.9252 15.5056C22.6313 15.2061 22.4001 14.8519 22.2449 14.4634C21.7431 13.2162 20.4095 10.9874 18.5995 10.5176C17.8109 10.275 17.1469 9.7402 16.7456 9.02442C16.3444 8.30864 16.2368 7.46698 16.4452 6.67453C16.6537 5.88207 17.1621 5.19984 17.8648 4.76974C18.5675 4.33963 19.4102 4.19478 20.2178 4.36531C25.2588 5.66567 27.6074 10.6266 28.1966 12.1171C28.353 12.5033 28.4309 12.9164 28.4258 13.3326C28.4207 13.7488 28.3327 14.1598 28.1668 14.5421C28.001 14.9244 27.7605 15.2704 27.4594 15.5602C27.1582 15.85 26.8022 16.0779 26.4119 16.2308Z"
          fill="#7A5D7F"
        />
        <path
          d="M51.2108 11.3201C51.2942 12.1598 51.0387 12.9981 50.5002 13.6512C49.9617 14.3043 49.1841 14.7191 48.3378 14.8046C47.7599 14.8689 47.0437 14.9332 46.7336 15.0982C46.4295 15.3223 46.1831 15.6145 46.0147 15.9511C45.735 16.4383 45.3302 16.8433 44.8414 17.1248C44.3525 17.4063 43.7972 17.5542 43.232 17.5535C42.6744 17.5515 42.1268 17.407 41.6419 17.134C41.2756 16.9274 40.954 16.651 40.6957 16.3208C40.4374 15.9905 40.2474 15.613 40.1366 15.2097C40.0257 14.8064 39.9963 14.3854 40.05 13.9709C40.1036 13.5563 40.2393 13.1563 40.4493 12.7939C41.1993 11.3849 42.3503 10.2258 43.7592 9.46048C45.1689 8.72221 46.6321 8.58239 47.7063 8.48172C48.5501 8.39888 49.3927 8.65105 50.0495 9.18304C50.7063 9.71502 51.1239 10.4835 51.2108 11.3201Z"
          fill="#7A5D7F"
        />
        <path
          d="M46.0149 15.9511C45.7065 16.5178 45.4371 17.1045 45.2086 17.7073C43.0405 22.9255 40.5961 28.1186 37.96 33.1187C37.6132 33.7926 36.5503 35.8285 34.1482 36.1193C33.9747 36.1454 33.7994 36.1585 33.6238 36.1585C31.8392 36.1585 29.7867 35.0399 28.8309 31.8827C27.6383 27.8753 26.0116 23.854 24.4581 19.9585L22.2393 14.4634C22.3951 14.851 22.6264 15.2044 22.92 15.5033C23.2136 15.8023 23.5636 16.0409 23.9502 16.2057C24.3367 16.3705 24.7522 16.4581 25.1729 16.4636C25.5936 16.4691 26.0113 16.3924 26.402 16.2378C26.7928 16.0831 27.1491 15.8537 27.4505 15.5625C27.7519 15.2714 27.9925 14.9241 28.1586 14.5407C28.3247 14.1573 28.4131 13.7452 28.4186 13.3279C28.4242 12.9106 28.3468 12.4964 28.1909 12.1087L30.4098 17.5926C31.6277 20.6212 32.8739 23.7141 33.965 26.8937C35.8624 23.0905 37.6471 19.195 39.2625 15.2883C39.5956 14.4299 39.9904 13.5963 40.4439 12.7939C40.2339 13.1563 40.0982 13.5563 40.0446 13.9709C39.9909 14.3854 40.0203 14.8064 40.1311 15.2097C40.2419 15.613 40.432 15.9905 40.6903 16.3208C40.9486 16.651 41.2701 16.9274 41.6364 17.134C42.1213 17.407 42.669 17.5515 43.2266 17.5535C43.7928 17.5552 44.3493 17.4078 44.8392 17.1262C45.3291 16.8447 45.7348 16.4392 46.0149 15.9511Z"
          fill="#FF7878"
        />
      </g>
      <defs>
        <clipPath id="clip0_7_152">
          <rect width="181" height="125" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
