import {
  Box,
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import {
  VirtualChoirDialog,
  VirtualChoirFormikTextField,
} from "../../../../../components";
import { useUploadedVideoForm } from "../../../state";
import { useIsLgScreen } from "../../../../../hooks/useIsLgScreen";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import {
  EmailIconSvg,
  EmialIconHoverSvg,
  FacebookLogoHoverSvg,
  FacebookLogoSvg,
  InstagramLogoHoverSvg,
  InstagramLogoSvg,
  TikTokLogoHoverSvg,
  TikTokLogoSvg,
  YouTubeLogoHoverSvg,
  YouTubeLogoSvg,
} from "../../../../../assets";
import { useIsMobile } from "../../../../../hooks/useIsMobile";
import { Field, Form, Formik, FormikErrors, FormikHelpers } from "formik";
import { useMemoizedFn } from "ahooks";
import { SubscribeFormValues } from "../UploadVideoDialog/types";
import { useSubscribeMutation } from "../../../hooks/useSubscribeMutation";
import { VirtualChoirError } from "../../../../../api";
import * as ReactDOMServer from "react-dom/server";
import { ShortButtonBackgroundSvg } from "../../../../../menuHovers";
import { theme } from "../../../../../theme";

const StyledLinkButton = styled(NavLink)<{ isMobile: boolean }>(
  ({ isMobile }) => ({
    padding: isMobile ? 2 : 0,
  })
);

const StyledButton = styled(Button)<{ isMobile: boolean }>(({ isMobile }) => ({
  padding: isMobile ? 2 : 0,
  marginTop: "-8px",
  justifyContent: "normal",
  minWidth: 0,
  "&:hover": {
    backgroundColor: "inherit",
  },
}));

const StyledFormButton = styled(Button)<{
  background: string;
  hover: string;
  isMobile: boolean;
}>(({ theme, background, hover, isMobile }) => ({
  backgroundImage: `url("data:image/svg+xml,${background}")`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  width: isMobile ? "90px" : "114px",
  height: isMobile ? "33px" : "41px",
  color: theme.palette.yellow.regular,
  fontWeight: 700,
  "&:hover": {
    backgroundImage: `url("data:image/svg+xml,${hover}")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: "inherit",
  },
}));

export type FormValues = SubscribeFormValues;

export interface SubscribeFormMutationValues {
  email: string;
}

export const UploadedVideoDialog: React.FC = () => {
  const form = useUploadedVideoForm();
  const { isOpen, closeDialog } = form;
  const isLgScreen = useIsLgScreen();
  const isMobile = useIsMobile();
  const subscribe = useSubscribeMutation();

  const [isHoveredInstagram, setIsHoveredInstagram] = useState(false);
  const [isHoveredFacebook, setIsHoveredFacebook] = useState(false);
  const [isHoveredYouTube, setIsHoveredYouTube] = useState(false);
  const [isHoveredTikTok, setIsHoveredTikTok] = useState(false);
  const [isHoveredEmail, setIsHoveredEmail] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  const socialMedia = [
    {
      socialMedia: "Instagram",
      path: "https://www.instagram.com/art.aparat",
    },
    {
      socialMedia: "Facebook",
      path: "https://www.facebook.com/artaparatbelgrade/",
    },
    {
      socialMedia: "YouTube",
      path: "https://www.youtube.com/@udruzenjeartaparat7614",
    },
    { socialMedia: "TikTok", path: "https://www.tiktok.com/@art.aparat" },
    { socialMedia: "Email", path: "/" },
  ];

  const socialMediaIcon = (index: number): JSX.Element => {
    if (index === 0) {
      return (
        <InstagramLogoSvg
          width={isLgScreen ? "24" : "34"}
          height={isLgScreen ? "24" : "34"}
        />
      );
    } else if (index === 1) {
      return <FacebookLogoSvg width={isLgScreen ? "30" : "38"} height="38" />;
    } else if (index === 2) {
      return <YouTubeLogoSvg width={isLgScreen ? "30" : "38"} height="29" />;
    } else if (index === 3) {
      return <TikTokLogoSvg width={isLgScreen ? "26" : "33"} height="33" />;
    } else {
      return <EmailIconSvg width={isLgScreen ? "31" : "39"} height="46" />;
    }
  };

  const socialMediaHoverIcon = (index: number): JSX.Element => {
    if (index === 0) {
      return (
        <InstagramLogoHoverSvg
          width={isLgScreen ? "24" : "34"}
          height={isLgScreen ? "24" : "34"}
        />
      );
    } else if (index === 1) {
      return (
        <FacebookLogoHoverSvg width={isLgScreen ? "30" : "38"} height="38" />
      );
    } else if (index === 2) {
      return (
        <YouTubeLogoHoverSvg width={isLgScreen ? "30" : "38"} height="29" />
      );
    } else if (index === 3) {
      return (
        <TikTokLogoHoverSvg width={isLgScreen ? "26" : "33"} height="33" />
      );
    } else {
      return <EmialIconHoverSvg width={isLgScreen ? "31" : "39"} />;
    }
  };

  const handleMouseEnter = (socialMedia: string) => {
    if (socialMedia === "Instagram") {
      setIsHoveredInstagram(true);
      setIsHoveredFacebook(false);
      setIsHoveredYouTube(false);
      setIsHoveredTikTok(false);
      setIsHoveredEmail(false);
    } else if (socialMedia === "Facebook") {
      setIsHoveredFacebook(true);
      setIsHoveredInstagram(false);
      setIsHoveredYouTube(false);
      setIsHoveredTikTok(false);
      setIsHoveredEmail(false);
    } else if (socialMedia === "YouTube") {
      setIsHoveredYouTube(true);
      setIsHoveredInstagram(false);
      setIsHoveredFacebook(false);
      setIsHoveredTikTok(false);
      setIsHoveredEmail(false);
    } else if (socialMedia === "TikTok") {
      setIsHoveredTikTok(true);
      setIsHoveredFacebook(false);
      setIsHoveredYouTube(false);
      setIsHoveredInstagram(false);
      setIsHoveredEmail(false);
    } else {
      setIsHoveredEmail(true);
      setIsHoveredInstagram(false);
      setIsHoveredFacebook(false);
      setIsHoveredYouTube(false);
      setIsHoveredTikTok(false);
    }
  };

  const handleMouseLeave = () => {
    setIsHoveredInstagram(false);
    setIsHoveredFacebook(false);
    setIsHoveredYouTube(false);
    setIsHoveredTikTok(false);
    setIsHoveredEmail(false);
  };

  const handleEmailClick = () => {
    window.open("mailto:email@example.com");
  };

  const background = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      <ShortButtonBackgroundSvg color={theme.palette.green.disabled} />
    )
  );

  const hover = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      <ShortButtonBackgroundSvg color={theme.palette.green.regular} />
    )
  );

  const validate = useMemoizedFn(
    async (values: FormValues): Promise<FormikErrors<FormValues>> => {
      const errors: FormikErrors<FormValues> = {};

      if (values.email === "") {
        errors.email = "Obavezno polje";
      }
      return errors;
    }
  );

  const doSubmit = useMemoizedFn((values: FormValues) => {
    return subscribe.mutateAsync({
      email: values.email,
    });
  });

  const handleSubmit = useMemoizedFn(
    (values: FormValues, formik: FormikHelpers<FormValues>) => {
      return doSubmit(values)
        .then(() => {
          setShowSuccessMsg(true);
        })
        .catch((err: VirtualChoirError) => {
          formik.setFieldError("name", "Mejl nije poslat. Probajte ponovo.");
        });
    }
  );

  return (
    <VirtualChoirDialog open={isOpen} onClose={closeDialog}>
      <Grid
        container
        direction={"row"}
        spacing={2}
        justifyContent="space-between"
      >
        <Grid item xxs={12} xs={12} md={12}>
          <DialogTitle
            sx={(theme) => ({
              color: theme.palette.purple.regular,
              textAlign: "center",
              fontSize: "24px",
              marginTop: isMobile ? 3 : 0,
            })}
          >
            HVALA NA POSLATOM SNIMKU. BRAVO!
          </DialogTitle>
        </Grid>
      </Grid>
      <DialogContent>
        <Grid container rowSpacing={4} justifyContent="center">
          <Grid item md={12}>
            <Box
              justifyContent={"center"}
              display={"flex"}
              alignContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
            >
              <Typography
                variant={isLgScreen ? "subtitle1" : isMobile ? "h5" : "h6"}
                sx={(theme) => ({
                  fontWeight: 400,
                  color: theme.palette.purple.regular,
                  fontFamily: "'Inter', 'sans-serif'",
                  maxWidth: "675px",
                  textAlign: "center",
                  ml: isMobile ? 3 : 0,
                  mr: isMobile ? 3 : 0,
                })}
              >
                Ako želiš da te svi čujemo, podeli snimak na svojim mrežama
                (TikTok i Instagram) uz hešteg <b>#sviuglasvirtuelnihor</b>.
                Slobodno nas taguj na videu ili storiju na <b>@art.aparat</b>!
                <br />
                <br />
                Ovde možeš uneti svoju imejl adresu, kako kako bismo te
                obaveštavali o novostima o virtuelnom horu:
              </Typography>
              <Box mt={2} />
              <Box
                sx={{
                  justifyContent: "center",
                  display: "flex",

                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <Formik
                  enableReinitialize
                  initialValues={{
                    email: "",
                  }}
                  validate={validate}
                  validateOnMount
                  onSubmit={handleSubmit}
                >
                  {({ isSubmitting, isValid, isValidating }) => {
                    return (
                      <>
                        <DialogContentText>
                          <Form style={{ margin: 0 }}>
                            <Grid
                              container
                              spacing={2}
                              justifyContent="space-between"
                            >
                              <Grid item md={12} sm={12} xs={12} xxs={12}>
                                <Field
                                  name="email"
                                  component={VirtualChoirFormikTextField}
                                  placeholder="email"
                                  property="standard"
                                />
                                {showSuccessMsg && (
                                  <Typography
                                    variant={
                                      isLgScreen ? "subtitle2" : "subtitle1"
                                    }
                                    sx={(theme) => ({
                                      color: theme.palette.purple.regular,
                                      width: "100%",
                                      marginTop: 1,
                                      fontWeight: 400,
                                      fontFamily: "'Inter', 'sans-serif'",
                                      textAlign: "center",
                                      lineHeight: "130%",
                                    })}
                                  >
                                    Sačuvali smo Vaš imejl. Hvala!
                                  </Typography>
                                )}
                              </Grid>
                              <Grid item md={12} sm={12} xs={12} xxs={12}>
                                <Box
                                  sx={{
                                    justifyContent: "center",
                                    display: "flex",
                                    alignContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <StyledFormButton
                                    color="inherit"
                                    type="submit"
                                    background={background}
                                    hover={hover}
                                    isMobile={isMobile}
                                    disabled={
                                      isSubmitting || !isValid || showSuccessMsg
                                    }
                                  >
                                    SAČUVAJ
                                  </StyledFormButton>
                                </Box>
                              </Grid>
                            </Grid>
                          </Form>
                        </DialogContentText>
                      </>
                    );
                  }}
                </Formik>
              </Box>

              <Box mt={5} />
              <Typography
                variant={isLgScreen ? "subtitle1" : isMobile ? "h5" : "h6"}
                sx={(theme) => ({
                  color: theme.palette.purple.regular,
                  fontFamily: "'Inter', 'sans-serif'",
                  maxWidth: "675px",
                  textAlign: "center",
                  lineHeight: "130%",
                  fontWeight: 400,
                })}
              >
                Zaprati nas na mrežama:
              </Typography>
              <Box
                display={"flex"}
                columnGap={2}
                mt={3}
                mb={1}
                justifyContent={"center"}
                alignContent={"center"}
                alignItems={"center"}
              >
                <StyledLinkButton
                  to={socialMedia[0].path}
                  onMouseEnter={() =>
                    handleMouseEnter(socialMedia[0].socialMedia)
                  }
                  onMouseLeave={handleMouseLeave}
                  isMobile={isMobile}
                  target="_blank"
                >
                  {isHoveredInstagram && !isMobile
                    ? socialMediaHoverIcon(0)
                    : socialMediaIcon(0)}
                </StyledLinkButton>
                <StyledLinkButton
                  to={socialMedia[1].path}
                  onMouseEnter={() =>
                    handleMouseEnter(socialMedia[1].socialMedia)
                  }
                  onMouseLeave={handleMouseLeave}
                  isMobile={isMobile}
                  target="_blank"
                >
                  {isHoveredFacebook && !isMobile
                    ? socialMediaHoverIcon(1)
                    : socialMediaIcon(1)}
                </StyledLinkButton>
                <StyledLinkButton
                  to={socialMedia[2].path}
                  onMouseEnter={() =>
                    handleMouseEnter(socialMedia[2].socialMedia)
                  }
                  onMouseLeave={handleMouseLeave}
                  isMobile={isMobile}
                  target="_blank"
                >
                  {isHoveredYouTube && !isMobile
                    ? socialMediaHoverIcon(2)
                    : socialMediaIcon(2)}
                </StyledLinkButton>
                <StyledLinkButton
                  to={socialMedia[3].path}
                  onMouseEnter={() =>
                    handleMouseEnter(socialMedia[3].socialMedia)
                  }
                  onMouseLeave={handleMouseLeave}
                  isMobile={isMobile}
                  target="_blank"
                >
                  {isHoveredTikTok && !isMobile
                    ? socialMediaHoverIcon(3)
                    : socialMediaIcon(3)}
                </StyledLinkButton>
                <StyledButton
                  onMouseEnter={() =>
                    handleMouseEnter(socialMedia[4].socialMedia)
                  }
                  onMouseLeave={handleMouseLeave}
                  isMobile={isMobile}
                  onClick={handleEmailClick}
                  disableRipple
                >
                  {isHoveredEmail && !isMobile
                    ? socialMediaHoverIcon(4)
                    : socialMediaIcon(4)}
                </StyledButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </VirtualChoirDialog>
  );
};
