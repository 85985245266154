import { VirtualChoirError } from "./errors";

export async function request<Response = any>(
  method: string,
  url: string,
  body?: FormData
): Promise<Response> {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), 120000);

  const response = await fetch(url, {
    method: method.toUpperCase(),
    body: body,
    credentials: "same-origin",
    redirect: "manual",
    signal: controller.signal,
  });

  clearTimeout(id);

  return handleResponseError(response).then(() => {
    return response.status !== 204 ? response.json() : null;
  });
}

export async function jsonRequest<Response = any, Request extends {} = {}>(
  method: string,
  uri: string,
  body?: Request
): Promise<Response> {
  const response = await fetch(uri, {
    method: method.toUpperCase(),
    headers: {
      "content-type": "application/json",
      accept: "application/json",
    },
    body: body ? JSON.stringify(body) : null,
    credentials: "same-origin",
    redirect: "manual",
  });

  return handleResponseError(response).then(() => {
    return response.status !== 204 ? response.json() : null;
  });
}

function handleResponseError(response: Response): Promise<Response> {
  if (is4xxClientError(response.status) || is5xxServerError(response.status)) {
    return VirtualChoirError.ofResponse(response);
  }
  return Promise.resolve(response);
}
function is4xxClientError(status: number): boolean {
  return status >= 400 && status < 500;
}

function is5xxServerError(status: number): boolean {
  return status >= 500 && status < 600;
}
