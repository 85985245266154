import { Box, Typography } from "@mui/material";
import { SviUglasSvg } from "../assets";
import { useIsMobile } from "../../../hooks/useIsMobile";
import YouTube, { YouTubeProps } from "react-youtube";
import { useIsLgScreen } from "../../../hooks/useIsLgScreen";

export interface InterviewQuestionProps {
  question: any;
  videoId?: string;
}

export const InterviewQuestion: React.FC<InterviewQuestionProps> = ({
  question,
  videoId,
}) => {
  const isMobile = useIsMobile();
  const isLgScreen = useIsLgScreen();

  const opts: YouTubeProps["opts"] = {
    height: isMobile ? "300px" : "480px",
    width: "100%",
    maxWidth: "633px",
    borderRadius: "22px",
  };

  const renderHTML = (htmlString: string) => {
    const modifiedString = htmlString.replaceAll(
      "<a",
      '<a style="text-decoration: none; color: inherit"'
    );
    return { __html: modifiedString };
  };

  return (
    <table>
      <tr>
        <td style={{ position: "relative" }}>
          <Box
            justifyContent={"flex-start"}
            display={"flex"}
            alignContent={"flex-start"}
            alignItems={"flex-start"}
          >
            <SviUglasSvg width={isMobile ? 40 : isLgScreen ? 70 : 100} />
          </Box>
        </td>
        <td>
          <Box
            sx={(theme) => ({
              borderRadius: "22px",
              border: "2px solid",
              borderColor: theme.palette.purple.regular,
              marginLeft: 2,
              width: "fit-content",
              maxWidth: "633px",
              marginRight: isMobile ? "55px" : isLgScreen ? "83px" : 0,
            })}
          >
            <Typography
              variant={isLgScreen ? "subtitle1" : isMobile ? "h5" : "h6"}
              sx={(theme) => ({
                padding: isMobile ? "20px" : isLgScreen ? "22px" : "30px",
                color: theme.palette.purple.regular,
                fontWeight: 700,
                whiteSpace: "pre-wrap",
                fontFamily: "'Inter', 'sans-serif'",
                lineHeight: "130%",
              })}
            >
              <span dangerouslySetInnerHTML={renderHTML(question)} />
            </Typography>
          </Box>
        </td>
      </tr>
      {videoId && (
        <>
          <Box mt={1} />
          <tr>
            <td></td>
            <td>
              <Box
                sx={{
                  paddingLeft: 2,
                  width: "100%",
                  borderRadius: "22px",
                  paddingRight: isMobile ? "55px" : 0,
                }}
              >
                <YouTube videoId={videoId} opts={opts} />
              </Box>
            </td>
          </tr>
        </>
      )}
    </table>
  );
};
