export const ButtonHoverSvg: React.FC<{
  color: React.CSSProperties["color"];
  backgroundColor?: React.CSSProperties["color"];
}> = ({ color, backgroundColor }) => {
  return (
    <svg
      width="207"
      height="78"
      viewBox="0 0 208 78"
      fill={backgroundColor ? backgroundColor : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M49.0484 3C60.5485 3 89.7411 4.5432 120.782 3C175.629 3 200.335 16.1073 203.937 31.6457C210.935 61.8346 181.755 68.9719 164.129 68.9719C145.472 68.9719 81.8588 77.6524 42.0506 74.1802C2.24234 70.708 4.01188 41.0013 4.01177 31.6457C4.01157 14.9598 32.1614 3 49.0484 3Z"
        stroke={color}
        stroke-width="6"
      />
    </svg>
  );
};
