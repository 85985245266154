import { Box, Button, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";

const DialogActionsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  gap: theme.spacing(1),
}));

const StyledPlayAgainButton = styled(Button)(({ theme }) => ({
  background: theme.palette.purple.regular,
  color: "white",
  margin: "4px 0px",
  borderRadius: "8px",
  fontWeight: 700,
  "&:hover": {
    background: theme.palette.purple.hover,
  },
}));

const StyledLeaveButton = styled(Button)(({ theme }) => ({
  background: theme.palette.yellow.regular,
  color: "white",
  margin: "4px 0px",
  borderRadius: "8px",
  fontWeight: 700,
  "&:hover": {
    background: theme.palette.yellow.hover,
  },
}));

export const QuizActions: React.FC = () => {
  const navigate = useNavigate();

  const handleLeaveQuiz = () => {
    navigate("/quiz");
  };

  const handlePlayAgain = () => {
    window.location.reload();
    window.scrollTo(0, 0);
  };

  return (
    <DialogActionsWrapper>
      <StyledLeaveButton variant="contained" onClick={handleLeaveQuiz}>
        Napusti kviz
      </StyledLeaveButton>

      <StyledPlayAgainButton
        color="primary"
        variant="contained"
        onClick={handlePlayAgain}
      >
        Igraj opet
      </StyledPlayAgainButton>
    </DialogActionsWrapper>
  );
};
