export const AlertTriangleSvg: React.FC<{ width?: number }> = ({ width }) => {
  return (
    <svg
      width={width ? width : "71"}
      height="58"
      viewBox="0 0 71 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.8655 66.5123C50.938 66.5123 64.7779 52.6724 64.7779 35.5999C64.7779 18.5275 50.938 4.6875 33.8655 4.6875C16.7931 4.6875 2.95312 18.5275 2.95312 35.5999C2.95312 52.6724 16.7931 66.5123 33.8655 66.5123Z"
        fill="#FF7878"
      />
      <path
        d="M4.8303 53.6198C24.0965 53.2689 43.3563 53.6474 62.6095 54.7555C63.6722 54.8186 64.7312 54.5811 65.6652 54.0704C66.5992 53.5596 67.3706 52.7962 67.8909 51.8674C68.4112 50.9387 68.6595 49.8822 68.6073 48.8189C68.5551 47.7556 68.2046 46.7285 67.5958 45.8552C66.5528 44.3539 65.3927 42.9306 64.2667 41.5025C59.373 35.3772 54.749 29.0407 50.3947 22.493C48.1688 19.1493 46.0176 15.7568 43.9412 12.3156C42.2401 9.49339 40.7437 5.21869 38.2968 3.00091C33.174 -1.63936 28.5045 11.1701 26.2331 14.5723C22.0364 20.8504 17.4936 26.8895 13.0726 32.9969C10.5819 36.4089 8.11554 39.8696 5.76128 43.3937C5.34697 44.0176 3.81159 46.8739 2.37856 49.4231C2.13014 49.8503 1.99951 50.3358 2 50.8299C2.00049 51.3241 2.1321 51.8093 2.38137 52.236C2.63065 52.6627 2.98867 53.0156 3.4189 53.2587C3.84914 53.5019 4.33617 53.6265 4.8303 53.6198Z"
        stroke="#FFECD2"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M32.1951 22.3471C32.0886 20.9213 32.2405 19.4881 32.6435 18.1163C33.1309 16.771 34.1691 15.5475 35.568 15.2405C37.2789 14.87 39.0483 16.0057 39.8866 17.5411C40.725 19.0765 40.8176 20.9092 40.7104 22.659C40.4921 26.1611 39.5687 29.583 37.9954 32.7195C37.4203 33.8649 36.5331 35.8097 35.105 36.0437C33.5647 36.2971 33.1943 34.9178 33.0383 33.7138C32.56 29.9421 32.2785 26.1481 32.1951 22.3471Z"
        stroke="#FFECD2"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M32.1503 39.8934C31.7211 40.0119 31.3235 40.2242 30.9864 40.515C30.6492 40.8058 30.3808 41.1679 30.2006 41.575C29.8545 42.4426 30.0251 43.4223 30.2006 44.3436C30.2583 44.8017 30.4057 45.2439 30.6344 45.645C30.9051 46.0086 31.2641 46.2971 31.6775 46.4834C32.2566 46.7993 32.9047 46.9676 33.5644 46.9736C34.2241 46.9795 34.8751 46.8228 35.4599 46.5175C35.9234 46.2224 36.3118 45.8234 36.5944 45.3522C36.8769 44.881 37.0459 44.3504 37.0879 43.8025C37.3511 40.8731 34.9529 39.0355 32.1503 39.8934Z"
        stroke="#FFECD2"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
