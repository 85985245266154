export interface YouTubeLogoProps {
  width: string;
  height?: string;
  color?: string;
}

export const YouTubeLogoSvg: React.FC<YouTubeLogoProps> = ({
  width,
  height,
}) => {
  return (
    <svg
      width={width}
      height={height ? height : "29"}
      viewBox="0 0 38 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="YT icon" clip-path="url(#clip0_159_199)">
        <path
          id="Vector"
          d="M25.1419 13.5555L21.4754 11.9418C20.527 11.556 19.6095 11.0932 18.7314 10.5577C18.578 10.4481 18.4303 10.3302 18.2888 10.2046C17.6454 9.66078 16.7637 8.91575 15.6879 9.14526C15.4809 9.18671 15.284 9.27051 15.1087 9.39176C14.9333 9.51301 14.783 9.66929 14.6665 9.85146C14.2069 10.5859 14.3976 11.5216 14.5406 12.2066C14.5712 12.3655 14.6053 12.5244 14.6155 12.6092C14.7335 13.7976 14.7937 14.9914 14.7959 16.186C14.7968 17.5501 14.7206 18.913 14.5678 20.2679C14.5448 20.4701 14.5785 20.6749 14.6649 20.8577C14.7512 21.0406 14.8865 21.1938 15.0546 21.2989C15.2228 21.4039 15.4166 21.4565 15.6129 21.4502C15.8091 21.444 15.9994 21.3792 16.1611 21.2636C19.0446 19.2121 22.0269 17.0865 25.1896 15.4693C25.3658 15.38 25.5135 15.2401 25.6151 15.0661C25.7167 14.8922 25.7678 14.6916 25.7624 14.4882C25.7571 14.2849 25.6953 14.0875 25.5847 13.9196C25.4741 13.7517 25.3192 13.6204 25.1385 13.5413L25.1419 13.5555ZM16.7875 18.2693C16.8249 17.5631 16.842 16.8852 16.842 16.1966C16.8385 14.93 16.7737 13.6643 16.6479 12.4044C16.6242 12.1941 16.589 11.9854 16.5424 11.7794C16.5219 11.684 16.4947 11.5534 16.4709 11.4263C16.6649 11.564 16.876 11.7405 17.0088 11.8535C17.2155 12.0353 17.4327 12.2039 17.659 12.3585C18.6309 12.9565 19.6475 13.4725 20.6991 13.9015L22.3605 14.6359C20.4336 15.7482 18.5884 17.0017 16.7875 18.2658V18.2693Z"
          fill="#7A5D7F"
        />
        <path
          id="Vector_2"
          d="M37.7103 17.6691C37.6456 17.2489 37.581 16.8287 37.5333 16.4085C37.3815 14.8425 37.3315 13.2676 37.3835 11.6946C37.4039 9.51601 37.4278 7.26324 36.941 5.04578C36.7367 4.11713 36.3486 2.77535 35.3205 1.84317C34.5628 1.22303 33.6715 0.802604 32.7229 0.617921C31.2271 0.305972 29.707 0.135782 28.1814 0.109458C21.536 -0.183614 13.9169 0.169485 8.01709 0.51199C5.51485 0.674416 2.74707 1.12638 1.20828 3.26969C-0.04795 4.99988 -0.0036794 7.21734 0.0303647 8.83453L0.272064 20.9458C0.326535 23.6046 0.666981 26.2635 2.78793 27.7288C4.22118 28.7175 6.03575 28.7387 7.36006 28.7564L27.664 28.9965H28.498C29.5812 29.0233 30.6636 28.9154 31.722 28.6751C35.0481 27.8348 37.6831 24.7558 37.9861 21.3519C38.0429 20.1171 37.9502 18.8799 37.7103 17.6691ZM35.9298 21.1647C35.7051 23.6965 33.7238 25.9916 31.2181 26.6236C30.049 26.8585 28.8565 26.9439 27.6673 26.8779L7.36347 26.6378C5.97447 26.6201 4.75908 26.5601 3.89777 25.9633C2.55983 25.0418 2.33516 22.9832 2.2943 20.8999L2.05256 8.79216C2.01852 7.09375 2.05256 5.61427 2.81855 4.55497C3.87392 3.10021 6.097 2.76829 8.13624 2.63765C13.985 2.28455 21.5428 1.93145 28.0963 2.23511C29.4967 2.26027 30.8923 2.41388 32.2667 2.69414C32.8824 2.80758 33.4647 3.0661 33.9689 3.44977C34.4115 3.84877 34.7213 4.50907 34.9426 5.52599C35.3749 7.50335 35.3545 9.53366 35.3341 11.684C35.282 13.3502 35.3366 15.0182 35.4975 16.6768C35.5519 17.1288 35.62 17.5773 35.6847 18.008C35.8921 19.0457 35.9744 20.106 35.9298 21.1647Z"
          fill="#7A5D7F"
        />
      </g>
      <defs>
        <clipPath id="clip0_159_199">
          <rect width="38" height="29" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const YouTubeLogoHoverSvg: React.FC<YouTubeLogoProps> = ({
  width,
  height,
}) => {
  return (
    <svg
      width={width}
      height={height ? height : "36"}
      viewBox="0 0 43 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.16017 4.85451C3.1892 1.87561 7.81985 0.173375 13.994 0.173375C16.7381 -0.11033 24.4902 -0.252182 33.5457 1.45005C44.8651 3.57784 42.2925 8.68453 42.807 13.7912C43.3215 18.8979 42.8073 29.1113 41.2638 32.0902C39.7202 35.0691 23.2554 36.3458 16.0522 35.9202C8.84897 35.4947 8.33506 36.3458 3.70411 32.0902C-0.926834 27.8346 0.616719 20.1746 0.102203 16.7701C-0.412313 13.3657 1.13114 7.83341 2.16017 4.85451Z"
        fill="#7A5D7F"
      />
      <g clip-path="url(#clip0_0_1)">
        <path
          d="M28.1419 17.5554L24.4754 15.9418C23.527 15.556 22.6095 15.0932 21.7314 14.5576C21.578 14.448 21.4303 14.3302 21.2888 14.2045C20.6454 13.6608 19.7637 12.9157 18.6879 13.1452C18.4809 13.1867 18.284 13.2705 18.1087 13.3917C17.9333 13.513 17.783 13.6693 17.6665 13.8514C17.2069 14.5859 17.3976 15.5216 17.5406 16.2066C17.5712 16.3655 17.6053 16.5244 17.6155 16.6091C17.7335 17.7975 17.7937 18.9913 17.7959 20.186C17.7968 21.5501 17.7206 22.913 17.5678 24.2678C17.5448 24.4701 17.5785 24.6749 17.6649 24.8577C17.7512 25.0406 17.8865 25.1937 18.0546 25.2989C18.2228 25.4039 18.4166 25.4565 18.6129 25.4502C18.8091 25.4439 18.9994 25.3791 19.1611 25.2636C22.0446 23.2121 25.0269 21.0864 28.1896 19.4692C28.3658 19.38 28.5135 19.2401 28.6151 19.0661C28.7167 18.8921 28.7678 18.6915 28.7624 18.4882C28.7571 18.2849 28.6953 18.0875 28.5847 17.9196C28.4741 17.7517 28.3192 17.6204 28.1385 17.5413L28.1419 17.5554ZM19.7875 22.2693C19.8249 21.5631 19.842 20.8852 19.842 20.1966C19.8385 18.93 19.7737 17.6643 19.6479 16.4043C19.6242 16.1941 19.589 15.9854 19.5424 15.7794C19.5219 15.684 19.4947 15.5534 19.4709 15.4263C19.6649 15.564 19.876 15.7405 20.0088 15.8535C20.2155 16.0352 20.4327 16.2039 20.659 16.3584C21.6309 16.9565 22.6475 17.4725 23.6991 17.9015L25.3605 18.6359C23.4336 19.7482 21.5884 21.0017 19.7875 22.2658V22.2693Z"
          fill="#FFECD2"
        />
        <path
          d="M40.7113 21.6687C40.6466 21.2485 40.582 20.8283 40.5343 20.4081C40.3825 18.8421 40.3325 17.2673 40.3845 15.6943C40.4049 13.5156 40.4288 11.2629 39.9419 9.04542C39.7377 8.11677 39.3496 6.775 38.3214 5.84282C37.5638 5.22267 36.6725 4.80225 35.7239 4.61756C34.228 4.30561 32.7079 4.13542 31.1824 4.1091C24.537 3.81603 16.9179 4.16913 11.0181 4.51163C8.51583 4.67406 5.74805 5.12603 4.20925 7.26933C2.95303 8.99952 2.9973 11.217 3.03134 12.8342L3.27304 24.9455C3.32751 27.6043 3.66796 30.2631 5.7889 31.7285C7.22216 32.7172 9.03673 32.7383 10.361 32.756L30.6649 32.9961H31.499C32.5822 33.0229 33.6645 32.915 34.723 32.6748C38.0491 31.8344 40.6841 28.7554 40.9871 25.3515C41.0439 24.1168 40.9512 22.8795 40.7113 21.6687ZM38.9308 25.1644C38.7061 27.6961 36.7248 29.9912 34.2191 30.6233C33.05 30.8581 31.8575 30.9435 30.6683 30.8775L10.3644 30.6374C8.97545 30.6198 7.76006 30.5597 6.89874 29.963C5.56081 29.0414 5.33613 26.9828 5.29528 24.8996L5.05354 12.7918C5.0195 11.0934 5.05354 9.61391 5.81953 8.55461C6.8749 7.09985 9.09797 6.76793 11.1372 6.63729C16.986 6.28419 24.5438 5.93109 31.0973 6.23476C32.4977 6.25991 33.8933 6.41352 35.2677 6.69378C35.8833 6.80722 36.4657 7.06574 36.9699 7.44941C37.4124 7.84841 37.7223 8.50871 37.9436 9.52564C38.3759 11.503 38.3555 13.5333 38.335 15.6837C38.283 17.3499 38.3376 19.0178 38.4984 20.6765C38.5529 21.1285 38.621 21.5769 38.6857 22.0077C38.8931 23.0453 38.9754 24.1057 38.9308 25.1644Z"
          fill="#FFECD2"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_1">
          <rect
            width="38"
            height="29"
            fill="white"
            transform="translate(3 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
