import React from "react";

export const BackToTopSvg: React.FC = () => {
  return (
    <svg
      width="66"
      height="61"
      viewBox="0 0 96 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 45.5C0 28.5864 16.7268 0 43.7286 0C73.8368 0 92.7142 14.2932 95.8206 45.5C98.927 76.7068 60.9333 91 40.6222 91C20.3111 91 0 62.4136 0 45.5Z"
        fill="#FFECD2"
      />
      <path
        d="M30.001 32C41.249 31.6571 59.3543 29.8571 67.001 29"
        stroke="#7A5D7F"
        stroke-width="6"
        stroke-linecap="round"
      />
      <path
        d="M29.833 61.3906C37.9345 56.9999 44.6835 50.413 49.3256 42.3661C51.1117 45.9353 52.8971 49.5102 55.4587 52.5624C58.0203 55.6145 61.3437 58.1247 65.2001 58.9253"
        stroke="#7A5D7F"
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
