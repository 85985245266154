import { useMediaRecorder } from "../../../../../state/useMediaRecorder";
import { useUploadTrackForm } from "../../../../../state/useUploadTrackForm";
import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import { useIsMobile } from "../../../../../../../hooks/useIsMobile";
import { TrackSvg } from "../../../assets/TrackSvg";
import { GuitarSvg, XSvg } from "../../../assets";
import { ProjectTrack } from "../../../../../api";
import { useIsLgScreen } from "../../../../../../../hooks/useIsLgScreen";

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.yellow.regular,
    color: theme.palette.purple.regular,
    maxWidth: 220,
    border: "1px solid",
    borderRadius: "8px",
    borderColor: theme.palette.yellow.regular,
  },
}));

export const DisplayTrack: React.FC = () => {
  const uploadTrackForm = useUploadTrackForm();
  const mediaRecorder = useMediaRecorder();
  const isMobile = useIsMobile();
  const isLgScreen = useIsLgScreen();

  // const handleLoadedMetadata = () => {
  //   const video = uploadTrackForm.inputFileRef?.current;
  //   if (!video) return;

  //   // Check if the video duration is a finite number
  // if (!Number.isFinite(video.duration)) {
  //   console.error('Invalid video duration:', video.duration);
  //   return;
  // }

  //   uploadTrackForm.setVideoMetadata(
  //     formatSecondsToTime(video.duration),
  //     video.duration
  //   );
  //   console.log(video.duration);
  // };

  const handleLoadedMetadata = () => {
    const video = uploadTrackForm.inputFileRef?.current;

    if (!video) {
      console.error("Video element not found");
      return;
    }

    if (uploadTrackForm.projectTrack === ProjectTrack.SECOND) {
      video.volume = 0.6;
    }

    // Check if the video duration is a finite number
    if (!Number.isFinite(video.duration)) {
      alert(
        "Vaš video nije ispravan. Ne možemo da očitamo njegovu dužinu trajanja."
      );
      return;
    }

    // Format and store the duration
    uploadTrackForm.setVideoMetadata(
      formatSecondsToTime(video.duration),
      video.duration
    );
  };

  const formatSecondsToTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const stopRecording = () => {
    mediaRecorder.stopRecording(
      uploadTrackForm.videoName!,
      uploadTrackForm.projectTrack
    );
  };

  return (
    <>
      {uploadTrackForm.deviceVideoUrl && uploadTrackForm.inputFileRef && (
        <Box
          sx={(theme) => ({
            borderRadius: "22px",
            border: "3px solid",
            borderColor: theme.palette.yellow.regular,
            padding: isMobile ? "20px 8px" : 1,
            justifyContent: "center",
            alignContent: "center",
            marginBottom: 5,
            position: "relative",
            height: "100%",
          })}
        >
          <Grid container m={3}>
            <Grid item md={2} mt={isMobile ? -4 : 0} ml={isMobile ? -3 : 0}>
              {uploadTrackForm.projectTrack === ProjectTrack.FIRST ? (
                <TrackSvg width={isMobile ? 54 : 74} />
              ) : (
                <GuitarSvg width={isMobile ? 54 : 74} />
              )}
            </Grid>
            <Grid item ml={isMobile ? 2 : 4} mt={isMobile ? -3 : 0}>
              <Typography
                variant={isLgScreen ? "subtitle1" : isMobile ? "h5" : "h6"}
                sx={(theme) => ({
                  color: theme.palette.yellow.regular,
                  fontWeight: 700,
                  fontFamily: "'Inter', 'sans-serif'",
                })}
              >
                {uploadTrackForm.projectTrack === ProjectTrack.FIRST
                  ? "OBEĆAJ MI"
                  : uploadTrackForm.projectTrack === ProjectTrack.SECOND
                  ? "ČAJE ŠUKARIJE"
                  : uploadTrackForm.videoName}
              </Typography>
              <Typography
                variant={!isMobile ? "subtitle1" : "h6"}
                sx={(theme) => ({
                  color: theme.palette.yellow.regular,
                  fontWeight: 400,
                  textAlign: "left",
                  lineHeight: "130%",
                  fontFamily: "'Inter', 'sans-serif'",
                })}
              >
                {uploadTrackForm.projectTrack === ProjectTrack.FIRST
                  ? "Ana Đurić Konstrakta"
                  : uploadTrackForm.projectTrack === ProjectTrack.SECOND
                  ? "Esma Redžepova"
                  : "Nepoznat izvođač"}
              </Typography>
            </Grid>
          </Grid>
          <Box position="relative" mt={isMobile ? 3 : 5}>
            <video
              crossOrigin="anonymous"
              src={uploadTrackForm.deviceVideoUrl}
              ref={uploadTrackForm.inputFileRef}
              onEnded={stopRecording}
              onLoadedMetadata={handleLoadedMetadata}
              playsInline
              style={{ width: "100%", height: "auto", borderRadius: "8px" }}
              poster={
                uploadTrackForm.projectTrack === ProjectTrack.FIRST
                  ? "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/kaver.png"
                  : "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/esma-kaver.jpg"
              }
            />
          </Box>

          <StyledTooltip
            title={
              <Typography variant={isLgScreen ? "subtitle1" : "h6"}>
                Poništi izbor
              </Typography>
            }
            placement="top"
          >
            <IconButton
              sx={{
                position: "absolute",
                ":hover": {
                  animation: "rotate 0.3s",
                },
                "@keyframes rotate": {
                  "0%": {
                    transform: "rotate(0deg)",
                  },
                  "100%": {
                    transform: "rotate(90deg)",
                  },
                },
                top: isMobile ? 0 : 10,
                right: isMobile ? 2 : 10,
                display: mediaRecorder.isRecording ? "none" : "",
              }}
              onClick={uploadTrackForm.deleteUploadedTrack}
            >
              <XSvg width={isMobile ? 34 : 44} />
            </IconButton>
          </StyledTooltip>
        </Box>
      )}
    </>
  );
};
