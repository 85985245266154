import { Box, Button, Grid, Stack, Typography, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { Answer, Question, QuizType } from "../api/dto";
import { useIsMobile } from "../../../hooks/useIsMobile";
import questionBackgroundSvg from "./../images/questionBackground.svg";
import * as ReactDOMServer from "react-dom/server";
import { theme } from "../../../theme";
import { ArrowLeftSvg } from "../images/ArrowLeftSvg";
import { ArrowRightSvg } from "../images/ArrowRightSvg";
import { useIsLgScreen } from "../../../hooks/useIsLgScreen";
import { ShortButtonBackgroundSvg } from "../../../menuHovers";
import { useMemoizedFn } from "ahooks";
import { useQuizResultMutation } from "../hooks/useQuizResultMutation";

const StyledBackgroundGrid = styled(Grid)<{
  isMobile: boolean;
  url: string;
  isLgScreen: boolean;
}>(({ url, isMobile, isLgScreen }) => ({
  background: `url(${url})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  width: "100%",
  height: isMobile ? "674px" : isLgScreen ? "554px" : "724px",
  marginTop: isMobile ? "-155px" : "-15px",
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  textAlign: "center",
  position: "relative",
}));

const StyledTypographyQuestion = styled(Typography)<{ isMobile: boolean }>(
  ({ theme, isMobile }) => ({
    color: theme.palette.purple.regular,
    textAlign: "center",
    fontWeight: 700,
    paddingTop: "35px",
    paddingLeft: "20px",
    paddingRight: "20px",
  })
);

const StyledTypographyResult = styled(Typography)(({ theme }) => ({
  color: theme.palette.purple.regular,
  textAlign: "center",
  fontWeight: 700,
  paddingLeft: "20px",
  paddingRight: "20px",
}));

interface StyledAnswerButtonProps {
  isMobile: boolean;
  selectedAnswers: Map<string, string>;
  answer: Answer;
  question: string;
  shake: boolean;
  hover: string;
  wrongHover: string;
  successHover: string;
}

const StyledAnswerButton = styled(Button)<StyledAnswerButtonProps>(
  ({
    theme,
    isMobile,
    selectedAnswers,
    answer,
    question,
    shake,
    hover,
    wrongHover,
    successHover,
  }) => ({
    textTransform: "none",
    textAlign: "center",
    padding: isMobile ? "10px" : "5px",
    backgroundImage:
      selectedAnswers.get(question) === answer.label ||
      (selectedAnswers.has(question) && answer.isCorrect)
        ? answer.isCorrect
          ? `url("data:image/svg+xml,${successHover}")`
          : `url("data:image/svg+xml,${wrongHover}")`
        : "transparent",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    boxShadow: "none",
    backgroundColor: "transparent",
    color:
      selectedAnswers.get(question) === answer.label ||
      (selectedAnswers.has(question) && answer.isCorrect)
        ? answer.isCorrect
          ? theme.palette.purple.regular
          : theme.palette.purple.regular
        : theme.palette.purple.regular,

    animation:
      shake &&
      selectedAnswers.get(question) === answer.label &&
      !answer.isCorrect
        ? `shake 1s ease-in-out infinite`
        : "",
    "@keyframes shake": {
      "0%": { transform: "translateX(0)" },
      "25%": { transform: "translateX(5px)" },
      "50%": { transform: "translateX(-5px)" },
      "75%": { transform: "translateX(5px)" },
      "100%": { transform: "translateX(0)" },
    },
    ...(isMobile
      ? {
          "&:hover": {
            backgroundColor: "inherit",
          },
        }
      : {
          "&:hover": {
            backgroundImage:
              selectedAnswers.get(question) === answer.label ||
              (selectedAnswers.has(question) && answer.isCorrect)
                ? answer.isCorrect
                  ? `url("data:image/svg+xml,${successHover}")`
                  : `url("data:image/svg+xml,${wrongHover}")`
                : `url("data:image/svg+xml,${hover}")`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            boxShadow: "none",
            backgroundColor: "transparent",
            color: theme.palette.yellow.regular,
          },
        }),

    "&:disabled": {
      backgroundImage:
        selectedAnswers.get(question) === answer.label ||
        (selectedAnswers.has(question) && answer.isCorrect)
          ? answer.isCorrect
            ? `url("data:image/svg+xml,${successHover}")`
            : `url("data:image/svg+xml,${wrongHover}")`
          : theme.palette.purple.light,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      boxShadow: "none",
      backgroundColor: "transparent",
      color:
        selectedAnswers.get(question) === answer.label ||
        (selectedAnswers.has(question) && answer.isCorrect)
          ? answer.isCorrect
            ? theme.palette.purple.regular
            : theme.palette.yellow.regular
          : theme.palette.purple.regular,
    },
  })
);

const gypsyCultureBackgroundImages = [
  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/gypsy-culture-01.jpg",
  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/gypsy-culture-02.jpg",
  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/gypsy-culture-03.jpg",
  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/gypsy-culture-04.jpg",
  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/gypsy-culture-05.jpg",
  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/gypsy-culture-06.jpg",
  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/gypsy-culture-07.jpg",
  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/gypsy-culture-08.jpg",
  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/gypsy-culture-09.jpg",
  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/gypsy-culture-10.jpg",
];

const gypsyHistoryBackgroundImages = [
  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/gypsy-history-01.jpg",
  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/gypsy-history-02.jpg",
  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/gypsy-history-03.jpg",
  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/gypsy-history-04.jpg",
  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/gypsy-history-05.jpg",
  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/gypsy-history-06.jpg",
  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/gypsy-history-07.jpg",
  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/gypsy-history-08.jpg",
  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/gypsy-history-09.jpg",
  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/gypsy-history-10.jpg",
];

export interface QuizProps {
  questions: Question[];
  quizType: QuizType;
}

export const Quiz: React.FC<QuizProps> = ({ questions, quizType }) => {
  const isMobile = useIsMobile();
  const isLgScreen = useIsLgScreen();
  const save = useQuizResultMutation();

  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = questions.length;
  const [shake, setShake] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState<Map<string, string>>(
    new Map()
  );
  const [showResult, setShowResult] = useState(false);
  const [background, setBackground] = useState(
    quizType === QuizType.GYPSY_CULTURE
      ? gypsyCultureBackgroundImages[0]
      : gypsyHistoryBackgroundImages[0]
  );

  const handleButtonClick = (question: string, answer: string) => {
    setSelectedAnswers(new Map(selectedAnswers.set(question, answer)));
    if (selectedAnswers.size === 10) {
      doSubmit(calculateScore());
      setTimeout(() => {
        setShowResult(true);
      }, 2000);
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setBackground(
      quizType === QuizType.GYPSY_CULTURE
        ? gypsyCultureBackgroundImages[activeStep + 1]
        : gypsyHistoryBackgroundImages[activeStep + 1]
    );
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setBackground(
      quizType === QuizType.GYPSY_CULTURE
        ? gypsyCultureBackgroundImages[activeStep - 1]
        : gypsyHistoryBackgroundImages[activeStep - 1]
    );
  };

  const doSubmit = useMemoizedFn((noOfCorrectAnswers: number) => {
    return save.mutateAsync({
      type:
        quizType === QuizType.GYPSY_CULTURE ? "GYPSY_CULTURE" : "GYPSY_HISTORY",
      noOfCorrectAnswers,
    });
  });

  const calculateScore = (): number => {
    let score = 0;

    selectedAnswers.forEach((value, key) => {
      let question = questions.find((q) => q.question === key);
      let correctAnswer = question?.answers.find((a) => a.isCorrect);
      if (question && correctAnswer) {
        if (
          selectedAnswers.has(question.question) &&
          selectedAnswers.get(question.question) === correctAnswer.label
        ) {
          score++;
        }
      }
    });
    return score;
  };

  const showScore = (): string => {
    let score = calculateScore();

    if (score <= 5) {
      if (quizType === QuizType.GYPSY_CULTURE) {
        return "Bravo, znaš ponešto o romskoj kulturi!";
      } else {
        return "Bravo, znaš ponešto o romskoj istoriji! Pogledaj naš serijal da bi saznao/la više!";
      }
    } else if (score >= 6 && score <= 8) {
      if (quizType === QuizType.GYPSY_CULTURE) {
        return "Svaka čast, znaš dosta o romskoj kulturi!";
      } else {
        return "Svaka čast, znaš dosta o romskoj istoriji! Pogledaj naš serijal da bi saznao/la još više!";
      }
    } else {
      if (quizType === QuizType.GYPSY_CULTURE) {
        return "Wow, pa ti si pravi znalac o romskoj kulturi!";
      }
      return "Wow, pa ti si pravi znalac o romskoj istoriji! Mogao/la bi da nam pomogneš za nastavak serijala!";
    }
  };

  const answerHover = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      <ShortButtonBackgroundSvg color={theme.palette.purple.regular} />
    )
  );

  const wrongAnswerHover = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      <ShortButtonBackgroundSvg color={theme.palette.errors.regular} />
    )
  );

  const successAnswerHover = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      <ShortButtonBackgroundSvg color={theme.palette.green.regular} />
    )
  );

  useEffect(() => {
    setShake(true);
    const timer = setTimeout(() => {
      setShake(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [selectedAnswers]);

  return (
    <StyledBackgroundGrid
      isMobile={isMobile}
      url={background}
      isLgScreen={isLgScreen}
      container
    >
      <Box
        justifyContent={"center"}
        display={"flex"}
        alignContent={"center"}
        alignItems={"center"}
      >
        {!isMobile && (
          <Grid item>
            <Button
              disableRipple
              sx={{
                marginRight: 5,
                width: isMobile ? 40 : 70,
              }}
              onClick={handleBack}
              disabled={activeStep === 0 || showResult}
            >
              <ArrowLeftSvg width={70} />
            </Button>
          </Grid>
        )}
        <Grid
          item
          sx={{
            maxWidth: isLgScreen ? "705px" : isMobile ? "100%" : "1005px",
            minWidth: isLgScreen ? "705px" : isMobile ? "100%" : "1005px",
          }}
        >
          <Box
            sx={(theme) => ({
              backgroundImage: `url(${questionBackgroundSvg})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: isMobile ? "90%" : "100%",
              minWidth: isMobile ? "320px" : "auto",
              height: "100%",
              maxHeight: isLgScreen ? "495px" : isMobile ? "100%" : "400px",
              minHeight: isLgScreen ? "355px" : isMobile ? "100%" : "400px",
              padding: showResult ? "100px 0px" : "15px 30px",
              margin: "auto",
              marginTop: isMobile ? 20 : 5,
            })}
          >
            {!showResult ? (
              <>
                <StyledTypographyQuestion
                  variant={isMobile ? "h4" : "h5"}
                  isMobile={isMobile}
                >
                  {questions[activeStep].question.toUpperCase()}
                </StyledTypographyQuestion>
                <Box
                  sx={{
                    width: "100%",
                    p: 1,
                    justifyContent: "center",
                    display: "flex",
                    alignContent: "center",
                    marginTop: isMobile ? 1 : 3,
                  }}
                >
                  <Stack direction="column" spacing={2}>
                    {questions[activeStep].answers.map((answer) => {
                      return (
                        <StyledAnswerButton
                          isMobile={isMobile}
                          shake={shake}
                          selectedAnswers={selectedAnswers}
                          answer={answer}
                          question={questions[activeStep].question}
                          onClick={() =>
                            handleButtonClick(
                              questions[activeStep].question,
                              answer.label
                            )
                          }
                          disabled={selectedAnswers.has(
                            questions[activeStep].question
                          )}
                          hover={answerHover}
                          wrongHover={wrongAnswerHover}
                          successHover={successAnswerHover}
                        >
                          <Typography
                            variant={
                              isLgScreen
                                ? "subtitle1"
                                : isMobile
                                ? "subtitle2"
                                : "h6"
                            }
                            sx={{
                              maxWidth: "480px",
                              fontFamily: "'Inter', 'sans-serif'",
                              lineHeight: "130%",
                              fontWeight:
                                selectedAnswers.get(
                                  questions[activeStep].question
                                ) === answer.label ||
                                (selectedAnswers.has(
                                  questions[activeStep].question
                                ) &&
                                  answer.isCorrect)
                                  ? 700
                                  : 400,
                            }}
                          >
                            {answer.label}
                          </Typography>
                        </StyledAnswerButton>
                      );
                    })}
                  </Stack>
                </Box>
              </>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <StyledTypographyResult variant="h5">
                  {showScore()}
                </StyledTypographyResult>
              </Box>
            )}
          </Box>
          {!isMobile && (
            <Typography
              variant="h6"
              sx={(theme) => ({
                color: theme.palette.yellow.regular,
                marginTop: 2,
                fontFamily: "'Inter', 'sans-serif'",
                lineHeight: "130%",
                fontWeight: 400,
              })}
            >
              {activeStep + 1} / {maxSteps}
            </Typography>
          )}

          {isMobile && (
            <Box
              sx={{
                justifyContent: "space-between",
                alignContent: "center",
                alignItems: "center",
                display: "flex",
                marginLeft: 3,
                marginRight: 3,
              }}
            >
              <Button
                disableRipple
                sx={{ width: 20, float: "left" }}
                onClick={handleBack}
                disabled={activeStep === 0 || showResult}
              >
                <ArrowLeftSvg width={40} />
              </Button>
              <Typography
                variant="h5"
                sx={(theme) => ({
                  color: theme.palette.yellow.regular,
                  fontFamily: "'Inter', 'sans-serif'",
                  lineHeight: "130%",
                  fontWeight: 400,
                })}
              >
                {activeStep + 1} / {maxSteps}
              </Typography>
              <Button
                disableRipple
                sx={{ width: 20, float: "right" }}
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1 || showResult}
              >
                <ArrowRightSvg width={40} />
              </Button>
            </Box>
          )}
        </Grid>
        {!isMobile && (
          <Grid item>
            <Button
              disableRipple
              sx={{
                marginLeft: 5,
                width: isMobile ? 40 : 70,
              }}
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1 || showResult}
            >
              <ArrowRightSvg width={70} />
            </Button>
          </Grid>
        )}
      </Box>
    </StyledBackgroundGrid>
  );
};
