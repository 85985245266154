import { TextField } from "@mui/material";
import React from "react";
import { useUploadVideoForm } from "../modules/virtual-choir/state/useUploadVideoForm/useUploadVideoForm";

export interface VirtualChoirTextFieldProps {
  variant: string | undefined;
  value: string;
  onChange: (value: string) => void;
  placeholder: string | undefined;
}

export const VirtualChoirTextField: React.FC<VirtualChoirTextFieldProps> =
  React.memo(({ value, onChange, placeholder }) => {
    const uploadVideoForm = useUploadVideoForm();

    const handleChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      value = event.target.value;
      onChange?.(value);
    };

    return (
      <>
        <TextField
          size="small"
          sx={(theme) => ({
            width: "100%",
            maxWidth: "372px",
            borderRadius: "10px",
            border: "1px solid",
            borderColor: theme.palette.purple.regular,
          })}
          onChange={handleChange}
          placeholder={placeholder}
          disabled={
            uploadVideoForm.isSaved && placeholder === "Vaše ime i prezime"
          }
        />
      </>
    );
  });
