import { useMemoizedFn } from "ahooks";
import { atom, useAtom } from "jotai";

type CombinedVideoState =
  | {
      isCombined: true;
      combindeVideoURL: string;
    }
  | {
      isCombined: false;
      combindeVideoURL?: string;
    };

export type UseCombinedVideoResult = CombinedVideoState & {
  changeState(url: string): void;
  clearState(): void;
};

const combinedVideoStateAtom = atom<CombinedVideoState>({
  isCombined: false,
});

export function useCombinedVideo(): UseCombinedVideoResult {
  const [combinedVideoRef, setCombinedVideoRef] = useAtom(
    combinedVideoStateAtom
  );

  const changeState = useMemoizedFn((url: string) => {
    setCombinedVideoRef({
      isCombined: true,
      combindeVideoURL: url,
    });
  });

  const clearState = useMemoizedFn(() => {
    setCombinedVideoRef({
      isCombined: false,
    });
  });

  return {
    ...combinedVideoRef,
    changeState,
    clearState,
  };
}
