import { useMemoizedFn } from "ahooks";
import { atom, useAtom } from "jotai";
import { ChangeEvent, createRef } from "react";
import { YouTubeEvent, YouTubePlayer } from "react-youtube";
import { ProjectTrack } from "../api";

type UploadTrackFromYouTubeFormState =
  | {
      isYouTubeTrackUploaded: false;
      youTubeVideoId?: string;
    }
  | {
      isYouTubeTrackUploaded: true;
      youTubeVideoId?: string;
    };

type OnYouTubeVideoReadyState =
  | {
      isReady: false;
      youTubePlayer?: YouTubePlayer;
    }
  | {
      isReady: true;
      youTubePlayer: YouTubePlayer;
    };

type UploadTrackFromDeviceFormState =
  | {
      isDeviceTrackUploaded: false;
      deviceVideoUrl?: string;
      inputFileRef?: any;
      videoDuration?: string;
      unformattedVideoDuration?: number;
      videoName?: string;
      projectTrack: ProjectTrack;
    }
  | {
      isDeviceTrackUploaded: true;
      deviceVideoUrl: string;
      inputFileRef: any;
      videoDuration?: string;
      unformattedVideoDuration?: number;
      videoName?: string;
      projectTrack: ProjectTrack;
    };

export type UseUploadTrackFormResult = UploadTrackFromYouTubeFormState &
  OnYouTubeVideoReadyState &
  UploadTrackFromDeviceFormState & {
    uploadTrackFromDevice(event: any): void;
    handleUploadTrackFromDeviceButtonClick(
      ref: React.RefObject<HTMLInputElement>
    ): void;
    setVideoMetadata(
      formattedDuration: string,
      unformattedVideoDuration: number
    ): void;
    choosePredefinedTrack(
      url: string,
      track: ProjectTrack,
      trackName: string
    ): void;
    uploadTrackFromYouTube(youTubeUrl: string): void;
    onYouTubeVideoReady(event: YouTubeEvent<any>): void;
    deleteUploadedTrack(): void;
  };

const youTubeTrackFormStateAtom = atom<UploadTrackFromYouTubeFormState>({
  isYouTubeTrackUploaded: false,
});

const onYouTubeVideoReadyStateAtom = atom<OnYouTubeVideoReadyState>({
  isReady: false,
});

const deviceTrackFormStateAtom = atom<UploadTrackFromDeviceFormState>({
  isDeviceTrackUploaded: false,
  projectTrack: ProjectTrack.WITHOUT,
});

export function useUploadTrackForm(): UseUploadTrackFormResult {
  const [youTubeTrackFormState, setYouTubeTrackFormState] = useAtom(
    youTubeTrackFormStateAtom
  );

  const uploadTrackFromYouTube = useMemoizedFn((youTubeUrl: string) => {
    const youTubeUrlParams = youTubeUrl.match(/(?:\?v=)([\w-]+)/);
    if (youTubeUrlParams) {
      setYouTubeTrackFormState({
        isYouTubeTrackUploaded: true,
        youTubeVideoId: youTubeUrlParams[1],
      });
    }
  });

  const [onYouTubeVideoReadyState, setOnYouTubeVideoReadyState] = useAtom(
    onYouTubeVideoReadyStateAtom
  );

  const onYouTubeVideoReady = useMemoizedFn((event: YouTubeEvent<any>) => {
    setOnYouTubeVideoReadyState({
      isReady: true,
      youTubePlayer: event.target,
    });
  });

  const [deviceTrackFormState, setDeviceTrackFormState] = useAtom(
    deviceTrackFormStateAtom
  );

  const uploadTrackFromDevice = useMemoizedFn(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      const MAX_FILE_SIZE_BYTES = 15 * 1024 * 1024;
      if (file && file.size > MAX_FILE_SIZE_BYTES) {
        alert("Maksimalna veličina fajla je 15MB.");
        event.target.value = "";
        return;
      }

      if (event.target && file) {
        const url = URL.createObjectURL(file);
        if (file.type.startsWith("video/")) {
          console.log(file);
          setDeviceTrackFormState({
            isDeviceTrackUploaded: true,
            deviceVideoUrl: url,
            inputFileRef: event.target,
            videoName: file.name,
            projectTrack: ProjectTrack.UNKNOWN,
          });
        }
      }
    }
  );

  const handleUploadTrackFromDeviceButtonClick = useMemoizedFn(
    (ref: React.RefObject<HTMLInputElement>) => {
      ref.current?.click();
    }
  );

  const setVideoMetadata = useMemoizedFn(
    (formattedDuration: string, unformattedVideoDuration: number) => {
      setDeviceTrackFormState({
        ...deviceTrackFormState,
        videoDuration: formattedDuration,
        unformattedVideoDuration: unformattedVideoDuration,
      });
    }
  );

  const deleteUploadedTrack = useMemoizedFn(() => {
    if (youTubeTrackFormState.isYouTubeTrackUploaded) {
      setYouTubeTrackFormState({
        isYouTubeTrackUploaded: false,
      });
    }
    if (deviceTrackFormState.isDeviceTrackUploaded) {
      setDeviceTrackFormState({
        isDeviceTrackUploaded: false,
        projectTrack: ProjectTrack.WITHOUT,
      });
    }
  });

  const choosePredefinedTrack = useMemoizedFn(
    (url: string, track: ProjectTrack, trackName: string) => {
      setDeviceTrackFormState({
        isDeviceTrackUploaded: true,
        deviceVideoUrl: url,
        inputFileRef: createRef(),
        projectTrack: track,
        videoName: trackName,
      });
    }
  );

  return {
    ...youTubeTrackFormState,
    ...onYouTubeVideoReadyState,
    ...deviceTrackFormState,
    uploadTrackFromDevice,
    handleUploadTrackFromDeviceButtonClick,
    setVideoMetadata,
    choosePredefinedTrack,
    uploadTrackFromYouTube,
    onYouTubeVideoReady,
    deleteUploadedTrack,
  };
}
