import { Box, Grid, Typography, styled } from "@mui/material";
import React from "react";
import { useIsMobile } from "../../hooks/useIsMobile";
import { MeetUsSvg } from "../vocal-workshops/assets";
import aboutUs from "../vocal-workshops/assets/meet-us.jpg";
import { theme } from "../../theme";
import * as ReactDOMServer from "react-dom/server";
import { LongButtonBackgroundSvg } from "../../menuHovers";
import { NavLink } from "react-router-dom";
import { useIsLgScreen } from "../../hooks/useIsLgScreen";
import { SEO } from "../../components";

const StyledBox = styled(NavLink)<{
  color: React.CSSProperties["color"];
  url: string;
  isMobile: boolean;
  isLgScreen: boolean;
  findOutMore: boolean;
}>(({ color, url, isMobile, isLgScreen, findOutMore }) => ({
  backgroundImage: `url("data:image/svg+xml,${url}")`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  padding: findOutMore ? (isMobile ? "23px 15px" : "20px 15px") : "20px 15px",
  textAlign: "center",
  fontSize: isMobile ? "16px" : isLgScreen ? "16px" : "20px",
  fontWeight: 700,
  color: color,
  textDecoration: "none",
  marginTop: 30,
}));

export const AboutUsPage: React.FC = () => {
  const isMobile = useIsMobile();
  const isLgScreen = useIsLgScreen();

  const purpleBackground = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      <LongButtonBackgroundSvg
        color={theme.palette.purple.regular}
        width={isMobile ? "125" : "145"}
      />
    )
  );

  const purpleBackground2 = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      <LongButtonBackgroundSvg
        color={theme.palette.purple.regular}
        width={isMobile ? "145" : "155"}
        height="55"
      />
    )
  );
  return (
    <>
      <SEO
        title="Svi UGLAS | O nama"
        description="Saznajte više o našoj misiji, viziji i timu koji stoji iza Svi UGLAS hora"
      />
      <Grid container sx={{ marginTop: isMobile ? "-155px" : "-12px" }}>
        {!isMobile ? (
          <>
            <Grid
              item
              sx={(theme) => ({
                backgroundColor: theme.palette.purple.disabled,
                paddingLeft: isLgScreen ? 22 : 30,
                paddingTop: 10,
                paddingRight: 10,
                paddingBottom: isLgScreen ? 3 : 10,
                minHeight: isLgScreen ? "584px" : "734px",
              })}
              md={6}
            >
              <MeetUsSvg width={isLgScreen ? "80" : "130"} />
              <Box mt={isLgScreen ? 0 : 3} />
              <Box sx={{ maxWidth: "507px" }}>
                <Typography
                  variant={"h3"}
                  sx={(theme) => ({
                    color: theme.palette.yellow.regular,
                    fontWeight: 700,
                  })}
                >
                  O NAMA
                </Typography>

                <Box mt={isMobile ? 3 : isLgScreen ? 3 : 5} />
              </Box>
              <Typography
                variant={isLgScreen ? "subtitle1" : "h6"}
                sx={(theme) => ({
                  maxWidth: isLgScreen ? "280px" : "420px",
                  color: theme.palette.yellow.regular,
                  fontWeight: 400,
                  fontFamily: "'Inter', 'sans-serif'",
                  lineHeight: "130%",
                })}
              >
                Dobro došli u virtuelni hor Svi UGLAS! Poslednjih sedam godina
                stvarali smo uživo, a sada smo osmislili mesto koje će povezati
                sve raspevane ljude, gde god se nalazili.
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              sx={{
                backgroundImage: `url(${aboutUs})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: "100%",
                position: "relative",
              }}
            ></Grid>
          </>
        ) : (
          <Grid
            item
            sx={{
              backgroundImage: `url(${aboutUs})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: "100%",
              height: "630px",
              position: "relative",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Box sx={{ ml: 10, mr: 10, mt: 10 }}>
              <MeetUsSvg width="80" />
              <Box mt={isMobile ? 1 : 5} />

              <Typography
                variant="h2"
                sx={(theme) => ({
                  color: theme.palette.yellow.regular,
                  fontWeight: 700,
                })}
              >
                O NAMA
              </Typography>

              <Box mt={isMobile ? 3 : 5} />
            </Box>
            <Typography
              variant="h5"
              sx={(theme) => ({
                color: theme.palette.yellow.regular,
                ml: 7,
                mr: 7,
                mb: 5,
                fontWeight: 400,
                fontFamily: "'Inter', 'sans-serif'",
                lineHeight: "130%",
                textAlign: "left",
              })}
            >
              Dobro došli u virtuelni hor Svi UGLAS! Poslednjih sedam godina
              stvarali smo uživo, a sada smo osmislili mesto koje će povezati
              sve raspevane ljude, gde god se nalazili.
            </Typography>
          </Grid>
        )}
      </Grid>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.yellow.regular,
          width: "100%",
          paddingBottom: 10,
          paddingLeft: isMobile ? 1 : 10,
          paddingRight: isMobile ? 1 : 10,
        })}
      >
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            maxWidth: isMobile ? "100%" : "750px",
            margin: "0 auto",
          }}
        >
          <Grid item md={12}>
            <Box
              justifyContent={"center"}
              display={"flex"}
              alignContent={"center"}
              alignItems={"center"}
            >
              <Typography
                variant={isLgScreen ? "h6" : isMobile ? "h5" : "h6"}
                sx={(theme) => ({
                  textAlign: "left",
                  marginTop: isMobile ? 3 : 5,
                  color: theme.palette.purple.regular,
                  fontWeight: 400,
                  fontFamily: "'Inter', 'sans-serif'",
                  ml: isMobile ? 6 : 0,
                  mr: isMobile ? 6 : 0,
                  lineHeight: "130%",
                  maxWidth: isLgScreen ? "550px" : isMobile ? "100%" : "640px",
                })}
              >
                Svi UGLAS nije sasvim običan hor. <br /> To je mesto koje nam
                pomaže da kroz muziku učimo o sebi i drugima. <br />
                <br /> To je prostor u kojem postajemo prijatelji, stvaramo
                pesme i video spotove, učimo o romskoj kulturi i dobro se
                zabavljamo! <br />
                <br /> Iza našeg hora stoji udruženje Art Aparat iz Beograda. Mi
                iz Art Aparata verujemo da muzika može biti alat do lepšeg i
                pravednijeg društva. Udruženje okuplja muzičke umetnike/ce, ali
                i omladinske radnike/ce i druge stručnjake iz različitih oblasti
                i zajednički razvijamo programe koje uključuju ljude i doprinose
                razumevanju, solidarnosti i drugarstvu.
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              mt={isMobile ? 2 : 5}
            >
              <StyledBox
                color={theme.palette.yellow.regular}
                url={purpleBackground2}
                isMobile={isMobile}
                to={
                  "https://www.youtube.com/watch?v=_14jiSzBteg&list=PLFEmThWIZU6mtd6ZWeo2Kf8T0YLIhOXbF&ab_channel=Udru%C5%BEenjeArtAparat"
                }
                target="_blank"
                isLgScreen={isLgScreen}
                findOutMore={false}
              >
                POGLEDAJ SPOTOVE
              </StyledBox>

              <StyledBox
                color={theme.palette.yellow.regular}
                url={purpleBackground}
                isMobile={isMobile}
                to={"https://artaparat.org/"}
                target="_blank"
                isLgScreen={isLgScreen}
                findOutMore={true}
              >
                SAZNAJ VIŠE O ART APARATU
              </StyledBox>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
