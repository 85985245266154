export enum QuizType {
  GYPSY_CULTURE = "gypsyCulture",
  GYPSY_HISTORY = "gypsyHistory",
}

export class Question {
  constructor(public question: string, public answers: Answer[]) {}
}

export class Answer {
  constructor(public label: string, public isCorrect: boolean) {}
}
