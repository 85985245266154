import { Button, Typography, styled } from "@mui/material";
import { Field, Form, Formik, FormikErrors, FormikHelpers } from "formik";
import { useUploadVideoForm } from "../../../../state/useUploadVideoForm/useUploadVideoForm";
import { useUploadVideoMutation } from "../../../../hooks/useUploadVideoMutation";
import { useMediaRecorder } from "../../../../state/useMediaRecorder";
import { ProjectTrack } from "../../../../api";
import { useMemoizedFn } from "ahooks";
import { UploadVideoFormValues } from "../types";
import { VirtualChoirError } from "../../../../../../api";
import {
  LoadingOverlay,
  VirtualChoirFormikTextField,
} from "../../../../../../components";
import * as ReactDOMServer from "react-dom/server";
import { ShortButtonBackgroundSvg } from "../../../../../../menuHovers";
import { theme } from "../../../../../../theme";
import { SendSvg } from "../../../HorizontalLinearStepper/assets";
import { useIsMobile } from "../../../../../../hooks/useIsMobile";
import { useIsLgScreen } from "../../../../../../hooks/useIsLgScreen";

const StyledButton = styled(Button)<{
  background: string;
  hover: string;
  isMobile: boolean;
  isLgScreen: boolean;
}>(({ theme, background, hover, isMobile, isLgScreen }) => ({
  position: "absolute",
  right: 0,
  bottom: isMobile ? -58 : isLgScreen ? -62 : -65,
  backgroundImage: `url("data:image/svg+xml,${background}")`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  width: isMobile ? "90px" : isLgScreen ? "95px" : "114px",
  height: isMobile ? "33px" : isLgScreen ? "37px" : "41px",
  color: theme.palette.yellow.regular,
  fontSize: isMobile ? "12px" : "",
  fontWeight: 700,
  "&:hover": {
    backgroundImage: `url("data:image/svg+xml,${hover}")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
}));

export type FormValues = UploadVideoFormValues;

export interface UploadVideoFormMutationValues {
  author: string;
  name: string;
}

export const SaveVideoForm: React.FC = () => {
  const uploadVideo = useUploadVideoMutation();
  const mediaRecorder = useMediaRecorder();
  const uploadVideoForm = useUploadVideoForm();
  const isMobile = useIsMobile();
  const isLgScreen = useIsLgScreen();

  const canUploadVideo =
    mediaRecorder.track === ProjectTrack.FIRST ||
    mediaRecorder.track === ProjectTrack.SECOND;

  const background = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      <ShortButtonBackgroundSvg color={theme.palette.green.disabled} />
    )
  );

  const hover = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      <ShortButtonBackgroundSvg color={theme.palette.green.regular} />
    )
  );

  const validate = useMemoizedFn(
    async (values: FormValues): Promise<FormikErrors<FormValues>> => {
      const errors: FormikErrors<FormValues> = {};

      if (values.author === "") {
        errors.author = "Obavezno polje";
      }
      return errors;
    }
  );

  const doSubmit = useMemoizedFn((values: FormValues) => {
    return uploadVideo.mutateAsync({
      author: values.author,
      name: mediaRecorder.trackName ? mediaRecorder.trackName : "unknown",
    });
  });

  const handleSubmit = useMemoizedFn(
    (values: FormValues, formik: FormikHelpers<FormValues>) => {
      return doSubmit(values).catch((err: VirtualChoirError) => {
        formik.setFieldError("name", "Video nije poslat. Probajte ponovo.");
      });
    }
  );

  return (
    <Formik
      enableReinitialize
      initialValues={{
        author: "",
      }}
      validate={validate}
      validateOnMount
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, isValid, isValidating }) => {
        return (
          <>
            <Typography
              variant={isLgScreen ? "h6" : "h5"}
              sx={(theme) => ({
                color: theme.palette.purple.regular,
                fontWeight: 700,
                marginBottom: 3,
                lineHeight: "100%",
              })}
            >
              {canUploadVideo
                ? "PREGLEDAJ, PREUZMI I POŠALJI SNIMAK"
                : "PREGLEDAJ I PREUZMI SNIMAK"}
            </Typography>
            <Form style={{ margin: 0 }}>
              {canUploadVideo && (
                <>
                  <Field
                    name="author"
                    component={VirtualChoirFormikTextField}
                    placeholder="Vaše ime i prezime"
                    property="standard"
                  />
                  <Typography
                    variant={isLgScreen ? "subtitle2" : "subtitle1"}
                    sx={(theme) => ({
                      color: theme.palette.purple.regular,
                      width: "100%",
                      maxWidth: "272px",
                      marginTop: 1,
                      fontFamily: "'Inter', 'sans-serif'",
                      lineHeight: "130%",
                      fontWeight: 400,
                    })}
                  >
                    *Unesite vaše ime i prezime da biste poslali video!
                  </Typography>
                </>
              )}
              <StyledButton
                background={background}
                hover={hover}
                isLgScreen={isLgScreen}
                sx={{ marginLeft: 3 }}
                startIcon={
                  <SendSvg
                    opacity={
                      isSubmitting || !isValid || uploadVideoForm.isSaved
                        ? 0.3
                        : 1
                    }
                    width={isMobile ? "15" : "20"}
                  />
                }
                type="submit"
                disabled={isSubmitting || !isValid || uploadVideoForm.isSaved}
                isMobile={isMobile}
              >
                POŠALJI
              </StyledButton>
              <LoadingOverlay isLoading={isSubmitting} />
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
