import { Box, Button, Grid, Typography, styled } from "@mui/material";
import { theme } from "../../theme";
import { BookSvg, ChatSvg, QuestionMarkSvg, VideoSvg } from "./assets";
import { useIsMobile } from "../../hooks/useIsMobile";
import { useNavigate } from "react-router-dom";
import * as ReactDOMServer from "react-dom/server";
import quizGypsyCultureBackground from "./assets/quiz-gypsy=culture.jpg";
import prettyLoudBackground from "./assets/pretty-loud.jpg";
import ginaRanjicicBackground from "./assets/gina-ranjicic.jpg";
import dzenetKokoBackground from "./assets/dzenet-koko.jpg";
import quizGypsyHistoryBackground from "./assets/quiz-gypsy-history.jpg";
import travellingGypsiesBackground from "./assets/travelling-gypsies.jpg";
import { useState } from "react";
import { CultureButtonBackgroundSvg } from "./assets/CultureButtonBackgroundSvg";
import { CultureButtonHoverSvg } from "./assets/CultureButtonHoverSvg";
import { useIsLgScreen } from "../../hooks/useIsLgScreen";
import { SEO } from "../../components";
import { ButtonHoverSvg } from "../home/assets/ButtonHoverSvg";

const StyledTypographyTitle = styled(Typography)<{ isMobile: boolean }>(
  ({ theme, isMobile }) => ({
    color: theme.palette.yellow.regular,
    textAlign: "center",
    fontWeight: 700,
    maxWidth: isMobile ? "340px" : "717px",
    lineHeight: "100%",
  })
);

const StyledTypographySubTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.yellow.regular,
  textAlign: "center",
  fontWeight: 400,
  fontFamily: "'Inter', 'sans-serif'",
  lineHeight: "130%",
}));

const StyledBoxContainer = styled(Box)<{
  url: string;
  isMobile: boolean;
  positionPercentage: string;
  positionPercentageX?: string;
  isLgScreen: boolean;
}>(
  ({
    theme,
    url,
    isMobile,
    positionPercentage,
    positionPercentageX,
    isLgScreen,
  }) => ({
    backgroundImage: `url(${url})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: `${
      positionPercentageX ? positionPercentageX : "55%"
    } ${positionPercentage}`,
    position: "relative",
    width: "100%",
    minHeight: isMobile ? "875px" : isLgScreen ? "635px" : "775px",
    marginTop: isMobile ? "-155px" : "-15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  })
);

const StyledBoxItem = styled(Box)(() => ({
  justifyContent: "center",
  alignContent: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "100%",
}));

const StyledGridItem = styled(Grid)<{
  color: React.CSSProperties["color"];
}>(({ theme, color }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
}));

const StyledButton = styled(Button)(() => ({
  width: "fit-content",
  "&:hover": {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
}));

const StyledBox = styled(Box)<{
  color: React.CSSProperties["color"];
  minHeight: string;
  url: string;
  isLgScreen: boolean;
  isMobile: boolean;
}>(({ color, minHeight, url, isLgScreen, isMobile }) => ({
  backgroundImage: `url("data:image/svg+xml,${url}")`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  padding: !isLgScreen ? "10px" : "5px",
  width: isLgScreen ? "130px" : isMobile ? "140px" : "200px",
  textAlign: "center",
  height: isLgScreen ? "49px" : isMobile ? "50px" : "75px",
  fontSize: isLgScreen ? "22px" : isMobile ? "18px" : "30px",
  fontWeight: 700,
  color: color,
}));

const StyledBoxLong = styled(Box)<{
  color: React.CSSProperties["color"];
  url: string;
  isMobile: boolean;
  isLgScreen: boolean;
}>(({ color, url, isMobile, isLgScreen }) => ({
  backgroundImage: `url("data:image/svg+xml,${url}")`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  padding: isMobile ? "21px" : isLgScreen ? "7px" : "15px",
  width: isMobile ? 201 : isLgScreen ? 200 : 290,
  textAlign: "center",
  height: isMobile ? 72 : isLgScreen ? 48 : 73,
  fontSize: isMobile ? "18px" : isLgScreen ? "22px" : "30px",
  fontWeight: 700,
  marginTop: 5,
  color: color,
}));

export const CulturePage: React.FC = () => {
  const isMobile = useIsMobile();
  const [isHoveredGypsyCulture, setIsHoveredGypsyCulture] = useState(false);
  const [isHoveredPrettyLoud, setIsHoveredPrettyLoud] = useState(false);
  const [isHoveredGinaRanjicic, setIsHoveredGinaRanjicic] = useState(false);
  const [isHoveredDzenetKoko, setIsHoveredDzenetKoko] = useState(false);
  const [isHoveredGypsyHistory, setIsHoveredGypsyHistory] = useState(false);
  const [isHoveredTravellingGypsies, setIsHoveredTravellingGypsies] =
    useState(false);
  const navigate = useNavigate();
  const isLgScreen = useIsLgScreen();

  const yellowBackground = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      <CultureButtonBackgroundSvg isMobile={isMobile} />
    )
  );

  const blueHover = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(<CultureButtonHoverSvg />)
  );

  const redHover = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      <ButtonHoverSvg
        color={theme.palette.yellow.regular}
        backgroundColor={theme.palette.errors.disabled}
      />
    )
  );

  const greenHover = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      <ButtonHoverSvg
        color={theme.palette.yellow.regular}
        backgroundColor={theme.palette.green.disabled}
      />
    )
  );

  const purpleHover = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      <ButtonHoverSvg
        color={theme.palette.yellow.regular}
        backgroundColor={theme.palette.purple.disabled}
      />
    )
  );

  const yellowBackgroundShort = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      <ButtonHoverSvg
        color={theme.palette.yellow.regular}
        backgroundColor={theme.palette.yellow.regular}
      />
    )
  );

  const handleMouseEnter = (buttonText: string) => {
    if (buttonText === "gypsyCulture") {
      setIsHoveredGypsyCulture(true);
    } else if (buttonText === "prettyLoud") {
      setIsHoveredPrettyLoud(true);
    } else if (buttonText === "ginaRanjicic") {
      setIsHoveredGinaRanjicic(true);
    } else if (buttonText === "dzenetKoko") {
      setIsHoveredDzenetKoko(true);
    } else if (buttonText === "qypsyHistory") {
      setIsHoveredGypsyHistory(true);
    } else {
      setIsHoveredTravellingGypsies(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHoveredGypsyCulture(false);
    setIsHoveredPrettyLoud(false);
    setIsHoveredGinaRanjicic(false);
    setIsHoveredDzenetKoko(false);
    setIsHoveredGypsyHistory(false);
    setIsHoveredTravellingGypsies(false);
  };

  const handleClick = (navigateTo: string) => {
    navigate(navigateTo);
  };

  return (
    <>
      <SEO
        title="Svi UGLAS | Kultura"
        description="Otkrijte romsku kulturu kroz kvizove i inspirativne intervjue. Zaronite u nasleđe romskog naroda!"
      />
      <Grid container>
        <StyledBoxContainer
          isMobile={isMobile}
          url={quizGypsyCultureBackground}
          positionPercentage="25%"
          isLgScreen={isLgScreen}
        >
          <StyledGridItem item color={theme.palette.blue.disabled}>
            <StyledBoxItem>
              <QuestionMarkSvg
                backgroundColor={theme.palette.blue.regular}
                color={theme.palette.yellow.regular}
                width={isMobile ? "80" : isLgScreen ? "80" : "127"}
              />
              <Box mt={isMobile ? 1 : isLgScreen ? 0 : 5} />
              <StyledTypographyTitle
                variant={isLgScreen ? "h3" : "h2"}
                isMobile={isMobile}
              >
                KVIZ:
                <br />
                KOLIKO ZNAŠ O ROMSKOJ KULTURI?
              </StyledTypographyTitle>
              <Box mt={5} />
              <StyledButton
                onMouseEnter={() => handleMouseEnter("gypsyCulture")}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleClick("/quiz/gypsy-culture")}
              >
                {!isMobile && isHoveredGypsyCulture ? (
                  <StyledBoxLong
                    color={theme.palette.yellow.regular}
                    minHeight={"100%"}
                    url={blueHover}
                    isLgScreen={isLgScreen}
                    isMobile={isMobile}
                  >
                    PROVERI ZNANJE
                  </StyledBoxLong>
                ) : (
                  <StyledBoxLong
                    color={theme.palette.blue.disabled}
                    minHeight={"100%"}
                    url={yellowBackground}
                    isMobile={isMobile}
                    isLgScreen={isLgScreen}
                  >
                    PROVERI ZNANJE
                  </StyledBoxLong>
                )}
              </StyledButton>
            </StyledBoxItem>
          </StyledGridItem>
        </StyledBoxContainer>
        <StyledBoxContainer
          isMobile={isMobile}
          url={prettyLoudBackground}
          positionPercentage="25%"
          isLgScreen={isLgScreen}
        >
          <StyledGridItem item color={theme.palette.errors.disabled}>
            <StyledBoxItem>
              <ChatSvg
                backgroundColor={theme.palette.errors.regular}
                color={theme.palette.yellow.regular}
                width={isMobile ? "90" : isLgScreen ? "95" : "155"}
              />
              <Box mt={isMobile ? 1 : isLgScreen ? 0 : 5} />
              <StyledTypographyTitle
                variant={isLgScreen ? "h3" : "h2"}
                isMobile={isMobile}
              >
                INTERVJU:
                <br />
                PRETTY LOUD
              </StyledTypographyTitle>
              <Box mt={5} />
              <StyledButton
                onMouseEnter={() => handleMouseEnter("prettyLoud")}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleClick("/culture/pretty-loud")}
              >
                {!isMobile && isHoveredPrettyLoud ? (
                  <StyledBox
                    color={theme.palette.yellow.regular}
                    minHeight={"100%"}
                    url={redHover}
                    isMobile={isMobile}
                    isLgScreen={isLgScreen}
                  >
                    PROČITAJ
                  </StyledBox>
                ) : (
                  <StyledBox
                    color={theme.palette.errors.disabled}
                    minHeight={"100%"}
                    url={yellowBackgroundShort}
                    isMobile={isMobile}
                    isLgScreen={isLgScreen}
                  >
                    PROČITAJ
                  </StyledBox>
                )}
              </StyledButton>
            </StyledBoxItem>
          </StyledGridItem>
        </StyledBoxContainer>
        <StyledBoxContainer
          isMobile={isMobile}
          url={ginaRanjicicBackground}
          positionPercentage="25%"
          isLgScreen={isLgScreen}
        >
          <StyledGridItem item color={theme.palette.green.disabled}>
            <StyledBoxItem>
              <BookSvg width={isMobile ? "90" : isLgScreen ? "90" : "140"} />
              <Box mt={isMobile ? 1 : isLgScreen ? 0 : 5} />
              <StyledTypographySubTitle
                variant={isLgScreen ? "h5" : isMobile ? "h5" : "h4"}
              >
                Zavirite u poeziju i život Gine Ranjičić:
              </StyledTypographySubTitle>
              <StyledTypographyTitle
                variant={isLgScreen ? "h3" : "h2"}
                isMobile={isMobile}
              >
                “AKTIVNA ŽENA U PASIVNA VREMENA”
              </StyledTypographyTitle>
              <Box mt={5} />
              <StyledButton
                onMouseEnter={() => handleMouseEnter("ginaRanjicic")}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleClick("/culture/gina-ranjicic")}
              >
                {!isMobile && isHoveredGinaRanjicic ? (
                  <StyledBox
                    color={theme.palette.yellow.regular}
                    minHeight={"100%"}
                    url={greenHover}
                    isMobile={isMobile}
                    isLgScreen={isLgScreen}
                  >
                    PROČITAJ
                  </StyledBox>
                ) : (
                  <StyledBox
                    color={theme.palette.green.disabled}
                    minHeight={"100%"}
                    url={yellowBackgroundShort}
                    isMobile={isMobile}
                    isLgScreen={isLgScreen}
                  >
                    PROČITAJ
                  </StyledBox>
                )}
              </StyledButton>
            </StyledBoxItem>
          </StyledGridItem>
        </StyledBoxContainer>
        <StyledBoxContainer
          isMobile={isMobile}
          url={dzenetKokoBackground}
          positionPercentage={isMobile ? "-155px" : "95%"}
          isLgScreen={isLgScreen}
        >
          <StyledGridItem item color={theme.palette.purple.disabled}>
            <StyledBoxItem>
              <ChatSvg
                backgroundColor={theme.palette.purple.light}
                color={theme.palette.yellow.regular}
                width={isMobile ? "90" : isLgScreen ? "95" : "155"}
              />
              <Box mt={isMobile ? 1 : isLgScreen ? 0 : 5} />
              <StyledTypographyTitle
                variant={isLgScreen ? "h3" : "h2"}
                isMobile={isMobile}
              >
                DŽENET KOKO:
                <br />
                ŠTA ČINI DOBRU FOTKU
              </StyledTypographyTitle>
              <Box mt={5} />
              <StyledButton
                onMouseEnter={() => handleMouseEnter("dzenetKoko")}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleClick("/culture/dzenet-koko")}
              >
                {!isMobile && isHoveredDzenetKoko ? (
                  <StyledBox
                    color={theme.palette.yellow.regular}
                    minHeight={"100%"}
                    url={purpleHover}
                    isMobile={isMobile}
                    isLgScreen={isLgScreen}
                  >
                    PROČITAJ
                  </StyledBox>
                ) : (
                  <StyledBox
                    color={theme.palette.purple.disabled}
                    minHeight={"100%"}
                    url={yellowBackgroundShort}
                    isMobile={isMobile}
                    isLgScreen={isLgScreen}
                  >
                    PROČITAJ
                  </StyledBox>
                )}
              </StyledButton>
            </StyledBoxItem>
          </StyledGridItem>
        </StyledBoxContainer>
        <StyledBoxContainer
          isMobile={isMobile}
          url={quizGypsyHistoryBackground}
          positionPercentage="25%"
          isLgScreen={isLgScreen}
        >
          <StyledGridItem item color={theme.palette.blue.disabled}>
            <StyledBoxItem>
              <QuestionMarkSvg
                backgroundColor={theme.palette.blue.regular}
                color={theme.palette.yellow.regular}
                width={isMobile ? "80" : isLgScreen ? "80" : "127"}
              />
              <Box mt={isMobile ? 1 : isLgScreen ? 0 : 5} />
              <StyledTypographyTitle
                variant={isLgScreen ? "h3" : "h2"}
                isMobile={isMobile}
              >
                KVIZ:
                <br />
                KOLIKO ZNAŠ O ROMSKOJ ISTORIJI?
              </StyledTypographyTitle>
              <Box mt={5} />
              <StyledButton
                onMouseEnter={() => handleMouseEnter("qypsyHistory")}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleClick("/quiz/gypsy-history")}
              >
                {!isMobile && isHoveredGypsyHistory ? (
                  <StyledBoxLong
                    color={theme.palette.yellow.regular}
                    minHeight={"100%"}
                    url={blueHover}
                    isMobile={isMobile}
                    isLgScreen={isLgScreen}
                  >
                    PROVERI ZNANJE
                  </StyledBoxLong>
                ) : (
                  <StyledBoxLong
                    color={theme.palette.blue.disabled}
                    minHeight={"100%"}
                    url={yellowBackground}
                    isMobile={isMobile}
                    isLgScreen={isLgScreen}
                  >
                    PROVERI ZNANJE
                  </StyledBoxLong>
                )}
              </StyledButton>
            </StyledBoxItem>
          </StyledGridItem>
        </StyledBoxContainer>
        <StyledBoxContainer
          isMobile={isMobile}
          url={travellingGypsiesBackground}
          positionPercentage="25%"
          positionPercentageX={isMobile ? "38%" : "55%"}
          isLgScreen={isLgScreen}
        >
          <StyledGridItem item color={theme.palette.purple.disabled}>
            <StyledBoxItem>
              <VideoSvg
                backgroundColor={theme.palette.purple.light}
                color={theme.palette.yellow.regular}
                width={isMobile ? "85" : isLgScreen ? "90" : "145"}
              />
              <Box mt={isMobile ? 1 : isLgScreen ? 0 : 5} />
              <StyledTypographySubTitle
                variant={isLgScreen ? "h5" : isMobile ? "h5" : "h4"}
              >
                Pogledajte naš serijal:
              </StyledTypographySubTitle>
              <StyledTypographyTitle
                variant={isLgScreen ? "h3" : "h2"}
                isMobile={isMobile}
              >
                “PUT ROMA”
              </StyledTypographyTitle>
              <Box mt={5} />
              <StyledButton
                onMouseEnter={() => handleMouseEnter("travellingGypsies")}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleClick("/culture/travelling-gypsies")}
              >
                {!isMobile && isHoveredTravellingGypsies ? (
                  <StyledBox
                    color={theme.palette.yellow.regular}
                    minHeight={"100%"}
                    url={purpleHover}
                    isMobile={isMobile}
                    isLgScreen={isLgScreen}
                  >
                    POGLEDAJ
                  </StyledBox>
                ) : (
                  <StyledBox
                    color={theme.palette.purple.disabled}
                    minHeight={"100%"}
                    url={yellowBackgroundShort}
                    isMobile={isMobile}
                    isLgScreen={isLgScreen}
                  >
                    POGLEDAJ
                  </StyledBox>
                )}
              </StyledButton>
            </StyledBoxItem>
          </StyledGridItem>
        </StyledBoxContainer>
      </Grid>
    </>
  );
};
