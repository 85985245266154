import { Box, Grid, Typography, styled } from "@mui/material";
import { theme } from "../../theme";
import { useIsMobile } from "../../hooks/useIsMobile";
import { NavLink } from "react-router-dom";
import * as ReactDOMServer from "react-dom/server";
import { HoverSvg } from "../culture/assets/HoverSvg";

const StyledGrid = styled(Grid)<{
  color: React.CSSProperties["color"];
  isMobile: boolean;
}>(({ color, isMobile }) => ({
  backgroundColor: color,
  width: "100%",
  marginTop: isMobile ? "-155px" : "-15px",
  height: isMobile ? "100%" : "920px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  paddingTop: isMobile ? 200 : 80,
  paddingBottom: 80,
}));

const StyledTypographyTitle = styled(Typography)<{ isMobile: boolean }>(
  ({ theme, isMobile }) => ({
    color: theme.palette.yellow.regular,
    textAlign: isMobile ? "center" : "left",
    fontWeight: 700,
  })
);

const StyledTypographySubTitle = styled(Typography)<{ isMobile: boolean }>(
  ({ theme, isMobile }) => ({
    color: theme.palette.yellow.regular,
    textAlign: isMobile ? "center" : "left",
    fontWeight: 400,
  })
);

const StyledButton = styled(NavLink)(() => ({
  textAlign: "center",
  textDecoration: "none",
  "&:hover": {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
}));

const StyledBoxContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
}));

const StyledBoxHover = styled(Box)<{
  hoverUrl: string;
  isMobile: boolean;
}>(({ theme, hoverUrl, isMobile }) => ({
  width: isMobile ? "100%" : "550px",
  height: "376px",
  justifyContent: "center",
  alignItems: "center",
  ...(isMobile
    ? {}
    : {
        "&:hover": {
          backgroundImage: `url("data:image/svg+xml,${hoverUrl}")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          "& .MuiTypography-root": {
            color: theme.palette.purple.regular,
            paddingLeft: 20,
            "&:nth-child(2)": {
              color: theme.palette.purple.regular,
              paddingLeft: 20,
            },
          },
        },
      }),
}));

export const QuizPage: React.FC = () => {
  const isMobile = useIsMobile();

  const yellowHover = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      <HoverSvg color={theme.palette.yellow.regular} />
    )
  );

  return (
    <StyledGrid
      container
      color={theme.palette.purple.regular}
      isMobile={isMobile}
    >
      <Grid item xs={12} sm={12} md={6}>
        <StyledButton to={"/quiz/gypsy-culture"}>
          <StyledBoxContainer>
            <StyledBoxHover hoverUrl={yellowHover} isMobile={isMobile}>
              <StyledTypographyTitle variant="h1" isMobile={isMobile}>
                ROMSKA <br /> KULTURA
              </StyledTypographyTitle>
              <StyledTypographySubTitle variant="h6" isMobile={isMobile}>
                Proverite koliko znate <br /> o romskoj kulturi u <br /> našem
                kvizu!
              </StyledTypographySubTitle>
            </StyledBoxHover>
          </StyledBoxContainer>
        </StyledButton>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <StyledButton to={"/quiz/gypsy-history"}>
          <StyledBoxContainer>
            <StyledBoxHover hoverUrl={yellowHover} isMobile={isMobile}>
              <StyledTypographyTitle variant="h1" isMobile={isMobile}>
                ROMSKA <br /> ISTORIJA
              </StyledTypographyTitle>
              <StyledTypographySubTitle variant="h6" isMobile={isMobile}>
                Proverite koliko znate <br /> o romskoj istoriji u <br /> našem
                kvizu!
              </StyledTypographySubTitle>
            </StyledBoxHover>
          </StyledBoxContainer>
        </StyledButton>
      </Grid>
    </StyledGrid>
  );
};
