import React, { useState } from "react";
import { StyledNavButton } from "../buttons";
import {
  EmailIconSvg,
  EmialIconHoverSvg,
  FacebookLogoHoverSvg,
  FacebookLogoSvg,
  InstagramLogoHoverSvg,
  InstagramLogoSvg,
  TikTokLogoHoverSvg,
  TikTokLogoSvg,
  YouTubeLogoHoverSvg,
  YouTubeLogoSvg,
} from "../../../../../assets";
import { Button, Link, styled } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useIsMobile } from "../../../../../hooks/useIsMobile";
import { useIsLgScreen } from "../../../../../hooks/useIsLgScreen";
import { LogoSvg } from "../../../assets";
import { Box } from "@mui/material";

const StyledLinkButton = styled(NavLink)<{ isMobile: boolean }>(
  ({ isMobile }) => ({
    padding: isMobile ? 15 : 5,
  })
);

const StyledButton = styled(Button)<{ isMobile: boolean }>(({ isMobile }) => ({
  padding: isMobile ? 15 : 0,
  justifyContent: "normal",
  marginTop: "-8px",
  minWidth: 0,
}));

export interface DesktopHeaderProps {
  pages: any[];
  socialMedia: any[];
  currentPage: string;
}

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({
  pages,
  socialMedia,
  currentPage,
}) => {
  const isMobile = useIsMobile();
  const isLgScreen = useIsLgScreen();

  const [isHoveredInstagram, setIsHoveredInstagram] = useState(false);
  const [isHoveredFacebook, setIsHoveredFacebook] = useState(false);
  const [isHoveredYouTube, setIsHoveredYouTube] = useState(false);
  const [isHoveredTikTok, setIsHoveredTikTok] = useState(false);
  const [isHoveredEmail, setIsHoveredEmail] = useState(false);

  const socialMediaIcon = (index: number): JSX.Element => {
    if (index === 0) {
      return (
        <InstagramLogoSvg
          width={isLgScreen ? "24" : "34"}
          height={isLgScreen ? "24" : "34"}
        />
      );
    } else if (index === 1) {
      return <FacebookLogoSvg width={isLgScreen ? "30" : "38"} height="38" />;
    } else if (index === 2) {
      return <YouTubeLogoSvg width={isLgScreen ? "30" : "38"} height="29" />;
    } else if (index === 3) {
      return <TikTokLogoSvg width={isLgScreen ? "26" : "33"} height="33" />;
    } else {
      return <EmailIconSvg width={isLgScreen ? "31" : "39"} height="27" />;
    }
  };

  const socialMediaHoverIcon = (index: number): JSX.Element => {
    if (index === 0) {
      return <InstagramLogoHoverSvg width={isLgScreen ? "24" : "34"} />;
    } else if (index === 1) {
      return <FacebookLogoHoverSvg width={isLgScreen ? "30" : "38"} />;
    } else if (index === 2) {
      return <YouTubeLogoHoverSvg width={isLgScreen ? "30" : "38"} />;
    } else if (index === 3) {
      return <TikTokLogoHoverSvg width={isLgScreen ? "26" : "33"} />;
    } else {
      return <EmialIconHoverSvg width={isLgScreen ? "31" : "39"} />;
    }
  };

  const handleMouseEnter = (socialMedia: string) => {
    if (socialMedia === "Instagram") {
      setIsHoveredInstagram(true);
      setIsHoveredFacebook(false);
      setIsHoveredYouTube(false);
      setIsHoveredTikTok(false);
      setIsHoveredEmail(false);
    } else if (socialMedia === "Facebook") {
      setIsHoveredFacebook(true);
      setIsHoveredInstagram(false);
      setIsHoveredYouTube(false);
      setIsHoveredTikTok(false);
      setIsHoveredEmail(false);
    } else if (socialMedia === "YouTube") {
      setIsHoveredYouTube(true);
      setIsHoveredInstagram(false);
      setIsHoveredFacebook(false);
      setIsHoveredTikTok(false);
      setIsHoveredEmail(false);
    } else if (socialMedia === "TikTok") {
      setIsHoveredTikTok(true);
      setIsHoveredFacebook(false);
      setIsHoveredYouTube(false);
      setIsHoveredInstagram(false);
      setIsHoveredEmail(false);
    } else {
      setIsHoveredEmail(true);
      setIsHoveredInstagram(false);
      setIsHoveredFacebook(false);
      setIsHoveredYouTube(false);
      setIsHoveredTikTok(false);
    }
  };

  const handleMouseLeave = () => {
    setIsHoveredInstagram(false);
    setIsHoveredFacebook(false);
    setIsHoveredYouTube(false);
    setIsHoveredTikTok(false);
    setIsHoveredEmail(false);
  };

  const handleEmailClick = () => {
    window.open("mailto:udruzenje.artaparat@gmail.com");
  };

  return (
    <Box
      justifyContent={"center"}
      display={"flex"}
      alignContent={"center"}
      alignItems={"center"}
      width={"100%"}
      gap={"2px"}
    >
      <Link
        underline="none"
        component={RouterLink}
        to="/"
        sx={{ paddingRight: isLgScreen ? 3 : 4 }}
      >
        <LogoSvg
          width={isLgScreen ? "121" : "181"}
          height={isLgScreen ? "auto" : "125"}
        />
      </Link>
      {pages.map((item: { text: string; paths: string[] }) => (
        <StyledNavButton
          active={item.paths.includes(currentPage)}
          to={item.paths[0]}
          text={item.text.toUpperCase()}
        />
      ))}
      <StyledLinkButton
        to={socialMedia[0].path}
        onMouseEnter={() => handleMouseEnter(socialMedia[0].socialMedia)}
        onMouseLeave={handleMouseLeave}
        isMobile={isMobile}
        target="_blank"
      >
        {isHoveredInstagram && !isMobile
          ? socialMediaHoverIcon(0)
          : socialMediaIcon(0)}
      </StyledLinkButton>
      <StyledLinkButton
        to={socialMedia[1].path}
        onMouseEnter={() => handleMouseEnter(socialMedia[1].socialMedia)}
        onMouseLeave={handleMouseLeave}
        isMobile={isMobile}
        target="_blank"
      >
        {isHoveredFacebook && !isMobile
          ? socialMediaHoverIcon(1)
          : socialMediaIcon(1)}
      </StyledLinkButton>
      <StyledLinkButton
        to={socialMedia[2].path}
        onMouseEnter={() => handleMouseEnter(socialMedia[2].socialMedia)}
        onMouseLeave={handleMouseLeave}
        isMobile={isMobile}
        target="_blank"
      >
        {isHoveredYouTube && !isMobile
          ? socialMediaHoverIcon(2)
          : socialMediaIcon(2)}
      </StyledLinkButton>
      <StyledLinkButton
        to={socialMedia[3].path}
        onMouseEnter={() => handleMouseEnter(socialMedia[3].socialMedia)}
        onMouseLeave={handleMouseLeave}
        isMobile={isMobile}
        target="_blank"
      >
        {isHoveredTikTok && !isMobile
          ? socialMediaHoverIcon(3)
          : socialMediaIcon(3)}
      </StyledLinkButton>
      <StyledButton
        onMouseEnter={() => handleMouseEnter(socialMedia[4].socialMedia)}
        onMouseLeave={handleMouseLeave}
        isMobile={isMobile}
        onClick={handleEmailClick}
        disableRipple
      >
        {isHoveredEmail && !isMobile
          ? socialMediaHoverIcon(4)
          : socialMediaIcon(4)}
      </StyledButton>
    </Box>
  );
};
