import React from "react";

export const ChatSvg: React.FC<{
  backgroundColor?: React.CSSProperties["color"];
  color?: React.CSSProperties["color"];
  width?: string;
  height?: string;
}> = ({ color, backgroundColor, width, height }) => {
  return (
    <svg
      width={width ? width : "155"}
      height={height ? height : "127"}
      viewBox="0 0 155 127"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_162_558)">
        <path
          d="M79.0899 126.84C114.116 126.84 142.51 98.4459 142.51 63.42C142.51 28.3941 114.116 0 79.0899 0C44.064 0 15.6699 28.3941 15.6699 63.42C15.6699 98.4459 44.064 126.84 79.0899 126.84Z"
          fill={backgroundColor ? backgroundColor : "#A2DD8A"}
        />
        <path
          d="M72.6703 53.0601C73.2103 45.5101 72.2103 37.92 72.7703 30.37C73.3303 22.82 75.7703 14.91 81.6103 10.14C86.1103 6.49004 92.0303 5.14 97.7803 4.35C104.522 3.4272 111.333 3.11266 118.13 3.41006C125.95 3.76006 134.13 5.09011 140.26 9.96011C145.69 14.2701 148.84 20.89 150.49 27.62C152.01 33.87 152.3 40.7901 149.16 46.4101C145.65 52.6801 138.62 56.07 131.8 58.36C120.102 62.2822 107.804 64.1169 95.4703 63.7801C94.299 63.6368 93.111 63.8239 92.0403 64.3201C91.2841 64.8365 90.6577 65.5211 90.2103 66.3201L77.1003 86.48C74.6857 78.1137 73.9348 69.3553 74.8903 60.7001"
          stroke={color ? color : "#7A5D7F"}
          stroke-width="6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M60.5704 84.28C60.1704 78.78 60.9005 73.2799 60.5005 67.7299C60.1005 62.1799 58.3305 56.4599 54.0405 52.9799C50.7605 50.3099 46.4405 49.33 42.2505 48.76C37.335 48.0805 32.3678 47.8496 27.4104 48.07C21.7104 48.32 15.7305 49.29 11.2705 52.84C7.27046 55.98 5.01047 60.8399 3.81047 65.7199C2.70047 70.2799 2.49047 75.33 4.81047 79.43C7.37047 84 12.4904 86.43 17.4704 88.14C26.0004 91.0188 34.9708 92.3728 43.9704 92.14C44.8238 92.0342 45.6898 92.1694 46.4704 92.53C47.0236 92.9165 47.479 93.4267 47.8005 94.02L57.3605 108.68C59.1205 102.6 60.7005 100.28 60.6505 93.7499"
          stroke={color ? color : "#7A5D7F"}
          stroke-width="6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_162_558">
          <rect width="154.59" height="126.84" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
