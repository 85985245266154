export const CloseMobileMenuSvg: React.FC = () => {
  return (
    <svg
      width="66"
      height="61"
      viewBox="0 0 96 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 45.5C0 28.5864 16.7268 0 43.7286 0C73.8368 0 92.7142 14.2932 95.8206 45.5C98.927 76.7068 60.9333 91 40.6222 91C20.3111 91 0 62.4136 0 45.5Z"
        fill="#7A5D7F"
      />
      <path
        d="M33.7653 58.6737C42.658 51.7776 56.2376 39.6682 61.9158 34.4755"
        stroke="#FFECD2"
        stroke-width="6"
        stroke-linecap="round"
      />
      <path
        d="M35.2697 32.9621C42.8857 41.2466 56.0889 53.7653 61.7385 58.9891"
        stroke="#FFECD2"
        stroke-width="6"
        stroke-linecap="round"
      />
    </svg>
  );
};
