export interface RecordedVideoDto {
  id: string;
  author: string;
  name: string;
  url: string;
}

export enum ProjectTrack {
  FIRST = "FIRST",
  SECOND = "SECOND",
  WITHOUT = "WITHOUT",
  UNKNOWN = "UNKNOWN",
}

export enum UserAgent {
  CHROME = "Chrome",
  FIREFOX = "Firefox",
  SAFARI = "Safari",
  UNKNOWN = "Unknown",
}

export class MergedVideosDto {
  constructor(public base64String: string) {}
}

export enum Device {
  ANDROID = "ANDROID",
  IOS = "IOS",
  DESKTOP = "DESKTOP",
}
