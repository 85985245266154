import { atom, useAtom } from "jotai";
import { useMemoizedFn } from "ahooks";

type UploadedVideoFormState =
  | {
      isOpen: false;
    }
  | {
      isOpen: true;
    };

export type UseUploadedVideoFormResult = UploadedVideoFormState & {
  openDialog(): void;
  closeDialog(): void;
};

const formStateAtom = atom<UploadedVideoFormState>({
  isOpen: false,
});

export function useUploadedVideoForm(): UseUploadedVideoFormResult {
  const [formState, setFormState] = useAtom(formStateAtom);

  const openDialog = useMemoizedFn(() => {
    setFormState({
      isOpen: true,
    });
  });

  const closeDialog = useMemoizedFn(() => {
    setFormState({
      ...formState,
      isOpen: false,
    });
  });

  return {
    ...formState,
    openDialog,
    closeDialog,
  };
}
