export const ArrowRightSvg: React.FC<{
  width: number;
  color?: string | null;
}> = ({ width, color }) => {
  return (
    <svg
      width={width}
      height="53"
      viewBox="0 0 70 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 27H51"
        stroke={color ? color : "#FFECD2"}
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M38 3C45.3365 13.083 55.3771 20.9595 67 25.7493C62.5314 28.7825 58.0549 31.8157 54.4184 35.7969C50.7818 39.778 48.0082 44.6843 47.623 50"
        stroke={color ? color : "#FFECD2"}
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
