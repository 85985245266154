import { Box, Button, Typography, styled } from "@mui/material";
import { theme } from "../../../../theme";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import * as ReactDOMServer from "react-dom/server";
import {
  EmailIconSvg,
  FacebookLogoHoverSvg,
  FacebookLogoSvg,
  InstagramLogoHoverSvg,
  InstagramLogoSvg,
  TikTokLogoHoverSvg,
  TikTokLogoSvg,
  YouTubeLogoHoverSvg,
  YouTubeLogoSvg,
} from "../../../../assets";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { useIsLgScreen } from "../../../../hooks/useIsLgScreen";
import { SendEmailBackgroundSvg } from "./assets";

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.purple.regular,
  fontWeight: 700,
}));

const StyledBox = styled(Box)<{
  color: React.CSSProperties["color"];
  url: string;
  isMobile: boolean;
  isLgScreen: boolean;
}>(({ color, url, isMobile, isLgScreen }) => ({
  backgroundImage: `url("data:image/svg+xml,${url}")`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  padding: "10px",
  textAlign: "center",
  fontSize: isMobile ? "14px" : isLgScreen ? "15px" : "18px",
  color: color,
  cursor: "pointer",
  fontWeight: 400,
  fontFamily: "'Inter', 'sans-serif'",
  lineHeight: "130%",
}));

const StyledLinkButton = styled(NavLink)(() => ({
  paddingRight: 20,
}));

const StyledButton = styled(Button)(() => ({
  padding: 0,
  justifyContent: "normal",
  minWidth: 0,
}));

export const Footer: React.FC = () => {
  const isMobile = useIsMobile();
  const snackbar = useSnackbar();
  const isLgScreen = useIsLgScreen();

  const [isHoveredInstagram, setIsHoveredInstagram] = useState(false);
  const [isHoveredFacebook, setIsHoveredFacebook] = useState(false);
  const [isHoveredYouTube, setIsHoveredYouTube] = useState(false);
  const [isHoveredTikTok, setIsHoveredTikTok] = useState(false);

  const purpleBackground = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      <SendEmailBackgroundSvg width={isLgScreen ? "325" : "355"} />
    )
  );

  const socialMedia = [
    {
      socialMedia: "Instagram",
      path: "https://www.instagram.com/art.aparat",
    },
    {
      socialMedia: "Facebook",
      path: "https://www.facebook.com/artaparatbelgrade/",
    },
    {
      socialMedia: "YouTube",
      path: "https://www.youtube.com/@udruzenjeartaparat7614",
    },
    { socialMedia: "TikTok", path: "https://www.tiktok.com/@art.aparat" },
  ];

  const socialMediaIcon = (index: number): JSX.Element => {
    if (index === 0) {
      return <InstagramLogoSvg width="25" height="34" />;
    } else if (index === 1) {
      return <FacebookLogoSvg width="28" height="38" />;
    } else if (index === 2) {
      return <YouTubeLogoSvg width="30" height="29" />;
    } else if (index === 3) {
      return <TikTokLogoSvg width="25" height="33" />;
    } else {
      return <EmailIconSvg width="32" height="27" />;
    }
  };

  const socialMediaHoverIcon = (index: number): JSX.Element => {
    if (index === 0) {
      return <InstagramLogoHoverSvg width="25" height="34" />;
    } else if (index === 1) {
      return <FacebookLogoHoverSvg width="28" />;
    } else if (index === 2) {
      return <YouTubeLogoHoverSvg width="30" />;
    } else {
      return <TikTokLogoHoverSvg width="25" height="33" />;
    }
  };

  const handleMouseEnter = (socialMedia: string) => {
    if (socialMedia === "Instagram") {
      setIsHoveredInstagram(true);
      setIsHoveredFacebook(false);
      setIsHoveredYouTube(false);
      setIsHoveredTikTok(false);
    } else if (socialMedia === "Facebook") {
      setIsHoveredFacebook(true);
      setIsHoveredInstagram(false);
      setIsHoveredYouTube(false);
      setIsHoveredTikTok(false);
    } else if (socialMedia === "YouTube") {
      setIsHoveredYouTube(true);
      setIsHoveredInstagram(false);
      setIsHoveredFacebook(false);
      setIsHoveredTikTok(false);
    } else {
      setIsHoveredTikTok(true);
      setIsHoveredFacebook(false);
      setIsHoveredYouTube(false);
      setIsHoveredInstagram(false);
    }
  };

  const handleMouseLeave = () => {
    setIsHoveredInstagram(false);
    setIsHoveredFacebook(false);
    setIsHoveredYouTube(false);
    setIsHoveredTikTok(false);
  };

  const handleCopy = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      snackbar.enqueueSnackbar("Mejl je kopiran!", {
        variant: "info",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    } catch (error) {
      console.error("Failed to copy text:", error);
    }
  };

  const handleEmailClick = () => {
    window.open("mailto:email@example.com");
  };

  return (
    <>
      {!isMobile ? (
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.yellow.regular,
            height: "279px",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            boxShadow:
              "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          })}
        >
          <StyledTitle variant={isLgScreen ? "subtitle1" : "h6"}>
            PIŠI NAM NA MEJL
          </StyledTitle>
          <StyledBox
            color={theme.palette.yellow.regular}
            url={purpleBackground}
            mt={1}
            isMobile={isMobile}
            onClick={() => handleCopy("udruzenje.artaparat@gmail.com")}
            isLgScreen={isLgScreen}
          >
            udruzenje.artaparat@gmail.com
          </StyledBox>
          <Box mt={3} />
          <StyledTitle variant={isLgScreen ? "subtitle1" : "h6"}>
            POSETI NAS NA DRUŠTVENIM MREŽAMA
          </StyledTitle>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={1}
          >
            <StyledLinkButton
              to={socialMedia[0].path}
              onMouseEnter={() => handleMouseEnter(socialMedia[0].socialMedia)}
              onMouseLeave={handleMouseLeave}
              target="_blank"
            >
              {isHoveredInstagram && !isMobile
                ? socialMediaHoverIcon(0)
                : socialMediaIcon(0)}
            </StyledLinkButton>
            <StyledLinkButton
              to={socialMedia[1].path}
              onMouseEnter={() => handleMouseEnter(socialMedia[1].socialMedia)}
              onMouseLeave={handleMouseLeave}
              target="_blank"
            >
              {isHoveredFacebook && !isMobile
                ? socialMediaHoverIcon(1)
                : socialMediaIcon(1)}
            </StyledLinkButton>
            <StyledLinkButton
              to={socialMedia[2].path}
              onMouseEnter={() => handleMouseEnter(socialMedia[2].socialMedia)}
              onMouseLeave={handleMouseLeave}
              target="_blank"
            >
              {isHoveredYouTube && !isMobile
                ? socialMediaHoverIcon(2)
                : socialMediaIcon(2)}
            </StyledLinkButton>
            <StyledLinkButton
              to={socialMedia[3].path}
              onMouseEnter={() => handleMouseEnter(socialMedia[3].socialMedia)}
              onMouseLeave={handleMouseLeave}
              target="_blank"
            >
              {isHoveredTikTok && !isMobile
                ? socialMediaHoverIcon(3)
                : socialMediaIcon(3)}
            </StyledLinkButton>
          </Box>
          <Typography
            variant="subtitle1"
            sx={(theme) => ({
              marginTop: 2,
              opacity: 0.3,
              fontWeight: 400,
              color: theme.palette.purple.regular,
              fontFamily: "'Inter', 'sans-serif'",
              lineHeight: "130%",
            })}
          >
            © 2023 ArtAparat
          </Typography>
        </Box>
      ) : (
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.yellow.regular,

            marginTop: "-5px",
            boxShadow:
              "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          })}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={3}
          >
            <StyledLinkButton to={socialMedia[0].path} target="_blank">
              {socialMediaIcon(0)}
            </StyledLinkButton>
            <StyledLinkButton to={socialMedia[1].path} target="_blank">
              {socialMediaIcon(1)}
            </StyledLinkButton>
            <StyledLinkButton to={socialMedia[2].path} target="_blank">
              {socialMediaIcon(2)}
            </StyledLinkButton>
            <StyledLinkButton to={socialMedia[3].path} target="_blank">
              {socialMediaIcon(3)}
            </StyledLinkButton>
            <StyledButton onClick={handleEmailClick} disableRipple>
              {socialMediaIcon(4)}
            </StyledButton>
          </Box>
          <Typography
            variant="subtitle1"
            sx={(theme) => ({
              marginTop: 1,
              opacity: 0.3,
              color: theme.palette.purple.regular,
              textAlign: "center",
              fontWeight: 400,
              fontFamily: "'Inter', 'sans-serif'",
              lineHeight: "130%",
            })}
          >
            © 2023 ArtAparat
          </Typography>
        </Box>
      )}
    </>
  );
};
