import { UseMutationResult, useMutation } from "react-query";
import { save } from "../api";
import { VirtualChoirError } from "../../../api";

export interface QuizResultMutationValues {
  type: string;
  noOfCorrectAnswers: number;
}

export function useQuizResultMutation(): UseMutationResult<
  void,
  VirtualChoirError,
  QuizResultMutationValues
> {
  return useMutation(
    ["quiz-result"],
    async (values: QuizResultMutationValues) => {
      return save(values.type, values.noOfCorrectAnswers);
    },
    {
      onSuccess: () => {},
      onError: (err: VirtualChoirError) => {
        console.error(err);
      },
    }
  );
}
