import { CssBaseline, ThemeProvider } from "@mui/material";
import React from "react";
import { StyledSnackbarProvider } from "./components";
import { theme } from "./theme";

export const ApplicationTheme: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <StyledSnackbarProvider>
        <CssBaseline />
        {children}
      </StyledSnackbarProvider>
    </ThemeProvider>
  );
};
