import React from "react";

export const EarSvg: React.FC<{
  backgroundColor?: React.CSSProperties["color"];
  width?: string;
}> = ({ backgroundColor, width }) => {
  return (
    <svg
      width={width ? width : "130"}
      height="144"
      viewBox="0 0 130 144"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="65"
        cy="79"
        r="65"
        fill={backgroundColor ? backgroundColor : "#A2DD8A"}
      />
      <path
        d="M94.0503 61.3005C97.6203 53.0105 97.0503 48.3005 96.9603 42.7005C96.8903 37.3405 94.0403 32.5605 90.4803 29.0905C86.9203 25.6205 80.2903 23.3405 74.9303 23.4005C69.5703 23.4605 57.4403 28.1705 53.9303 31.7305C50.4203 35.2905 50.5103 42.3106 50.5703 47.6706C50.5703 58.4606 44.4603 62.0405 38.1803 61.5705C31.4903 61.0705 24.9503 55.9205 25.0003 47.6805C25.2158 35.8095 29.9719 24.4723 38.2903 16.0005C42.4503 11.8105 46.8003 7.44051 52.2903 5.13051C57.6203 2.91051 63.7503 3.00051 69.8903 3.00051C75.4114 3.00484 80.8814 4.05681 86.0103 6.10054C91.2803 8.10054 96.5003 10.2705 100.6 14.0105C105.343 18.2211 109.166 23.365 111.83 29.1205C114.3 34.7205 114.49 41.1205 114.49 47.6005C114.49 55.8405 113.49 64.2105 109.2 71.2705C105.52 76.1705 100.53 80.0005 95.6203 83.6805C93.2688 85.9963 91.1395 88.5274 89.2603 91.2405C86.627 94.9869 84.5019 99.0657 82.9403 103.37C80.7603 107.48 77.3203 110.371 73.7803 112.881C69.3703 116.051 64.5203 118.791 59.1703 119.571C53.5103 120.401 49.2503 118.821 44.7203 116.001C40.6103 113.431 35.3803 106.091 35.0703 103.401"
        stroke="#FFECD2"
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M50.5995 47.4604C54.3395 46.1004 59.6895 41.6504 65.6795 41.6504C72.9995 41.6504 78.2295 44.6504 79.4295 46.7604C83.4295 53.6304 86.0895 61.8304 77.0195 69.9004C74.2195 72.3904 68.2895 72.7004 66.2995 75.9004C65.0395 77.9004 65.3795 80.5004 65.2995 82.9004C65.1895 86.0704 64.4795 91.3104 61.8795 93.1304C59.2795 94.9504 51.1895 95.1304 49.7495 92.2704C49.0372 90.8668 48.5705 89.3516 48.3695 87.7904C48.1995 83.8804 48.2895 79.9804 43.2695 77.2004"
        stroke="#FFECD2"
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
