import { TextFieldProps } from "@mui/material";
import { FieldProps } from "formik";
import React from "react";
import { VirtualChoirTextField } from "./VirtualChoirTextField";

export interface VirtualChoirFormikTextFieldProps
  extends FieldProps<string>,
    Omit<TextFieldProps, "name" | "value" | "error" | "onChange"> {
  variant: any;
}

export const VirtualChoirFormikTextField: React.FC<
  VirtualChoirFormikTextFieldProps
> = ({ variant, ...props }) => (
  <VirtualChoirTextField
    variant={props.property}
    value={props.field.value}
    onChange={(value: string) => {
      props.field.onChange({
        name: props.field.name,
        target: {
          name: props.field.name,
          value: value,
        },
      });
    }}
    placeholder={props.placeholder}
  />
);
