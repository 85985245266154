import { Grid, IconButton, Skeleton, styled } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useMediaRecorder } from "../../../../state/useMediaRecorder";
import { useUploadTrackForm } from "../../../../state/useUploadTrackForm";
import { DisplayTrack, UploadTrack } from "./components";
import { Box } from "@mui/material";
import { ManageVideoRecorder } from "./components/ManageVideoRecorder";
import { useUserMedia } from "../../../../state";
import { StreamMixer } from "../../../../classes";
import { useCheckUserAgent } from "../../../../../../hooks/useCheckUserAgent";
import { UserAgent } from "../../../../api";
import {
  DisplayRecordedVideo,
  SaveVideoForm,
  StoreAndDownloadRules,
  VideoInformation,
} from "../../../Dialogs/UploadVideoDialog/components";
import { useIsMobile } from "../../../../../../hooks/useIsMobile";
import { useIsLgScreen } from "../../../../../../hooks/useIsLgScreen";
import { XSvg } from "../../assets";
import { ConnectedWrongBrowserDialog } from "../../../Dialogs/WrongBrowserDialog";

const StyledBoxContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
}));

export const VideoRecorder: React.FC = () => {
  const recordedVideoRef = useRef<HTMLVideoElement>(null);
  const mixerRef = useRef<StreamMixer | null>(null);
  const outputRef = useRef<HTMLVideoElement>(null);

  const { mediaStream: stream } = useUserMedia();
  const mediaRecorder = useMediaRecorder();
  const uploadTrackForm = useUploadTrackForm();
  const userAgent = useCheckUserAgent();
  const isMobile = useIsMobile();
  const isLgScreen = useIsLgScreen();

  const isIOS = /iPad|iPhone|iPod/.test(navigator.platform);

  const isTrackUploaded =
    uploadTrackForm.isDeviceTrackUploaded ||
    uploadTrackForm.isYouTubeTrackUploaded;

  const turnOffCamera = () => {
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
    }
  };

  const cancelRecordedVideo = () => {
    mediaRecorder.cancelRecordedVideo();
    uploadTrackForm.deleteUploadedTrack();
  };

  useEffect(() => {
    if (stream && recordedVideoRef.current) {
      recordedVideoRef.current.srcObject = stream;
      recordedVideoRef.current.setAttribute("autoplay", "");
    }
    return () => {
      turnOffCamera();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stream, recordedVideoRef]);

  return (
    <>
      {!mediaRecorder.recordedVideo && !mediaRecorder.isRecordingCompleted && (
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          justifyContent="center"
        >
          {isTrackUploaded && (
            <Grid item xs={12} sm={12} md={6}>
              <DisplayTrack />
            </Grid>
          )}
          {!isTrackUploaded && !mediaRecorder.isRecording && (
            <Grid item xs={12} sm={12} md={6} sx={{ marginLeft: 0 }}>
              <UploadTrack />
            </Grid>
          )}

          {stream ? (
            <Grid item xs={12} sm={12} md={6}>
              <Box
                sx={(theme) => ({
                  backgroundColor: theme.palette.yellow.regular,
                  borderRadius: "22px",
                  paddingLeft: 1,
                  paddingRight: 1,
                  paddingBottom: 1,
                  paddingTop: isMobile ? 0 : 5,
                  width: "100%",
                  justifyContent: "center",
                  alignContent: "center",
                  marginBottom: 0,
                  minHeight: isMobile ? "320px" : "100%",
                  position: "relative",
                })}
              >
                {!isMobile && (
                  <ManageVideoRecorder
                    recordedVideoRef={recordedVideoRef}
                    mixerRef={mixerRef}
                    outputRef={outputRef}
                  />
                )}
                <video
                  ref={recordedVideoRef}
                  muted
                  playsInline
                  style={{
                    width: "100%",
                    transform: "scaleX(-1)",
                    marginTop: 10,
                    borderRadius: "8px",
                  }}
                  crossOrigin="anonymous"
                />
                {isMobile && (
                  <ManageVideoRecorder
                    recordedVideoRef={recordedVideoRef}
                    mixerRef={mixerRef}
                    outputRef={outputRef}
                  />
                )}
              </Box>
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={6}>
              <Box
                sx={(theme) => ({
                  backgroundColor: theme.palette.yellow.regular,
                  borderRadius: "22px",
                  paddingLeft: isMobile ? 3 : 1,
                  paddingRight: isMobile ? 3 : 1,
                  paddingBottom: isMobile ? 3 : 1,
                  paddingTop: isMobile ? 3 : 5,
                  width: "100%",
                  justifyContent: "center",
                  alignContent: "center",
                  marginBottom: 5,
                  minHeight: isMobile ? "320px" : "100%",
                  position: "relative",
                })}
              >
                {!isMobile && (
                  <ManageVideoRecorder
                    recordedVideoRef={recordedVideoRef}
                    mixerRef={mixerRef}
                    outputRef={outputRef}
                  />
                )}
                <Box
                  sx={{
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Skeleton
                    animation="wave"
                    sx={{
                      bgcolor: "grey.900",
                      height: isMobile
                        ? "222px"
                        : isLgScreen
                        ? "285px"
                        : "441px",
                      width: isMobile
                        ? "297px"
                        : isLgScreen
                        ? "380px"
                        : "588px",
                      marginTop: 2,
                    }}
                    variant="rectangular"
                  />
                </Box>

                {isMobile && (
                  <ManageVideoRecorder
                    recordedVideoRef={recordedVideoRef}
                    mixerRef={mixerRef}
                    outputRef={outputRef}
                  />
                )}
              </Box>
            </Grid>
          )}
        </Grid>
      )}

      {mediaRecorder.recordedVideo && mediaRecorder.isRecordingCompleted && (
        <StyledBoxContainer>
          <Box
            sx={(theme) => ({
              backgroundColor: theme.palette.yellow.regular,
              borderRadius: "22px",
              padding: 5,
              minWidth: isLgScreen ? "920px" : "100%",
              maxWidth: isLgScreen ? "920px" : "1200px",
              justifyContent: "center",
              alignContent: "center",
              marginBottom: 0,
              minHeight: isLgScreen ? "480px" : isMobile ? "100%" : "570px",
              maxHeight: isLgScreen ? "480px" : isMobile ? "100%" : "570px",
              position: "relative",
            })}
          >
            <Grid
              container
              justifyContent="space-between"
              rowGap={isMobile ? 3 : 8}
            >
              <Grid item md={3} sm={12} xs={12} sx={{ height: "auto" }}>
                <SaveVideoForm />
                {!isMobile && <VideoInformation />}
              </Grid>
              <Grid item md={5} sm={12} xs={12} xxs={12}>
                <DisplayRecordedVideo />
              </Grid>
              <Grid item md={3} sm={12} xs={12}>
                <StoreAndDownloadRules />
              </Grid>
            </Grid>
            <IconButton
              sx={{
                position: "absolute",
                top: 0,
                right: isMobile ? 2 : 3,
                display: mediaRecorder.isRecording ? "none" : "",
              }}
              onClick={cancelRecordedVideo}
              disableRipple
            >
              <XSvg width={isMobile ? 34 : isLgScreen ? 35 : 44} />
            </IconButton>
          </Box>
        </StyledBoxContainer>
      )}
      <video
        ref={outputRef}
        id="output"
        controls
        style={{ display: "none", height: 400 }}
        crossOrigin="anonymous"
      />
      {userAgent !== UserAgent.CHROME && !isIOS && (
        <ConnectedWrongBrowserDialog />
      )}
    </>
  );
};
