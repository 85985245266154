import { useMemoizedFn } from "ahooks";
import { atom, useAtom } from "jotai";
import { format } from "date-fns";
import { ProjectTrack } from "../api";

type MediaRecorderState =
  | {
      isRecording: true;
      isRecordingCompleted: false;
      mediaRecorder: MediaRecorder;
      trackName: string;
      track: ProjectTrack;
    }
  | {
      isRecording: false;
      isRecordingCompleted: false;
      mediaRecorder?: MediaRecorder;
      trackName?: string;
      track?: ProjectTrack;
    }
  | {
      isRecording: false;
      isRecordingCompleted: true;
      mediaRecorder?: MediaRecorder;
      trackName: string;
      track: ProjectTrack;
    };

type RecordedVideoState = {
  recordedVideo: Blob | null;
  recordedVideoSize?: string;
  recordedAt?: string;
};

export type UseMediaRecorderResult = MediaRecorderState &
  RecordedVideoState & {
    startRecording(
      mediaRecorder: MediaRecorder,
      trackName: string,
      track: ProjectTrack
    ): void;
    stopRecording(trackName: string, track: ProjectTrack): void;
    cancelRecordedVideo(): void;
    saveRecordedVideo(chunks: Blob[]): void;
  };

const mediaRecorderStateAtom = atom<MediaRecorderState>({
  isRecording: false,
  isRecordingCompleted: false,
});

const recordedVideoStateAtom = atom<RecordedVideoState>({
  recordedVideo: null,
});

export function useMediaRecorder(): UseMediaRecorderResult {
  const [mediaRecorderState, setMediaRecorderState] = useAtom(
    mediaRecorderStateAtom
  );
  const [recordedVideoState, setRecordedVideoState] = useAtom(
    recordedVideoStateAtom
  );

  const startRecording = useMemoizedFn(
    (request: MediaRecorder, trackName: string, track: ProjectTrack) => {
      setMediaRecorderState({
        isRecording: true,
        isRecordingCompleted: false,
        mediaRecorder: request,
        trackName: trackName,
        track: track,
      });
      request.start();
    }
  );

  const stopRecording = useMemoizedFn(
    (trackName: string, track: ProjectTrack) => {
      mediaRecorderState.mediaRecorder?.stop();
      setMediaRecorderState({
        isRecording: false,
        isRecordingCompleted: true,
        trackName: trackName,
        track: track,
      });
    }
  );

  const cancelRecordedVideo = useMemoizedFn(() => {
    setMediaRecorderState({
      isRecording: false,
      isRecordingCompleted: false,
    });
    setRecordedVideoState({
      recordedVideo: null,
    });
  });

  const saveRecordedVideo = useMemoizedFn((chunks: Blob[]) => {
    const recordedVideoBlob = new Blob(chunks, { type: "video/mp4" });
    const recordedVideoSizeInMiB = recordedVideoBlob.size / 1048576; // 1024 on the square
    const recordedAt = format(new Date(), "dd.MM.yyyy. HH:mm");

    setRecordedVideoState({
      recordedVideo: recordedVideoBlob,
      recordedVideoSize: recordedVideoSizeInMiB.toFixed(2),
      recordedAt: recordedAt,
    });
  });

  return {
    ...mediaRecorderState,
    ...recordedVideoState,
    startRecording,
    stopRecording,
    cancelRecordedVideo,
    saveRecordedVideo,
  };
}
