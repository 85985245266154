export const QuestionMarkSvg: React.FC<{
  backgroundColor?: React.CSSProperties["color"];
  color?: React.CSSProperties["color"];
  width?: string;
  height?: string;
}> = ({ color, backgroundColor, width, height }) => {
  return (
    <svg
      width={width ? width : "127"}
      height={height ? height : "148"}
      viewBox="0 0 127 148"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_162_554)">
        <path
          d="M63.42 138.59C98.4459 138.59 126.84 110.196 126.84 75.17C126.84 40.1441 98.4459 11.75 63.42 11.75C28.3941 11.75 0 40.1441 0 75.17C0 110.196 28.3941 138.59 63.42 138.59Z"
          fill={backgroundColor ? backgroundColor : "#FF7878"}
        />
        <path
          d="M50.6101 120.48C42.5201 119.13 37.76 124 35.8 131.96C35.3527 133.431 35.2601 134.987 35.5301 136.5C36.0435 138.315 37.1711 139.894 38.7201 140.97C43.8701 144.88 55.9201 145.63 59.0001 139.97C66.0001 127.03 56.6601 120.97 50.6101 120.51"
          stroke={color ? color : "#7A5D7F"}
          stroke-width="6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M72.1203 86.21C68.8203 87.6 66.5803 90.5199 65.1203 93.6099C63.6603 96.6999 62.9003 100.03 61.6403 103.18C60.8403 105.18 59.6403 107.29 57.5703 108.18C56.0435 108.705 54.3976 108.778 52.8303 108.39C49.6203 107.75 46.2103 106.01 45.6103 103.03C43.6103 94.94 52.4903 72.5 67.6803 68.57C72.5803 67.3 79.1803 66.09 84.0703 64.77C99.3503 60.66 109.91 41.2 104.16 27.14C98.4103 13.08 86.4103 5.69993 70.5003 3.34993C53.9603 0.919929 40.7503 12.21 35.9003 27.42C34.0803 33.13 31.0303 41.57 36.1703 45.17C39.7403 47.07 43.0703 47.7099 46.6903 46.1099C47.9704 45.4863 49.0843 44.5679 49.9403 43.43C54.9403 37.36 55.6103 28.43 62.1503 23.78C64.2046 22.4094 66.5656 21.5672 69.0235 21.3282C71.4815 21.0892 73.9605 21.4608 76.2403 22.41C78.47 23.33 80.4212 24.8157 81.9013 26.7202C83.3814 28.6247 84.3393 30.8822 84.6803 33.27C84.7482 35.7398 84.1929 38.1869 83.0656 40.3856C81.9384 42.5842 80.2754 44.4635 78.2303 45.8499"
          stroke={color ? color : "#7A5D7F"}
          stroke-width="6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_162_554">
          <rect width="126.84" height="147.05" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
