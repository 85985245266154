import { atom, useAtom } from "jotai";
import { UploadVideoRequest } from "./RecordedVideoSnapshot";
import { useMemoizedFn } from "ahooks";
import { useUploadTrackForm } from "../useUploadTrackForm";

type UploadVideoFormState =
  | {
      isOpen: false;
      recordedVideo?: UploadVideoRequest;
      isSaved?: boolean;
    }
  | {
      isOpen: true;
      recordedVideo: UploadVideoRequest;
      isSaved?: boolean;
    };

export type UseUploadVideoFormResult = UploadVideoFormState & {
  openDialog(recordedVideo: UploadVideoRequest): void;
  closeDialog(): void;
  setSaveToTrue(): void;
};

const formStateAtom = atom<UploadVideoFormState>({
  isOpen: false,
});

export function useUploadVideoForm(): UseUploadVideoFormResult {
  const [formState, setFormState] = useAtom(formStateAtom);
  const uploadTrackForm = useUploadTrackForm();

  const cleanup = () => {
    uploadTrackForm.deleteUploadedTrack();
  };

  const openDialog = useMemoizedFn((request: UploadVideoRequest) => {
    setFormState({
      isOpen: true,
      recordedVideo: request,
    });
  });

  const closeDialog = useMemoizedFn(() => {
    setFormState({
      ...formState,
      isOpen: false,
    });
    cleanup();
  });

  const setSaveToTrue = useMemoizedFn(() => {
    setFormState({
      ...formState,
      isSaved: true,
    });
  });

  return {
    ...formState,
    openDialog,
    closeDialog,
    setSaveToTrue,
  };
}
