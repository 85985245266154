import { Box, Button, Toolbar } from "@mui/material";
import React from "react";
import { BackToTopSvg } from "../../../assets";
import { useMobileHeader } from "../state";
import { useUploadedVideoForm } from "../../virtual-choir/state";

export interface MobileLayoutAppBarProps {
  actions?: React.ReactNode;
}

const MobileLayoutToolbar: React.FC<MobileLayoutAppBarProps> = React.memo(
  ({ actions }) => {
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
    const { isOpen } = useMobileHeader();
    const uploadedVideoForm = useUploadedVideoForm();

    return (
      <>
        {!uploadedVideoForm.isOpen && (
          <Toolbar sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                position: "fixed",
                top: "17px",
                left: "17px",
                right: "47px",
                zIndex: isOpen ? "0" : "9999",
              }}
            >
              <Button disableRipple onClick={scrollToTop}>
                <BackToTopSvg />
              </Button>
            </Box>
            {actions}
          </Toolbar>
        )}
      </>
    );
  }
);

export const MobileLayoutAppBar: React.FC<MobileLayoutAppBarProps> = ({
  actions,
}) => <MobileLayoutToolbar actions={actions} />;
