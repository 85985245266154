import { useMemoizedFn } from "ahooks";
import { atom, useAtom } from "jotai";
import { useEffect, useRef } from "react";
import { useCheckUserAgent } from "../../../hooks/useCheckUserAgent";
import { UserAgent } from "../api/dto";

type MediaStreamState = {
  mediaStream: MediaStream | null;
};

export type UseMediaStreamResult = MediaStreamState & {
  destroy(): void;
};

const mediaStreamStateAtom = atom<MediaStreamState>({
  mediaStream: null,
});

export function useUserMedia(): UseMediaStreamResult {
  const [mediaStreamState, setMediaStreamState] = useAtom(mediaStreamStateAtom);
  const userAgent = useCheckUserAgent();

  const audioContext = useRef<AudioContext | null>(null);
  const microphoneStream = useRef<MediaStream | null>(null);
  const audioNode = useRef<MediaStreamAudioSourceNode | undefined>(undefined);
  const isIOS = /iPad|iPhone|iPod/.test(navigator.platform);

  useEffect(() => {
    audioContext.current = new window.AudioContext();
    navigator.mediaDevices
      .getUserMedia({
        audio: isIOS
          ? true
          : {
              echoCancellation: false,
            },
        video:
          userAgent === UserAgent.FIREFOX
            ? { facingMode: "user" }
            : {
                width: { exact: 480 },
                height: { exact: 360 },
                facingMode: "user",
                frameRate: 50,
              },
      })
      .then((mediaStream) => {
        microphoneStream.current = mediaStream;
        audioNode.current =
          audioContext.current?.createMediaStreamSource(mediaStream);
        if (audioContext.current) {
          audioNode.current?.connect(audioContext.current.destination);
        }
        audioNode.current!.disconnect();

        setMediaStreamState({
          mediaStream: mediaStream,
        });
      })
      .catch((error) => {
        console.error(error);
        setMediaStreamState({
          mediaStream: null,
        });
      });
    return () => {
      // Release resources when the component unmounts
      if (microphoneStream.current) {
        microphoneStream.current.getTracks().forEach((track) => track.stop());
      }
      if (audioContext.current) {
        audioContext.current.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const destroy = useMemoizedFn(() => {
    setMediaStreamState({
      mediaStream: null,
    });
  });

  return {
    ...mediaStreamState,
    destroy,
  };
}
