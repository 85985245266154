import { Box, Grid, Typography } from "@mui/material";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { VideoSvg } from "../assets";
import { theme } from "../../../theme";
import travellingGypsiesBackground from "../assets/travelling-gypsies.jpg";
import { CultureFooter } from "./CultureFooter";
import YouTube, { YouTubeProps } from "react-youtube";
import { useIsLgScreen } from "../../../hooks/useIsLgScreen";

export const TravellingGypsiesPage: React.FC = () => {
  const isMobile = useIsMobile();
  const isLgScreen = useIsLgScreen();

  const opts: YouTubeProps["opts"] = {
    height: isLgScreen ? "320" : isMobile ? "200" : "390",
    width: isLgScreen ? "480" : isMobile ? "310" : "640",
  };

  return (
    <>
      <Grid container sx={{ marginTop: isMobile ? "-155px" : "-12px" }}>
        {!isMobile ? (
          <>
            <Grid
              item
              sx={(theme) => ({
                backgroundColor: theme.palette.purple.regular,
                paddingLeft: isLgScreen ? 22 : 30,
                paddingTop: 10,
                paddingRight: 10,
                paddingBottom: isLgScreen ? 5 : 10,
                minHeight: isLgScreen ? "584px" : "734px",
              })}
              md={6}
            >
              <VideoSvg
                color={theme.palette.yellow.regular}
                backgroundColor={theme.palette.purple.light}
                width={isLgScreen ? "100" : "145"}
              />
              <Box mt={2} />
              <Box sx={{ maxWidth: "507px" }}>
                <Typography
                  variant={isLgScreen ? "h6" : "h5"}
                  sx={(theme) => ({
                    color: theme.palette.yellow.regular,
                    fontWeight: 400,
                    fontFamily: "'Inter', 'sans-serif'",
                    lineHeight: "130%",
                  })}
                >
                  Pogledajte naš serijal:
                </Typography>
                <Typography
                  variant={"h3"}
                  sx={(theme) => ({
                    color: theme.palette.yellow.regular,
                    fontWeight: 700,
                    lineHeight: "100%",
                  })}
                >
                  “PUT ROMA”
                </Typography>

                <Box mt={isMobile ? 3 : 5} />
              </Box>
              <Typography
                variant={isLgScreen ? "subtitle1" : "h6"}
                sx={(theme) => ({
                  maxWidth: isLgScreen ? "310px" : "420px",
                  color: theme.palette.yellow.regular,
                  fontWeight: 400,
                  fontFamily: "'Inter', 'sans-serif'",
                  lineHeight: "130%",
                })}
              >
                Priča nam priču o narodu koji je vekovima lutao svetom u potrazi
                za svojim mestom pod suncem. Pridruži nam se na vijugavom putu
                pod zvezdama i otkrij uzbudljive priče iz romske kulture i
                istorije!
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              sx={{
                backgroundImage: `url(${travellingGypsiesBackground})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: "100%",
                position: "relative",
              }}
            ></Grid>
          </>
        ) : (
          <Grid
            item
            sx={{
              backgroundImage: `url(${travellingGypsiesBackground})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: "100%",
              height: "630px",
              position: "relative",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Box sx={{ ml: 6, mr: 6, mt: 10 }}>
              <VideoSvg
                width="80"
                color={theme.palette.yellow.regular}
                backgroundColor={theme.palette.purple.light}
              />
              <Box mt={isMobile ? 1 : 5} />

              <Typography
                variant="h5"
                sx={(theme) => ({
                  color: theme.palette.yellow.regular,
                  fontWeight: 400,
                  fontFamily: "'Inter', 'sans-serif'",
                  lineHeight: "130%",
                })}
              >
                Pogledajte naš serijal:
              </Typography>
              <Typography
                variant="h2"
                sx={(theme) => ({
                  color: theme.palette.yellow.regular,
                  fontWeight: 700,
                })}
              >
                “PUT ROMA”
              </Typography>
              <Box mt={isMobile ? 3 : 5} />
            </Box>
            <Typography
              variant="h5"
              sx={(theme) => ({
                color: theme.palette.yellow.regular,
                ml: 6,
                mr: 6,
                mb: 5,
                fontWeight: 400,
                fontFamily: "'Inter', 'sans-serif'",
              })}
            >
              Priča nam priču o narodu koji je vekovima lutao svetom u potrazi
              za svojim mestom pod suncem. Pridruži nam se na vijugavom putu pod
              zvezdama i otkrij uzbudljive priče iz romske kulture i istorije!
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        sx={{
          backgroundImage: `url(https://virtualchoir.fra1.digitaloceanspaces.com/project-images/put-roma-1.jpg)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          position: "relative",
          minHeight: isMobile ? "auto" : isLgScreen ? "634px" : "734px",
        }}
        alignContent="center"
        alignItems="center"
      >
        {!isMobile ? (
          <>
            <Grid
              item
              sx={(theme) => ({
                paddingLeft: isLgScreen ? 22 : 30,
                paddingTop: 10,
                paddingRight: 10,
                paddingBottom: 10,
              })}
              md={6}
            >
              <VideoSvg
                color={theme.palette.yellow.regular}
                backgroundColor={theme.palette.errors.regular}
                width={isLgScreen ? "110" : "145"}
              />
              <Box mt={3} />
              <Box sx={{ maxWidth: "507px" }}>
                <Typography
                  variant="h3"
                  sx={(theme) => ({
                    color: theme.palette.yellow.regular,
                    fontWeight: 700,
                    lineHeight: "100%",
                  })}
                >
                  ODAKLE DOLAZE ROMI?
                </Typography>

                <Box mt={isMobile ? 3 : 5} />
              </Box>
              <Typography
                variant={isLgScreen ? "subtitle1" : "h6"}
                sx={(theme) => ({
                  maxWidth: isLgScreen ? "313px" : "420px",
                  color: theme.palette.yellow.regular,
                  fontWeight: 400,
                  fontFamily: "'Inter', 'sans-serif'",
                  lineHeight: "120%",
                })}
              >
                Romi žive u Srbiji već stotinama godina, a da li znaš odakle
                potiču?
                <br /> <br />
                Saznaj u prvoj epizodi serijala “Put Roma”!
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  width: "100%",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <YouTube videoId="mcg6HywQLKo" opts={opts} />
              </Box>
            </Grid>
          </>
        ) : (
          <Grid
            item
            sx={{
              width: "100%",
              position: "relative",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Box sx={{ ml: 6, mr: 6, mt: 10 }}>
              <VideoSvg
                width="80"
                color={theme.palette.yellow.regular}
                backgroundColor={theme.palette.errors.regular}
              />
              <Box mt={isMobile ? 1 : 5} />
              <Typography
                variant="h2"
                sx={(theme) => ({
                  color: theme.palette.yellow.regular,
                  fontWeight: 700,
                  lineHeight: "100%",
                })}
              >
                ODAKLE DOLAZE ROMI?
              </Typography>
              <Box mt={isMobile ? 3 : 5} />
            </Box>
            <Typography
              variant="h5"
              sx={(theme) => ({
                color: theme.palette.yellow.regular,
                ml: 6,
                mr: 6,
                mb: 5,
                fontWeight: 400,
                fontFamily: "'Inter', 'sans-serif'",
                lineHeight: "130%",
              })}
            >
              Romi žive u Srbiji već stotinama godina, a da li znaš odakle
              potiču?
              <br /> <br />
              Saznaj u prvoj epizodi serijala “Put Roma”!
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                flexDirection: "column",
                position: "relative",
                marginBottom: 5,
              }}
            >
              <YouTube videoId="mcg6HywQLKo" opts={opts} />
            </Box>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        sx={{
          backgroundImage: `url(https://virtualchoir.fra1.digitaloceanspaces.com/project-images/put-roma-2.jpg)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          position: "relative",
          minHeight: isMobile ? "auto" : isLgScreen ? "634px" : "734px",
        }}
        alignContent="center"
        alignItems="center"
      >
        {!isMobile ? (
          <>
            <Grid
              item
              sx={(theme) => ({
                paddingLeft: isLgScreen ? 22 : 30,
                paddingTop: 10,
                paddingRight: 10,
                paddingBottom: 10,
              })}
              md={6}
            >
              <VideoSvg
                color={theme.palette.yellow.regular}
                backgroundColor={theme.palette.green.regular}
                width={isLgScreen ? "110" : "145"}
              />
              <Box mt={3} />
              <Box sx={{ maxWidth: "507px" }}>
                <Typography
                  variant="h3"
                  sx={(theme) => ({
                    color: theme.palette.yellow.regular,
                    fontWeight: 700,
                    lineHeight: "100%",
                  })}
                >
                  ZAŠTO SU ROMI OTIŠLI IZ INDIJE?
                </Typography>

                <Box mt={isMobile ? 3 : 5} />
              </Box>
              <Typography
                variant={isLgScreen ? "subtitle1" : "h6"}
                sx={(theme) => ({
                  maxWidth: isLgScreen ? "313px" : "420px",
                  color: theme.palette.yellow.regular,
                  fontWeight: 400,
                  fontFamily: "'Inter', 'sans-serif'",
                  lineHeight: "130%",
                })}
              >
                Misteriozni razlozi odlaska Roma iz Indije dugo su zaokupljali
                umove i maštu naučnika.
                <br /> <br />
                Pogledaj drugu epizodu "Puta Roma" i saznaj odgovor na ovu
                vekovima staru zagonetku!
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  width: "100%",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <YouTube videoId="QUtttYRwD1U" opts={opts} />
              </Box>
            </Grid>
          </>
        ) : (
          <Grid
            item
            sx={{
              width: "100%",
              position: "relative",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Box sx={{ ml: 6, mr: 6, mt: 10 }}>
              <VideoSvg
                width="80"
                color={theme.palette.yellow.regular}
                backgroundColor={theme.palette.green.regular}
              />
              <Box mt={isMobile ? 1 : 5} />
              <Typography
                variant="h2"
                sx={(theme) => ({
                  color: theme.palette.yellow.regular,
                  fontWeight: 700,
                  lineHeight: "100%",
                })}
              >
                ZAŠTO SU ROMI OTIŠLI IZ INDIJE?
              </Typography>
              <Box mt={isMobile ? 3 : 5} />
            </Box>
            <Typography
              variant="h5"
              sx={(theme) => ({
                color: theme.palette.yellow.regular,
                ml: 6,
                mr: 6,
                mb: 5,
                fontWeight: 400,
                fontFamily: "'Inter', 'sans-serif'",
                lineHeight: "130%",
              })}
            >
              Misteriozni razlozi odlaska Roma iz Indije dugo su zaokupljali
              umove i maštu naučnika.
              <br /> <br />
              Pogledaj drugu epizodu "Puta Roma" i saznaj odgovor na ovu
              vekovima staru zagonetku!
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                flexDirection: "column",
                position: "relative",
                marginBottom: 5,
              }}
            >
              <YouTube videoId="QUtttYRwD1U" opts={opts} />
            </Box>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        sx={{
          backgroundImage: `url(https://virtualchoir.fra1.digitaloceanspaces.com/project-images/put-roma-3.jpg)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          position: "relative",
          minHeight: isMobile ? "auto" : isLgScreen ? "634px" : "734px",
        }}
        alignContent="center"
        alignItems="center"
      >
        {!isMobile ? (
          <>
            <Grid
              item
              sx={(theme) => ({
                paddingLeft: isLgScreen ? 22 : 30,
                paddingTop: 10,
                paddingRight: 10,
                paddingBottom: 10,
              })}
              md={6}
            >
              <VideoSvg
                color={theme.palette.yellow.regular}
                backgroundColor={theme.palette.blue.regular}
                width={isLgScreen ? "110" : "145"}
              />
              <Box mt={3} />
              <Box sx={{ maxWidth: "507px" }}>
                <Typography
                  variant="h3"
                  sx={(theme) => ({
                    color: theme.palette.yellow.regular,
                    fontWeight: 700,
                    lineHeight: "100%",
                  })}
                >
                  KAKO SU ROMI STIGLI U EVROPU?
                </Typography>

                <Box mt={isMobile ? 3 : 5} />
              </Box>
              <Typography
                variant={isLgScreen ? "subtitle1" : "h6"}
                sx={(theme) => ({
                  maxWidth: isLgScreen ? "313px" : "420px",
                  color: theme.palette.yellow.regular,
                  fontWeight: 400,
                  fontFamily: "'Inter', 'sans-serif'",
                  lineHeight: "130%",
                })}
              >
                Romski jezik ima jedan neobični kvalitet - on je istovremeno i
                geografska mapa i istorijski udžbenik njihovog dugog putovanja
                ka Evropi.
                <br />
                <br />
                Saznaj kako!
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  width: "100%",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <YouTube videoId="k5OgPFVsaXE" opts={opts} />
              </Box>
            </Grid>
          </>
        ) : (
          <Grid
            item
            sx={{
              width: "100%",
              position: "relative",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Box sx={{ ml: 6, mr: 6, mt: 10 }}>
              <VideoSvg
                width="80"
                color={theme.palette.yellow.regular}
                backgroundColor={theme.palette.blue.regular}
              />
              <Box mt={isMobile ? 1 : 5} />
              <Typography
                variant="h2"
                sx={(theme) => ({
                  color: theme.palette.yellow.regular,
                  fontWeight: 700,
                  lineHeight: "100%",
                })}
              >
                KAKO SU ROMI STIGLI U EVROPU?
              </Typography>
              <Box mt={isMobile ? 3 : 5} />
            </Box>
            <Typography
              variant="h5"
              sx={(theme) => ({
                color: theme.palette.yellow.regular,
                ml: 6,
                mr: 6,
                mb: 5,
                fontWeight: 400,
                fontFamily: "'Inter', 'sans-serif'",
                lineHeight: "130%",
              })}
            >
              Romski jezik ima jedan neobični kvalitet - on je istovremeno i
              geografska mapa i istorijski udžbenik njihovog dugog putovanja ka
              Evropi.
              <br />
              <br />
              Saznaj kako!
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                flexDirection: "column",
                position: "relative",
                marginBottom: 5,
              }}
            >
              <YouTube videoId="k5OgPFVsaXE" opts={opts} />
            </Box>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        sx={{
          backgroundImage: `url(https://virtualchoir.fra1.digitaloceanspaces.com/project-images/put-roma-4.jpg)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          position: "relative",
          minHeight: isMobile ? "auto" : isLgScreen ? "634px" : "734px",
        }}
        alignContent="center"
        alignItems="center"
      >
        {!isMobile ? (
          <>
            <Grid
              item
              sx={(theme) => ({
                paddingLeft: isLgScreen ? 22 : 30,
                paddingTop: 10,
                paddingRight: 10,
                paddingBottom: 10,
              })}
              md={6}
            >
              <VideoSvg
                color={theme.palette.yellow.regular}
                backgroundColor={theme.palette.purple.light}
                width={isLgScreen ? "110" : "145"}
              />
              <Box mt={3} />
              <Box sx={{ maxWidth: "507px" }}>
                <Typography
                  variant="h3"
                  sx={(theme) => ({
                    color: theme.palette.yellow.regular,
                    fontWeight: 700,
                    lineHeight: "100%",
                  })}
                >
                  TAJNI JEZIK
                </Typography>

                <Box mt={isMobile ? 3 : 5} />
              </Box>
              <Typography
                variant={isLgScreen ? "subtitle1" : "h6"}
                sx={(theme) => ({
                  maxWidth: isLgScreen ? "313px" : "420px",
                  color: theme.palette.yellow.regular,
                  fontWeight: 400,
                  fontFamily: "'Inter', 'sans-serif'",
                  lineHeight: "130%",
                })}
              >
                Romi su tokom svog puta u Evropu morali da razviju tajni jezik
                vizuelnih simbola, kako bi sunarodnicima koji su išli za njima
                pomogli da se snađu na opasnom putu u nepoznato.
                <br />
                <br />
                Pogledaj četvrtu epizodu "Puta Roma" i saznaj kakve su poruke na
                ovaj način jedni drugima ostavljali.
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <YouTube videoId="R6JOZ6ZTRLk" opts={opts} />
              </Box>
            </Grid>
          </>
        ) : (
          <Grid
            item
            sx={{
              width: "100%",
              position: "relative",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Box sx={{ ml: 6, mr: 6, mt: 10 }}>
              <VideoSvg
                width="80"
                color={theme.palette.yellow.regular}
                backgroundColor={theme.palette.purple.light}
              />
              <Box mt={isMobile ? 1 : 5} />
              <Typography
                variant="h2"
                sx={(theme) => ({
                  color: theme.palette.yellow.regular,
                  fontWeight: 700,
                  lineHeight: "100%",
                })}
              >
                TAJNI JEZIK
              </Typography>
              <Box mt={isMobile ? 3 : 5} />
            </Box>
            <Typography
              variant="h5"
              sx={(theme) => ({
                color: theme.palette.yellow.regular,
                ml: 6,
                mr: 6,
                mb: 5,
                fontWeight: 400,
                fontFamily: "'Inter', 'sans-serif'",
                lineHeight: "130%",
              })}
            >
              Romi su tokom svog puta u Evropu morali da razviju tajni jezik
              vizuelnih simbola, kako bi sunarodnicima koji su išli za njima
              pomogli da se snađu na opasnom putu u nepoznato.
              <br />
              <br />
              Pogledaj četvrtu epizodu "Puta Roma" i saznaj kakve su poruke na
              ovaj način jedni drugima ostavljali.
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                flexDirection: "column",
                position: "relative",
                marginBottom: 5,
              }}
            >
              <YouTube videoId="R6JOZ6ZTRLk" opts={opts} />
            </Box>
          </Grid>
        )}
      </Grid>
      <CultureFooter />
    </>
  );
};
