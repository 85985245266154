import { Box, Button, List, SwipeableDrawer, styled } from "@mui/material";
import React, { useState } from "react";
import { StyledNavButton, StyledSocialMediaButton } from "../buttons";
import {
  CloseMobileMenuSvg,
  EmailIconSvg,
  FacebookLogoSvg,
  InstagramLogoSvg,
  MenuHamburgerSvg,
  TikTokLogoSvg,
  YouTubeLogoSvg,
} from "../../../../../assets";
import { useMobileHeader } from "../../../state";
import { useUploadedVideoForm } from "../../../../virtual-choir/state";

const StyledButton = styled(Button)(() => ({
  padding: 15,
  minHeight: 0,
  minWidth: 45,
}));
export interface MobileHeaderProps {
  pages: any[];
  socialMedia: any[];
  currentPage: string;
}

export const MobileHeader: React.FC<MobileHeaderProps> = ({
  pages,
  socialMedia,
  currentPage,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const mobileHeader = useMobileHeader();
  const uploadedVideoForm = useUploadedVideoForm();

  const socialMediaIcon = (index: number): JSX.Element => {
    if (index === 0) {
      return <InstagramLogoSvg width="34" height="34" />;
    } else if (index === 1) {
      return <FacebookLogoSvg width="38" height="38" />;
    } else if (index === 2) {
      return <YouTubeLogoSvg width="38" height="29" />;
    } else if (index === 3) {
      return <TikTokLogoSvg width="33" height="33" />;
    } else {
      return <EmailIconSvg width="39" height="27" />;
    }
  };

  const handleEmailClick = () => {
    window.open("mailto:email@example.com");
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setMenuOpen(open);
      if (open) {
        mobileHeader.open();
      } else {
        mobileHeader.close();
      }
    };

  const mobileMenuItems = () => (
    <Box
      sx={(theme) => ({
        position: "relative",
        width: "100%",
        height: "100%",
        backgroundColor: theme.palette.yellow.regular,
      })}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          position: "absolute",
          top: 0,
          right: 0,
          padding: "16px",
        }}
      >
        <Button>
          <CloseMobileMenuSvg />
        </Button>
      </Box>
      <List sx={{ marginTop: "100px" }}>
        {pages.map((item: { text: string; paths: string[] }) => (
          <StyledNavButton
            active={item.paths.includes(currentPage)}
            to={item.paths[0]}
            text={item.text.toUpperCase()}
          />
        ))}
      </List>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          position: "absolute",
          bottom: 20,
          width: "100%",
        }}
      >
        {socialMedia.map((item, index) =>
          index !== 4 ? (
            <StyledSocialMediaButton
              to={item.path}
              element={socialMediaIcon(index)}
            />
          ) : (
            <StyledButton onClick={handleEmailClick} disableRipple>
              {socialMediaIcon(4)}
            </StyledButton>
          )
        )}
      </Box>
    </Box>
  );

  return (
    <>
      {!uploadedVideoForm.isOpen && (
        <Box display="flex" justifyContent="flex-end">
          <Button
            sx={(theme) => ({
              color: theme.palette.purple.regular,
              position: "fixed",
              top: "17px",
              right: "17px",
              zIndex: mobileHeader.isOpen ? "0" : "9999",
            })}
            onClick={toggleDrawer(true)}
          >
            <MenuHamburgerSvg />
          </Button>
          <SwipeableDrawer
            anchor="right"
            open={menuOpen}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            PaperProps={{
              sx: (theme) => ({
                width: "100%",
                backgroundColor: theme.palette.yellow.regular,
              }),
            }}
          >
            {mobileMenuItems()}
          </SwipeableDrawer>
        </Box>
      )}
    </>
  );
};
