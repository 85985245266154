import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { ChatSvg } from "../../assets";
import dzenetKokoBackground from "../../assets/dzenet-koko.jpg";
import { theme } from "../../../../theme";
import { InterviewQuestion } from "../InterviewQuestion";
import { InterviewAnswer } from "../InterviewAnswer";
import { CultureFooter } from "../CultureFooter";
import { useIsLgScreen } from "../../../../hooks/useIsLgScreen";

export const DzenetKokoPage: React.FC = () => {
  const isMobile = useIsMobile();
  const isLgScreen = useIsLgScreen();

  return (
    <>
      <Grid container sx={{ marginTop: isMobile ? "-155px" : "-12px" }}>
        {!isMobile ? (
          <>
            <Grid
              item
              sx={(theme) => ({
                backgroundColor: theme.palette.purple.disabled,
                paddingLeft: isLgScreen ? 22 : 30,
                paddingTop: isLgScreen ? 3 : 10,
                paddingRight: 10,
                paddingBottom: isLgScreen ? 5 : 10,
                minHeight: isLgScreen ? "584px" : "734px",
              })}
              md={6}
            >
              <ChatSvg
                color={theme.palette.yellow.regular}
                backgroundColor={theme.palette.purple.light}
                width={isLgScreen ? "100" : "155"}
              />
              <Box mt={2} />
              <Box sx={{ maxWidth: "507px" }}>
                <Typography
                  variant="h3"
                  sx={(theme) => ({
                    color: theme.palette.yellow.regular,
                    fontWeight: 700,
                  })}
                >
                  DŽENET KOKO: <br />
                  ŠTA ČINI DOBRU FOTKU
                </Typography>

                <Box mt={isMobile ? 3 : 5} />
              </Box>
              <Typography
                variant={isLgScreen ? "subtitle1" : "h6"}
                sx={(theme) => ({
                  maxWidth: isLgScreen ? "320px" : "420px",
                  color: theme.palette.yellow.regular,
                  fontFamily: "'Inter', 'sans-serif'",
                  lineHeight: "130%",
                  fontWeight: 400,
                })}
              >
                Dženet Koko bavi se fotografijom, aktivizmom, a od skoro i
                filmskom režijom. Raznolikost njenih fotografija veoma nas je
                zaintrigirala, zbog čega smo želeli da je upoznamo i saznamo
                nešto o njoj i njenom pristupu umetničkom stvaranju.
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              sx={{
                backgroundImage: `url(${dzenetKokoBackground})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: "100%",
                position: "relative",
              }}
            ></Grid>
          </>
        ) : (
          <Grid
            item
            sx={{
              backgroundImage: `url(${dzenetKokoBackground})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: "100%",
              position: "relative",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Box sx={{ ml: 6, mr: 6, mt: 10 }}>
              <ChatSvg
                width="90"
                color={theme.palette.yellow.regular}
                backgroundColor={theme.palette.purple.light}
              />
              <Box mt={isMobile ? 3 : 5} />

              <Typography
                variant="h2"
                sx={(theme) => ({
                  color: theme.palette.yellow.regular,
                  fontWeight: 700,
                })}
              >
                DŽENET KOKO: <br />
                ŠTA ČINI DOBRU FOTKU
              </Typography>

              <Box mt={isMobile ? 8 : 5} />
            </Box>
            <Typography
              variant="h5"
              sx={(theme) => ({
                color: theme.palette.yellow.regular,
                ml: 6,
                mr: 6,
                mb: 5,
                fontFamily: "'Inter', 'sans-serif'",
                lineHeight: "130%",
                textAlign: "left",
                fontWeight: 400,
              })}
            >
              Dženet Koko bavi se fotografijom, aktivizmom, a od skoro i
              filmskom režijom. Raznolikost njenih fotografija veoma nas je
              zaintrigirala, zbog čega smo želeli da je upoznamo i saznamo nešto
              o njoj i njenom pristupu umetničkom stvaranju.
            </Typography>
          </Grid>
        )}
      </Grid>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.yellow.regular,
          width: "100%",
          paddingBottom: 10,
          paddingLeft: isMobile ? 0 : 10,
          paddingRight: isMobile ? 0 : 10,
        })}
      >
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            maxWidth: isMobile ? "100%" : "750px",
            margin: "0 auto",
          }}
        >
          <Grid item md={12}>
            <Box
              justifyContent={"center"}
              display={"flex"}
              alignContent={"center"}
              alignItems={"center"}
            >
              <Typography
                variant={isLgScreen ? "h6" : isMobile ? "h5" : "h6"}
                sx={(theme) => ({
                  textAlign: "left",
                  marginTop: isMobile ? 3 : 5,
                  color: theme.palette.purple.regular,
                  fontFamily: "'Inter', 'sans-serif'",
                  marginLeft: isMobile ? 6 : 0,
                  marginRight: isMobile ? 6 : 0,
                  lineHeight: "130%",
                  maxWidth: isLgScreen ? "500px" : isMobile ? "100%" : "640px",
                  fontWeight: 400,
                })}
              >
                Rođena je u Prizrenu 1991.godine i usled rata na Kosovu,
                preselila se sa porodicom prvo u Novi Sad, a potom u Beograd.
                Studirala je novinarstvo i komunikologiju na Fakultetu
                primenjenih nauka u Beogradu. Zanima je angažovana umetnost kao
                i sve što ima veze sa edukacijom, radom na sebi i unapređenju
                društva.
                <br />
                <br />
                Sedamnaestog maja svratila je kod nas u kancelariju da malo
                porazgovaramo o fotografiji i njenom radu.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box
          justifyContent={"center"}
          display={"flex"}
          alignContent={"center"}
          alignItems={"center"}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              width: "100%",
              maxWidth: isLgScreen ? "760px" : isMobile ? "98%" : "950px",
              marginTop: isMobile ? 3 : 8,
            }}
          >
            <Box>
              <InterviewQuestion question="Šta je prva stvar koja je ključna za dobru fotografiju?" />
              <InterviewAnswer
                hasAvatarImg
                backgroundColor={theme.palette.purple.regular}
                answer="Prva je svetlo. Balans između svetla i senki. Zatim, ugao koji
            prikazuje realnu ili nadrealnu perspektivu nečega: čaše, događaja,
            čoveka ili ulice, sve zavisi šta vas zanima."
              />
              <InterviewQuestion question="A prva stvar kada stvari hvataš “usputno”?" />
              <InterviewAnswer
                hasAvatarImg
                backgroundColor={theme.palette.purple.regular}
                answer="Ući u momenat, bez preteranog razmišljanja o tehnici, više idite na intuiciju."
              />
              <InterviewQuestion question="A kada je ispred tebe model?" />
              <InterviewAnswer
                hasAvatarImg
                backgroundColor={theme.palette.purple.regular}
                answer="Sinergija trenutka i fotografa, dobro postavljen model i data dobra instrukcija za poziranje."
                imgSrc={[
                  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/dzenet-koko-1.png",
                ]}
              />
              <InterviewQuestion question="Šta po tebi čini dobar autoportret iliti selfi?" />
              <InterviewAnswer
                hasAvatarImg
                backgroundColor={theme.palette.purple.regular}
                answer="Ples između svetla i mraka, atmosfera i raspoloženje. Usklađenost u prostoru je ključ! Odrazi, ogledala, ogromna zgrada, i ti, u odrazu."
                imgSrc={[
                  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/dzenet-koko-2.png",
                  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/dzenet-koko-3.png",
                ]}
                imgInColumns
              />
              <InterviewQuestion question="A autoportret gde je samo tvoje lice?" />
              <InterviewAnswer
                hasAvatarImg
                backgroundColor={theme.palette.purple.regular}
                answer="Jedan od projekata mi je bio fotkanje leve i desne strane lica, levog i desnog profila. Jako je interesantno koliko su različiti i koliko imaš različit odnos prema njima. U svemu tome stoji manjak samopouzdanja. Pogledajte šta je interesantno na vašem licu i probajte to da uhvatite iz interesantnog ugla, ista stvar stoji i za portret, ili predmet. Što se tiče savršene simetrije – ona je najčešće dosadna. Neke sitne nepravilnosti su lepe i važne da se uhvate fotografijom."
                imgSrc={[
                  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/dzenet-koko-4.png",
                  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/dzenet-koko-5.png",
                ]}
              />
              <InterviewQuestion question="Da li misliš da je za dobru fotografiju dovoljna kamera na telefonu?" />
              <InterviewAnswer
                hasAvatarImg
                backgroundColor={theme.palette.purple.regular}
                answer="Naravno, s obzirom da svuda idem sa telefonom, važan mi je da zabeležim nešto što primetim. Tehnologija ide u tom pravcu da su kamere sve bolje, ali se vraća analogno, tako da se i ja vraćam malo na to. Sve u svemu, kamera na telefonu jeste prostor za kreativu i sa tim se treba igrati, isto kao sa fotoaparatom. Vizual koji sam na kraju izabrala za film ,,Moj put” bio je fotografija napravljena je u letu u sred snimanja, telefonom."
                imgSrc={[
                  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/dzenet-koko-6.png",
                ]}
              />
              <InterviewQuestion question="Koji je tvoj odnos prema filterima “za ulepšavanje”?" />
              <InterviewAnswer
                hasAvatarImg
                backgroundColor={theme.palette.purple.regular}
                answer="Do skoro sam mogla odmah da ih uočim kad neko “okači”, sada su toliko suptilni i dobri postali da lagano možeš da se predstaviš svetu kao prelepotica a da niko ne primeti da to nisi ti. To je upravo ono što mi se ne sviđa. Ta iluzija koju praviš sebi pa se pogledaš u ogledalo i pomalo crkneš iznutra, opasno je po samopouzdanje. Kad sam bila mala, imali smo časopise i to su bili uzori lepote, sada su to filteri.<br/><br/>Volim da koristim one smešne da se zabavljam sa porodicom i prijateljima. Što se tiče korišćenja filtera u fotografiji koristim samo pojedine presete u Photoshopu, za tipa jače kontraste, da zatamnim ili zamaglim pozadinu. Kolor korekcija je isto bitna."
              />
              <InterviewQuestion question="Šta misliš o veštačkoj inteligenciji?" />
              <InterviewAnswer
                hasAvatarImg
                backgroundColor={theme.palette.purple.regular}
                answer="Još uvek je veštačka inteligencija pomalo “plastična”, teško da može da zameni čoveka. Ljudi će uvek tražiti neki ljudski pečat, ljudsku ruku u fotografiji. Ključ fotografije je nečiji duh i energija, i to tehnologija ne može da replicira, barem još uvek. Sa druge strane, interesantno je igrati se sa AI-em i sada olakšava rad u mnogim poljima, posebno digitalnoj umetnosti."
              />
              <InterviewQuestion question="Kad se prisetiš početka, kako si otkrila svoju ljubav prema fotografiji?" />
              <InterviewAnswer
                hasAvatarImg
                backgroundColor={theme.palette.purple.regular}
                answer="Imala sam 6 godina. Igrala sam se sa malom narandžastom kutijicom Tik Tak bombonica i išla sam po kući i pravila se da hvatam kadrove fotoaparatom. Sa 12-13 sam već kupila onaj plastični aparat na film i krenula da fotkam uglavnom makro, svakodnevne stvari iz nekog drugog ugla, tipa neobičnu lampu u sobi (hahaha). U srednjoj školi sam prvi put shvatila da je fotografija nešto čime bi želela da se bavim. Prva desetka na studijama novinarstva mi je bila iz fotografije i to je bilo dovoljno da shvatim da je to nešto što moram da radim. Stvar je postala ozbiljna kada sam upisala Fabriku fotografa. Tada sam počela da istražujem različite forme i žanrove fotografije: studio, editorijale, decu, starije, profi modele, proizvodnu fotografiju, sve sam probala i sve sam fotkala."
                imgSrc={[
                  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/dzenet-koko-7.png",
                ]}
              />
              <InterviewQuestion question="Kako izgleda tvoj kreativni proces, kako biraš teme za fotografije?" />
              <InterviewAnswer
                hasAvatarImg
                backgroundColor={theme.palette.purple.regular}
                answer="Kreativni proces uglavnom teče tako da mi neka ideja dođe kao vizija ili me nešto usput naizgled bezazleno inspiriše. I onda smišljam na koji način to nešto da prikažem što sam spoznala ili usnila.<br/>Mislim da mi fotkanje ljudi ide najbolje, dobra sam sa ljudima. Volim akt i portret. Sinergija i usklađenost mene i modela kada fotkam, taj ples, ta komunikacija je sjajna. Žene su mi inspiracija, njih najviše volim da fotkam i snimam. Sa druge strane, volim i geometriju, ulicu, arhitekturu, šarenilo. Ipak, shvatila sam da iz mene kao osobe izbija to šarenilo, pa gledam da nekako bude tu. Ipak, ne bežim ni od crno-belog, i to treba umeti. Imala sam jednom zadatak da fotkam za izložbu koja će biti u tv seriji i tada sam prvi put radila akt, i to crno-belo. Ispalo je da je to bilo toliko dobro da su svi mislili da je to umetnički projekat rađen zasebno (hahaha). Glumci u seriji su zaista bili oduševljeni mojim radovima."
                imgSrc={[
                  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/dzenet-koko-8.png",
                ]}
              />
              <InterviewQuestion question="Šta radiš kada nemaš inspiracije za fotografije?" />
              <InterviewAnswer
                hasAvatarImg
                backgroundColor={theme.palette.purple.regular}
                answer="Slušam sebe. Meditiram. Povezujem se sa prirodom. Čitam, gledam filmove. Sedim sama noću u tišini, razmišljam o problemima u svetu i njihovim potencijalnim rešenjima. Dobro je naterati se na rad, svakako jer čim se upustite u osmišljavanje koncepta već je ste na dobrom putu da iz toga izađe nešto dobro ili prosto samo ugovorite snimanje sa vama poznatim saradnicima da se međusobno ispirišete. Recimo tako sam dogovorila jedno snimanje sa koleginicom koja je inače slikarka a imala je neku oslikanu odeću pa sam želela da to ufotkam u studiju. Ispalo je jako dobro i zbog te jedne moje okačene fotke na netu, otvorilo se puno novih prilika za posao i klijenta kojeg imam i dan danas."
                imgSrc={[
                  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/dzenet-koko-9.png",
                  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/dzenet-koko-10.png",
                ]}
                imgInColumns
              />
              <InterviewAnswer
                hasAvatarImg
                backgroundColor={theme.palette.purple.regular}
                answer="Nikad ne znaš šta može da se desi. Sve se rasplamsava kada se pokreneš, šta god kreativno radio. Svaki pokret je bitan, svaki! Morate se voditi intuicijom, nikad me nije izneverila. Na primer, za film “Moj put” imala sam osećaj da treba da se vratim i snimim jednu sagovornicu koja je pre toga bila svađalački nastrojena (hahah) i zaista ona je bila srž celog filma."
              />
              <InterviewQuestion question="Kako se nosiš sa kritikom?" />
              <InterviewAnswer
                hasAvatarImg
                backgroundColor={theme.palette.purple.regular}
                answer="U početku mi je baš bilo teško, sada je sve lakše. Ranije mi je to baš ljuljalo samopouzdanje, sada baš ne. Dosta je važno da se kritika usvoji i iskoristi da postaneš bolja zbog toga, da napreduješ. Ako ti neko čije mišljenje ceniš da pozitivnu kritiku, tako se stiče samopouzdanje, ali za to moraš da svoje fotke nekome pokažeš, da izađeš u svet. Obavezno morate da se gurate u vatru, čak i ako nešto mislite da ne možete, probajte! Praksom se najbolje uči! Ako nešto ne znate - posavetujte se sa nekim kolegom, ima toliko izvora informacija i toliko fotografa pre nas."
              />
              <InterviewQuestion question="Jel misliš da je važno biti ažuran i stalno izbacivati nešto novo ili čekati “savršene” fotografije?" />
              <InterviewAnswer
                hasAvatarImg
                backgroundColor={theme.palette.purple.regular}
                answer="Ako non-stop izbacuješ nešto što nije baš kvalitetno, bićeš shvaćen kao loš fotograf. Sa druge strane, ne treba ući u ponor perfekcionizma gde skoro nikad ne izbacuješ nešto novo. Ja mislim da su gotovo svi umetnici sebi najveći kritičari."
              />
              <InterviewQuestion question="Kako je izgledao tvoj rad na dokumentarnim  filmovima “Ja jesam” (Me sem) i “Moj put” (Moro drom)?" />
              <InterviewAnswer
                hasAvatarImg
                backgroundColor={theme.palette.purple.regular}
                answer="Prvi je poseban baš zato što je bio prvi i bilo je jako izazovno snimiti 10 aktera u samo dve nedelje. Trebalo je da budem ceo filmski set. Na tom projektu u Romskom centru za žene i decu imala sam pomoć od koleginice Anđele Močnik Petković koja je intervjuisala sve aktere.<br/>“Ja jesam” je krenuo iz ideje prezentovanja pozitivnih primera, a tema je naizgled dosadna – u pitanju je popis. Ipak u suštini filma je identitet i važnost iskazivanja romskog identiteta na popisu. Takođe priča se o romskim korenima i važnosti jezika i tradicije."
                imgSrc={[
                  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/dzenet-koko-11.png",
                ]}
              />
              <InterviewAnswer
                hasAvatarImg
                backgroundColor={theme.palette.purple.regular}
                answer="Drugi je snimljen kao deo projekta preventive dečijih brakova, Romske ženske mreže i u sklopu nove grupe “Hrabra i samostalna”. “Moj put” se bavi problemom dečjih brakova i edukovanjem devojčica o tome da postoji alternativa. Ovaj dokumentarno-igrani filmić prati priču dve žene koje pričaju svoju priču ulaska u brak, svojevoljno i nasilno. Bilo je pomalo potresno raditi onaj realniji deo filma a svakako zanimljiv rad sa glumicom Tamarom Jovanović, koja je inače imala ulogu Romkinje u koju je bio zaljubljen Toma Zdravković u filmu ,,Toma” Bitan deo tog projekta je i prikupljanje sredstava za romske učenice. Da imaju podršku na putu školovanja kako se ne bi desilo da ulaze u brak zbog siromaštva.<br/><br/>Romskim pitanjima se bavim od svoje 18. godine i to sam shvatila kao neku svoju ličnu svrhu. To je nešto čime moram da se bavim, pa su tako oba filma bila spoj privatnog i poslovnog. Htela sam da pokažem da smo mi, Romi mnogo više od onoga kako nas uobičajeno vide, i obrazovani i čisti."
              />
              <InterviewQuestion question="Koje su razlike u tvom pristupu snimanju i fotografisanju? Kako juriš priču?" />
              <InterviewAnswer
                hasAvatarImg
                backgroundColor={theme.palette.purple.regular}
                answer="Fotografija je početak, film je samo mnoštvo fotografija. Kada fotografišem mene nešto inspiriše pa idem da ga uhvatim ili stvorim. Kada snimam više prilazim tome planski i sve moram jasno da imam u glavi. Trudim se u poslednje vreme da idem više ka tome da imam fotografski pogled na stvari kada snimam - ono što mi dođe da bih ga imala za kasnije i iskoristila negde. Recimo, kada sam snimala film, primetila sam kadar koji mi se svideo, ali nije potreban za film, i ja sam ga usnimila i u nekom trenutku će biti iskorišćen. Čak i kada je film dokumentarni, volim da se igram i da snimim nešto što može da bude dobra metafora, tipa scena gde nosim masku na aerodromu i stojim dok gomila ljudi prolazi pored mene. To recimo prenosi poruku da Rome drugi često smatraju nevidljivim ili “bezličnim”."
              />
              <InterviewQuestion question="Da li misliš da je fotografija (vizuelna umetnost generalno) dobar alat za borbu protiv diskriminacije i razvoj boljeg društva?" />
              <InterviewAnswer
                hasAvatarImg
                backgroundColor={theme.palette.purple.regular}
                answer="Naravno. Mislim da je vizuelni medijum užasno moćan i da nas emotivno pogađa i oblikuje. Evo na primer, dete moje prijateljice je reklo da neće da se igra sa dojučerašnjim drugarom samo zato što je druge boje kože (dete mešovitog braka), jer je gledalo program koji se bavio afričkom decom. Dete kada vidi nešto na televiziji može da stekne negativni kontekst i to utiče na ponašanje. Naravno, u tome ima i uticaja šire kulture. Zato je jako važno ko upravlja vizuelnim kontekstom, pokrivalice na televiziji su dobar primer toga. Ako stavite Roma koji kopa đubre dok se priča o romskim pravima, nastavljamo sa istom pričom i vrtimo se u krug. To jeste istina, ali je to samo jedan deo istine. Kada sam radila film, htela sam da izbegnem snimke dece iz romskog naselja kako trčkaraju goli i bosi. Ja sam to recimo rešila tako što nisam prikazivala lica. Ono što gledaocu ostaje u glavi je lik, a ako slikaš samo nogice kako trčkaraju, prenosiš poruku, a izbegavaš jedan deo tog kruga diskriminacije i smeštanja u taj jedan-te-isti kontekst na kreativniji način."
                imgSrc={[
                  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/dzenet-koko-12.png",
                  "https://virtualchoir.fra1.digitaloceanspaces.com/project-images/dzenet-koko-13.png",
                ]}
              />
              <InterviewQuestion question="Šta misliš da je u društvenom smislu važno za borbu protiv diskriminacije?" />
              <InterviewAnswer
                hasAvatarImg
                backgroundColor={theme.palette.purple.regular}
                answer="Mnogo toga, ali stvar koja će sigurno smanjiti diskriminaciju jeste multikulturalnost, naša zemlja polako ide u pravcu toga. Puno ljudi iz drugih zemalja se doseljava ovde i to smanjuje važnost nacionalnosti. Odrasla sam u Prizrenu, gde zbog prisustva drugih kultura, nisam osetila šta znači biti Romkinja. Nisam osetila omalovažavanje, jer su i svi ostali bili različiti. Nikome to nije bilo važno, ni na ulici, ni u školi. Važno je da roditelji osveste kod sebe i da svoju decu uče poštovanjem razlitičosti i da budu samo podrška jedni drugima a ne kamen spoticanja."
              />
              <InterviewQuestion question="Kakvi su ti planovi za budućnost i na čemu trenutno radiš?" />
              <InterviewAnswer
                hasAvatarImg
                backgroundColor={theme.palette.purple.regular}
                answer="Uh, mnogo toga planiram. (hahahah) Nastavljam da radim na projektu promovisanja pozitivnih primera, na kome sam fotografisala i intervjuisala Rome i Romkinje. Potrebno je da se vidi još novih ljudi, čuje još novih glasova. Ja sam sebi time zapečatirala etiketu “Romkinja”, nisam to morala, pamte me ljudi već po mojim profesionalnim dostignućima. Ipak, osećam se da sam morala, jer mi je muka diskriminacije koja se i dalje dešava prema Romima/kinjama!<br/><br/>Plan je svakako da dam svoj dalji doprinos novoj grupi visokoobrazovanih Romkinja ,,Hrabra i samostalna” koja pre svega želi da spreči dečije brakove.<br/><br/>Takođe, zajedno sa svojim starim prijateljem, Marijanom Živanovićem, nekadašnjim moderatorom na Tv novinarstvu FPN, pokrećem akciju ulepšavanja spoljašnjosti romskog naselja na Bežaniji, jer smatram (na tragu priče o vizuelnom) da je užasno važno kako to sve izgleda, da bi se sprečila diskriminacija. Ne samo da izgleda, već i da se očisti. Sa druge strane, važno je i edukovati ljude tamo kako da uzgajaju povrće i cveće, da imaju svoje bašte, jer su i one lepe, a i korisne. Valja edukovati Rome da su oni zapravo ekološki aktivisti. Oni koji skupljaju sekundarne sirovine da bi zaradili, rade dobru stvar za okolinu. Akcija je ekološka i estetska. Ideja je da se ova akcija proširi i na druga romska naselja po gradu.<br/><br/>Različiti projekti vezani za fotografiju i snimanje su u planu, dokumentaristika uglavnom. Hoću da nastavim priču za edukaciju devojčica oko teme ranih brakova, da se izvuku iz problema na vreme. Planiram da se bavim neformalnim obrazovanjem Roma i Romkinja u budućnosti, da ti ljudi nauče različita korisna i praktična znanja za svakodnevni život. Obrazovanje na razne teme: prava, umetnost, ekologija itd. Živimo u svetu mogućnosti, važno je da se skupimo i pomognemo ljudima da vide šta postoji izvan njihove svakodnevice."
              />
              <InterviewQuestion question="Koji su saveti mladima koji treba da krenu u saradnju sa klijentima?" />
              <InterviewAnswer
                hasAvatarImg
                backgroundColor={theme.palette.purple.regular}
                answer="Jako je važno da se krećete tamo gde se nalaze potencijalni klijenti. Ako hoćete da fotkate ljude, to je barem lako: izađite na ulicu sa svojim fotkama, priđite nekome i pitajte: “Da li mogu da te fotografišem, evo vidi moje fotke?” Ako hoćete da fotkate ljude, to možete da radite na svakom koraku. Posle te fotografije prikupite i okačite ili odštampate i odnesete nekome kome mogu da se svide i da ih širi dalje. Negde će se to čuti i krenuće prvi poslovi. U tom smislu, lična preporuka je i dalje najbolji marketing. Baš zato morate da upoznajete ljude i krećete se u tim krugovima. Kada dođe prvi posao, sastanite se sa klijentima i tačno utvrdite ono što žele! Jako je važno da se odmah razumete, da bi i klijent bio zadovoljan, a da biste i vi odradili dobar, konkretan i precizan posao. Morate da se gurnete u vatru, čak i kada u nešto niste sigurni."
              />
              <InterviewQuestion question="Koji je tvoj savet početnicima koji žele da počnu da se bave fotografijom?" />
              <InterviewAnswer
                hasAvatarImg
                backgroundColor={theme.palette.purple.regular}
                answer="Prvo, važno je da se edukujete, šta je dobra fotografija, šta je tehnika itd. Na internetu ima puno besplatnih saveta, tekstova, Jutjub i tome slično. Možete i da upišete neki kurs ili radionicu. Drugo, da vidite šta vas inspiriše i u čemu ste dobri. Treće, da razvijate to u čemu ste dobri, jer ako bacite fokus samo na to – to će sigurno da uspe. Bilo kako da vidite uspeh, da li je to milion lajkova na Instagramu ili da vam se javi neko koga cenite i kaže: “Bravo”, uspeh će doći. Ja sam čak i probala sportsku fotografiju, ali sam shvatila da nisam dobra u tome i da to nije za mene. (hahaha) Fotografija je toliko šarenolika i široka da svako može da uzme jedan pedalj nje i da se bavi njom. Možete bukvalno da uzmete jednu čašu i samo nju da fotkate i da od nje napravite priču. Sve u svemu, posmatrajte svet kroz svoju dušu, i probajte da je prenesete drugima."
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <CultureFooter />
    </>
  );
};
