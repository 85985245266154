import { Box, StepIconProps } from "@mui/material";
import React from "react";
import { AlertTriangleSvg, ConsentSvg, RecordingSvg } from "../assets";
import { useIsMobile } from "../../../../../hooks/useIsMobile";
import { useIsLgScreen } from "../../../../../hooks/useIsLgScreen";

interface ColorlibStepIconProps extends StepIconProps {
  icon: number;
}

export const ColorlibStepIcon: React.FC<ColorlibStepIconProps> = (props) => {
  const { active, completed } = props;
  const isMobile = useIsMobile();
  const isLgScreen = useIsLgScreen();

  const icons: { [index: string]: React.ReactElement } = {
    1: (
      <Box sx={{ zIndex: 1 }}>
        <AlertTriangleSvg width={isMobile ? 52 : isLgScreen ? 52 : 71} />
      </Box>
    ),
    2: (
      <Box>
        <ConsentSvg
          opacity={active || completed ? 1 : 0.5}
          width={isMobile ? 52 : isLgScreen ? 52 : 66}
        />
      </Box>
    ),
    3: (
      <Box>
        <RecordingSvg
          opacity={active || completed ? 1 : 0.5}
          width={isMobile ? 51 : isLgScreen ? 52 : 69}
        />
      </Box>
    ),
  };

  return <>{icons[String(props.icon)]}</>;
};
