import {
  Box,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
  stepConnectorClasses,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import { ColorlibStepIcon } from "./components/ColorlibStepIcon";
import { ImportantRecordingNotes } from "./components/ImportantRecordingNotes";
import { RecordingConsent } from "./components/RecordingConsent";
import { VideoRecorder } from "./components/VideoRecorder/VideoRecorder";
import { HorizontalLinearStepperActions } from "./components/HorizontalLinearStepperActions";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { TitleSvg } from "../../../home/assets";
import { useIsLgScreen } from "../../../../hooks/useIsLgScreen";
import { ConnectedUploadedVideoDialog } from "../Dialogs/UploadedVideoDialog";
import { RecordingDisabled } from "./components/RecordingDisabled";

const StepperContainer = styled(Box)<{ isMobile: boolean }>(
  ({ theme, isMobile }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.purple.regular,
    marginTop: isMobile ? "-155px" : "-15px",
  })
);

const StepperContent = styled(Box)(() => ({
  paddingTop: "30px",
}));

const ColorlibConnector = styled(StepConnector)<{ isMobile: boolean }>(
  ({ theme, isMobile }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 30,
      left: isMobile ? "calc(-60% + 16px)" : "calc(-50% + 36px)",
      right: isMobile ? "calc(60% + 16px)" : "calc(50% + 36px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: theme.palette.yellow.regular,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: theme.palette.yellow.regular,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor: "#FFECD24D",
      borderRadius: 1,
    },
  })
);

const StyledBoxContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
}));

const steps = ["Važne napomene", "Saglasnost", "Snimanje"];

export const HorizontalLinearStepper: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const isMobile = useIsMobile();
  const isLgScreen = useIsLgScreen();
  const isRecordingActive = false;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      <StepperContainer isMobile={isMobile}>
        <Box
          sx={{
            width: "100%",
            maxWidth: "1500px",
            marginTop: isMobile ? 22 : 3,
            marginLeft: isMobile ? 2 : 5,
            marginRight: isMobile ? 2 : 5,
          }}
        >
          {isMobile && (
            <Box
              justifyContent="center"
              alignContent="center"
              display="flex"
              mt={"-120px"}
              mb={"40px"}
            >
              <TitleSvg width={150} height={150} />
            </Box>
          )}
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector isMobile={isMobile} />}
          >
            {steps.map((label, index) => {
              return (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    <Typography
                      variant={isLgScreen ? "subtitle1" : "h6"}
                      sx={(theme) => ({
                        color:
                          index <= activeStep
                            ? theme.palette.yellow.regular
                            : "#FFECD24D",
                        fontWeight: 700,
                        marginTop: "-20px",
                      })}
                    >
                      {label.toUpperCase()}
                    </Typography>
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>

          <StepperContent>
            <StyledBoxContainer>
              <Box
                sx={(theme) => ({
                  backgroundColor:
                    activeStep !== 2
                      ? theme.palette.yellow.regular
                      : "transparent",
                  borderRadius: activeStep !== 2 ? "22px" : "none",
                  padding: activeStep !== 2 ? (isMobile ? 2.5 : 4) : 4,
                  width: "100%",
                  maxWidth: isLgScreen ? "920px" : "1200px",
                  justifyContent: "center",
                  alignContent: "center",
                  marginBottom: 10,
                  minHeight: isLgScreen ? "535px" : isMobile ? "100%" : "670px",
                  maxHeight: isLgScreen ? "535px" : isMobile ? "100%" : "700px",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 0,
                })}
              >
                {!isRecordingActive && <RecordingDisabled />}
                {activeStep === 0 && isRecordingActive && (
                  <ImportantRecordingNotes />
                )}
                {activeStep === 1 && isRecordingActive && <RecordingConsent />}
                {activeStep === 2 && isRecordingActive && <VideoRecorder />}
                <Box mt={3} />
                {isRecordingActive && (
                  <HorizontalLinearStepperActions
                    activeStep={activeStep}
                    stepsLength={steps.length}
                    handleNext={handleNext}
                    handleBack={handleBack}
                  />
                )}
              </Box>
            </StyledBoxContainer>
          </StepperContent>
        </Box>
      </StepperContainer>
      <ConnectedUploadedVideoDialog />
    </>
  );
};
