import React from "react";
import { MainLayoutAppBar } from "../MainLayoutAppBar";
import { Box, styled } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { useIsLgScreen } from "../../../../hooks/useIsLgScreen";
import { DesktopHeader, MobileHeader } from "./components";
import { MobileLayoutAppBar } from "../MobileLayoutAppBar";

const Actions = styled(Box)<{ isMobile: boolean; isLgScreen: boolean }>(
  ({ theme, isMobile, isLgScreen }) => ({
    display: "flex",
    alignItems: "center",
    flex: !isMobile ? "auto" : "",
    gap: isLgScreen ? theme.spacing(1) : theme.spacing(2),
  })
);

export const Header: React.FC = () => {
  const location = useLocation();
  const isMobile = useIsMobile();
  const isLgScreen = useIsLgScreen();

  const currentPage = location.pathname;

  const pages = [
    { text: "Početna", paths: ["/"] },
    { text: "Pevačke radionice", paths: ["/vocal-workshops"] },
    { text: "Studio za snimanje", paths: ["/virtual-choir"] },
    {
      text: "Kultura",
      paths: [
        "/culture",
        "/quiz",
        "/quiz/gypsy-culture",
        "/quiz/gypsy-history",
        "/culture/gina-ranjicic",
        "/culture/pretty-loud",
        "/culture/dzenet-koko",
        "/culture/pretty-loud",
        "/culture/travelling-gypsies",
      ],
    },
    { text: "O nama", paths: ["/about-us"] },
  ];
  const socialMedia = [
    {
      socialMedia: "Instagram",
      path: "https://www.instagram.com/art.aparat",
    },
    {
      socialMedia: "Facebook",
      path: "https://www.facebook.com/artaparatbelgrade/",
    },
    {
      socialMedia: "YouTube",
      path: "https://www.youtube.com/@udruzenjeartaparat7614",
    },
    { socialMedia: "TikTok", path: "https://www.tiktok.com/@art.aparat" },
    { socialMedia: "Email", path: "/" },
  ];

  return (
    <>
      {!isMobile ? (
        <MainLayoutAppBar
          actions={
            <Actions isMobile={isMobile} isLgScreen={isLgScreen}>
              <DesktopHeader
                pages={pages}
                socialMedia={socialMedia}
                currentPage={currentPage}
              />
            </Actions>
          }
        />
      ) : (
        <MobileLayoutAppBar
          actions={
            <Actions isMobile={isMobile} isLgScreen={isLgScreen}>
              <MobileHeader
                pages={pages}
                socialMedia={socialMedia}
                currentPage={currentPage}
              />
            </Actions>
          }
        />
      )}
    </>
  );
};
