import { jsonRequest } from "../../../api";

export async function save(
  quizType: string,
  noOfCorrectAnswers: number
): Promise<void> {
  await jsonRequest<void>("POST", "/api/quiz-results", {
    type: quizType,
    noOfCorrectAnswers: noOfCorrectAnswers,
  });
}
