import {
  Box,
  Button,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import { useMediaRecorder } from "../../../../../../../state/useMediaRecorder";
import { useUploadVideoForm } from "../../../../../../../state/useUploadVideoForm/useUploadVideoForm";
import { useSupportedMimeType, useUserMedia } from "../../../../../../../state";
import { MutableRefObject, RefObject, useRef } from "react";
import { UploadVideoRequest } from "../../../../../../../state/useUploadVideoForm/RecordedVideoSnapshot";
import { useUploadTrackForm } from "../../../../../../../state/useUploadTrackForm";
import { useIsMobile } from "../../../../../../../../../hooks/useIsMobile";
import { Recorder, StreamMixer } from "../../../../../../../classes";
import { useCombinedVideo } from "../../../../../../../state/useCombinedVideo";
import { useCheckUserAgent } from "../../../../../../../../../hooks/useCheckUserAgent";
import { ProjectTrack, UserAgent } from "../../../../../../../api";
import * as ReactDOMServer from "react-dom/server";
import { CancelSvg, RecordingBackgroundSvg } from "../../../../../assets";
import { theme } from "../../../../../../../../../theme";
import { StartRecordingSvg } from "../../../../../assets/StartRecordingSvg";
import { useIsLgScreen } from "../../../../../../../../../hooks/useIsLgScreen";
import { useMergeVideos } from "../../../../../../../state/useMergeVideos";

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.purple.regular,
    color: theme.palette.yellow.regular,
    maxWidth: 255,
    width: "100%",
    borderRadius: "8px",
    border: "1px solid",
    borderColor: theme.palette.purple.regular,
  },
}));

const StyledButton = styled(Button)<{
  isTrackUploaded: boolean;
  disabledBackground: string;
  background: string;
  hover: string;
  isMobile: boolean;
  isLgScreen: boolean;
}>(
  ({
    theme,
    isTrackUploaded,
    disabledBackground,
    background,
    hover,
    isMobile,
    isLgScreen,
  }) => ({
    mr: 1,
    backgroundImage: `url("data:image/svg+xml,${
      isTrackUploaded ? background : disabledBackground
    }")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: isMobile ? "120px" : isLgScreen ? "160px" : "200px",
    height: isMobile ? "43px" : isLgScreen ? "55px" : "71px",
    color: theme.palette.yellow.regular,
    fontWeight: 700,
    "&:hover": {
      backgroundColor: "transparent",
      backgroundImage: `url("data:image/svg+xml,${hover}")`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
    mb: 4,
    fontSize: isMobile ? 18 : isLgScreen ? 22 : 32,
  })
);

export interface ManageVideoRecorderActionsProps {
  recordedVideoRef: RefObject<HTMLVideoElement>;
  outputRef: RefObject<HTMLVideoElement>;
  mixerRef: MutableRefObject<StreamMixer | null>;
  startTimer(): void;
  stopTimer(): void;
}

export const ManageVideoRecorderActions: React.FC<
  ManageVideoRecorderActionsProps
> = ({ recordedVideoRef, outputRef, mixerRef, startTimer, stopTimer }) => {
  const recorderRef = useRef<Recorder | null>(null);
  const isMobile = useIsMobile();

  const form = useUploadVideoForm();
  const { mediaStream: stream } = useUserMedia();
  const { mimeType, videoBitsPerSecond } = useSupportedMimeType(isMobile);
  const mediaRecorder = useMediaRecorder();
  const uploadTrackForm = useUploadTrackForm();
  const combinedVideo = useCombinedVideo();
  const userAgent = useCheckUserAgent();
  const isLgScreen = useIsLgScreen();
  const mergeVideos = useMergeVideos();

  const isIOS = /iPad|iPhone|iPod/.test(navigator.platform);

  const isTrackUploaded =
    (uploadTrackForm.isDeviceTrackUploaded ||
      uploadTrackForm.isYouTubeTrackUploaded) &&
    uploadTrackForm.videoDuration !== undefined;

  const handleStartRecording = async () => {
    try {
      combinedVideo.clearState();
      if (stream) {
        if (recordedVideoRef.current && isTrackUploaded) {
          recordedVideoRef.current.srcObject = stream;
          recordedVideoRef.current.play();
        }

        const recorder = new MediaRecorder(stream, {
          mimeType,
          videoBitsPerSecond,
        });
        const chunks: Blob[] = [];

        recorder.addEventListener("dataavailable", (event) => {
          chunks.push(event.data);
        });

        recorder.addEventListener("stop", () => {
          stopTimer();
          mediaRecorder.saveRecordedVideo(chunks);
          if (isTrackUploaded && userAgent !== UserAgent.SAFARI && !isIOS) {
            stopRecordingWithTrack();
            mixerRef.current?.stopDrawingFrames();
          }

          combineVideos(chunks);

          form.openDialog(
            new UploadVideoRequest(new Blob(chunks, { type: "video/mp4" }))
          );
        });

        mediaRecorder.startRecording(
          recorder,
          uploadTrackForm.videoName!,
          uploadTrackForm.projectTrack
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const combineVideos = (chunks: Blob[]) => {
    mergeVideos.mergeVideos(
      uploadTrackForm.projectTrack === ProjectTrack.FIRST ||
        uploadTrackForm.projectTrack === ProjectTrack.SECOND
        ? undefined
        : uploadTrackForm.deviceVideoUrl,
      URL.createObjectURL(new Blob(chunks, { type: "video/mp4" })),
      uploadTrackForm.projectTrack
    );
  };

  const stopRecordingWithTrack = async () => {
    if (recorderRef.current) {
      await recorderRef.current.stop();
      const blob = recorderRef.current.getBlob();
      if (outputRef.current) {
        outputRef.current.srcObject = null;
        outputRef.current.src = URL.createObjectURL(blob);
        outputRef.current.muted = false;
        combinedVideo.changeState(outputRef.current.src);
      }
    }
  };

  const playTrack = () => {
    if (uploadTrackForm.isYouTubeTrackUploaded) {
      uploadTrackForm.youTubePlayer.playVideo();
    } else if (uploadTrackForm.isDeviceTrackUploaded) {
      uploadTrackForm.inputFileRef.current.play();
    }
  };

  const startRecording = async () => {
    playTrack();
    await waitForVideoToLoad(uploadTrackForm.inputFileRef.current);
    handleStartRecording();
    startTimer();
  };

  const waitForVideoToLoad = (
    videoElement: HTMLVideoElement
  ): Promise<void> => {
    return new Promise((resolve) => {
      const checkCurrentTime = () => {
        if (videoElement.currentTime > 0) {
          resolve();
        } else {
          requestAnimationFrame(checkCurrentTime);
        }
      };

      checkCurrentTime();
    });
  };

  const cancel = () => {
    mediaRecorder.cancelRecordedVideo();
    uploadTrackForm.deleteUploadedTrack();
    stopTimer();
  };

  const recordingDisabledBackground = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      <RecordingBackgroundSvg
        color={theme.palette.green.disabled}
        opacity={0.3}
      />
    )
  );

  const recordingBackground = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      <RecordingBackgroundSvg
        color={theme.palette.green.disabled}
        opacity={1}
      />
    )
  );

  const recordingHover = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      <RecordingBackgroundSvg color={theme.palette.green.regular} opacity={1} />
    )
  );

  const cancelRecordingBackground = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      <RecordingBackgroundSvg
        color={theme.palette.errors.disabled}
        opacity={1}
      />
    )
  );
  const cancelRecordingHover = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      <RecordingBackgroundSvg
        color={theme.palette.errors.regular}
        opacity={1}
      />
    )
  );

  return (
    <Box justifyContent="center" alignContent="center" display="flex">
      {mediaRecorder.isRecording ? (
        <>
          <StyledButton
            isTrackUploaded={isTrackUploaded}
            disabledBackground={""}
            background={cancelRecordingBackground}
            hover={cancelRecordingHover}
            startIcon={
              <CancelSvg width={isMobile ? 22 : isLgScreen ? 30 : 35} />
            }
            disabled={!isTrackUploaded}
            onClick={cancel}
            isMobile={isMobile}
            isLgScreen={isLgScreen}
            sx={{ mt: 2 }}
          >
            PONIŠTI
          </StyledButton>
        </>
      ) : isTrackUploaded ? (
        <StyledButton
          isTrackUploaded={isTrackUploaded}
          disabledBackground={recordingDisabledBackground}
          background={recordingBackground}
          hover={recordingHover}
          startIcon={
            <StartRecordingSvg width={isMobile ? 30 : isLgScreen ? 46 : 56} />
          }
          disabled={!isTrackUploaded}
          onClick={startRecording}
          isMobile={isMobile}
          isLgScreen={isLgScreen}
        >
          SNIMI
        </StyledButton>
      ) : (
        <StyledTooltip
          placement="bottom"
          title={
            <Typography variant="h6" sx={{ padding: 1 }}>
              Odaberite matricu da biste započeli snimanje
            </Typography>
          }
        >
          <StyledButton
            isTrackUploaded={isTrackUploaded}
            disabledBackground={recordingDisabledBackground}
            background={recordingDisabledBackground}
            hover={recordingDisabledBackground}
            startIcon={
              <StartRecordingSvg width={isMobile ? 30 : isLgScreen ? 46 : 56} />
            }
            sx={{ cursor: "auto" }}
            isMobile={isMobile}
            isLgScreen={isLgScreen}
          >
            SNIMI
          </StyledButton>
        </StyledTooltip>
      )}
    </Box>
  );
};
