export const SviUglasSvg: React.FC<{ width?: number }> = ({ width }) => {
  return (
    <svg
      width={width ? width : "100"}
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="50" cy="50" r="50" fill="#7A5D7F" />
      <svg
        width="100"
        height="100"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_335_133)" transform="translate(13, 20)">
          <path
            d="M40.3889 46.2066C40.2618 46.6515 39.9907 47.0433 39.617 47.3225C39.2432 47.6018 38.7872 47.7532 38.3181 47.7538C38.1197 47.7526 37.9222 47.7268 37.7303 47.6772C35.6302 47.0817 33.4659 46.7336 31.2827 46.6401C31.1489 46.6316 31.0158 46.6141 30.8844 46.5878C31.2125 46.5878 31.5747 46.6233 31.9464 46.6644C32.515 46.7206 33.0829 46.552 33.5253 46.1956C33.9678 45.8392 34.2484 45.3242 34.3055 44.764C34.3625 44.2037 34.1914 43.644 33.8297 43.208C33.4681 42.7721 32.9454 42.4956 32.3769 42.4393C31.325 42.3314 30.2674 42.2859 29.21 42.3029C29.7637 40.2791 30.7896 36.3848 31.808 31.8514C32.004 30.8993 32.2929 29.9681 32.6708 29.0708C33.0696 28.1284 33.6736 27.2838 34.4401 26.5967C34.0567 26.9877 33.8396 27.5085 33.8336 28.0522C33.8275 28.5959 34.0328 29.1213 34.4074 29.5206C34.782 29.9198 35.2975 30.1627 35.848 30.1993C36.3986 30.2359 36.9424 30.0634 37.3681 29.7174C37.0308 30.012 36.7705 30.3826 36.6095 30.7975C36.3451 31.4374 36.1432 32.1006 36.0065 32.7783C35.1 36.8165 34.2031 40.3034 33.5981 42.557C35.3866 42.7617 37.1558 43.106 38.8889 43.5867C39.162 43.6616 39.4175 43.7888 39.6407 43.961C39.8639 44.1332 40.0505 44.3471 40.1898 44.5904C40.3291 44.8337 40.4183 45.1017 40.4525 45.379C40.4867 45.6563 40.4651 45.9375 40.3889 46.2066Z"
            fill="#A2DD8A"
          />
          <path
            d="M52.9692 36.7583C52.4003 34.9644 51.6417 32.9406 50.5191 31.0607C50.492 31.01 50.4602 30.9619 50.4243 30.9169C49.4154 29.235 48.1202 27.6691 46.3775 26.4862C43.9862 24.853 40.7775 24.2924 37.7832 24.9913C38.3369 24.8724 38.9158 24.973 39.3949 25.2712C39.874 25.5694 40.2148 26.0413 40.3438 26.5851C40.4728 27.1288 40.3796 27.7007 40.0843 28.1773C39.789 28.6539 39.3153 28.9969 38.7655 29.1323C40.586 28.7155 42.5203 29.0295 43.9255 29.99C44.8797 30.6581 45.6942 31.5013 46.3244 32.4735C46.057 33.0004 45.4976 33.5629 44.9628 34.0731C44.7024 34.3068 44.4282 34.5252 44.1417 34.7271C43.8913 34.914 43.641 35.1008 43.4002 35.3045C41.9419 36.5135 40.9577 37.9842 40.5557 39.5744C40.0304 41.615 40.531 43.7565 41.8585 45.1805C42.401 45.7422 43.0546 46.1882 43.7791 46.491C44.5036 46.7938 45.2834 46.9469 46.0703 46.9408C46.6124 46.9423 47.1522 46.8706 47.6746 46.7277C49.2808 46.2942 50.7296 45.2328 51.7915 43.7266C52.2119 44.2367 52.6833 44.7037 53.1986 45.1207C52.9745 44.946 52.7878 44.7291 52.6496 44.4827C52.5114 44.2362 52.4244 43.9651 52.3936 43.6851C52.3629 43.4052 52.389 43.122 52.4705 42.8521C52.552 42.5822 52.6872 42.331 52.8683 42.1131C53.0493 41.8952 53.2726 41.7151 53.525 41.5831C53.7775 41.4512 54.0541 41.3701 54.3387 41.3447C54.6233 41.3192 54.9102 41.3498 55.1826 41.4347C55.4551 41.5196 55.7076 41.6571 55.9256 41.8393C55.1102 41.1721 53.9363 39.7594 52.9692 36.7583ZM48.3705 41.1385C47.8699 41.9028 47.1967 42.4466 46.533 42.6335C45.9811 42.7773 45.3496 42.6334 45.031 42.3008C44.7125 41.9682 44.574 41.2469 44.7371 40.619C44.9268 39.9127 45.4274 39.1895 46.1708 38.5635C46.3604 38.4102 46.55 38.2664 46.7397 38.1225C47.1646 37.8202 47.5687 37.4906 47.9495 37.1358C48.0785 37.0069 48.234 36.863 48.3895 36.7098C48.5526 37.1694 48.7081 37.6198 48.8541 38.0533C49.0001 38.4869 49.131 38.8699 49.277 39.2605C49.0412 39.9155 48.7374 40.5449 48.3705 41.1385Z"
            fill="#8FC3F2"
          />
          <path
            d="M38.7657 29.1233C38.2616 29.2148 37.7843 29.4152 37.3681 29.71C36.9428 30.0527 36.401 30.2229 35.853 30.1858C35.3049 30.1488 34.7918 29.9073 34.418 29.5107C34.0442 29.114 33.8378 28.5919 33.8409 28.0505C33.8439 27.5092 34.0561 26.9894 34.4344 26.5968C34.4932 26.5384 34.5566 26.4846 34.624 26.4361C35.5494 25.7136 36.6342 25.2156 37.7909 24.9823C38.3446 24.8635 38.9235 24.964 39.4026 25.2622C39.8817 25.5604 40.2226 26.0324 40.3516 26.5761C40.4806 27.1199 40.3873 27.6918 40.092 28.1683C39.7967 28.6449 39.323 28.9879 38.7733 29.1233H38.7657Z"
            fill="#FFECD2"
          />
          <path
            d="M71.729 36.8184C71.5087 37.5618 71.3302 38.3166 71.1942 39.0795C71.1342 39.598 70.883 40.0767 70.4883 40.4247C70.0935 40.7727 69.5828 40.9656 69.0532 40.9668C68.9694 40.9695 68.8855 40.9639 68.8029 40.95C68.5219 40.9191 68.2499 40.8339 68.0025 40.6992C67.755 40.5646 67.537 40.3831 67.3608 40.1652C67.1846 39.9474 67.0538 39.6974 66.9759 39.4296C66.8979 39.1619 66.8744 38.8816 66.9065 38.6048C66.9938 37.8387 67.2441 36.9118 67.5551 35.7383C68.1923 33.3576 69.1499 29.7697 68.4426 27.7459C68.535 28.0095 68.6791 28.2525 68.8669 28.4612C69.0546 28.6698 69.2821 28.84 69.5366 28.962C69.7911 29.084 70.0674 29.1554 70.3499 29.1722C70.6323 29.1889 70.9154 29.1506 71.1828 29.0596C71.4503 28.9686 71.6969 28.8265 71.9087 28.6415C72.1204 28.4566 72.2931 28.2323 72.4169 27.9816C72.5407 27.7308 72.6132 27.4585 72.6302 27.1802C72.6472 26.9018 72.6084 26.6229 72.516 26.3594C73.6538 29.6071 72.5406 33.7743 71.729 36.8184Z"
            fill="#A2DD8A"
          />
          <path
            d="M59.4469 44.8631C59.3981 45.3886 59.1515 45.8772 58.7557 46.2327C58.3599 46.5882 57.8434 46.7848 57.3078 46.784C57.2358 46.7887 57.1636 46.7887 57.0916 46.784C55.6562 46.6247 54.3015 46.0477 53.2003 45.1265C52.9761 44.9519 52.7895 44.735 52.6513 44.4885C52.5131 44.2421 52.4261 43.971 52.3953 43.691C52.3646 43.411 52.3907 43.1278 52.4722 42.8579C52.5537 42.588 52.6889 42.3368 52.8699 42.119C53.051 41.9011 53.2743 41.721 53.5267 41.589C53.7792 41.4571 54.0558 41.376 54.3403 41.3505C54.6249 41.325 54.9118 41.3556 55.1843 41.4406C55.4567 41.5255 55.7093 41.663 55.9272 41.8451C56.3724 42.2318 56.9256 42.4773 57.5145 42.5496C58.0806 42.6073 58.6009 42.8824 58.9626 43.3155C59.3243 43.7485 59.4983 44.3045 59.4469 44.8631Z"
            fill="#FFECD2"
          />
          <path
            d="M71.185 29.0631C70.645 29.2465 70.0533 29.2118 69.5392 28.9667C69.025 28.7217 68.6302 28.286 68.4409 27.755C68.2172 27.116 67.8208 26.4956 66.4573 26.2601C65.9097 26.1656 65.4201 25.8667 65.0905 25.4254C64.7608 24.9842 64.6166 24.4348 64.6877 23.8914C64.7588 23.348 65.0398 22.8526 65.4722 22.5082C65.9046 22.1638 66.4551 21.997 67.0092 22.0425C67.073 22.0466 67.1363 22.0554 67.1988 22.0686C69.8537 22.5283 71.6951 24.0065 72.5086 26.3666C72.6931 26.8973 72.6575 27.4782 72.4096 27.9832C72.1618 28.4881 71.7216 28.8762 71.185 29.0631Z"
            fill="#FFECD2"
          />
          <path
            d="M66.4631 26.2571C65.6823 26.1413 64.889 26.1331 64.1059 26.2328C62.5699 26.3935 60.4043 27.2531 59.6628 27.9931C59.3252 28.3332 58.6615 29.4039 58.7658 30.5513C58.8606 31.5118 59.4902 32.405 60.647 33.2048C64.6597 35.9592 65.0655 39.6928 64.0984 42.2604C63.0705 45.0074 60.4953 46.8013 57.7133 46.8106C57.5047 46.8106 57.298 46.7938 57.0913 46.7751C57.1633 46.7798 57.2355 46.7798 57.3075 46.7751C57.8628 46.7763 58.3971 46.5666 58.7995 46.1895C59.2018 45.8124 59.4411 45.297 59.4678 44.7505C59.4944 44.204 59.3062 43.6684 58.9424 43.2551C58.5785 42.8418 58.0671 42.5826 57.5142 42.5314C58.6881 42.6435 59.6893 41.7409 60.0515 40.7804C60.5863 39.3602 59.9036 37.8634 58.178 36.6824C55.3827 34.7521 54.6147 32.4984 54.4687 30.9344C54.3823 29.8551 54.5265 28.7699 54.892 27.7489C55.2575 26.728 55.8362 25.7939 56.5907 25.0069C58.231 23.3569 61.4567 22.2263 63.6489 21.9965C64.7678 21.8658 65.899 21.8771 67.015 22.0301C66.4609 21.9846 65.9104 22.1514 65.4779 22.4958C65.0455 22.8402 64.7645 23.3356 64.6934 23.879C64.6223 24.4224 64.7666 24.9719 65.0962 25.4131C65.4259 25.8543 65.9154 26.1532 66.4631 26.2477V26.2571Z"
            fill="#FF7878"
          />
          <path
            d="M15.6128 39.8367C16.1174 40.3899 16.7318 40.8351 17.4181 41.1447C17.1584 41.6131 16.8549 42.0567 16.5117 42.4696C15.0951 44.2131 13.2879 45.2857 11.3915 45.4969C11.1447 45.5244 10.8966 45.5388 10.6482 45.5399C8.6478 45.5075 6.70546 44.8724 5.08238 43.7198C3.44393 42.4622 2.2606 40.472 1.57981 37.7942C1.33897 36.8598 1.10573 35.4135 0.855415 33.8905C0.605097 32.3675 0.337712 30.6596 0.0968754 29.8168C-0.0587856 29.2734 0.0110011 28.6913 0.290886 28.1986C0.57077 27.7058 1.03782 27.3428 1.5893 27.1894C2.14078 27.0361 2.7315 27.1048 3.23152 27.3806C3.73155 27.6564 4.09991 28.1167 4.25557 28.6601C4.56657 29.7495 4.82447 31.4332 5.12789 33.2197C5.35166 34.5801 5.57542 35.9909 5.77454 36.7552C6.21449 38.4912 6.87822 39.6984 7.74106 40.3617C8.34295 40.7886 9.03782 41.0708 9.77015 41.1858C10.1421 41.2755 10.5265 41.3046 10.908 41.2718C11.6494 41.1952 12.4706 40.6589 13.1513 39.818C13.4965 39.3919 13.9288 38.7379 14.0066 38.0577C14.0222 37.5237 13.9725 36.9897 13.8587 36.4674L13.8416 36.3646C14.1128 37.655 14.7236 38.8524 15.6128 39.8367Z"
            fill="#8FC3F2"
          />
          <path
            d="M12.1275 27.5761C11.9906 27.0282 12.0787 26.4491 12.3727 25.9648C12.6668 25.4805 13.1429 25.1301 13.6976 24.9899C13.972 24.9213 14.2573 24.9068 14.5374 24.9471C14.8174 24.9874 15.0866 25.0818 15.3296 25.2248C15.5725 25.3679 15.7844 25.5568 15.9531 25.7807C16.1218 26.0046 16.244 26.2591 16.3127 26.5297C16.5023 27.2771 16.709 28.1965 16.9082 29.1832C16.778 29.2536 16.6514 29.3303 16.5289 29.413C15.0118 30.3642 14.0105 31.9806 13.7413 33.8586C13.6543 34.5113 13.6575 35.1726 13.7508 35.8245C13.3354 33.455 12.6622 29.6579 12.1275 27.5761Z"
            fill="#8FC3F2"
          />
          <path
            d="M67.6014 47.1727C67.2422 47.1719 66.8889 47.0828 66.5735 46.9136C66.258 46.7444 65.9904 46.5003 65.7947 46.2034C65.5991 45.9066 65.4817 45.5664 65.4531 45.2135C65.4246 44.8607 65.4857 44.5064 65.6311 44.1828C65.8383 43.7276 66.1595 43.3317 66.5645 43.0323C66.9695 42.7329 67.4451 42.5398 67.9466 42.4711C68.3998 42.4072 68.862 42.4781 69.274 42.6748C69.7162 42.8869 70.0761 43.2355 70.2989 43.6675C70.5217 44.0996 70.5953 44.5915 70.5085 45.0685C70.3644 45.9319 69.4712 47.3745 67.6014 47.1727Z"
            fill="#FFECD2"
          />
          <path
            d="M29.2944 45.6014C29.0655 45.2935 28.9103 44.9386 28.8404 44.5632C28.7704 44.1878 28.7875 43.8017 28.8905 43.4337C28.9588 43.1777 29.0631 42.804 29.1996 42.3125C28.6136 42.3219 28.0864 42.3555 27.5953 42.3966C27.8797 40.1785 28.0277 37.6184 27.7072 35.3312C27.7109 35.3114 27.7109 35.2911 27.7072 35.2713C27.2748 32.2198 25.5757 29.6765 23.2792 28.6301C21.3298 27.7462 19.0864 27.9499 16.9113 29.1907C17.3171 31.1715 17.7134 33.4419 18.0073 35.1331C17.9761 34.9018 17.9761 34.6674 18.0073 34.4361C18.0585 34.0623 18.2311 33.3821 18.8342 32.9991C19.5421 32.558 20.1742 32.3369 20.7305 32.3357C20.9835 32.3336 21.234 32.3859 21.4644 32.4889C22.3955 32.9056 23.1711 34.2156 23.4138 35.7554C23.3968 36.486 22.9473 37.0223 22.0864 37.3624C20.922 37.8128 19.4031 37.6427 18.8152 37.0036C18.4715 36.6127 18.2208 36.1511 18.0813 35.6526L18.1003 35.7554C18.2539 36.6467 18.3847 37.5325 18.2899 38.5004C18.1842 39.4314 17.8881 40.3315 17.4195 41.1465C19.1527 41.9874 21.362 42.1145 23.3721 41.4268C23.3038 41.9967 23.2261 42.548 23.1484 43.0675C20.4555 43.5944 17.1862 44.6914 15.0111 46.4012C10.5585 49.9031 10.3859 53.7302 12.1799 55.9576C13.1982 57.2246 14.8897 58.0132 16.8316 58.0132C18.5232 58.0132 20.4043 57.4189 22.1907 56.023C23.5276 54.9784 24.6066 53.5227 25.3917 51.6877C26.0308 50.1815 26.393 48.6511 26.7381 47.1711C26.7647 47.0534 26.7988 46.917 26.8254 46.7805C28.1609 46.5798 29.514 46.5171 30.8627 46.5937C30.2324 46.4678 29.6729 46.1138 29.2944 45.6014ZM21.4075 50.032C20.9069 51.1962 20.2697 52.0876 19.5111 52.6837C17.7418 54.0534 16.0522 53.9264 15.5516 53.3134C15.1021 52.7528 15.6976 51.299 17.7001 49.7256C18.7962 48.8585 20.4859 48.1615 22.1698 47.67C21.9772 48.4725 21.7236 49.2596 21.4113 50.0246L21.4075 50.032Z"
            fill="#FF7878"
          />
          <path
            d="M34.3053 44.7607C34.2478 45.3204 33.9678 45.8348 33.5264 46.1917C33.0851 46.5485 32.5183 46.7186 31.9501 46.6648C31.5708 46.6237 31.2162 46.5976 30.8881 46.5882H30.8711C30.5259 46.5176 30.199 46.378 29.9106 46.1782C29.6222 45.9784 29.3784 45.7225 29.1942 45.4263C29.0099 45.13 28.8892 44.7998 28.8394 44.4559C28.7896 44.1119 28.8117 43.7616 28.9045 43.4264C28.9728 43.1704 29.0771 42.7967 29.2136 42.3052C30.2711 42.2882 31.3286 42.3338 32.3805 42.4416C32.947 42.4997 33.4672 42.7761 33.8277 43.2105C34.1883 43.6449 34.3599 44.2021 34.3053 44.7607Z"
            fill="#FFECD2"
          />
          <path
            d="M18.2956 38.4999C18.19 39.4308 17.8939 40.331 17.4252 41.146C16.7389 40.8363 16.1244 40.3911 15.6199 39.8379C14.7277 38.8527 14.1148 37.6532 13.843 36.3603C13.8165 36.2164 13.7918 36.0706 13.7652 35.9099C13.7508 35.8844 13.7448 35.8549 13.7482 35.8258C13.6549 35.174 13.6517 34.5127 13.7387 33.86C14.008 31.982 15.0168 30.3656 16.5263 29.4144C16.6488 29.3317 16.7754 29.2549 16.9056 29.1846C17.3114 31.1654 17.7078 33.4358 18.0017 35.1269C17.9958 35.1522 17.9958 35.1784 18.0017 35.2036C18.0115 35.3418 18.0343 35.4788 18.07 35.6128C18.0728 35.6239 18.0728 35.6354 18.07 35.6464L18.0889 35.7492C18.2615 36.6312 18.3923 37.5245 18.2956 38.4999Z"
            fill="#FFECD2"
          />
          <path
            d="M22.6933 21.417C22.3697 21.4617 22.0434 21.4848 21.7167 21.4862C20.0398 21.4833 18.4073 20.9554 17.0548 19.9787C15.7023 19.0019 14.6997 17.6269 14.192 16.0521C14.0972 15.7833 14.058 15.4986 14.0769 15.2146C14.0957 14.9307 14.1721 14.6534 14.3015 14.399C14.431 14.1447 14.6109 13.9186 14.8306 13.7339C15.0502 13.5493 15.3053 13.4099 15.5806 13.3241C15.8558 13.2382 16.1458 13.2076 16.4333 13.2341C16.7208 13.2606 17 13.3436 17.2543 13.4783C17.5087 13.6129 17.7331 13.7965 17.9142 14.018C18.0954 14.2396 18.2296 14.4947 18.309 14.7683C18.5514 15.5455 19.0618 16.2148 19.7519 16.6604C20.442 17.106 21.2685 17.3 22.0884 17.2088C23.3058 17.0387 24.4095 15.8652 24.2806 14.8785C24.1592 13.9947 23.2622 13.0678 22.3046 12.0979C20.9657 10.7282 19.4487 9.18095 19.1623 6.87687C18.876 4.57279 20.1731 2.12294 22.3387 1.00174C24.1857 0.0318948 25.9797 -0.231571 27.6883 0.201962C27.4095 0.116179 27.1159 0.0870605 26.8253 0.116375C26.5348 0.145689 26.2533 0.23282 25.9979 0.372497C25.7425 0.512173 25.5185 0.701472 25.3396 0.928948C25.1606 1.15643 25.0304 1.41734 24.9567 1.69588C24.8831 1.97441 24.8677 2.26477 24.9113 2.54937C24.9549 2.83397 25.0567 3.1069 25.2105 3.35159C25.3643 3.59629 25.5669 3.80765 25.806 3.97292C26.0451 4.13818 26.3158 4.25391 26.6017 4.31305C25.9797 4.15235 25.2211 4.29622 24.3583 4.74657C23.7704 5.06238 23.3665 5.75939 23.4424 6.35363C23.5562 7.24686 24.4531 8.16626 25.4108 9.14358C26.7382 10.504 28.2553 12.0438 28.5511 14.331C28.9911 17.6087 26.2471 20.9237 22.6933 21.417Z"
            fill="#8FC3F2"
          />
          <path
            d="M57.0899 20.4728C53.5779 21.0166 50.5912 21.5267 47.1057 22.1826C46.972 22.2094 46.8362 22.2237 46.6998 22.2256C46.1635 22.2239 45.647 22.0256 45.2508 21.6693C44.8546 21.3131 44.6072 20.8243 44.5565 20.2981C44.5059 19.7719 44.6557 19.246 44.9769 18.8227C45.2981 18.3994 45.7676 18.109 46.294 18.008C47.3636 17.8024 48.3914 17.6156 49.4002 17.4474C49.2542 16.3262 49.1158 15.2367 48.9868 14.1809C48.7972 12.5234 48.581 10.8061 48.3136 8.99537L48.2264 8.38243C48.1581 7.92274 48.0975 7.48175 48.0368 7.04635L46.605 7.19209C46.8912 7.16985 47.1701 7.09152 47.4251 6.96167C47.6802 6.83183 47.9064 6.65309 48.0904 6.43595C48.2745 6.21881 48.4126 5.96763 48.4968 5.69717C48.581 5.4267 48.6096 5.14238 48.5808 4.86089C48.552 4.57941 48.4665 4.30644 48.3292 4.05797C48.1919 3.8095 48.0056 3.59057 47.7813 3.41399C47.557 3.23741 47.2992 3.10675 47.0229 3.02967C46.7467 2.9526 46.4576 2.93065 46.1727 2.96514L53.9818 2.18405C54.5504 2.12631 55.119 2.29349 55.5624 2.64884C56.0059 3.00419 56.288 3.51861 56.3466 4.07889C56.4052 4.63917 56.2355 5.19942 55.8749 5.63642C55.5143 6.07343 54.9923 6.35139 54.4237 6.40912L52.3263 6.62215C52.387 7.00523 52.4382 7.38644 52.4989 7.78634L52.5842 8.39927C52.8516 10.253 53.0678 11.9965 53.2745 13.6802C53.3883 14.6668 53.5172 15.6871 53.6538 16.7242C54.5507 16.571 55.4648 16.4271 56.4148 16.2813C56.977 16.2002 57.549 16.3408 58.0066 16.6726C58.4643 17.0044 58.7706 17.5007 58.8592 18.0537C58.9479 18.6068 58.8116 19.1719 58.48 19.6265C58.1484 20.0812 57.6483 20.3886 57.0881 20.4821L57.0899 20.4728Z"
            fill="#A2DD8A"
          />
          <path
            d="M31.8571 8.13481C31.5944 8.23983 31.3131 8.29257 31.0296 8.28999C30.7461 8.28741 30.4659 8.22956 30.2052 8.11977C29.9445 8.00999 29.7084 7.85044 29.5105 7.65032C29.3127 7.45021 29.157 7.2135 29.0524 6.95381C28.7167 6.12038 27.8198 4.6329 26.6023 4.31709C26.3164 4.25795 26.0458 4.14224 25.8066 3.97698C25.5675 3.81171 25.3649 3.60032 25.2111 3.35563C25.0573 3.11093 24.9555 2.83803 24.9119 2.55343C24.8683 2.26884 24.8837 1.97847 24.9574 1.69994C25.031 1.4214 25.1612 1.16049 25.3402 0.93301C25.5191 0.705533 25.7431 0.516235 25.9985 0.376558C26.2539 0.236882 26.5354 0.149751 26.8259 0.120436C27.1165 0.091122 27.4101 0.120218 27.6889 0.206C31.0815 1.07307 32.6593 4.38998 33.0575 5.38412C33.1627 5.64232 33.2152 5.91846 33.2118 6.19669C33.2083 6.47492 33.1492 6.74975 33.0376 7.00537C32.9261 7.26098 32.7643 7.49236 32.5617 7.68618C32.3592 7.88001 32.1197 8.03248 31.8571 8.13481Z"
            fill="#FFECD2"
          />
          <path
            d="M48.5366 4.85273C48.5918 5.41381 48.4196 5.97364 48.0576 6.41015C47.6956 6.84665 47.1732 7.12445 46.6043 7.18298C46.225 7.22596 45.7319 7.26707 45.5252 7.36985C45.3208 7.5159 45.155 7.70815 45.0417 7.93045C44.8537 8.25626 44.5814 8.52715 44.2526 8.71556C43.9239 8.90398 43.5503 9.00318 43.17 9.00307C42.7949 9.00174 42.4266 8.9052 42.1004 8.72277C41.8539 8.58484 41.6374 8.40028 41.4635 8.17969C41.2896 7.95909 41.1615 7.70679 41.0868 7.4373C41.0121 7.16782 40.9922 6.88646 41.0281 6.60936C41.0641 6.33225 41.1552 6.06487 41.2964 5.82258C41.802 4.88809 42.5739 4.11969 43.517 3.61194C44.4652 3.11861 45.4513 3.02518 46.1719 2.95604C46.7405 2.89959 47.3086 3.0675 47.7519 3.423C48.1951 3.77851 48.4773 4.29261 48.5366 4.85273Z"
            fill="#FFECD2"
          />
          <path
            d="M45.0348 7.94744C44.8276 8.32627 44.6464 8.71832 44.4924 9.12098C43.0341 12.6079 41.3938 16.0781 39.6169 19.4193C39.3836 19.8696 38.6687 21.23 37.053 21.4262C36.9356 21.443 36.8171 21.4511 36.6984 21.4505C35.4999 21.4505 34.1194 20.703 33.4746 18.5952C32.6725 15.9155 31.5783 13.2283 30.5315 10.6271L29.0391 6.95332C29.2575 7.46713 29.6721 7.87583 30.1934 8.0914C30.7147 8.30697 31.301 8.31215 31.8262 8.10584C32.3513 7.89953 32.7732 7.49824 33.001 6.98838C33.2289 6.47851 33.2443 5.90088 33.0442 5.37988L34.5366 9.04436C35.3558 11.0681 36.194 13.1349 36.926 15.2614C38.2041 12.7182 39.4026 10.117 40.4911 7.50644C40.7149 6.93301 40.9798 6.37598 41.2838 5.83957C41.1427 6.08186 41.0515 6.34924 41.0156 6.62634C40.9796 6.90345 40.9996 7.18483 41.0743 7.45432C41.149 7.7238 41.277 7.97608 41.4509 8.19668C41.6249 8.41727 41.8413 8.60183 42.0878 8.73976C42.414 8.92219 42.7823 9.01872 43.1574 9.02006C43.5387 9.02117 43.9134 8.92243 44.2433 8.73397C44.5732 8.5455 44.8463 8.27407 45.0348 7.94744Z"
            fill="#FF7878"
          />
        </g>
        <defs>
          <clipPath id="clip0_335_133">
            <rect width="73" height="58" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </svg>
  );
};
