import { CultureFooter } from "../../culture/components";
import { QuizType } from "../api/dto";
import { Quiz } from "./Quiz";

const questions = [
  {
    question: "Koji kontinent je postojbina Roma?",
    answers: [
      {
        label: "Azija",
        isCorrect: true,
      },
      {
        label: "Afrika",
        isCorrect: false,
      },
      {
        label: "Evropa",
        isCorrect: false,
      },
      {
        label: "Južna Amerika",
        isCorrect: false,
      },
    ],
  },
  {
    question: "Koja azijska država je postojbina Roma?",
    answers: [
      {
        label: "Kina",
        isCorrect: false,
      },
      {
        label: "Irak",
        isCorrect: false,
      },
      {
        label: "Indija",
        isCorrect: true,
      },
      {
        label: "Sirija",
        isCorrect: false,
      },
    ],
  },
  {
    question: "Kako su naučnici saznali gde je postojbina Roma?",
    answers: [
      {
        label: "Istraživanjem genetskog koda dveju naroda",
        isCorrect: false,
      },
      {
        label: "Utvrđivanjem sličnosti sanskrita i romskog jezika",
        isCorrect: true,
      },
      {
        label: "Uz pomoć pisanih predanja Roma",
        isCorrect: false,
      },
      {
        label: "Arheološkim analizama pojedinih građevina",
        isCorrect: false,
      },
    ],
  },
  {
    question: "Šta znači da su Romi dugo bili nomadski narod?",
    answers: [
      {
        label: "Bavili su se poljoprivredom",
        isCorrect: false,
      },
      {
        label: "Bavili su se stočarstvom",
        isCorrect: false,
      },
      {
        label: "Bili su sastavljeni iz nekoliko plemena",
        isCorrect: false,
      },
      {
        label: "Putovali su i često menjali lokacije",
        isCorrect: true,
      },
    ],
  },
  {
    question: "Zašto su Romi otišli iz Indije?",
    answers: [
      {
        label: "Želeli su da otkriju novi kontinent",
        isCorrect: false,
      },
      {
        label: "Izbegli su od rata i progona",
        isCorrect: true,
      },
      {
        label: "Da bi našli zabavu",
        isCorrect: false,
      },
      {
        label: "Čuli su da u Indiji pada vlada",
        isCorrect: false,
      },
    ],
  },
  {
    question: "Kada su Romi otišli iz svoje postojbine?",
    answers: [
      {
        label: "Između 11. i 13. veka",
        isCorrect: true,
      },
      {
        label: "U 18. veku",
        isCorrect: false,
      },
      {
        label: "U 5. veku PNE",
        isCorrect: false,
      },
      {
        label: "Između 15. i 17. veka",
        isCorrect: false,
      },
    ],
  },
  {
    question: "Kako znamo kroz koje su se zemlje Romi na svom putu kretali?",
    answers: [
      {
        label: "Crtali su mape i pravili globuse",
        isCorrect: false,
      },
      {
        label: "Imali su svoje istoričare koji su pisali udžbenike",
        isCorrect: false,
      },
      {
        label: "Prema rečima na romskom, preuzetih iz drugih jezika",
        isCorrect: true,
      },
      {
        label: "Prema narodnoj nošnji i narodnim plesovima Roma",
        isCorrect: false,
      },
    ],
  },
  {
    question:
      "Kako su Romi na svom putu međusobno komunicirali sa onima koji su krenuli posle njih?",
    answers: [
      {
        label: "Pismima i uz pomoć porukica na papiru",
        isCorrect: false,
      },
      {
        label: "Ostavljali su šifre (patrine) od različitih materijala",
        isCorrect: true,
      },
      {
        label: "Bojili su kore od drveća u zelenu i žutu boju",
        isCorrect: false,
      },
      {
        label: "Imali su svoje glasnike koji su na konjima obilazili one iza",
        isCorrect: false,
      },
    ],
  },
  {
    question: "Od čega se pravio patrin?",
    answers: [
      {
        label: "Od lišća, drveta, perja, stakla, tkanine ili kože",
        isCorrect: true,
      },
      {
        label: "Od povrća, hleba, sira i jabuka",
        isCorrect: false,
      },
      {
        label: "Od testa, pilećeg i ćurećeg mesa",
        isCorrect: false,
      },
      {
        label: "Od plastike, bakra ili gvožđa",
        isCorrect: false,
      },
    ],
  },
  {
    question: "Koje su se poruke slale putem patrina?",
    answers: [
      {
        label: "Kako da naprave recept za ručak",
        isCorrect: false,
      },
      {
        label: "Ko je najbolji u pravljenju skulptura",
        isCorrect: false,
      },
      {
        label: "Da se odmah vrate nazad odakle su došli",
        isCorrect: false,
      },
      {
        label: "Gde ima sveže vode, hrane i gde je sigurno",
        isCorrect: true,
      },
    ],
  },
];

export const GypsyHistoryQuiz: React.FC = () => {
  return (
    <>
      <Quiz questions={questions} quizType={QuizType.GYPSY_HISTORY} />
      <CultureFooter />
    </>
  );
};
