export const ConsentSvg: React.FC<{ opacity: number; width?: number }> = ({
  opacity,
  width,
}) => {
  return (
    <svg
      width={width ? width : "66"}
      height="55"
      viewBox="0 0 66 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.9124 62.5748C47.9849 62.5748 61.8248 48.7349 61.8248 31.6624C61.8248 14.59 47.9849 0.75 30.9124 0.75C13.84 0.75 0 14.59 0 31.6624C0 48.7349 13.84 62.5748 30.9124 62.5748Z"
        fill="#8FC3F2"
        style={{ opacity: opacity }}
      />
      <path
        d="M27.7738 53.8149C25.7559 53.8149 24.0792 52.7962 22.729 51.2608C20.1651 48.3362 20.053 48.4435 17.4941 45.5287C14.9351 42.6139 14.9692 42.6041 12.4054 39.6796C9.84151 36.7551 9.58317 36.989 7.01932 34.0742C6.3627 33.3852 5.85549 32.5679 5.52946 31.6737C5.20342 30.7795 5.0656 29.8275 5.12463 28.8775C5.18366 27.9275 5.43827 26.9999 5.8725 26.1529C6.30674 25.3059 6.91123 24.5578 7.6481 23.9553C9.09132 22.7345 10.9602 22.1366 12.844 22.2932C14.6475 22.4102 16.1195 23.4728 17.4112 24.9399C19.629 27.4599 19.7021 27.377 21.9199 29.9116C24.1376 32.4462 23.6551 33.0994 26.5163 34.859C27.5983 33.1969 28.7974 31.2179 30.2109 29.3218C31.449 27.6695 32.5847 25.7393 34.0713 24.004C35.4459 22.4004 37.303 21.1185 38.892 19.5149C40.3883 18.0039 41.9091 16.4198 43.6005 14.9672C45.2918 13.5147 47.1245 12.457 48.928 11.1653C50.7315 9.87364 52.6178 8.95732 54.5383 7.85087C56.2296 6.87602 57.9015 6.26673 59.6464 6.73953C60.5239 7.00008 61.3405 7.43301 62.0485 8.01301C62.7566 8.59301 63.3419 9.3084 63.7701 10.1173C64.7449 11.8087 64.5402 13.4903 64.0723 15.2353C63.6043 16.9803 62.8586 18.4182 61.1477 19.393C59.1702 20.5699 57.2702 21.8723 55.4595 23.2924C53.6428 24.6918 51.9034 26.1887 50.2489 27.7767C48.5381 29.3998 47.3731 31.4616 45.9255 33.1384C44.3365 34.9564 42.7718 36.6283 41.5045 38.3489C39.9838 40.4059 38.3509 42.107 37.3078 43.7837C36.059 45.714 35.1276 47.8319 34.549 50.0569C33.5352 52.2162 31.1078 53.5273 28.7487 53.8636C28.423 53.8685 28.0974 53.8522 27.7738 53.8149Z"
        stroke="#FFECD2"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
        style={{ opacity: opacity }}
      />
    </svg>
  );
};
