import React from "react";

export const MeetUsSvg: React.FC<{ width?: string }> = ({ width }) => {
  return (
    <svg
      width={width ? width : "130"}
      height="145"
      viewBox="0 0 130 145"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="65" cy="80" r="65" fill="#CF97D9" />
      <path
        d="M5 71.076C6.28 67.246 9.91002 64.576 13.77 63.386C17.63 62.196 21.77 62.256 25.77 62.336C27.0857 62.3013 28.4002 62.4392 29.6801 62.746C31.1068 63.2317 32.4396 63.959 33.6201 64.896C36.5269 66.9969 39.2318 69.3638 41.7001 71.966"
        stroke="#FFECD2"
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M63.48 30.4651C61.375 31.6163 59.7009 33.4202 58.7099 35.6051C57.5506 38.0056 57.0691 40.6773 57.3176 43.3315C57.5661 45.9857 58.5352 48.5216 60.12 50.6651C60.4844 51.2015 60.9895 51.627 61.5799 51.8951C62.1357 52.0535 62.7195 52.0876 63.2899 51.9951C66.9199 51.6951 70.9399 51.1951 73.2899 48.4251C74.3661 47.0814 75.0573 45.4708 75.2899 43.7651C76.4099 37.1251 71.76 26.2551 63.48 30.4651Z"
        stroke="#FFECD2"
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M51.6504 71.0452C53.4204 64.3652 59.8204 59.3352 66.7004 58.6652C70.1587 58.4238 73.6218 59.0598 76.7686 60.5143C79.9153 61.9688 82.6437 64.1946 84.7004 66.9852"
        stroke="#FFECD2"
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M90.7898 43.8853C90.3655 45.1978 90.2459 46.5897 90.4399 47.9553C90.6969 49.2417 91.2441 50.4524 92.0398 51.4953C93.4419 53.3667 95.3672 54.7806 97.5724 55.5584C99.7777 56.3362 102.164 56.443 104.43 55.8653C106.676 55.2088 108.658 53.8611 110.094 52.0139C111.53 50.1666 112.347 47.9137 112.43 45.5753C112.43 40.6853 108.1 35.2053 103 34.9853C100.271 34.9265 97.5978 35.7655 95.3919 37.3734C93.186 38.9814 91.5691 41.2692 90.7898 43.8853Z"
        stroke="#FFECD2"
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M91.8301 68.5651C93.6124 65.0466 96.6493 62.3242 100.341 60.9357C104.033 59.5471 108.111 59.5933 111.77 61.0651C114.843 62.4564 117.574 64.5045 119.77 67.0651"
        stroke="#FFECD2"
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M39.4102 75.4652C36.2102 77.4652 34.5502 81.1252 33.4702 84.7152C32.6602 87.3952 32.1402 90.5552 33.8102 92.8052C35.3302 94.8052 38.1101 95.4052 40.6401 95.6652C42.7301 95.8852 45.0302 95.9452 46.7102 94.6652C47.375 94.124 47.9401 93.4709 48.3801 92.7352C50.7701 88.9852 51.9701 82.7352 50.7701 78.3552C49.5601 73.7452 42.9502 73.3252 39.4102 75.4652Z"
        stroke="#FFECD2"
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.7891 113.245C25.7891 110.245 30.659 100.345 39.499 99.9553C42.689 99.8053 41.7091 99.9553 44.9091 99.9553C51.2391 99.9553 55.8191 104.955 56.4791 112.035"
        stroke="#FFECD2"
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M76.4307 72.9056C72.6207 75.2056 70.4308 79.6056 69.8708 84.0156C69.5802 86.1932 69.7297 88.407 70.3107 90.5256C70.6038 91.6913 71.1967 92.76 72.0307 93.6256C73.8407 95.3656 76.6707 95.3456 79.1707 95.0856C82.2307 94.7656 85.4507 94.1656 87.7407 92.0856C91.5507 88.6856 91.3707 83.0856 90.5907 78.4656C90.0007 74.9456 88.4907 72.7656 84.9407 71.8356C83.5235 71.4298 82.0387 71.3154 80.5761 71.4993C79.1134 71.6832 77.7033 72.1616 76.4307 72.9056Z"
        stroke="#FFECD2"
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M65.3496 113.246C66.3496 108.106 69.3495 102.806 73.1495 99.8857C76.0095 97.6957 81.6995 97.2757 84.7695 98.0857C88.8695 99.1557 92.2596 104.276 95.4196 109.196"
        stroke="#FFECD2"
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.2798 34.3357C14.1466 36.2865 12.549 38.7517 11.6398 41.4957C10.9098 43.4957 10.4798 45.8857 11.5398 47.7657C12.2793 48.8715 13.283 49.7755 14.4599 50.3957C16.6197 51.692 18.9215 52.7356 21.3198 53.5057C22.866 54.1304 24.5687 54.2562 26.1898 53.8657C27.5717 53.3016 28.7477 52.3279 29.5598 51.0757C30.7518 49.5275 31.6238 47.7576 32.1251 45.8691C32.6265 43.9806 32.7471 42.0112 32.4799 40.0757C31.0599 31.9857 22.4098 29.0657 16.2798 34.3357Z"
        stroke="#FFECD2"
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M38.8003 19.4655C38.5553 21.3787 38.4451 23.3068 38.4703 25.2355C38.4703 27.1455 38.7203 29.2355 39.9403 30.6655C40.5246 31.3144 41.2484 31.8225 42.0572 32.1516C42.8661 32.4806 43.739 32.6222 44.6103 32.5655C46.3578 32.4432 48.043 31.8676 49.5002 30.8955C50.5369 30.2877 51.4414 29.4785 52.1603 28.5155C53.5703 26.5155 53.6403 23.8055 53.3203 21.3455C52.8103 17.4155 50.4603 11.9155 45.6903 11.8955C41.6903 11.8955 39.2303 16.0455 38.8003 19.4655Z"
        stroke="#FFECD2"
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M78.3892 11.4655C77.2385 14.7196 77.0476 18.236 77.8393 21.5955C78.122 23.0886 78.8145 24.4736 79.8393 25.5955C81.7093 27.4655 84.6593 27.6455 87.2993 27.4555C90.7293 27.2155 94.5593 26.2255 96.1893 23.2055C96.7089 22.171 97.0118 21.0413 97.0793 19.8856C97.5593 14.5956 94.7693 7.65556 89.9893 4.88556C84.9993 1.92556 79.9592 6.96554 78.3892 11.4655Z"
        stroke="#FFECD2"
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
