export const ShortButtonBackgroundSvg: React.FC<{
  color: React.CSSProperties["color"];
}> = ({ color }) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 113 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <path
        d="M25.4619 0C31.9619 0 48.4619 0.888885 66.0067 0C97.0065 0 110.971 7.54982 113.007 16.5C116.962 33.8889 100.469 38 90.5068 38C79.9619 38 44.0068 43 21.5067 41C-0.993452 39 0.00671695 21.8889 0.0066525 16.5C0.00653756 6.88889 15.9172 0 25.4619 0Z"
        fill={color}
      />
    </svg>
  );
};
