export const MenuHamburgerSvg: React.FC = () => {
  return (
    <svg
      width="66"
      height="61"
      viewBox="0 0 96 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 45.5C0 28.5864 16.7268 0 43.7286 0C73.8368 0 92.7142 14.2932 95.8206 45.5C98.927 76.7068 60.9333 91 40.6222 91C20.3111 91 0 62.4136 0 45.5Z"
        fill="#FFECD2"
      />
      <path
        d="M30.002 32C41.25 31.6571 59.3553 29.8571 67.002 29"
        stroke="#7A5D7F"
        stroke-width="6"
        stroke-linecap="round"
      />
      <path
        d="M29.956 46.7664C41.1637 47.7773 59.3542 48.1655 67.0484 48.2332"
        stroke="#7A5D7F"
        stroke-width="6"
        stroke-linecap="round"
      />
      <path
        d="M67.0582 64.9268C55.8353 64.1012 37.6409 64.0137 29.9466 64.0731"
        stroke="#7A5D7F"
        stroke-width="6"
        stroke-linecap="round"
      />
    </svg>
  );
};
