export const CultureButtonHoverSvg: React.FC<{
  color?: React.CSSProperties["color"];
}> = ({ color }) => {
  return (
    <svg
      width="280"
      height="78"
      viewBox="0 0 291 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M49.0484 3C60.5485 3 172.459 4.5432 203.5 3C258.346 3 283.053 16.1073 286.655 31.6457C293.652 61.8346 264.473 68.9719 246.847 68.9719C228.19 68.9719 81.8588 77.6524 42.0506 74.1802C2.24234 70.708 4.01188 41.0013 4.01177 31.6457C4.01157 14.9598 32.1614 3 49.0484 3Z"
        fill={color ? color : "#355D82"}
        stroke="#FFECD2"
        stroke-width="6"
      />
    </svg>
  );
};
