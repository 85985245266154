import { useMemoizedFn } from "ahooks";
import { atom, useAtom } from "jotai";
import { useMergeVideosMutation } from "../hooks/useMergeVideosMutation";
import { Device, MergedVideosDto, ProjectTrack } from "../api";
import { isAndroid, isIOS } from "react-device-detect";
import { useUploadTrackForm } from "./useUploadTrackForm";

type MergeVideosState =
  | {
      isMerging: false;
      isMerged: true;
      base64Video: string;
    }
  | {
      isMerging: boolean;
      isMerged: false;
      base64Video?: string;
    };

export type UseMergeVideosResult = MergeVideosState & {
  mergeVideos(
    trackUrl: string | undefined,
    recordedVideoUrl: string | undefined,
    projectTrack: ProjectTrack
  ): void;
  deleteMergedVideo(): void;
};

const mergeVideosStateAtom = atom<MergeVideosState>({
  isMerging: false,
  isMerged: false,
});

export function useMergeVideos(): UseMergeVideosResult {
  const [mergeVideosState, setMergeVideosState] = useAtom(mergeVideosStateAtom);
  const mergeVideosMutation = useMergeVideosMutation();
  const uploadedForm = useUploadTrackForm();

  const mergeVideos = useMemoizedFn(
    async (
      trackUrl: string | undefined,
      recordedVideoUrl: string | undefined,
      projectTrack: ProjectTrack
    ) => {
      setMergeVideosState({
        isMerged: false,
        isMerging: true,
      });
      let track = undefined;
      if (trackUrl) {
        track = await fetch(trackUrl).then((r) => r.blob());
      }
      if (recordedVideoUrl) {
        const recordedVideo = await fetch(recordedVideoUrl).then((r) =>
          r.blob()
        );
        mergeVideosMutation
          .mutateAsync({
            track: track,
            recordedVideo: recordedVideo,
            projectTrack: projectTrack,
            device: isAndroid
              ? Device.ANDROID
              : isIOS
              ? Device.IOS
              : Device.DESKTOP,
            trackDuration: uploadedForm.unformattedVideoDuration
              ? uploadedForm.unformattedVideoDuration
              : 0,
          })
          .then((response: MergedVideosDto) => {
            setMergeVideosState({
              isMerged: true,
              isMerging: false,
              base64Video: response.base64String,
            });
          })
          .catch((error: any) => {
            setMergeVideosState({
              isMerged: false,
              isMerging: false,
            });
          });
      }
    }
  );

  const deleteMergedVideo = useMemoizedFn(() => {
    setMergeVideosState({
      isMerged: false,
      isMerging: false,
    });
  });

  return {
    ...mergeVideosState,
    mergeVideos,
    deleteMergedVideo,
  };
}
