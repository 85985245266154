import { Box, Grid } from "@mui/material";
import usaid from "../assets/usaid.png";
import swissAgency from "../assets/swissAgency.png";
import partnersGlobal from "../assets/partnersglobal.png";

export const HomePageFooter: React.FC = () => {
  return (
    <Box sx={{ marginTop: "auto" }}>
      <Grid container spacing={2} rowGap={8} mt={10} mb={10}>
        <Grid
          item
          xxs={12}
          xs={12}
          sm={12}
          md={4}
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          display="flex"
        >
          <img
            src={usaid}
            style={{ margin: "auto", width: "50%" }}
            alt="usaid"
          />
        </Grid>
        <Grid
          item
          xxs={12}
          xs={12}
          sm={12}
          md={4}
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          display="flex"
        >
          <img
            src={partnersGlobal}
            style={{ display: "block", margin: "auto", width: "50%" }}
            alt="partnersGlobal"
            crossOrigin="anonymous"
          />
        </Grid>
        <Grid
          item
          xxs={12}
          xs={12}
          sm={12}
          md={4}
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          display="flex"
        >
          <img
            src={swissAgency}
            style={{ display: "block", margin: "auto", width: "50%" }}
            alt="swissAgency"
            crossOrigin="anonymous"
          />
        </Grid>
      </Grid>
    </Box>
  );
};
