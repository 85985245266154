import { Box, Grid, Typography } from "@mui/material";
import dzenetKokoAvatar from "../assets/dzenet-koko-avatar.png";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { useIsLgScreen } from "../../../hooks/useIsLgScreen";

export interface InterviewAnswerProps {
  answer: string;
  backgroundColor: React.CSSProperties["color"];
  hasAvatarImg: boolean;
  interviewee?: string;
  intervieweeColor?: React.CSSProperties["color"];
  imgSrc?: string[];
  imgInColumns?: boolean;
  photographer?: string;
}

export const InterviewAnswer: React.FC<InterviewAnswerProps> = ({
  answer,
  backgroundColor,
  hasAvatarImg,
  interviewee,
  intervieweeColor,
  imgSrc,
  imgInColumns,
  photographer,
}) => {
  const isMobile = useIsMobile();
  const isLgScreen = useIsLgScreen();

  const renderHTML = (htmlString: string) => {
    return { __html: htmlString };
  };

  return (
    <table style={{ width: "100%", marginTop: 10, marginBottom: 10 }}>
      <tr>
        <td>
          <Box
            sx={(theme) => ({
              borderRadius: "22px",
              backgroundColor: backgroundColor,
              marginLeft: isMobile ? "64px" : isLgScreen ? "92px" : "124px",
              width: "fit-content",
              maxWidth: "749px",
              float: "right",
            })}
          >
            <Typography
              variant={isLgScreen ? "subtitle1" : isMobile ? "h5" : "h6"}
              sx={(theme) => ({
                padding: isMobile ? "20px" : isLgScreen ? "22px" : "30px",
                color: theme.palette.yellow.regular,
                fontWeight: 400,
                fontFamily: "'Inter', 'sans-serif'",
                lineHeight: "130%",
              })}
            >
              <span dangerouslySetInnerHTML={renderHTML(answer)} />
            </Typography>
          </Box>
        </td>
        <td style={{ display: "flex", alignItems: "flex-start" }}>
          {hasAvatarImg ? (
            <img
              alt="dzenet-koko-avatar"
              src={dzenetKokoAvatar}
              style={{ width: isMobile ? 40 : isLgScreen ? 70 : 100 }}
              crossOrigin="anonymous"
            />
          ) : (
            <Box
              sx={(theme) => ({
                width: isMobile ? "40px" : isLgScreen ? "80px" : "100px",
                height: isMobile ? "40px" : isLgScreen ? "80px" : "100px",
                borderRadius: "100px",
                background: intervieweeColor,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              })}
            >
              <Typography
                variant={isLgScreen ? "subtitle1" : "h6"}
                sx={(theme) => ({
                  color: theme.palette.yellow.regular,
                  fontWeight: 700,
                })}
              >
                {interviewee}
              </Typography>
            </Box>
          )}
        </td>
      </tr>
      {imgSrc &&
        !imgInColumns &&
        imgSrc.map((item: string) => (
          <>
            <Box mt={1} />
            <tr>
              <td>
                <img
                  src={item}
                  style={{
                    float: "right",
                    width: "100%",
                    paddingLeft: isMobile
                      ? "64px"
                      : isLgScreen
                      ? "92px"
                      : "124px",
                    height: "auto",
                  }}
                  alt="interview"
                  crossOrigin="anonymous"
                />
                {photographer && (
                  <>
                    <br />
                    <Typography
                      variant="subtitle2"
                      sx={(theme) => ({
                        float: "right",
                        color: theme.palette.purple.regular,
                        fontFamily: "'Inter', 'sans-serif'",
                      })}
                    >
                      {photographer}
                    </Typography>
                  </>
                )}
              </td>
              <td></td>
            </tr>
          </>
        ))}
      {imgSrc && imgInColumns && (
        <>
          <Box mt={1} />
          <tr>
            <td>
              <Grid
                container
                spacing={1}
                sx={{
                  paddingLeft: isMobile
                    ? "64px"
                    : isLgScreen
                    ? "92px"
                    : "124px",
                  justifyContent: "space-between",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Grid item xxs={6} xs={6} sm={6} md={6}>
                  <img
                    src={imgSrc[0]}
                    style={{
                      width: "100%",
                      height: "auto",
                    }}
                    alt="interview"
                    crossOrigin="anonymous"
                  />
                </Grid>
                <Grid item xxs={6} xs={6} sm={6} md={6}>
                  <img
                    src={imgSrc[1]}
                    style={{
                      width: "100%",
                      height: "auto",
                    }}
                    alt="interview"
                    crossOrigin="anonymous"
                  />
                </Grid>
              </Grid>
            </td>
            <td></td>
          </tr>
        </>
      )}
    </table>
  );
};
