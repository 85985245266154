import { Box, styled } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useIsMobile } from "../../../../../hooks/useIsMobile";
import { useState } from "react";
import * as ReactDOMServer from "react-dom/server";
import {
  LongButtonBackgroundSvg,
  ShortButtonBackgroundSvg,
} from "../../../../../menuHovers";
import { theme } from "../../../../../theme";
import { useIsLgScreen } from "../../../../../hooks/useIsLgScreen";

const StyledButton = styled(NavLink)<{
  long: boolean;
  isLgScreen: boolean;
  isMobile: boolean;
}>(({ long, isLgScreen, isMobile }) => ({
  alignItems: "center",
  justifyContent: "flex-start",
  textDecoration: "none",
  fontWeight: 700,
  textAlign: "center",
  fontSize: isLgScreen ? "13px" : isMobile ? "20px" : "16px",
  padding: isMobile ? 15 : 0,
  width: long
    ? isLgScreen
      ? "163px"
      : "207px"
    : isLgScreen
    ? "82px"
    : "105px",
}));

export interface StyledNavButtonProps {
  active: boolean;
  to: string;
  text: string;
}

export const StyledNavButton: React.FC<StyledNavButtonProps> = ({
  active,
  to,
  text,
}) => {
  const isMobile = useIsMobile();
  const isLgScreen = useIsLgScreen();

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const shortHover = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      <ShortButtonBackgroundSvg color={theme.palette.purple.regular} />
    )
  );

  const longHover = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      <LongButtonBackgroundSvg color={theme.palette.purple.regular} />
    )
  );

  const long =
    to === "/vocal-workshops" || to === "/virtual-choir" ? true : false;

  const background =
    to === "/vocal-workshops" || to === "/virtual-choir"
      ? longHover
      : shortHover;

  return (
    <StyledButton
      to={to}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      long={long}
      isLgScreen={isLgScreen}
      isMobile={isMobile}
    >
      {!isMobile && (isHovered || active) ? (
        <Box
          sx={(theme) => ({
            backgroundImage: `url("data:image/svg+xml,${background}")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            padding: "8px",
            textAlign: "center",
            color: theme.palette.yellow.regular,
            fontWeight: 900,
          })}
        >
          {text}
        </Box>
      ) : (
        <Box
          sx={(theme) => ({
            color: theme.palette.purple.regular,
            fontWeight: 900,
            marginBottom: isMobile ? "20px" : "0px",
          })}
        >
          {text}
        </Box>
      )}
    </StyledButton>
  );
};
