import React from "react";

export const VideoSvg: React.FC<{
  backgroundColor?: React.CSSProperties["color"];
  color?: React.CSSProperties["color"];
  width?: string;
  height?: string;
}> = ({ backgroundColor, color, width, height }) => {
  return (
    <svg
      width={width ? width : "145"}
      height={height ? height : "127"}
      viewBox="0 0 145 127"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_162_562)">
        <path
          d="M71.5802 126.84C106.606 126.84 135 98.4459 135 63.42C135 28.3941 106.606 0 71.5802 0C36.5543 0 8.16016 28.3941 8.16016 63.42C8.16016 98.4459 36.5543 126.84 71.5802 126.84Z"
          fill={backgroundColor ? backgroundColor : "#8FC3F2"}
        />
        <path
          d="M89.0696 19.79C103.17 19.11 118.26 16.15 131.82 19.55C133.251 19.8023 134.602 20.3919 135.76 21.27C137.27 22.61 137.76 24.71 138.2 26.68C142.14 44.87 141.94 69.21 141.2 88.16C141.072 91.2919 139.92 94.2955 137.92 96.7091C135.92 99.1227 133.183 100.813 130.13 101.52C122.9 103.2 115.82 102.96 107.02 103.52C78.6196 105.25 50.1496 106.32 21.7196 105.16C19.8412 105.18 17.9715 104.904 16.1796 104.34C11.2596 102.49 8.96959 96.9201 7.61959 91.8401C5.00137 81.3435 3.61535 70.5774 3.48958 59.76C3.21958 49.52 2.17956 37.37 4.33956 27.32C5.93956 19.89 12.1496 20.54 18.4096 20.45C29.3096 20.3 40.2096 19.79 51.1096 19.66C63.7596 19.48 76.4296 20.4 89.0696 19.79Z"
          stroke={color ? color : "#7A5D7F"}
          stroke-width="6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M26.7104 21.43C26.6559 49.2253 28.1549 77.0019 31.2004 104.63"
          stroke={color ? color : "#7A5D7F"}
          stroke-width="6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.71973 48.4799L26.7197 47.7"
          stroke={color ? color : "#7A5D7F"}
          stroke-width="6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.37012 78.8C12.6101 77.34 20.0301 77.0299 27.3701 76.7299"
          stroke={color ? color : "#7A5D7F"}
          stroke-width="6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M114.68 19.13C116.901 46.8375 117.569 74.6478 116.68 102.43"
          stroke={color ? color : "#7A5D7F"}
          stroke-width="6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M139.7 44.28L116.7 45.28"
          stroke={color ? color : "#7A5D7F"}
          stroke-width="6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M140.43 74.6701C133.1 73.7801 125.68 74.0501 118.3 74.3201"
          stroke={color ? color : "#7A5D7F"}
          stroke-width="6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M57.7705 40.8C58.8572 54.5867 59.8572 68.3834 60.7705 82.19C63.2238 80.35 65.7372 78.6034 68.3105 76.95C77.7405 70.89 88.0405 66.01 96.4205 58.56C86.6538 53.0734 76.8205 47.7067 66.9205 42.4601"
          stroke={color ? color : "#7A5D7F"}
          stroke-width="6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_162_562">
          <rect width="144.62" height="126.84" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
