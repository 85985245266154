import { UseMutationResult, useMutation } from "react-query";
import { subscribe } from "../api";
import { VirtualChoirError } from "../../../api";
import { useSnackbar } from "notistack";
import { SubscribeFormMutationValues } from "../components/Dialogs/UploadedVideoDialog";

export function useSubscribeMutation(): UseMutationResult<
  void,
  VirtualChoirError,
  SubscribeFormMutationValues
> {
  const snackbar = useSnackbar();

  return useMutation(
    ["subscribe"],
    async (formValues: SubscribeFormMutationValues) => {
      return subscribe(formValues.email);
    },
    {
      onSuccess: () => {},
      onError: (err: VirtualChoirError) => {
        console.error(err);
        snackbar.enqueueSnackbar("Mejl nije sačuvan! Pokušajte ponovo.", {
          variant: "error",
        });
      },
    }
  );
}
