import { useMemoizedFn } from "ahooks";
import { atom, useAtom } from "jotai";

type RecordingConsentFormState = {
  isAccepted: boolean;
};

export type UseRecordingConsentFormResult = RecordingConsentFormState & {
  handleApproval(event: React.ChangeEvent<HTMLInputElement>): void;
};

const recordingConsentFormStateAtom = atom<RecordingConsentFormState>({
  isAccepted: false,
});

export function useRecordingConsentForm(): UseRecordingConsentFormResult {
  const [recordingConsentFormState, setRecordingConsentFormState] = useAtom(
    recordingConsentFormStateAtom
  );

  const handleApproval = useMemoizedFn(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRecordingConsentFormState({
        isAccepted: event.target.checked,
      });
    }
  );

  return {
    ...recordingConsentFormState,
    handleApproval,
  };
}
