import { Box, Grid, Typography } from "@mui/material";
import { useIsMobile } from "../../hooks/useIsMobile";
import meetUsBackground from "./assets/meet-us.jpg";
import practiceTheSong1Background from "./assets/practice-the-song-1.jpg";
import improveYourSingingTechniqueBackground from "./assets/improve-your-singing-technique.jpg";
import { theme } from "../../theme";
import { EarSvg, MeetUsSvg, MusicalNoteSvg } from "./assets";
import { useIsLgScreen } from "../../hooks/useIsLgScreen";
import { SEO } from "../../components";
import YouTube, { YouTubeProps } from "react-youtube";

export const VocalWorkshopsPage: React.FC = () => {
  const isMobile = useIsMobile();
  const isLgScreen = useIsLgScreen();

  const opts: YouTubeProps["opts"] = {
    width: isLgScreen ? "300px" : isMobile ? "auto" : "360px",
    height: isLgScreen ? "170px" : isMobile ? "auto" : "209px",
    origin: "https://youtube.com",
  };

  return (
    <>
      <SEO
        title="Svi UGLAS | Pevačke radionice"
        description="Unapredite svoj vokalni talenat uz stručne video savete o pevanju i učenju pesama. Otkrijte svoj pravi glas!"
      />
      <Grid
        container
        sx={{
          backgroundImage: `url(${meetUsBackground})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          position: "relative",
          minHeight: isMobile ? "auto" : isLgScreen ? "640px" : "834px",
          marginTop: isMobile ? "-155px" : "-15px",
        }}
        alignContent="center"
        alignItems="center"
      >
        {!isMobile ? (
          <>
            <Grid
              item
              sx={(theme) => ({
                paddingLeft: isLgScreen ? 25 : 30,
                paddingRight: 10,
                paddingBottom: 10,
              })}
              md={8}
            >
              <MeetUsSvg width={isLgScreen ? "95" : "130"} />
              <Box mt={1} />
              <Box sx={{ maxWidth: "507px" }}>
                <Typography
                  variant="h3"
                  sx={(theme) => ({
                    color: theme.palette.yellow.regular,
                    fontWeight: 700,
                    lineHeight: "100%",
                  })}
                >
                  UPOZNAJ NAS
                </Typography>

                <Box mt={isMobile ? 3 : isLgScreen ? 3 : 5} />
              </Box>
              <Typography
                variant={isLgScreen ? "subtitle1" : "h6"}
                sx={(theme) => ({
                  maxWidth: isLgScreen ? "330px" : "420px",
                  color: theme.palette.yellow.regular,
                  lineHeight: "130%",
                  fontWeight: 400,
                  fontFamily: "'Inter', 'sans-serif'",
                })}
              >
                Ko smo mi i zašto treba da učiš baš od nas? Saznaj ko su Maja i
                Mara, koje su se udružile da vam pomognu da učenje i snimanje
                pesama bude jedno zabavno, korisno i nezaboravno iskustvo!
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  width: "100%",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <YouTube videoId="h3QnoxH5i7g" opts={opts} />
                <Box mt={2} />
                <YouTube videoId="mEksFi9iKv0" opts={opts} />
              </Box>
            </Grid>
          </>
        ) : (
          <Grid
            item
            sx={{
              width: "100%",
              position: "relative",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Box sx={{ ml: 6, mr: 6, mt: 10 }}>
              <MeetUsSvg width="90" />
              <Box mt={isMobile ? 1 : 5} />
              <Typography
                variant="h2"
                sx={(theme) => ({
                  color: theme.palette.yellow.regular,
                  fontWeight: 700,
                  lineHeight: "100%",
                })}
              >
                UPOZNAJ NAS
              </Typography>
              <Box mt={isMobile ? 3 : 5} />
            </Box>
            <Typography
              variant="h5"
              sx={(theme) => ({
                color: theme.palette.yellow.regular,
                ml: 6,
                mr: 6,
                mb: 5,
                fontWeight: 400,
                lineHeight: "130%",
                fontFamily: "'Inter', 'sans-serif'",
                textAlign: "left",
              })}
            >
              Ko smo mi i zašto treba da učiš baš od nas? Saznaj ko su Maja i
              Mara, koje su se udružile da vam pomognu da učenje i snimanje
              pesama bude jedno zabavno, korisno i nezaboravno iskustvo!
            </Typography>
            <Box sx={{ ml: 6, mr: 6 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  flexDirection: "column",
                  position: "relative",
                  marginBottom: 5,
                }}
              >
                <YouTube videoId="h3QnoxH5i7g" opts={opts} />
                <Box mt={2} />
                <YouTube videoId="mEksFi9iKv0" opts={opts} />
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        sx={{
          backgroundImage: `url(${practiceTheSong1Background})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          position: "relative",
          minHeight: isMobile ? "auto" : isLgScreen ? "640px" : "834px",
        }}
        alignContent="center"
        alignItems="center"
      >
        {!isMobile ? (
          <>
            <Grid
              item
              sx={(theme) => ({
                paddingLeft: isLgScreen ? 25 : 30,
                paddingTop: 10,
                paddingRight: 10,
                paddingBottom: 10,
              })}
              md={8}
            >
              <MusicalNoteSvg
                backgroundColor={theme.palette.errors.regular}
                width={isLgScreen ? "95" : "130"}
              />
              <Box mt={1} />
              <Box sx={{ maxWidth: "610px" }}>
                <Typography
                  variant="h3"
                  sx={(theme) => ({
                    color: theme.palette.yellow.regular,
                    fontWeight: 700,
                    lineHeight: "100%",
                  })}
                >
                  UVEŽBAJ PESMU "KONSTRAKTA - OBEĆAJ MI"
                </Typography>

                <Box mt={isMobile ? 3 : isLgScreen ? 3 : 5} />
              </Box>
              <Typography
                variant={isLgScreen ? "subtitle1" : "h6"}
                sx={(theme) => ({
                  maxWidth: isLgScreen ? "330px" : "420px",
                  color: theme.palette.yellow.regular,
                  fontWeight: 400,
                  fontFamily: "'Inter', 'sans-serif'",
                  lineHeight: "130%",
                })}
              >
                Muzičke radionice su prvi korak da postaneš deo virtuelnog hora
                Svi UGLAS. Ovde možeš da naučiš pesme koje smo spremili.
                Detaljno pređi i dobro nauči sve pesme pre nego što odeš u
                muzički studio da nam ih pošalješ.
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  width: "100%",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <YouTube videoId="WxhYC6IDkcU" opts={opts} />
                <Box mt={2} />
                <YouTube videoId="Q7dR-rlFr7k" opts={opts} />
              </Box>
            </Grid>
          </>
        ) : (
          <Grid
            item
            sx={{
              width: "100%",
              position: "relative",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Box sx={{ ml: 6, mr: 6, mt: 10 }}>
              <MusicalNoteSvg
                backgroundColor={theme.palette.errors.regular}
                width="90"
              />
              <Box mt={isMobile ? 1 : 5} />
              <Typography
                variant="h2"
                sx={(theme) => ({
                  color: theme.palette.yellow.regular,
                  fontWeight: 700,
                  lineHeight: "100%",
                })}
              >
                UVEŽBAJ PESMU "KONSTRAKTA - OBEĆAJ MI"
              </Typography>
              <Box mt={isMobile ? 3 : isLgScreen ? 3 : 5} />
            </Box>
            <Typography
              variant="h5"
              sx={(theme) => ({
                color: theme.palette.yellow.regular,
                ml: 6,
                mr: 6,
                mb: 5,
                lineHeight: "130%",
                fontWeight: 400,
                fontFamily: "'Inter', 'sans-serif'",
                textAlign: "left",
              })}
            >
              Muzičke radionice su prvi korak da postaneš deo virtuelnog hora
              Svi UGLAS. Ovde možeš da naučiš pesme koje smo spremili. Detaljno
              predji i dobro nauči sve pesme pre nego što odeš u muzički studio
              da nam ih pošalješ.
            </Typography>
            <Box sx={{ ml: 6, mr: 6 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  flexDirection: "column",
                  position: "relative",
                  marginBottom: 5,
                }}
              >
                <YouTube videoId="WxhYC6IDkcU" opts={opts} />
                <Box mt={2} />
                <YouTube videoId="Q7dR-rlFr7k" opts={opts} />
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        sx={{
          backgroundImage: `url(${improveYourSingingTechniqueBackground})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          position: "relative",
          minHeight: isMobile ? "auto" : isLgScreen ? "640px" : "834px",
        }}
        alignContent="center"
        alignItems="center"
      >
        {!isMobile ? (
          <>
            <Grid
              item
              sx={(theme) => ({
                paddingLeft: isLgScreen ? 25 : 30,
                paddingTop: 10,
                paddingRight: 10,
                paddingBottom: 10,
              })}
              md={8}
            >
              <EarSvg
                backgroundColor={theme.palette.green.regular}
                width={isLgScreen ? "95" : "130"}
              />
              <Box mt={1} />
              <Box sx={{ maxWidth: "507px" }}>
                <Typography
                  variant="h3"
                  sx={(theme) => ({
                    color: theme.palette.yellow.regular,
                    fontWeight: 700,
                    lineHeight: "100%",
                  })}
                >
                  POBOLJŠAJ PEVAČKU TEHNIKU
                </Typography>

                <Box mt={isMobile ? 3 : isLgScreen ? 3 : 5} />
              </Box>
              <Typography
                variant={isLgScreen ? "subtitle1" : "h6"}
                sx={(theme) => ({
                  maxWidth: isLgScreen ? "330px" : "420px",
                  color: theme.palette.yellow.regular,
                  fontWeight: 400,
                  fontFamily: "'Inter', 'sans-serif'",
                  lineHeight: "130%",
                })}
              >
                Kako da pravilno dišeš dok pevaš? Kako da pravilno izgovaraš
                reči? Kako da tvoj ton bude jak i postojan? Ovde možeš da saznaš
                sve o tome i još mnogo više.
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  width: "100%",
                  flexDirection: "column",
                  position: "relative",
                  marginTop: 2,
                  marginBottom: 2,
                }}
              >
                <YouTube videoId="L1FczMkVe8M" opts={opts} />
                <Box mt={2} />
                <YouTube videoId="UtJIaw5CjEo" opts={opts} />
                <Box mt={2} />
                <YouTube videoId="bFbzmfB6e4Y" opts={opts} />
              </Box>
            </Grid>
          </>
        ) : (
          <Grid
            item
            sx={{
              width: "100%",
              position: "relative",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Box sx={{ ml: 6, mr: 6, mt: 10 }}>
              <MusicalNoteSvg
                backgroundColor={theme.palette.green.regular}
                width="90"
              />
              <Box mt={isMobile ? 1 : 5} />
              <Typography
                variant="h2"
                sx={(theme) => ({
                  color: theme.palette.yellow.regular,
                  fontWeight: 700,
                  lineHeight: "100%",
                  maxWidth: "300px",
                })}
              >
                POBOLJŠAJ PEVAČKU TEHNIKU
              </Typography>
              <Box mt={isMobile ? 3 : 5} />
            </Box>
            <Typography
              variant="h5"
              sx={(theme) => ({
                color: theme.palette.yellow.regular,
                ml: 6,
                mr: 6,
                mb: 5,
                lineHeight: "130%",
                fontWeight: 400,
                fontFamily: "'Inter', 'sans-serif'",
                textAlign: "left",
              })}
            >
              Kako da pravilno dišeš dok pevaš? Kako da pravilno izgovaraš reči?
              Kako da tvoj ton bude jak i postojan? Ovde možeš da saznaš sve o
              tome i još mnogo više.
            </Typography>
            <Box sx={{ ml: 6, mr: 6 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  flexDirection: "column",
                  position: "relative",
                  marginBottom: 5,
                }}
              >
                <YouTube videoId="L1FczMkVe8M" opts={opts} />
                <Box mt={2} />
                <YouTube videoId="UtJIaw5CjEo" opts={opts} />
                <Box mt={2} />
                <YouTube videoId="bFbzmfB6e4Y" opts={opts} />
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        sx={{
          backgroundImage: `url(${practiceTheSong1Background})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          position: "relative",
          minHeight: isMobile ? "auto" : isLgScreen ? "640px" : "834px",
        }}
        alignContent="center"
        alignItems="center"
      >
        {!isMobile ? (
          <>
            <Grid
              item
              sx={(theme) => ({
                paddingLeft: isLgScreen ? 25 : 30,
                paddingTop: 10,
                paddingRight: 10,
                paddingBottom: 10,
              })}
              md={8}
            >
              <MusicalNoteSvg
                backgroundColor={theme.palette.errors.regular}
                width={isLgScreen ? "95" : "130"}
              />
              <Box mt={1} />
              <Box sx={{ maxWidth: "610px" }}>
                <Typography
                  variant="h3"
                  sx={(theme) => ({
                    color: theme.palette.yellow.regular,
                    fontWeight: 700,
                    lineHeight: "100%",
                  })}
                >
                  UVEŽBAJ PESMU "ESMA REDŽEPOVA - ČAJE ŠUKARIJE"
                </Typography>

                <Box mt={isMobile ? 3 : isLgScreen ? 3 : 5} />
              </Box>
              <Typography
                variant={isLgScreen ? "subtitle1" : "h6"}
                sx={(theme) => ({
                  maxWidth: isLgScreen ? "330px" : "420px",
                  color: theme.palette.yellow.regular,
                  fontWeight: 400,
                  fontFamily: "'Inter', 'sans-serif'",
                  lineHeight: "130%",
                })}
              >
                Ovo je jedna od najpoznatijih romskih pesama. Nauči je, snimi i
                pošalji u sekciji Muzički studio i postani deo novog spota!
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  width: "100%",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <YouTube videoId="Mx-tTXPBSTg" opts={opts} />
                <Box mt={2} />
              </Box>
            </Grid>
          </>
        ) : (
          <Grid
            item
            sx={{
              width: "100%",
              position: "relative",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Box sx={{ ml: 6, mr: 6, mt: 10 }}>
              <MusicalNoteSvg
                backgroundColor={theme.palette.errors.regular}
                width="90"
              />
              <Box mt={isMobile ? 1 : 5} />
              <Typography
                variant="h2"
                sx={(theme) => ({
                  color: theme.palette.yellow.regular,
                  fontWeight: 700,
                  lineHeight: "100%",
                })}
              >
                UVEŽBAJ PESMU "ESMA REDŽEPOVA - ČAJE ŠUKARIJE"
              </Typography>
              <Box mt={isMobile ? 3 : isLgScreen ? 3 : 5} />
            </Box>
            <Typography
              variant="h5"
              sx={(theme) => ({
                color: theme.palette.yellow.regular,
                ml: 6,
                mr: 6,
                mb: 5,
                lineHeight: "130%",
                fontWeight: 400,
                fontFamily: "'Inter', 'sans-serif'",
                textAlign: "left",
              })}
            >
              Ovo je jedna od najpoznatijih romskih pesama. Nauči je, snimi i
              pošalji u sekciji Muzički studio i postani deo novog spota!
            </Typography>
            <Box sx={{ ml: 6, mr: 6 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  flexDirection: "column",
                  position: "relative",
                  marginBottom: 5,
                }}
              >
                <YouTube videoId="Mx-tTXPBSTg" opts={opts} />
                <Box mt={2} />
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};
