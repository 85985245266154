import { Box, Typography, styled } from "@mui/material";
import { BookSvg, ChatSvg, QuestionMarkSvg, VideoSvg } from "../assets";
import { theme } from "../../../theme";
import quizGypsyCultureBackground from "../assets/quiz-gypsy=culture.jpg";
import ginaRanjicicBackground from "../assets/gina-ranjicic.jpg";
import dzenetKokoBackground from "../assets/dzenet-koko.jpg";
import quizGypsyHistoryBackground from "../assets/quiz-gypsy-history.jpg";
import prettyLoudBackground from "../assets/pretty-loud.jpg";
import travellingGypsiesBackground from "../assets/travelling-gypsies.jpg";
import { NavLink, useLocation } from "react-router-dom";
import { useState } from "react";
import { QuizType } from "../../quiz/api/dto";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { useIsLgScreen } from "../../../hooks/useIsLgScreen";
import ScrollContainer from "react-indiana-drag-scroll";

const StyledNavLink = styled(NavLink)<{
  background: string;
  isMobile: boolean;
  isLgScreen: boolean;
}>(({ theme, background, isMobile, isLgScreen }) => ({
  backgroundImage: `url(${background})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  width: isMobile ? "300px" : isLgScreen ? "400px" : "550px",
  height: isMobile ? "240px" : isLgScreen ? "280px" : "370px",
  borderRadius: "22px",
  marginTop: isMobile ? 0 : 10,
  justifyContent: "center",
  alignContent: "center",
  display: "flex",
  flexDirection: "column",
  cursor: "pointer",
  textDecoration: "none",
  "&:hover": {
    opacity: 0.9,
  },
}));

const StyledBox = styled(Box)<{
  isMobile: boolean;
  isLgScreen: boolean;
}>(({ isMobile, isLgScreen }) => ({
  marginLeft: isMobile ? "30px" : isLgScreen ? "40px" : "60px",
  alignContent: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: isMobile ? "5px" : "20px",
}));

const StyledTypography = styled(Typography)<{
  isMobile: boolean;
  marginTop: string;
}>(({ theme, isMobile, marginTop }) => ({
  color: theme.palette.yellow.regular,
  maxWidth: isMobile ? "198px" : "348px",
  fontWeight: 700,
  marginTop: isMobile ? marginTop : "-15px",
  lineHeight: "100%",
}));

export const CultureFooter: React.FC = () => {
  const location = useLocation();
  const currentURL = location.pathname;
  const isMobile = useIsMobile();
  const isLgScreen = useIsLgScreen();

  const [isCultureQuizHovered, setIsCultureQuizHovered] = useState(false);
  const [isHistoryQuizHovered, setIsHistoryQuizHovered] = useState(false);

  const handleMouseEnter = (quiz: QuizType) => {
    if (quiz === QuizType.GYPSY_CULTURE) {
      setIsCultureQuizHovered(true);
      setIsHistoryQuizHovered(false);
    } else {
      setIsCultureQuizHovered(false);
      setIsHistoryQuizHovered(true);
    }
  };

  const handleMouseLeave = () => {
    setIsCultureQuizHovered(false);
    setIsHistoryQuizHovered(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#EFDCC1",
        height: isMobile ? "406px" : isLgScreen ? "450px" : "626px",
      }}
    >
      <Box
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        display="flex"
        flexDirection="column"
      >
        <Typography
          variant={isLgScreen ? "h6" : "h5"}
          mt={5}
          sx={(theme) => ({
            color: theme.palette.purple.regular,
            fontWeight: 700,
            fontFamily: "'Inter', 'sans-serif'",
            lineHeight: "130%",
          })}
        >
          Saznaj više o romskoj kulturi:
        </Typography>

        <Box
          sx={{
            width: "100%",
            overflowX: "auto",
            scrollbarWidth: "none" /* Firefox */,
            "-ms-overflow-style": "none" /* IE and Edge */,
            "&::-webkit-scrollbar": {
              width: 0,
              background: "transparent" /* Chrome, Safari, and Opera */,
            },
          }}
        >
          <ScrollContainer
            vertical={false}
            style={{
              overflowX: "auto",
              flexGrow: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: isMobile ? 2 : 5,
                width: "fit-content",
                marginLeft: isMobile ? 5 : 25,
                marginRight: 10,
                marginTop: isMobile ? 3 : isLgScreen ? 4 : 8,
                position: "relative",
              }}
            >
              {currentURL !== "/quiz/gypsy-culture" && (
                <StyledNavLink
                  background={quizGypsyCultureBackground}
                  to={"/quiz/gypsy-culture"}
                  onMouseEnter={() => handleMouseEnter(QuizType.GYPSY_CULTURE)}
                  onMouseLeave={handleMouseLeave}
                  isMobile={isMobile}
                  isLgScreen={isLgScreen}
                >
                  <StyledBox isMobile={isMobile} isLgScreen={isLgScreen}>
                    <QuestionMarkSvg
                      backgroundColor={theme.palette.blue.regular}
                      color={theme.palette.yellow.regular}
                      width={isMobile ? "50" : isLgScreen ? "60" : "80"}
                      height={"auto"}
                    />
                    <StyledTypography
                      variant={isLgScreen ? "h5" : "h4"}
                      isMobile={isMobile}
                      marginTop={isLgScreen ? "0px" : "0px"}
                    >
                      {isCultureQuizHovered && !isMobile
                        ? "KVIZ: ROMSKA KULTURA"
                        : "KOLIKO ZNAŠ O ROMSKOJ KULTURI?"}
                    </StyledTypography>
                  </StyledBox>
                </StyledNavLink>
              )}
              {currentURL !== "/culture/gina-ranjicic" && (
                <StyledNavLink
                  background={ginaRanjicicBackground}
                  sx={{ backgroundPosition: "55% 30%" }}
                  to={"/culture/gina-ranjicic"}
                  isMobile={isMobile}
                  isLgScreen={isLgScreen}
                >
                  <StyledBox isMobile={isMobile} isLgScreen={isLgScreen}>
                    <BookSvg
                      width={isMobile ? "50" : isLgScreen ? "60" : "80"}
                      height={"auto"}
                    />
                    <Typography
                      variant="h6"
                      sx={(theme) => ({
                        color: theme.palette.yellow.regular,
                        mt: isMobile ? "5px" : "-15px",
                        fontFamily: "'Inter', 'sans-serif'",
                        lineHeight: "130%",
                        fontWeight: 400,
                      })}
                    >
                      Zavirite u poeziju i život Gine Ranjičić:
                    </Typography>
                    <StyledTypography
                      variant={isLgScreen ? "h5" : "h4"}
                      isMobile={isMobile}
                      marginTop={"0px"}
                    >
                      “AKTIVNA ŽENA U PASIVNA VREMENA”
                    </StyledTypography>
                  </StyledBox>
                </StyledNavLink>
              )}

              {currentURL !== "/culture/dzenet-koko" && (
                <StyledNavLink
                  background={dzenetKokoBackground}
                  to={"/culture/dzenet-koko"}
                  isMobile={isMobile}
                  isLgScreen={isLgScreen}
                >
                  <StyledBox isMobile={isMobile} isLgScreen={isLgScreen}>
                    <ChatSvg
                      backgroundColor={theme.palette.purple.light}
                      color={theme.palette.yellow.regular}
                      width={isMobile ? "50" : isLgScreen ? "60" : "80"}
                      height={"auto"}
                    />
                    <StyledTypography
                      variant={isLgScreen ? "h5" : "h4"}
                      isMobile={isMobile}
                      marginTop={"10px"}
                    >
                      DŽENET KOKO: <br /> O FOTKANJU
                    </StyledTypography>
                  </StyledBox>
                </StyledNavLink>
              )}

              {currentURL !== "/quiz/gypsy-history" && (
                <StyledNavLink
                  background={quizGypsyHistoryBackground}
                  to={"/quiz/gypsy-history"}
                  onMouseEnter={() => handleMouseEnter(QuizType.GYPSY_HISTORY)}
                  onMouseLeave={handleMouseLeave}
                  isMobile={isMobile}
                  isLgScreen={isLgScreen}
                >
                  <StyledBox isMobile={isMobile} isLgScreen={isLgScreen}>
                    <QuestionMarkSvg
                      backgroundColor={theme.palette.blue.regular}
                      color={theme.palette.yellow.regular}
                      width={isMobile ? "50" : isLgScreen ? "60" : "80"}
                      height={"auto"}
                    />
                    <StyledTypography
                      variant={isLgScreen ? "h5" : "h4"}
                      isMobile={isMobile}
                      marginTop={"10px"}
                    >
                      {isHistoryQuizHovered
                        ? "KVIZ: ROMSKA ISTORIJA"
                        : "KOLIKO ZNAŠ O ROMSKOJ ISTORIJI?"}
                    </StyledTypography>
                  </StyledBox>
                </StyledNavLink>
              )}
              {currentURL !== "/culture/pretty-loud" && (
                <StyledNavLink
                  background={prettyLoudBackground}
                  to={"/culture/pretty-loud"}
                  isMobile={isMobile}
                  isLgScreen={isLgScreen}
                >
                  <StyledBox isMobile={isMobile} isLgScreen={isLgScreen}>
                    <ChatSvg
                      backgroundColor={theme.palette.errors.regular}
                      color={theme.palette.yellow.regular}
                      width={isMobile ? "50" : isLgScreen ? "60" : "80"}
                      height={"auto"}
                    />
                    <StyledTypography
                      variant={isLgScreen ? "h5" : "h4"}
                      isMobile={isMobile}
                      marginTop={"10px"}
                    >
                      INTERVJU: <br />
                      PRETTY LOUD
                    </StyledTypography>
                  </StyledBox>
                </StyledNavLink>
              )}

              {currentURL !== "/culture/travelling-gypsies" && (
                <StyledNavLink
                  background={travellingGypsiesBackground}
                  to={"/culture/travelling-gypsies"}
                  isMobile={isMobile}
                  isLgScreen={isLgScreen}
                >
                  <StyledBox isMobile={isMobile} isLgScreen={isLgScreen}>
                    <VideoSvg
                      backgroundColor={theme.palette.purple.light}
                      color={theme.palette.yellow.regular}
                      width={isMobile ? "50" : isLgScreen ? "60" : "80"}
                      height={"auto"}
                    />
                    <Typography
                      variant="h6"
                      sx={(theme) => ({
                        color: theme.palette.yellow.regular,
                        mt: isMobile ? "5px" : "-15px",
                        fontFamily: "'Inter', 'sans-serif'",
                        lineHeight: "130%",
                        fontWeight: 400,
                      })}
                    >
                      Pogledajte naš serijal:
                    </Typography>
                    <StyledTypography
                      variant={isLgScreen ? "h5" : "h4"}
                      isMobile={isMobile}
                      marginTop={"0px"}
                    >
                      “PUT ROMA”
                    </StyledTypography>
                  </StyledBox>
                </StyledNavLink>
              )}
            </Box>
          </ScrollContainer>
        </Box>
      </Box>
    </Box>
  );
};
