export const ArrowLeftSvg: React.FC<{ width: number }> = ({ width }) => {
  return (
    <svg
      width={width}
      height="53"
      viewBox="0 0 70 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M67 26L19 26"
        stroke="#FFECD2"
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M32 50C24.6635 39.917 14.6229 32.0405 3 27.2507C7.46865 24.2175 11.9451 21.1842 15.5816 17.2031C19.2182 13.222 21.9918 8.31575 22.377 3"
        stroke="#FFECD2"
        stroke-width="6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
