import { Typography, styled } from "@mui/material";
import { useIsLgScreen } from "../../../../../../hooks/useIsLgScreen";

const StyledTypographySubtitle1 = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  color: theme.palette.purple.regular,
  fontFamily: "'Inter', 'sans-serif'",
  lineHeight: "130%",
}));

const StyledTypographyBody1 = styled(Typography)(({ theme }) => ({
  textAlign: "justify",
  marginBottom: "10px",
  color: theme.palette.purple.regular,
  fontFamily: "'Inter', 'sans-serif'",
  lineHeight: "130%",
  fontWeight: 400,
}));

export const StoreAndDownloadRules: React.FC = () => {
  const isLgScreen = useIsLgScreen();

  return (
    <>
      <Typography
        variant={isLgScreen ? "subtitle1" : "h6"}
        sx={(theme) => ({
          marginBottom: "20px",
          fontWeight: 700,
          color: theme.palette.purple.regular,
          fontFamily: "'Inter', 'sans-serif'",
          lineHeight: "130%",
        })}
      >
        PRAVILA O PREUZIMANJU I ČUVANJU
      </Typography>
      <StyledTypographyBody1 variant={isLgScreen ? "body2" : "body1"}>
        Kada snimite video u virtuelnom studiju Svi UGLAS, možete da ga čuvate
        na svom uređaju za ličnu upotrebu i delite svoje izvođenje na mrežama.
      </StyledTypographyBody1>
      <StyledTypographySubtitle1
        variant={isLgScreen ? "subtitle2" : "subtitle1"}
      >
        Odgovorno deljenje:
      </StyledTypographySubtitle1>
      <StyledTypographyBody1 variant={isLgScreen ? "body2" : "body1"}>
        Kada delite video snimke s drugima, budite odgovorni. Zabranjeno je
        deljenje sadržaja koji je uvredljiv, ilegalan, nasilan ili koji krši
        bilo koju drugu formu pravila ili zakona.
      </StyledTypographyBody1>
    </>
  );
};
