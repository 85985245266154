import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import { useRecordingConsentForm } from "../../../state/useRecordingConsentForm";
import { useIsLgScreen } from "../../../../../hooks/useIsLgScreen";
import { useIsMobile } from "../../../../../hooks/useIsMobile";

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.purple.regular,
  lineHeight: "130%",
  fontFamily: "'Inter', 'sans-serif'",
  fontWeight: 400,
}));

export const RecordingConsent: React.FC = () => {
  const recordingConsentForm = useRecordingConsentForm();
  const isLgScreen = useIsLgScreen();
  const isMobile = useIsMobile();

  return (
    <Box>
      <StyledTypography
        sx={{ fontWeight: 700 }}
        variant={isLgScreen ? "subtitle1" : isMobile ? "h5" : "h6"}
      >
        Pristajem da moj video i audio snimak budu korišćeni u okviru izrade
        spota za hor Svi UGLAS i na društvenim mrežama, u okviru ovog projekta u
        skladu sa vrednostima projekta.
      </StyledTypography>
      <br />
      <StyledTypography
        variant={isLgScreen ? "subtitle1" : isMobile ? "h5" : "h6"}
      >
        U video će ući samo one izvedbe koje budu adekvatne, gde se peva i gde
        nema ostalog sadržaja.
      </StyledTypography>
      <br />
      <StyledTypography
        variant={isLgScreen ? "subtitle1" : isMobile ? "h5" : "h6"}
      >
        Glavne vrednosti našeg projekta su zajedništvo i međusobna podrška. Nije
        uvek lako pevati pred kamerom, zato budimo dobronamerni kada
        komentarišemo tuđu izvedbu i trudimo se da jedni druge što više
        ohrabrujemo. Zajedno smo jači.
      </StyledTypography>
      <br />
      <StyledTypography
        variant={isLgScreen ? "subtitle1" : isMobile ? "h5" : "h6"}
      >
        Ophođenje koje je grubo, nepristojno i uvredljivo, biće prijavljeno i
        sankcionisano isključivanjem iz onlajn hora.
      </StyledTypography>
      <FormGroup sx={{ marginTop: "20px" }}>
        <FormControlLabel
          control={
            <Checkbox
              sx={(theme) => ({
                color: theme.palette.purple.regular,
                "&.Mui-checked": {
                  color: theme.palette.purple.regular,
                },
                "& .MuiSvgIcon-root": { fontSize: 30 },
                "&.hover": {
                  backgroundColor: "inherit",
                },
              })}
              onChange={recordingConsentForm.handleApproval}
              checked={recordingConsentForm.isAccepted}
              disableRipple
            />
          }
          label={
            <StyledTypography
              variant={isLgScreen ? "subtitle1" : isMobile ? "h5" : "h6"}
              sx={{ fontWeight: 700 }}
            >
              Saglasan/na sam sa pravilima korišćenja
            </StyledTypography>
          }
        />
      </FormGroup>
    </Box>
  );
};
