import { styled } from "@mui/material";
import { SnackbarProvider } from "notistack";
import React from "react";

const VirtualChoirSnackbarProvider = styled(SnackbarProvider)(({ theme }) => ({
  "&.SnackbarItem-contentRoot": {
    padding: theme.spacing(2, 3),
    boxShadow: "none",
    minWidth: "auto",
  },
  ".SnackbarItem-message": {
    padding: 0,
  },
  "&.SnackbarItem-variantSuccess": {
    background: theme.palette.green.regular,
  },
  "&.go1930647212": {
    backgroundColor: theme.palette.blue.disabled,
    color: theme.palette.yellow.regular,
  },
}));

export const StyledSnackbarProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <VirtualChoirSnackbarProvider
      maxSnack={3}
      autoHideDuration={5000}
      anchorOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
    >
      {children}
    </VirtualChoirSnackbarProvider>
  );
};
