import {
  Box,
  Button,
  Grid,
  LinearProgress,
  LinearProgressProps,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { VirtualChoirVideo } from "../../../../../../components";
import { useMergeVideos } from "../../../../state/useMergeVideos";
import { useIsMobile } from "../../../../../../hooks/useIsMobile";
import { useIsLgScreen } from "../../../../../../hooks/useIsLgScreen";
import { useEffect, useState } from "react";
import * as ReactDOMServer from "react-dom/server";
import { ShortButtonBackgroundSvg } from "../../../../../../menuHovers";
import { theme } from "../../../../../../theme";
import { useUploadTrackForm } from "../../../../state/useUploadTrackForm";
import { ProjectTrack } from "../../../../api";
import { useMediaRecorder } from "../../../../state/useMediaRecorder";

const StyledButton = styled(Button)<{
  background: string;
  hover: string;
  isMobile: boolean;
}>(({ theme, background, hover, isMobile }) => ({
  backgroundImage: `url("data:image/svg+xml,${background}")`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  width: isMobile ? "90px" : "151px",
  height: isMobile ? "33px" : "41px",
  color: theme.palette.yellow.regular,
  fontWeight: 700,
  fontSize: isMobile ? "12px" : "",
  "&:hover": {
    backgroundImage: `url("data:image/svg+xml,${hover}")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
}));

export const DisplayRecordedVideo: React.FC = () => {
  const mergeVideos = useMergeVideos();
  const isMobile = useIsMobile();
  const isLgScreen = useIsLgScreen();
  const uploadTrackForm = useUploadTrackForm();
  const mediaRecorder = useMediaRecorder();

  const [progress, setProgress] = useState(5);

  const getRandomNumber = () => {
    const min = 1;
    const max = 4;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomNumber;
  };

  const background = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      <ShortButtonBackgroundSvg color={theme.palette.green.disabled} />
    )
  );

  const hover = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      <ShortButtonBackgroundSvg color={theme.palette.green.regular} />
    )
  );

  const handleTryAgain = () => {
    if (mediaRecorder.recordedVideo) {
      setProgress(5);
      mergeVideos.mergeVideos(
        uploadTrackForm.projectTrack === ProjectTrack.FIRST ||
          uploadTrackForm.projectTrack === ProjectTrack.SECOND
          ? undefined
          : uploadTrackForm.deviceVideoUrl,
        URL.createObjectURL(mediaRecorder.recordedVideo),
        uploadTrackForm.projectTrack
      );
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (mergeVideos.isMerged) {
        setProgress(100);
      }
      const newProgress = getRandomNumber();
      setProgress((prevProgress) =>
        prevProgress + newProgress >= 99
          ? prevProgress
          : prevProgress + newProgress
      );
    }, 2000);
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      display="flex"
    >
      {mergeVideos.isMerging ? (
        <>
          <Grid container>
            <Grid item xxs={12}>
              <Typography
                variant="subtitle1"
                sx={(theme) => ({
                  marginTop: "10px",
                  marginBottom: "20px",
                  textAlign: "center",
                  width: "100%",
                  fontFamily: "'Inter', 'sans-serif'",
                  color: theme.palette.purple.regular,
                  lineHeight: "130%",
                  fontWeight: 400,
                })}
              >
                Spajanje videa sa matricom je u toku... <br />
                Molimo Vas, budite strpljivi. <br />
                Hvala!
              </Typography>
              <Stack>
                <LinearProgressWithLabel value={progress} />
              </Stack>
            </Grid>
          </Grid>
        </>
      ) : mergeVideos.isMerged ? (
        <VirtualChoirVideo
          isReady={mergeVideos.isMerged}
          src={"data:video/mp4;base64," + mergeVideos.base64Video}
          enableControls
          sx={{
            maxWidth: "100%",
            maxHeight: isLgScreen ? "420px" : isMobile ? "100%" : "480px",
          }}
        />
      ) : (
        <Grid container>
          <Grid item xxs={12}>
            <Typography
              variant="subtitle1"
              sx={(theme) => ({
                marginTop: "10px",
                marginBottom: "20px",
                textAlign: "center",
                width: "100%",
                fontFamily: "'Inter', 'sans-serif'",
                color: theme.palette.purple.regular,
                lineHeight: "130%",
                fontWeight: 400,
              })}
            >
              Spajanje videa sa matricom nije uspelo! <br />
              Molimo Vas, proverite svoju internet konekciju i pokušajte opet.{" "}
              <br />
              Hvala!
            </Typography>
            <Box
              display="flex"
              justifyContent="center"
              alignContent="center"
              alignItems="center"
            >
              <StyledButton
                onClick={handleTryAgain}
                background={background}
                hover={hover}
                isMobile={isMobile}
              >
                PROBAJ PONOVO
              </StyledButton>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  const isMobile = useIsMobile();
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} color="secondary" />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant={isMobile ? "h6" : "body1"}
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}
